import React from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UserAddFlow from '../admin/UserAddFlow';
import UserNotificationFlow from '../admin/UserNotificationFlow';
import DatatableSearch from './DatatableSearch';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const DatatableToolbar = ({
  title,
  titleIcon,
  numSelected,
  handleRowAdd,
  searchButton,
  searched,
  requestSearch,
  users,
  userAddButton,
  notificationButton,
  handleNotificationSend,
  setIserror,
  isError,
  errorMessages,
  isNotificationSuccessful,
  businessUnitAddButton,
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        id="tableTitle"
        component="div"
        className="data-table__title"
      >
        {titleIcon && titleIcon}
        {title && <span>{title}</span>}
      </Typography>
      {searchButton && (
        <DatatableSearch searched={searched} requestSearch={requestSearch} />
      )}
      {userAddButton && <UserAddFlow handleRowAdd={handleRowAdd} />}
      {notificationButton && (
        <UserNotificationFlow
          handleNotificationSend={handleNotificationSend}
          isMultipleUserSelection={true}
          users={users}
          isNotificationSuccessful={isNotificationSuccessful}
          isError={isError}
          setIsError={setIserror}
          errorMessages={errorMessages}
        />
      )}
      {businessUnitAddButton && (
        <Button
          component={Link}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          to="new"
        >
          Add
        </Button>
      )}
    </Toolbar>
  );
};

export default DatatableToolbar;
