import _ from 'lodash';
import {
  useLocation,
  useNavigate as reactUseNavigate,
  useSearchParams as reactSearchParams,
} from 'react-router-dom';

// convenient overrides to preserve location state by default

export function useNavigate() {
  const location = useLocation();
  const navigate = reactUseNavigate();

  return function (to, options) {
    navigate(to, _.merge({ state: location.state }, options || {}));
  };
}

export function useSearchParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = reactSearchParams();

  return [
    searchParams,
    function (nextInit, options) {
      setSearchParams(
        nextInit,
        _.merge({ state: location.state }, options || {})
      );
    },
  ];
}

const Navigation = {
  useNavigate,
  useSearchParams,
};

export default Navigation;
