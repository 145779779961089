import { Close } from '@mui/icons-material';

const {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} = require('@mui/material');

export default function LaunchWorkflowDialog(props) {
  const { onClose, openParams } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      className="audits-workflow-popup"
      onClose={handleClose}
      open={openParams.open}
      maxWidth={'100%'}
    >
      <DialogTitle
        style={{
          fontWeight: 'bold',
          margin: '0 10px 0 10px',
          textAlign: 'center',
          borderBottom: 'thin solid black',
        }}
      >
        Launch workflow: {openParams.item?.repositoryName}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="required">
          <strong>Required: </strong>Access to the github repository "
          {openParams.item?.repositoryName}"
        </Box>
        <List>
          <ListItem>
            <ListItemText>
              Navigate to the{' '}
              <a
                className="github"
                rel="noreferrer"
                target={'_blank'}
                href={
                  `https://github.com/OSFDigital/${openParams.item?.repositoryName}` +
                  '/actions/workflows/sync-sfmc.yml'
                }
              >
                workflow
              </a>{' '}
              location in Github.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Locate and expand the "Run workflow" dropdown.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Click "Run workflow".</ListItemText>
          </ListItem>
        </List>
        <Box className="explain">
          Once the worklow has successfully run, the last sync date should be
          updated on the Audits page.
        </Box>
        <Box className="screenshot">
          <a
            rel="noreferrer"
            target={'_blank'}
            href={'/images/github/launch-workflow-01.png'}
          >
            <img
              style={{ maxWidth: '800px' }}
              src={'/images/github/launch-workflow-01.png'}
              alt={'How to launch a workflow - screenshot from Github.'}
            />
          </a>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
