import { Button, Popover } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { forwardRef, useImperativeHandle, useState } from 'react';

const CustomPopover = forwardRef(
  (
    {
      component,
      buttonComponent,
      buttonRef,
      buttonText,
      buttonColor,
      buttonDisabled,
      buttonVariant,
      buttonSize,
      anchorOrigin,
      transformOrigin,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverId = useId();

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? popoverId : undefined;

    if (buttonRef?.current) {
      buttonRef.current.onclick = handleClick;
    }

    // useImperativeHandle(ref, () => ({
    //   onButtonClick(event) {
    //     handleClick(event);
    //   },
    // }));

    // if (buttonComponent) {
    //   buttonComponent.onClick = handleClick;
    // }

    useImperativeHandle(ref, () => ({
      close() {
        handleClose();
      },
    }));

    return (
      <div>
        {buttonComponent || (
          <Button
            className="popover-button"
            disabled={buttonDisabled}
            aria-describedby={id}
            size={buttonSize || ''}
            variant={buttonVariant || 'contained'}
            color={buttonColor || 'primary'}
            onClick={handleClick}
          >
            {buttonText || 'Show content'}
          </Button>
        )}
        <Popover
          className="custom-popover"
          BackdropProps={{}}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: anchorOrigin?.vertical || 'bottom',
            horizontal: anchorOrigin?.horizontal || 'left',
          }}
          transformOrigin={{
            vertical: transformOrigin?.vertical || 'top',
            horizontal: transformOrigin?.horizontal || 'left',
          }}
        >
          <>{component}</>
        </Popover>
      </div>
    );
  }
);

export default CustomPopover;
