import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemAvatar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Business as BusinessIcon } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import React, { useContext, useState } from 'react';
import { Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import './userListManager.scss';
import { DarkModeContext } from '../../context/darkModeContext';

import { checkPermissions } from '../../service/adminApiService/adminApiService';

const UserListManager = ({ smallIcon, businessUnit }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { darkMode } = useContext(DarkModeContext);

  const handleClickOpenDialog = (e) => {
    e.stopPropagation();
    setOpenDialog(true);
  };

  const handleCloseDialog = (e) => {
    e.stopPropagation();
    setOpenDialog(false);
  };

  return (
    <Box className="user-list-manager" onClick={(e) => e.stopPropagation()}>
      <Tooltip
        title="User List"
        placement="top"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          className="icon-copy"
          color="primary"
          size={smallIcon ? 'small' : 'medium'}
          aria-label="User List"
          onClick={handleClickOpenDialog}
          style={smallIcon ? { transform: 'scale(.8)' } : {}}
        >
          <PeopleIcon />
        </IconButton>
      </Tooltip>
      <div>
        <Dialog
          id="userListManager"
          className={darkMode ? 'dark' : ''}
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle>
            <Typography
              sx={{ flex: '1 1 100%' }}
              id="tableTitle"
              component="div"
              className="data-table__title"
            >
              <PeopleAltIcon className="icon" />
              <span>User List</span>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ marginBottom: '1rem' }}>
            <Box className="business-unit__details">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemAvatar>
                  <Avatar className="bu-avatar">
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <span>{businessUnit.displayName || businessUnit.name}</span>
              </Box>
              <Box>
                <span>{String(businessUnit.mid)}</span>
              </Box>
            </Box>
            {businessUnit?.users && businessUnit?.users.length > 0 ? (
              <Paper
                sx={{
                  width: '100%',
                  padding: '1rem',
                  background: 'inherit',
                }}
              >
                <TableContainer sx={{ borderRadius: '12px' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>View users</TableCell>
                        <TableCell>Audits</TableCell>
                        <TableCell>SFMC</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {businessUnit.users.map((user) => (
                        <React.Fragment key={`bu-${user.id}`}>
                          <TableRow>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>
                              {checkPermissions(user, 'admin', {
                                businessUnit,
                              }) ? (
                                <Check fontSize="small" color="success" />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell>
                              {checkPermissions(
                                user,
                                ['businessUnitUsersView'],
                                { businessUnit }
                              ) ? (
                                <Check fontSize="small" color="success" />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell>
                              {checkPermissions(user, 'audits', {
                                businessUnit,
                              }) ? (
                                <Check fontSize="small" color="success" />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell>
                              {checkPermissions(user, ['sfmc'], {
                                businessUnit,
                              }) ? (
                                <Check fontSize="small" color="success" />
                              ) : (
                                ''
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              <Box sx={{ padding: '1rem' }}>There are no users.</Box>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default UserListManager;
