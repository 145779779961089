import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as auditService from '../../lib/auditsService';
import { Alert, Box, CircularProgress } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import BusinessUnitDetails from '../BusinessUnitDetails';
import _ from 'lodash';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ExtensionIcon from '@mui/icons-material/Extension';
import Util from '../../lib/util';
import DEDependencyGraph from './components/DEDependencyGraph';
import '../Audits.scss';

export default function DEDependencyAudit({ user } = {}) {
  const params = useParams();
  const location = useLocation();
  const isMounted = useMountedState();
  const [mid] = useState({ timestamp: new Date(), value: params.mid });
  const [businessUnit, setBusinessUnit] = useState(null);
  const [audits, setAudits] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingAudits, setIsLoadingAudits] = useState(false);

  const selection = useMemo(() => {
    return {
      dataExtension: params.dataextension,
      query: params.query,
    };
  }, [params.dataextension, params.query]);

  const onBusinessUnitChange = useCallback(
    (bu) => {
      if (isMounted()) {
        setBusinessUnit(bu);
      }
    },
    [isMounted]
  );

  async function fetchBusinessUnits(mid, limit, offset) {
    return await auditService.getDataExtensionUsage(mid, {
      limit: limit,
      offset: offset,
      type: 'query',
      includeFields: [
        'dataExtension_name',
        'query_targetName',
        'query_targetCustomerKey',
        'source_name',
      ].join(','),
    });
  }

  useEffect(() => {
    loadBusinessUnits();

    async function loadBusinessUnits() {
      if (!businessUnit?.id) return;

      setIsLoadingAudits(true);

      try {
        let tempAudits = [];
        let currentPage = 1;
        let totalPages = 1;
        const PAGE_SIZE = 10000;

        while (currentPage <= totalPages) {
          const res = await fetchBusinessUnits(
            businessUnit.mid,
            PAGE_SIZE,
            PAGE_SIZE * (currentPage - 1)
          );

          if (res.status !== 200) {
            throw new Error(res.status);
          }

          if (currentPage === 1) {
            totalPages = Math.ceil(res.data.pagination.total / PAGE_SIZE);
          }

          tempAudits = [...tempAudits, ...res.data.usage];
          currentPage++;
        }

        setAudits(tempAudits);
      } catch (error) {
        setErrorMessage(
          Util.getErrorMessageFromStatusCode(parseInt(error.message))
        );
        setAudits(null);
      } finally {
        setIsLoadingAudits(false);
      }
    }
  }, [businessUnit, isMounted]);

  const dataExtensionsNames = useMemo(
    function getDeNames() {
      if (!audits) return [];

      let auditsDeNames = audits
        .filter((audit) => audit.dataExtension_name && audit?.query_targetName)
        .flatMap((audit) => [audit.dataExtension_name, audit.query_targetName]);

      auditsDeNames = _.sortBy(auditsDeNames, (name) => name.toLowerCase());
      auditsDeNames = _.uniqBy(auditsDeNames, (name) => name.toLowerCase());

      return auditsDeNames;
    },
    [audits]
  );

  const queriesNames = useMemo(
    function getQueryNames() {
      if (!audits) return [];

      let auditsQueryNames = audits
        .filter((audit) => audit.dataExtension_name)
        .map((audit) => audit.source_name);

      auditsQueryNames = _.sortBy(auditsQueryNames, (name) =>
        name.toLowerCase()
      );
      auditsQueryNames = _.uniqBy(auditsQueryNames, (name) =>
        name.toLowerCase()
      );

      return auditsQueryNames;
    },
    [audits]
  );

  const backUrlFull = useMemo(
    () =>
      Util.resolveBackUrl({
        navigationState: location.state,
        fallback: document.referrer,
        fallbackHome: true,
      }),
    [location.state]
  );
  const backUrlParts = backUrlFull.split('/');
  const backUrl = backUrlParts[backUrlParts.length - 1];

  function doShowDependencyGraph() {
    return !!audits && !isLoadingAudits;
  }

  return (
    <>
      <div className="audits de-dependencies">
        <Box className={'backButtonBox' + (!backUrlFull ? ' invalid' : '')}>
          <Link to={`/${backUrl}`}>
            <ArrowCircleLeftIcon />
            <span>BACK</span>
          </Link>
        </Box>
        <div className="audits-header">
          <h2 className="audits-title">
            <ExtensionIcon className="audits-title__icon" />
            <span>Data extension dependencies</span>
          </h2>
        </div>

        <div className="de-dependency__container">
          <BusinessUnitDetails
            mid={mid?.value}
            onLoad={onBusinessUnitChange}
            user={user}
            showOnlyHeader={true}
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          {isLoadingAudits && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress className="main-color" />
            </div>
          )}

          {doShowDependencyGraph() && (
            <DEDependencyGraph
              audits={audits}
              dependencySelection={selection}
              dataExtensionsNames={dataExtensionsNames}
              queriesNames={queriesNames}
              mid={mid}
            />
          )}
        </div>
      </div>
    </>
  );
}

const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
