import React, { useEffect, useRef, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { updateUserData } from '../../service/adminApiService/adminApiService';
import Groups from '../adminDetailPanel/Groups';
import BusinessUnits from '../adminDetailPanel/BusinessUnits';
import Flags from '../adminDetailPanel/Flags';
import Scopes from '../adminDetailPanel/Scopes';
import SaveIcon from '@mui/icons-material/Save';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import UserDeleteFlow from '../admin/UserDeleteFlow';
import UserCopyFlow from '../admin/UserCopyFlow';
import _ from 'lodash';

function getStyles(account, userAccounts, theme) {
  return {
    fontWeight: userAccounts.indexOf(String(account)) === -1 ? 400 : 600,
    padding: '.5rem',
    display: 'block',
    borderBottom: '1px solid #f5f5f5',
  };
}

const DetailPanel = ({
  rowData,
  accounts,
  permissions,
  loadUsersData,
  handleRowAdd,
  handleRowDelete,
  setErrorMessages,
  setIserror,
}) => {
  let permissionsSchema = {
    groups: [],
    scopes: {},
    flags: [],
  };

  let userData = {
    permissions: permissionsSchema,
    ...rowData,
    availableAccounts: accounts,
    availableScopes: _.sortBy(permissions.scopes, (s) => s),
    availableGroups: _.sortBy(permissions.groups, (g) => g),
    availableFlags: _.sortBy(permissions.flags, (f) => f),
  };
  const theme = useTheme();
  // To prevent multiple api calls
  const allowUseEffectRun = useRef(true);
  const [userState, setUserState] = useState(userData);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const permissionsSaveHandler = () => {
    updateUser(userState.username, userState.permissions);
    loadUsersData();
  };

  async function updateUser(username, permissions) {
    try {
      await updateUserData({ username, permissions });
      setMessage('User is successfully saved.');
      setTimeout(() => setMessage(''), 3000);
      setErrorMessages([]);
      setError(false);
      setIserror(false);
    } catch (error) {
      const errorRes = error.response.data;
      setMessage(`User could not be saved.`);
      setTimeout(() => setMessage(''), 3000);
      setErrorMessages([errorRes.message]);
      setError(true);
      setIserror(true);
    }
  }

  useEffect(() => {
    if (allowUseEffectRun.current) {
      allowUseEffectRun.current = false;
      loadUsersData();
    }
  }, [loadUsersData]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        padding: '5rem 1rem 2rem 1rem',
        position: 'relative',
      }}
      className="detail-panel"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <Typography
          component="h4"
          variant="h6"
          className="detail-panel__username"
        >
          <AccountCircleIcon className="icon" />
          <Box sx={{ lineHeight: '1' }}>{rowData.username}</Box>
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          <Groups
            userState={userState}
            setUserState={setUserState}
            theme={theme}
            getStyles={getStyles}
          />
          <BusinessUnits
            userState={userState}
            setUserState={setUserState}
            theme={theme}
            getStyles={getStyles}
          />

          <Flags
            userState={userState}
            setUserState={setUserState}
            theme={theme}
            getStyles={getStyles}
          />
        </Box>
        <Scopes
          userState={userState}
          setUserState={setUserState}
          theme={theme}
          getStyles={getStyles}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={permissionsSaveHandler}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          {message && error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {message}
            </Alert>
          )}
          {message && !error && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {message}
            </Alert>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              display: 'flex',
              gap: '.3rem',
            }}
          >
            <UserCopyFlow userState={userState} handleRowAdd={handleRowAdd} />
            {/* <UserUpdateFlow
              rowData={rowData}
              updateUser={updateUser}
              loadUsersData={loadUsersData}
            /> */}
            <UserDeleteFlow
              rowData={rowData}
              handleRowDelete={handleRowDelete}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailPanel;
