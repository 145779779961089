import { useState, useRef, useCallback } from 'react';
import {
  Typography,
  Box,
  Grid,
  Button,
  Stack,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import ClipboardCopy from '../../components/ui/ClipboardCopy';
import CodeIcon from '@mui/icons-material/Code';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import './AmpscriptEditor.scss';
import Card from '../../components/ui/Card';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import { checkPermissions } from '../../service/adminApiService/adminApiService';

function AmpscriptEditor({ user } = {}) {
  const [scriptRaw, setScriptRaw] = useState(
    `VAR @text\nSET @text = "Hello, world!"\nOutput(v(@text))`
  );
  const [scriptEvaluated, setScriptEvaluated] = useState(undefined);
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [permissionsError, setPermissionsError] = useState(false);
  const [openClear, setOpenClear] = useState(false);
  const clipboard = useRef();

  function closeClear() {
    setOpenClear(false);
  }

  const showAmpscriptEditor = () =>
    checkPermissions(user, ['ampscriptEditor', 'admin']);

  const isPreviewAvailable = useCallback(() => {
    return scriptEvaluated && !isLoadingEvaluation;
  }, [scriptEvaluated, isLoadingEvaluation]);

  async function evaluateScript() {
    setIsLoadingEvaluation(true);
    setEvaluationError(false);
    setPermissionsError(false);
    try {
      const response = await fetch('/api/evaluate-ampscript', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          script: scriptRaw,
        }),
      });

      if (response.status === 403) {
        throw new Error('Insufficient permissions.');
      } else if (response.status !== 200) {
        throw new Error('Error evaluating script');
      }

      const data = await response.json();

      setScriptEvaluated(data.evaluatedScript);
    } catch (error) {
      if (error.message === 'Insufficient permissions.') {
        setPermissionsError(true);
      }
      setEvaluationError(true);
      setScriptEvaluated('');
      setScriptEvaluated(undefined);
    }
    setIsLoadingEvaluation(false);
  }

  return showAmpscriptEditor() ? (
    <>
      <ClipboardCopy ref={clipboard} flashPopperClassName={'clipboard'} />
      <ConfirmDialog
        dialogProps={{ style: { top: '-50%' } }}
        dialogTitleComponents={<></>}
        dialogContentComponents={<strong>Clear the editor?</strong>}
        closeOnBackdropClick={false}
        open={openClear}
        okText="Clear"
        cancelText="Cancel"
        onCancel={closeClear}
        onOk={() => {
          setScriptRaw('');
          setScriptEvaluated(undefined);
          setOpenClear(false);
        }}
      />
      <Card style={{ width: '100%', padding: '2rem' }}>
        <Box sx={{ mb: 3 }}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            component="div"
            className="data-table__title"
          >
            <CodeIcon className="icon" />
            <span>Ampscript Editor</span>
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={2}>
              {!isLoadingEvaluation && (
                <Button
                  endIcon={<PlayArrowIcon />}
                  variant="contained"
                  onClick={() => evaluateScript()}
                  size="small"
                  disabled={!scriptRaw || scriptRaw === ''}
                >
                  Run
                </Button>
              )}

              {isLoadingEvaluation && (
                <Button variant="contained" size="small" disabled={true}>
                  Run{' '}
                  <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />
                </Button>
              )}

              <Button
                onClick={(evt) => {
                  setOpenClear(true);
                }}
                size="small"
                color="error"
              >
                Clear
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <h4>Editor</h4>
              <Tooltip
                title="Copy the script to the clipboard"
                placement="right"
              >
                <IconButton
                  onClick={(evt) => {
                    clipboard.current.copy(evt, scriptRaw);
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <h4>Preview</h4>
              <Tooltip title="Copy Preview" placement="right">
                <span>
                  <IconButton
                    disabled={!isPreviewAvailable()}
                    onClick={(evt) => {
                      clipboard.current.copy(evt, scriptEvaluated);
                    }}
                  >
                    <ContentCopy />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <div className="ampscript-editor__editor">
              <textarea
                rows="20"
                cols="50"
                wrap="off"
                spellCheck={false}
                value={scriptRaw}
                onChange={(e) => setScriptRaw(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              className={`ampscript-editor__preview ${
                isLoadingEvaluation ? 'loading' : ''
              }`}
            >
              {isLoadingEvaluation && (
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                  }}
                  color="inherit"
                />
              )}
              {!evaluationError && <pre>{scriptEvaluated}</pre>}
              {evaluationError && (
                <Alert severity="error">
                  {permissionsError ? (
                    <div>Insufficient permissions.</div>
                  ) : (
                    <>
                      <div>An error occurred while evaluating the script.</div>
                      <div>Please, verify your code and try again.</div>
                    </>
                  )}
                </Alert>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  ) : (
    <Alert severity="error">Insufficient permissions.</Alert>
  );
}

export default AmpscriptEditor;
