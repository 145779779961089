import {
  Alert,
  Autocomplete,
  Box,
  Grid,
  styled,
  TextField,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from '../../../state/Navigation';
import _ from 'lodash';
import renderGraphvizGraph from '../helpers/GraphvizRenderHelper';
import scssVariables from '../../../styles/variables.scss';
import DEDependencyGraphLegend from './DEDependencyGraphLegend';
import DEDependencyGraphInfoModal from './DEDependencyGraphInfoModal';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
  },
}));

export default function DEDependencyGraph({
  audits,
  dependencySelection,
  dataExtensionsNames,
  queriesNames,
  mid,
}) {
  const navigate = useNavigate();
  const graphRef = useRef();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const selectedAudits = useMemo(
    function getSelectedAudits() {
      if (
        _.isEmpty(audits) ||
        (!dependencySelection.dataExtension && !dependencySelection.query)
      )
        return [];

      if (dependencySelection.query) {
        return audits.filter(function doSelectedQueryMatches(audit) {
          return dependencySelection.query === audit.source_name;
        });
      }

      if (dependencySelection.dataExtension) {
        return audits.filter(function isSelectedDeTargetOrSource(audit) {
          return (
            dependencySelection.dataExtension === audit.dataExtension_name ||
            dependencySelection.dataExtension === audit.query_targetName
          );
        });
      }
    },
    [dependencySelection.query, dependencySelection.dataExtension, audits]
  );

  useEffect(() => {
    if (_.isEmpty(selectedAudits)) return;

    const rectangles = getSourceOrTargetDeNames(selectedAudits);
    const cylinders = getSelectedQueries(selectedAudits);
    const links = buildLinks(selectedAudits);

    renderGraph(rectangles, cylinders, links);

    function getSourceOrTargetDeNames(selectedAudits) {
      return _.uniqBy(
        selectedAudits.flatMap((audit) => {
          const names = [audit.dataExtension_name];

          if (
            dependencySelection.query ||
            dependencySelection.dataExtension === audit.query_targetName
          ) {
            names.push(audit.query_targetName);
          }

          return names;
        }),
        (name) => name.toLowerCase()
      );
    }

    function getSelectedQueries(selectedAudits) {
      return _.uniqBy(
        selectedAudits.map((audit) => audit.source_name),
        (name) => name.toLowerCase()
      );
    }

    function buildLinks(selectedAudits) {
      return _.uniqBy(
        selectedAudits.flatMap((audit) => {
          const links = [
            `"${audit.dataExtension_name}" -> "query ${audit.source_name}" [color=green];`,
          ];

          if (
            dependencySelection.query ||
            dependencySelection.dataExtension === audit.query_targetName
          ) {
            const queryName = audit.source_name;
            const invalidTargetString = audit.query_targetName
              ? ''
              : 'invalid ';
            const targetName =
              audit.query_targetName || audit.query_targetCustomerKey;

            links.push(
              `"query ${queryName}" -> "${invalidTargetString}${targetName}" [color=blue];`
            );
          }

          return links;
        }),
        (link) => link
      );
    }

    function renderGraph(rectangles, cylinders, links) {
      const graphvizDotString = buildGraphvizDotString(
        rectangles,
        cylinders,
        links
      );

      renderGraphvizGraph(graphRef.current, graphvizDotString, {
        onNodeClick: (event) => {
          const g = event.target.closest('g.node');

          if (!g) return;

          if (isDataExtension(g)) {
            const clickedDeName = g.id.substr(3);
            if (clickedDeName !== dependencySelection.dataExtension) {
              navigate(`/audits/de-dependency/${mid.value}/e/${clickedDeName}`);
            }
          } else if (isQuery(g)) {
            const clickedQueryName = g.id.substr(3);
            if (clickedQueryName !== dependencySelection.query) {
              navigate(
                `/audits/de-dependency/${mid.value}/q/${clickedQueryName}`
              );
            }
          }

          event.preventDefault();

          function isDataExtension(node) {
            return node.id.startsWith('$d$');
          }

          function isQuery(node) {
            return node.id.startsWith('$q$');
          }
        },
        setNodeClass: (node) => {
          const ancestorKey = node.parent.parent.parent.key;
          if (
            ancestorKey === dependencySelection.dataExtension ||
            ancestorKey === `query ${dependencySelection.query}`
          ) {
            return 'selected';
          }
        },
      });

      function buildGraphvizDotString(rectangles, cylinders, links) {
        return `digraph {
            {
                node [shape=rectangle]
                ${rectangles
                  .map(
                    (item) =>
                      `"${item}" [id="$d$${item}",href="/audits/de-dependency/${mid.value}/e/${item}"];`
                  )
                  .join('\n        ')}
            }
            {
                node [shape=cylinder]
                ${cylinders
                  .map(
                    (item) =>
                      `"query ${item}" [id="$q$${item}",label="${item}",href="/audits/de-dependency/${mid.value}/q/${item}"];`
                  )
                  .join('\n        ')}
            }
            ${links.join('\n    ')}
        }`;
      }
    }
  }, [dependencySelection, navigate, mid.value, selectedAudits]);

  function doShowEmptyAuditsErrorMessage() {
    return (
      _.isEmpty(audits) ||
      (_.isEmpty(selectedAudits) &&
        (dependencySelection.dataExtension || dependencySelection.query))
    );
  }

  function doShowDependencyGraph() {
    return !doShowEmptyAuditsErrorMessage();
  }

  return (
    <Box className="de-dependency">
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          style={{
            alignItems: 'center',
            display: 'flex',
            fontSize: 'larger',
            color: 'rgba(0,0,0) !important',
          }}
          component={'span'}
        >
          Browse
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={dataExtensionsNames}
            value={dependencySelection.dataExtension || ''}
            onChange={(event, option) => {
              navigate(`/audits/de-dependency/${mid.value}/e/${option}`);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <CssTextField
                {...params}
                focusColor={scssVariables.colorPrimary}
                label="Data extensions"
              />
            )}
            disableClearable
          />
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            id="combo-box-demo"
            options={queriesNames}
            value={dependencySelection.query || ''}
            onChange={(event, option) => {
              navigate(`/audits/de-dependency/${mid.value}/q/${option}`);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <CssTextField
                {...params}
                focusColor={scssVariables.colorPrimary}
                label="Queries"
              />
            )}
            disableClearable
          />
        </Grid>
        <Grid item>
          <IconButton color="primary" onClick={() => setIsInfoModalOpen(true)}>
            <InfoIcon />
          </IconButton>
          <DEDependencyGraphInfoModal
            open={isInfoModalOpen}
            onClose={() => setIsInfoModalOpen(false)}
          />
        </Grid>
      </Grid>
      {doShowEmptyAuditsErrorMessage() && (
        <Alert severity="warning">
          There is no data for this business unit.
        </Alert>
      )}
      {doShowDependencyGraph() ? (
        <>
          <DEDependencyGraphLegend />
          <div ref={graphRef}></div>
        </>
      ) : (
        ''
      )}
    </Box>
  );
}
