/**
 * @param {Object} package sfmc package
 * @param {string} package.id internal id
 * @param {string} package.name main BU name
 * @returns {Promise<Response>}
 */
export async function deleteSfmcAccount({ id, name }) {
  return await fetch(`/api/wsfmc/admin/account/${id}`, {
    method: 'DELETE',
    body: '{}',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}
