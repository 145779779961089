import { Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Card from '../../components/ui/Card';
import ErrorHandler from '../../components/ui/ErrorHandler';
import {
  checkPermissions,
  getAccountsData,
  getPermissionsData,
  getUsersData,
} from '../../service/adminApiService/adminApiService';
import AdminDatatable from '../../components/datatable/AdminDatatable';

const UserAdmin = ({ user }) => {
  // To prevent multiple api calls
  const allowUseEffectRun = useRef(true);
  const [usersData, setUsersData] = useState({});
  const [permissionsData, setPermissionsData] = useState({});
  const [accountsData, setAccountsData] = useState({});

  let navigate = useNavigate();

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  async function loadUsersData() {
    try {
      const apiRes = await getUsersData({});
      setUsersData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setUsersData(errorRes);
    }
  }

  async function loadPermissionsData() {
    try {
      const apiRes = await getPermissionsData({});
      setPermissionsData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setPermissionsData(errorRes);
    }
  }

  async function loadAccountsData() {
    try {
      const apiRes = await getAccountsData({});
      if (apiRes.businessUnits) {
        const uniqueMids = [];
        const byMid = _.groupBy(apiRes.businessUnits, 'mid');
        for (const mid of Object.keys(byMid)) {
          uniqueMids.push(_.sortBy(byMid[mid], (bu) => (bu.active ? 0 : 1))[0]);
        }
        const accountsData = {
          ...apiRes,
          businessUnits: _.sortBy(uniqueMids, (bu) =>
            (bu.displayName || bu.name)?.toLowerCase()
          ),
        };
        setAccountsData(accountsData);
      }
    } catch (error) {
      const errorRes = { error: error };
      setAccountsData(errorRes);
    }
  }

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }
    if (allowUseEffectRun.current) {
      allowUseEffectRun.current = false;

      loadAccountsData().then(() => {
        loadUsersData();
        loadPermissionsData();
      });
    }
  });

  return (
    <>
      {usersData.users &&
      accountsData.businessUnits &&
      !permissionsData.error ? (
        <>
          <AdminDatatable
            users={usersData.users}
            accounts={accountsData.businessUnits}
            permissions={permissionsData}
            loadUsersData={loadUsersData}
          />
        </>
      ) : (
        !usersData.error &&
        !accountsData.error &&
        !permissionsData.error && (
          <Skeleton
            variant="rectangular"
            height={'100%'}
            style={{
              flex: '1 1',
              width: '100%',
              borderRadius: '0.8rem',
              minHeight: '641px',
            }}
          />
        )
      )}
      {(usersData.error || accountsData.error || permissionsData.error) && (
        <Card style={{ height: '641px', width: '100%' }}>
          <ErrorHandler
            message={
              usersData.error
                ? usersData.error.message
                : accountsData.error
                ? accountsData.error.message
                : permissionsData.error
                ? permissionsData.error.message
                : ''
            }
          />
        </Card>
      )}
    </>
  );
};

export default UserAdmin;
