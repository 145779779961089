import xmlFormatter from 'xml-formatter';

/**
 * Util class in webapp
 */
class Util {
  /**
   * Resolve back url
   * @param {object} options
   * @param {object} [options.navigationState]
   * @param {string} [options.fallback]
   * @param {boolean} [options.fallbackHome]
   * @returns {string|undefined}
   */
  static resolveBackUrl({ navigationState, fallback, fallbackHome } = {}) {
    let result = navigationState?.referrer || fallback;

    const host = result?.match(/https{0,1}:[/][/]([^/]+)/)?.[1];

    if (host && host !== window.location.host) {
      // external URL not allowed.
      result = undefined;
    }

    if (!result && fallbackHome) {
      result = '/';
    }

    return result;
  }

  /**
   * Full DOM path of an element
   * @param {HTMLElement} domElement
   * @returns {Array<HTMLElement>}
   */
  static composedDOMPath(domElement) {
    const path = domElement ? [domElement] : [];

    while (domElement?.parentNode) {
      path.push(domElement.parentNode);
      domElement = domElement.parentNode;
    }

    return path;
  }

  /**
   * Format a number with commas
   * @param {number} x
   * @returns {string}
   */
  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Deeply freezes an object and all of its properties.
   *
   * @param {object} obj1 - The object to freeze
   * @returns {object} - The frozen object
   */
  static deepFreeze(obj1) {
    if (!obj1) {
      return obj1;
    }
    Reflect.ownKeys(obj1).forEach((property) => {
      if (
        typeof obj1[property] === 'object' &&
        !Object.isFrozen(obj1[property])
      )
        Util.deepFreeze(obj1[property]);
    });
    return Object.freeze(obj1);
  }

  /**
   * Format XML with indentation
   * @param {string} sourceXml
   * @returns {string}
   */
  static formatXml(sourceXml) {
    return sourceXml ? xmlFormatter(sourceXml, { indentation: ' ' }) : '';
  }

  static formatDate = (input) => {
    return input
      .replace(/-/g, '/')
      .replace('T', ' ')
      .replace(/:\d{2}\.\d{3}Z$/, ' UTC');
  };

  /**
   * Creates a string telling he time since the given date
   * @param {string|number|Date} time
   * @returns {string}
   */
  static formatDateAsTimeSince(time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }

    const time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];

    let seconds = (+new Date() - time) / 1000;
    let token = 'ago';
    let list_choice = 1;

    if (seconds === 0) {
      return 'Just now';
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }

    let i = 0;
    let format;

    while ((format = time_formats[i++])) {
      if (seconds >= format[0]) {
        continue;
      }

      if (typeof format[2] == 'string') {
        return format[list_choice];
      }

      return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
    return time;
  }

  /**
   * Get the error message from the status code
   * @param {number} statusCode - The status code
   * @returns {string} The error message
   */
  static getErrorMessageFromStatusCode(statusCode) {
    if (statusCode.message === 401 || statusCode.message === 403) {
      return 'Insufficient permissions';
    }

    if (statusCode.message === 404) return 'Not found';

    return 'An error occurred. If the problem persist, please contact support.';
  }

  /**
   * Failure-safe json parsing
   * @oaram {string} input - The string to parse
   * @param {any} fallback - The fallback value if the parsing fails. May be a function that takes the input as an argument
   * @returns {any} The parsed value or the fallback value
   */
  static tryParseJSON(input, fallback) {
    try {
      return JSON.parse(input);
    } catch {
      if (typeof fallback === 'function') {
        return fallback(input);
      } else if (fallback) {
        return fallback;
      }
    }
  }

  /**
   * Converts to csv row
   * @param {Array.<string|Number|Boolean>} row
   * @param {string} delimiter
   * @returns {string} csv row
   */
  static toCsv(row, delimiter = ',') {
    delimiter = delimiter || ',';
    const mustQuoteRegex = new RegExp(
      '[\r\n"' + Util.escapeRegex(delimiter) + ']'
    );
    const csvRow = row.map((cell) =>
      cell && mustQuoteRegex.test(cell)
        ? `"${cell.replace(/"/g, '""')}"`
        : typeof cell !== 'undefined' && cell !== null
        ? cell
        : ''
    );

    return csvRow.join(delimiter);
  }

  static escapeRegex(input) {
    return input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  static getEnvVariable(name) {
    try {
      const value = process.env[name];
      return value;
    } catch {
      return undefined;
    }
  }

  static generateRandomHexString(maxIntValue) {
    return Math.floor(
      Math.random() * (maxIntValue || Number.MAX_SAFE_INTEGER)
    ).toString(16);
  }
}

export default Util;
