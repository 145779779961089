import { useState, useEffect } from 'react';
import { getWsfmcConfigurations } from '../../service/adminApiService/adminApiService';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import './advancedMaintenanceModePopup.scss';
import { useLocation } from 'react-router-dom';

export default function AdvancedMaintenanceModePopup() {
  const [isMaintenanteOn, setIsMaintenanceOn] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowPopup(true);
    const getMaintenanceStatus = async () => {
      try {
        const data = await getWsfmcConfigurations('ADVANCED_MAINTENANCE_MODE');
        setIsMaintenanceOn(
          data.properties.ADVANCED_MAINTENANCE_MODE.value === true
        );
      } catch (error) {
        setIsMaintenanceOn(false);
      }
    };

    getMaintenanceStatus();
  }, [location.pathname]);

  return isMaintenanteOn && showPopup ? (
    <div className="advm-popup">
      <Alert severity="error" onClose={() => setShowPopup(false)}>
        <AlertTitle>Advanced Maintenance Mode is ON!</AlertTitle>
        The end users <b>ARE NOT ABLE</b> to access the application.
      </Alert>
    </div>
  ) : (
    ''
  );
}
