import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import LayersIcon from '@mui/icons-material/Layers';
import BusinessIcon from '@mui/icons-material/Business';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Scopes = ({ userState, setUserState, theme, getStyles }) => {
  const handleScopeChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const obj = {};
    obj[name] = value;
    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        scopes: {
          ...userState.permissions.scopes,
          ...obj,
        },
      },
    });
  };

  const isEmptyObj = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleScopeChipDelete = (value, account) => (event) => {
    const obj = {};
    obj[account] = userState.permissions.scopes[account].filter(
      (account) => account !== value
    );
    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        scopes: {
          ...userState.permissions.scopes,
          ...obj,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {!isEmptyObj(userState.permissions.scopes) && (
        <Typography
          component="h4"
          variant="h6"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.1rem',
            fontWeight: '600',
          }}
        >
          <LayersIcon className="icon" />
          <Box sx={{ lineHeight: '1' }}>Scopes for Business Units</Box>
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        {Object.entries(userState.permissions.scopes).map((account, index) => {
          const businessUnit = userState.availableAccounts.find(
            (acc) => acc.mid === Number(account[0])
          );
          if (!businessUnit) return '';
          return (
            <Box
              key={`${businessUnit.displayName || businessUnit.name}_${index}`}
              sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
            >
              <Typography
                sx={{ opacity: businessUnit.active ? '1' : '.6' }}
                className="scope__title"
                component="h5"
              >
                <BusinessIcon className="icon small-icon" />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{businessUnit.displayName || businessUnit.name}</span>
                  <div style={{ display: 'flex', gap: '.1rem' }}>
                    <span className="scope__title-mid">{businessUnit.mid}</span>
                    {businessUnit.responsibles.length > 0 && (
                      <span className="scope__title-mid">
                        ({businessUnit.responsibles[0].username})
                      </span>
                    )}
                  </div>
                </Box>
              </Typography>
              <FormControl
                key={`${
                  businessUnit.displayName || businessUnit.name
                }_${index}`}
                sx={{
                  m: 1,
                  width: 300,
                }}
              >
                <Box>
                  <InputLabel id="demo-multiple-chip-label"></InputLabel>
                  <Select
                    sx={{ minHeight: '56px', width: '300px' }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    name={account[0]}
                    multiple
                    value={account[1]}
                    onChange={handleScopeChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label={''} />
                    }
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                          padding: '.5rem',
                        }}
                      >
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            onDelete={handleScopeChipDelete(value, account[0])}
                            className={
                              businessUnit.active
                                ? 'chip chip_active'
                                : 'chip chip_inactive'
                            }
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {userState.availableScopes.map((scope) => (
                      <MenuItem
                        key={scope}
                        value={scope}
                        style={getStyles(
                          scope,
                          Object.keys(userState.permissions.scopes),
                          theme
                        )}
                      >
                        {scope}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Scopes;
