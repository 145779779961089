import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import './widget.scss';
import scssVariables from '../../styles/variables.scss';

const Widget = ({ type, userData, accountData }) => {
  let data;

  switch (type) {
    case 'user':
      data = {
        title: 'Users',
        isMoney: false,
        totalNumber: userData.total,
        link: 'See all users',
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: '#fff',
              backgroundColor: scssVariables.colorPrimary,
            }}
          />
        ),
      };
      break;
    case 'account':
      data = {
        title: 'Accounts',
        isMoney: false,
        totalNumber: accountData.total,
        link: 'View all accounts',
        icon: (
          <DomainIcon
            className="icon"
            style={{
              color: '#fff',
              backgroundColor: scssVariables.colorPrimary,
            }}
          />
        ),
      };
      break;
    case 'earning':
      data = {
        title: 'EARNINGS',
        isMoney: true,
        totalNumber: 55555,
        link: 'View net earnings',
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{
              color: '#fff',
              backgroundColor: scssVariables.colorPrimary,
            }}
          />
        ),
      };
      break;
    case 'balance':
      data = {
        title: 'BALANCE',
        isMoney: true,
        link: 'See details',
        totalNumber: 777777,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              color: '#fff',
              backgroundColor: scssVariables.colorPrimary,
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && '$'} {data.totalNumber}
        </span>
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* <KeyboardArrowUpIcon />
          {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
