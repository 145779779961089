import { Popper } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { Box } from '@mui/system';
import { forwardRef, useImperativeHandle, useState } from 'react';

const FlashPopper = forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState(null);
  const [className, setClassName] = useState(null);
  const [placement, setPlacement] = useState(null);
  const [timeout, setCurrentTimeout] = useState(null);
  const id = useId();
  const open = Boolean(anchorEl);
  const currentId = open ? id : undefined;

  useImperativeHandle(ref, () => ({
    pop({ domElement, placement, className, message, delay }) {
      clearTimeout(timeout);
      className =
        className && typeof className == 'string' ? className : 'default';
      setMessage(message);
      setPlacement(placement);
      setClassName(className);
      setAnchorEl(domElement);
      delay = Number(delay) >= 0 ? Number(delay) : 1000;
      setCurrentTimeout(setTimeout(() => setAnchorEl(null), delay));
    },
  }));

  const mainClass =
    props?.className && typeof props?.className === 'string'
      ? props.className
      : 'default';

  return (
    <Popper
      className={`flash-popper ${mainClass}`}
      placement={placement || 'bottom'}
      id={currentId}
      open={open}
      anchorEl={anchorEl}
    >
      <Box className={`popper-box ${className}`}>{message}</Box>
    </Popper>
  );
});

export default FlashPopper;
