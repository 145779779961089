import React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import Avatar from 'react-avatar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import PolicyIcon from '@mui/icons-material/Policy';
import CloudIcon from '@mui/icons-material/Cloud';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CodeIcon from '@mui/icons-material/Code';
import DetailPanel from './DetailPanel';
import UserCopyFlow from '../admin/UserCopyFlow';
import UserNotificationFlow from '../admin/UserNotificationFlow';

const AdminDatatableRow = ({
  user,
  labelId,
  openDetailPanel,
  handleOpenDetailPanel,
  accounts,
  permissions,
  loadUsersData,
  handleRowAdd,
  handleRowDelete,
  handleNotificationSend,
  setErrorMessages,
  setIserror,
  isError,
  errorMessages,
  isNotificationSuccessful,
}) => {
  const avatar = (user) => {
    return (
      <Avatar
        maxInitials={1}
        size={30}
        round={true}
        name={user === undefined ? ' ' : user.username}
      />
    );
  };
  const isAdmin = (user) => {
    return (
      _.findIndex(user?.permissions?.groups, (group) => {
        return group === 'admin';
      }) >= 0
    );
  };

  const isAdminAudits = (user) => {
    return (
      _.findIndex(user?.permissions?.groups, (group) => {
        return group === 'admin.audits';
      }) >= 0
    );
  };

  const isAdminSFMC = (user) => {
    return (
      _.findIndex(user?.permissions?.groups, (group) => {
        return group === 'admin.sfmc';
      }) >= 0
    );
  };

  const isCodeIssues = (user) => {
    return (
      _.findIndex(user?.permissions?.groups, (group) => {
        return group === 'codeIssues';
      }) >= 0
    );
  };

  const isDashboards = (user) => {
    return (
      _.findIndex(user?.permissions?.groups, (group) => {
        return group === 'dashboards';
      }) >= 0
    );
  };

  const adminOrUserIcon = (user) => {
    return isAdmin(user) ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Tooltip title="Admin">
          <AdminPanelSettingsIcon
            className="icon"
            style={{ fontSize: '1.8rem', width: '25px', height: '25px' }}
          />
        </Tooltip>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Tooltip title="User">
          <PersonIcon
            className="icon"
            style={{
              fontSize: '1.8rem',
              transform: 'scale(.9)',
              width: '25px',
              height: '25px',
            }}
          />
        </Tooltip>
      </Box>
    );
  };

  const adminAuditIcon = (user) => {
    return (
      isAdminAudits(user) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <Tooltip title="Admin Audits">
            <PolicyIcon
              className="icon"
              style={{
                fontSize: '1.8rem',
                transform: 'scale(.8)',
                width: '25px',
                height: '25px',
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const adminSFMCIcon = (user) => {
    return (
      isAdminSFMC(user) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <Tooltip title="Admin SFMC">
            <CloudIcon
              className="icon"
              style={{
                fontSize: '1.8rem',
                transform: 'scale(.8)',
                width: '25px',
                height: '25px',
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const codeIssuesIcon = (user) => {
    return (
      isCodeIssues(user) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <Tooltip title="Code Issues">
            <CodeIcon
              className="icon"
              style={{
                fontSize: '1.8rem',
                transform: 'scale(.8)',
                width: '25px',
                height: '25px',
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const dashboardsIcon = (user) => {
    return (
      isDashboards(user) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          }}
        >
          <Tooltip title="Dashboards">
            <DashboardIcon
              className="icon"
              style={{
                fontSize: '1.8rem',
                transform: 'scale(.8)',
                width: '25px',
                height: '25px',
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} key={user.username}>
        <TableCell align="left">
          <Box sx={{ display: 'flex', gap: '.2rem' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => handleOpenDetailPanel(event, user.username)}
            >
              {openDetailPanel === user.username ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            <UserCopyFlow
              userState={user}
              handleRowAdd={handleRowAdd}
              smallIcon
            />
            <UserNotificationFlow
              userState={user}
              handleNotificationSend={handleNotificationSend}
              isNotificationSuccessful={isNotificationSuccessful}
              isError={isError}
              setIsError={setIserror}
              errorMessages={errorMessages}
              smallIcon
            />
          </Box>
        </TableCell>
        <TableCell align="left">{user.username}</TableCell>
        <TableCell
          align="left"
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {avatar(user)}
        </TableCell>
        <TableCell align="left">
          <span className="icon-role">{adminOrUserIcon(user)}</span>
          <span className="icon-role">{adminAuditIcon(user)}</span>
          <span className="icon-role">{adminSFMCIcon(user)}</span>
          <span className="icon-role">{codeIssuesIcon(user)}</span>
          <span className="icon-role">{dashboardsIcon(user)}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={openDetailPanel === user.username}
            timeout="auto"
            unmountOnExit
          >
            {user.username && (
              <DetailPanel
                rowData={user}
                accounts={accounts}
                permissions={permissions}
                loadUsersData={loadUsersData}
                handleRowAdd={handleRowAdd}
                handleRowDelete={handleRowDelete}
                setErrorMessages={setErrorMessages}
                setIserror={setIserror}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default AdminDatatableRow;
