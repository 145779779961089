import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';

const UserCopyFlow = ({ userState, handleRowAdd, smallIcon }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState('');

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const userCopyHandler = () => {
    let newData = {
      username: username,
      permissions: userState.permissions,
      tableData: {
        id: username,
      },
    };
    return new Promise((resolve) => {
      handleRowAdd(newData, userState.permissions, resolve);
      handleCloseDialog();
    });
  };

  return (
    <Box>
      <Tooltip
        title="Copy User"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          className="icon-copy"
          color="primary"
          size={smallIcon ? 'small' : 'medium'}
          aria-label="Copy User"
          onClick={handleClickOpenDialog}
          style={smallIcon ? { transform: 'scale(.8)' } : {}}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Copy User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong>{userState.username}</strong> will be copied. Please enter
              email address here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={userCopyHandler}
            >
              Copy
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default UserCopyFlow;
