import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from '../state/Navigation';
import { Box } from '@mui/material';

import BusinessUnits from '../audits/BusinessUnits';
import DEUsageAudit from '../audits/DEUsageAudit';
import DEDependencyAudit from '../audits/DEDependencyAudit/DEDependencyAudit';
import Sandbox from '../sandbox/Sandbox';
import BrowseDataExtensions from '../browse/BrowseDataExtensions';
import AppInsights from '../lib/appInsights';
import Navbar from '../components/navbar/Navbar';
import Login from '../components/login/Login';

import '../styles/home/home.scss';
import '../styles/dark.scss';

import { DarkModeContext } from '../context/darkModeContext';
import Dashboard from '../pages/dashboard/Dashboard';
import Spinner from '../components/spinner/Spinner';
import AccountAdmin from '../pages/wsfmc-admin/accountAdmin';
import NewAccount from '../pages/wsfmc-admin/newAccount';
import EditAccount from '../pages/wsfmc-admin/editAccount';
import UserAdmin from '../pages/admin/UserAdmin';
import Feedback from '../components/feedback/Feedback';
import AmpscriptEditor from '../pages/ampscript-evaluation/AmpscriptEditor';
import BuilderSuite from '../pages/builder-suite/BuilderSuite';
import { me } from '../service/adminApiService/adminApiService';
import MCLogin from '../pages/mc-login/MCLogin';
import AdvancedMaintenanceModePopup from '../components/admin/AdvancedMaintenanceModePopup';
import WsfmcPropertiesAdmin from '../pages/admin-wsfmc-properties/WsfmcPropertiesAdmin';
import BuilderSuitePackage from '../pages/builder-suite/BuilderSuitePackage';
import DDSAdminPage from '../pages/data-discovery-studio-admin/DDSAdminPage';
import DDSAdminAddAccountPage from '../pages/data-discovery-studio-admin/DDSAdminAddAccountPage';

const pages = [
  { name: 'Home', route: '/' },
  { name: 'Dashboard', route: '/dashboard' },
  { name: 'Admin', route: '/admin' },
  { name: 'Ampscript Editor', route: '/ampscript-editor' },
];
const settings = ['Logout'];

function App() {
  const { darkMode } = useContext(DarkModeContext);
  // const { collapseMode } = useContext(SidebarCollapseContext);
  const [userJson, setUserJson] = useState('{}');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const user = useMemo(() => {
    const user = JSON.parse(userJson);
    return user;
  }, [userJson]);

  useEffect(() => {
    AppInsights.trackPageView(user);
  }, [location.pathname, user]);

  async function reloadUser() {
    await me(true)
      .then((user) => {
        setUserJson(JSON.stringify(user));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    reloadUser();
  }, [location.pathname]);

  const routeIsActive = (route) => {
    return (
      route &&
      (location.pathname === route || location.pathname.startsWith(`${route}/`))
    );
  };

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      {user?.username ? (
        <div className="home">
          {/* <Sidebar user={user} /> */}
          <div className="home-container">
            <Navbar
              user={user}
              settings={settings}
              navigate={navigate}
              pages={pages}
              routeIsActive={routeIsActive}
            />
            <AdvancedMaintenanceModePopup />
            <div
              className="main"
              // style={collapseMode ? { marginLeft: '60px' } : {}}
            >
              <Box sx={{ m: 2 }}>
                <Routes>
                  <Route path="/">
                    <Route
                      index={true}
                      element={
                        <BusinessUnits user={user} reloadUser={reloadUser} />
                      }
                    />
                    <Route path="audits">
                      <Route index={true} element={<Navigate to="/" />} />
                      <Route
                        index={false}
                        path="de-usage/:mid"
                        element={<DEUsageAudit user={user} />}
                      />
                      <Route
                        index={false}
                        path="de-dependency/:mid"
                        element={<DEDependencyAudit user={user} />}
                      />
                      <Route
                        index={false}
                        path="de-dependency/:mid/e/:dataextension"
                        element={<DEDependencyAudit user={user} />}
                      />
                      <Route
                        index={false}
                        path="de-dependency/:mid/q/:query"
                        element={<DEDependencyAudit user={user} />}
                      />
                    </Route>
                    <Route path="sfmc">
                      <Route index={true} element={<Navigate to="/" />} />
                      <Route
                        index={false}
                        path="connected-account/:mid"
                        // element={<BuilderSuite user={user} />}
                        element={<MCLogin user={user} />}
                      />
                    </Route>
                    <Route path="builder-suite">
                      <Route index={true} element={<Navigate to="/" />} />
                      <Route
                        index={false}
                        path="deploy-zip/:mid"
                        element={<BuilderSuite user={user} />}
                      />
                      {/* <Route
                        index={false}
                        path="deploy-vault/:mid"
                        element={<VaultBuilderSuite user={user} />}
                      /> */}
                      <Route
                        index={false}
                        path="create-package/:mid"
                        element={<BuilderSuitePackage user={user} />}
                      />
                    </Route>
                  </Route>
                  <Route path="admin/sfmc/account">
                    <Route
                      index={true}
                      element={<AccountAdmin user={user} />}
                    />
                    <Route path="new" element={<NewAccount user={user} />} />
                    <Route
                      path="edit/:eid/:clientId"
                      element={<EditAccount user={user} />}
                    />
                  </Route>
                  <Route path="sandbox" element={<Sandbox user={user} />} />
                  <Route path="dashboard" element={<Dashboard user={user} />} />
                  <Route path="admin" element={<UserAdmin user={user} />} />
                  <Route
                    path="admin-wsfmc-properties"
                    element={<WsfmcPropertiesAdmin user={user} />}
                  />
                  <Route path="dds-admin">
                    <Route
                      index={true}
                      element={<DDSAdminPage user={user} />}
                    />
                    <Route
                      index={false}
                      path="add"
                      element={<DDSAdminAddAccountPage user={user} />}
                    />
                  </Route>
                  <Route
                    path="ampscript-editor"
                    element={<AmpscriptEditor user={user} />}
                  />
                  <Route path="browse/:mid/e">
                    <Route
                      index={true}
                      element={<BrowseDataExtensions user={user} />}
                    />
                    <Route
                      index={false}
                      path=":name"
                      element={<BrowseDataExtensions user={user} />}
                    />
                    <Route
                      index={false}
                      path=":name/:query"
                      element={<BrowseDataExtensions user={user} />}
                    />
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Box>
            </div>
            <Feedback user={user} />
          </div>
        </div>
      ) : user && !isLoading ? (
        <Login />
      ) : (
        isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              weight: '100vw',
            }}
          >
            <div style={{ transform: 'scale(8)', opacity: '.3' }}>
              <Spinner></Spinner>
            </div>
          </Box>
        )
      )}
    </div>
  );
}

export default App;
