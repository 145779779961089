import axios from 'axios';

const api = axios.create({
  baseURL: '/api/dashboards',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export async function getUserData(
  { activeOnly, sortBy, sortDirection },
  token
) {
  try {
    const endpoint = '/users';
    let endpointParams =
      endpoint +
      '?activeOnly=' +
      activeOnly +
      '&' +
      'sortBy=' +
      sortBy +
      '&' +
      'sortDirection=' +
      sortDirection;
    const response = await api.get(endpointParams);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the user data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getAccountData(
  { activeOnly, sortBy, sortDirection },
  token
) {
  try {
    const endpoint = '/accounts';
    let endpointParams =
      endpoint +
      '?activeOnly=' +
      activeOnly +
      '&' +
      'sortBy=' +
      sortBy +
      '&' +
      'sortDirection=' +
      sortDirection;
    const response = await api.get(endpointParams);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the account data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getTrackingData(
  { sortBy, sortDirection, limit, offset },
  token
) {
  try {
    const endpoint = '/tracking';
    let endpointParams =
      endpoint +
      '?sortBy=' +
      sortBy +
      '&' +
      'sortDirection=' +
      sortDirection +
      '&' +
      'limit=' +
      limit +
      '&' +
      'offset=' +
      offset;
    const response = await api.get(endpointParams);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the tracking data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getTrackingPagesData(
  { sortDirection, limit, offset },
  token
) {
  try {
    const endpoint = '/tracking/pages';
    let endpointParams =
      endpoint +
      '?sortDirection=' +
      sortDirection +
      '&' +
      'limit=' +
      limit +
      '&' +
      'offset=' +
      offset;
    const response = await api.get(endpointParams);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the tracking pages data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getTrackingItemsData(
  { sortDirection, limit, offset },
  token
) {
  try {
    const endpoint = '/tracking/items';
    let endpointParams =
      endpoint +
      '?sortDirection=' +
      sortDirection +
      '&' +
      'limit=' +
      limit +
      '&' +
      'offset=' +
      offset;
    const response = await api.get(endpointParams);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the tracking items data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getTrackingDataSummary() {
  try {
    const response = await api.get('/tracking/last-events-summary');
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the tracking items data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getTrackingDataByMonthSummary() {
  try {
    const response = await api.get(
      '/tracking/last-events-summary?groupBy=month'
    );
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the tracking items data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getAccountTrackingDataSummary() {
  try {
    const response = await api.get(
      '/tracking/last-events-summary?byBusinessUnit=1'
    );
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the account tracking items data.'
    );
    error.status = 500;
    throw error;
  }
}

export async function authenticate(token) {
  const endpoint = '/auth';
  const response = await api.post(endpoint, { token });
  return response.data;
}
