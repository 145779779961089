import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Util from '../../lib/util';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import scssVariables from '../../styles/variables.scss';
const AccountDatatableRow = ({ pkg, deleteRef }) => {
  return (
    <React.Fragment>
      <TableRow hover={true} key={`pkg-${pkg.id}`}>
        <TableCell>{pkg.name}</TableCell>
        <TableCell align="right">{pkg.mid || ''}</TableCell>
        <TableCell align="right">{pkg.clientId}</TableCell>
        <TableCell align="right">{pkg.tenantSubdomain}</TableCell>
        <TableCell align="right">
          {pkg.businessUnits?.length} ({pkg.activeBusinessUnits?.length})
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {Util.formatDate(pkg.created)}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          <IconButton component={Link} to={`edit/${pkg.eid}/${pkg.clientId}`}>
            <Edit style={{ color: scssVariables.colorPrimary }} />
          </IconButton>
          <IconButton
            onClick={() =>
              deleteRef?.current?.open({
                ...{ name: pkg.name },
                ...pkg,
              })
            }
          >
            <Delete style={{ color: '#d81b1b' }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default AccountDatatableRow;
