import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import './richTextEditor.scss';

import { useContext, useRef, useState, useEffect } from 'react';
import RichTextEditor from './RichTextEditor';
import { DarkModeContext } from '../../context/darkModeContext';
import './userNotificationFlow.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const UserNotificationFlow = ({
  userState,
  handleNotificationSend,
  isMultipleUserSelection,
  smallIcon,
  users,
  buDisplayName,
  isNotificationSuccessful,
  isError,
  setIsError,
  errorMessages,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [reason, setReason] = useState('');
  const contentRef = useRef(null);
  const [selected, setSelected] = useState([]);
  const [renderAgain, setRenderAgain] = useState(false);
  const { darkMode } = useContext(DarkModeContext);
  const [allowNotificationSend, setAllowNotificationSend] = useState('');

  let options;
  let isAllSelected;

  if (isMultipleUserSelection && users) {
    options = users?.map((user) => user.username);
    isAllSelected = options?.length > 0 && selected.length === options?.length;
  }

  useEffect(() => {
    if (contentRef?.current?.innerHTML.length && title && reason) {
      setAllowNotificationSend(true);
    } else {
      setAllowNotificationSend(false);
    }
  }, [contentRef?.current?.innerHTML.length, title, reason]);

  const handleUserChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const handleClickOpenDialog = () => {
    setTitle('');
    setContent('');
    setReason('');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setTitle('');
    setContent('');
    setReason('');
    setSelected([]);
    setOpenDialog(false);
    setIsError(false);
  };

  const userNotificationHandler = () => {
    const messageBody = contentRef.current;
    const message = messageBody.innerHTML;

    let notificationData = [];

    if (!isMultipleUserSelection) {
      notificationData.push({
        body: { message: message, title: title, type: 'html' },
        status: 'unread',
        reason: reason,
        channel: 'portal',
        username: userState?.username || '',
      });
    }

    if (isMultipleUserSelection && selected.length > 0) {
      notificationData = selected.map((username) => ({
        body: { message: message, title: title, type: 'html' },
        status: 'unread',
        reason: reason,
        channel: 'portal',
        username: username,
      }));
    }

    if (users && !isMultipleUserSelection) {
      notificationData = users.map((user) => ({
        body: { message: message, title: title, type: 'html' },
        status: 'unread',
        reason: reason,
        channel: 'portal',
        username: user.username,
      }));
    }

    handleNotificationSend(notificationData);
  };

  useEffect(() => {
    if (isNotificationSuccessful) {
      handleCloseDialog();
    }
  }, [isNotificationSuccessful]);

  return (
    <Box>
      {isMultipleUserSelection ? (
        <Tooltip title="Send Notifications">
          <Button
            variant="contained"
            color="primary"
            startIcon={<NotificationsIcon />}
            onClick={handleClickOpenDialog}
            sx={{ marginLeft: '1rem' }}
          >
            Notify
          </Button>
        </Tooltip>
      ) : (
        <Tooltip
          title="Send Notification"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IconButton
            className="icon-notification"
            color="primary"
            size={smallIcon ? 'small' : 'medium'}
            aria-label="Send Notification"
            onClick={handleClickOpenDialog}
            style={smallIcon ? { transform: 'scale(.8)' } : {}}
          >
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      )}

      <div>
        <Dialog
          className={
            darkMode ? 'notification-dialog dark' : 'notification-dialog'
          }
          open={openDialog}
          onClose={handleCloseDialog}
        >
          {isError ? (
            <Alert
              severity="error"
              style={{ transform: 'translateY(-10px)', marginTop: '10px' }}
            >
              {errorMessages.map((msg, i) => {
                return (
                  <div key={i} style={{ fontWeight: '600' }}>
                    {msg}
                  </div>
                );
              })}
            </Alert>
          ) : (
            ''
          )}
          <DialogTitle
            style={{ display: 'flex', alignItems: 'center', gap: '.1rem' }}
          >
            <NotificationsIcon className="icon" />{' '}
            <span>
              {isMultipleUserSelection
                ? 'Send Notifications'
                : 'Send Notification'}{' '}
            </span>
          </DialogTitle>
          <DialogContent>
            {userState?.username && (
              <DialogContentText sx={{ marginBottom: '.5rem' }}>
                <strong>{userState.username} </strong>
                will be notified with the following message.
              </DialogContentText>
            )}
            {users && !isMultipleUserSelection && (
              <DialogContentText sx={{ marginBottom: '.5rem' }}>
                All users from<strong> {buDisplayName} </strong>
                will be notified with the following message.
              </DialogContentText>
            )}
            {isMultipleUserSelection && (
              <FormControl sx={{ m: 1, width: '100%', margin: '.5rem 0' }}>
                <InputLabel id="multiple-select-label">Users</InputLabel>
                <Select
                  labelId="multiple-select-label"
                  label="Users"
                  multiple
                  value={selected}
                  onChange={handleUserChange}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              sx={{ margin: '.5rem 0' }}
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
            <RichTextEditor
              content={content}
              setContent={setContent}
              contentRef={contentRef}
              renderAgain={renderAgain}
              setRenderAgain={setRenderAgain}
            />
            <TextField
              sx={{ margin: '.5rem 0' }}
              margin="dense"
              id="reason"
              label="Reason"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setReason(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={userNotificationHandler}
              disabled={!allowNotificationSend}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default UserNotificationFlow;
