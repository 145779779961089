import './accountAdmin.scss';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
  Alert,
  Box,
  Button,
  FormControl,
  LinearProgress,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { validateMIDs } from './updatePackage';
import { checkPermissions } from '../../service/adminApiService/adminApiService';

export default function NewAccount({ user }) {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState('');
  const [publicClientId, setPublicClientId] = useState('');
  const [additionalMIDs, setAddtionalMIDs] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [authUrl, setAuthUrl] = useState('');
  const [error, setError] = useState('');

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }
  }, []);

  function isValid() {
    return (
      validateClientId() &&
      validateClientSecret &&
      validateSubdomain() &&
      validateMIDs(additionalMIDs)
    );
  }

  function validateClientId() {
    return /^[\w-]+$/.test(clientId?.trim());
  }

  function validatePublicClientId() {
    return /^[\w-]*$/.test(publicClientId?.trim());
  }

  function validateClientSecret() {
    return /^[\w-]+$/.test(clientSecret?.trim());
  }

  function validateSubdomain() {
    return /^https:[/][/][\w-]+.auth.marketingcloudapis.com[/]{0,1}$/.test(
      authUrl
    );
  }

  async function submitCredentials(evt) {
    evt.preventDefault();
    setSubmitted(true);
    if (!isValid()) return;
    try {
      setLoading(true);

      const body = JSON.stringify({
        credentials: {
          clientId: clientId?.trim(),
          clientSecret: clientSecret?.trim(),
          authUrl: authUrl?.trim(),
          additionalMIDs: additionalMIDs?.trim(),
          publicClientId: publicClientId?.trim(),
        },
      });
      await fetch('/api/wsfmc/admin/setupcredentials', {
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setError('');
          navigate(`/admin/sfmc/account/edit/${data.eid}/${data.clientId}`);
        } else {
          try {
            const json = await response.json();
            setError(json.message);
          } catch {
            setError('Unable to process the request.');
          }
        }
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="new-sfmc-account">
      <Box
        className={'backButtonBox'}
        // style={collapseMode ? { left: '62px' } : {}}
      >
        <Link to={`/admin/sfmc/account`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <h2>New business unit</h2>
      <div>
        <br />
        <FormControl
          disabled={loading}
          onSubmit={submitCredentials}
          component="form"
          noValidate
          autoComplete="off"
        >
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={{ width: '600px' }}
            error={
              !validateClientId() && (clientId || submitted) ? true : false
            }
            required
            id="outlined-required"
            label="Client Id"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={{ width: '600px', marginTop: '25px' }}
            error={
              !validateClientSecret() && (clientSecret || submitted)
                ? true
                : false
            }
            required
            id="outlined-password-input"
            label="Client secret"
            type="password"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={{ width: '600px', marginTop: '25px' }}
            error={
              !validateSubdomain() && (authUrl || submitted) ? true : false
            }
            required
            id="outlined-required"
            label="Authentication Base URI"
            value={authUrl}
            onChange={(e) => setAuthUrl(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={{ width: '600px', marginTop: '25px' }}
            error={
              !validatePublicClientId() && (publicClientId || submitted)
                ? true
                : false
            }
            label="Public Client Id"
            value={publicClientId}
            onChange={(e) => setPublicClientId(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={{ width: '600px', marginTop: '25px' }}
            error={!validateMIDs(additionalMIDs)}
            id="outlined-required"
            label="Additional MIDs"
            value={additionalMIDs}
            placeholder="Optional. Enter additional MIDs separated by spaces."
            title="Optional. Enter additional MIDs separated by spaces."
            onChange={(e) => setAddtionalMIDs(e.target.value)}
          />
          <Button
            sx={{ marginTop: '25px' }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Submit
          </Button>
        </FormControl>
      </div>
      {loading ? (
        <>
          <br />
          <LinearProgress sx={{ width: '600px' }} />
        </>
      ) : error ? (
        <>
          <br />
          <div>
            <Alert color="error" sx={{ width: '600px' }}>
              {error}
            </Alert>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
