import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import './DEDependencyGraphLegend.scss';
import { useState } from 'react';

export default function DEDependencyGraphLegend() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="de-dependency-graph-legend" data-is-collapsed={isCollapsed}>
      <div
        className="de-dependency-graph-legend__header"
        onClick={toggleCollapse}
      >
        <h3>Legend</h3>
        <IconButton onClick={toggleCollapse} disableRipple>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </div>
      {!isCollapsed ? (
        <>
          <div className="de-dependency-graph-legend__body">
            <div className="de-dependency-graph-legend__item">
              <div className="de-dependency-graph-legend__rectangle"></div>
              <div className="de-dependency-graph-legend__text">
                Data extension
              </div>
            </div>
            <div className="de-dependency-graph-legend__item">
              <div className="de-dependency-graph-legend__cylinder">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="de-dependency-graph-legend__text">
                Query activity
              </div>
            </div>
            <div className="de-dependency-graph-legend__item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="35"
                viewBox="0 0 50 35"
                className="de-dependency-graph-legend__arrow"
              >
                <line
                  x1="0"
                  y1="17.5"
                  x2="35"
                  y2="17.5"
                  stroke="green"
                  strokeWidth="2"
                />
                <polygon
                  points="48,17.5 35,10.5 35,24.5"
                  fill="none"
                  stroke="green"
                  strokeWidth="2"
                />
              </svg>
              <div className="de-dependency-graph-legend__text">
                [data extension] -&gt; is read by -&gt; [query]
              </div>
            </div>
            <div className="de-dependency-graph-legend__item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="35"
                viewBox="0 0 50 35"
                className="de-dependency-graph-legend__arrow"
              >
                <line
                  x1="0"
                  y1="17.5"
                  x2="35"
                  y2="17.5"
                  stroke="blue"
                  strokeWidth="2"
                />
                <polygon
                  points="48,17.5 35,10.5 35,24.5"
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
              <div className="de-dependency-graph-legend__text">
                [query] -&gt; writes into -&gt; [data extension]
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
