import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DatatableSearch from '../../../components/datatable/DatatableSearch';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from '../../../state/Navigation';

const DDSAdminToolbar = ({
  handleRowAdd,
  searched,
  onSearch,
  showSearch,
  showAddButton,
}) => {
  const navigate = useNavigate();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        id="tableTitle"
        component="div"
        className="data-table__title"
      >
        <PeopleAltIcon className="icon" />
        <span>Data Discovery Studio Admin</span>
      </Typography>

      {showSearch ? (
        <DatatableSearch searched={searched} requestSearch={onSearch} />
      ) : (
        ''
      )}

      {showAddButton ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => navigate('/dds-admin/add')}
        >
          Add
        </Button>
      ) : (
        ''
      )}
    </Toolbar>
  );
};

export default DDSAdminToolbar;
