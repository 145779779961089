import React, { useCallback, useRef, useState } from 'react';
import './datatable.scss';
import '../../pages/wsfmc-admin/accountAdmin.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Card from '../ui/Card';
import { AccountTree, Business as BusinessIcon } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import DeletePackage from '../../pages/wsfmc-admin/components/DeletePackage';
import DatatableHead from './DatatableHead';
import DatatableToolbar from './DatatableToolbar';
import BusinessUnitDatatableRow from './BusinessUnitDatatableRow';
import {
  addResponsibles,
  clearResponsibles,
  removeResponsibles,
  toggleEnableDataDownload,
  toggleActiveStatus,
  toggleSfmcAuth,
} from '../../service/accountApiService/accountApiService';
import ResponsibleAddRemoveFlow from '../account/ResponsibleAddRemoveFlow';
import ConfirmDialog from '../ui/ConfirmDialog';

function getStyles(user, users, theme) {
  return {
    fontWeight: 600,
    padding: '.5rem',
    display: 'block',
    borderBottom: '1px solid #f5f5f5',
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'Actions',
    align: 'left',
    disablePadding: true,
    label: 'Actions',
    pointerEvents: false,
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    pointerEvents: true,
  },
  {
    id: 'displayName',
    align: 'left',
    disablePadding: false,
    label: 'Display Name',
    pointerEvents: true,
  },
  {
    id: 'mid',
    align: 'right',
    disablePadding: true,
    label: 'MID',
    pointerEvents: true,
  },
  {
    id: 'parentMid',
    align: 'right',
    disablePadding: true,
    label: 'Parent MID',
    pointerEvents: true,
  },
  {
    id: 'active',
    align: 'right',
    disablePadding: true,
    label: 'Active',
    pointerEvents: true,
  },
  {
    id: 'enableDataDownload',
    align: 'right',
    disablePadding: true,
    label: 'Data download',
    pointerEvents: true,
  },
  {
    id: 'enableSfmcAuth',
    align: 'right',
    disablePadding: true,
    label: 'SFMC Auth',
    pointerEvents: true,
  },
  {
    id: 'responsibles',
    align: 'left',
    disablePadding: true,
    label: 'Responsibles',
    pointerEvents: true,
  },
  {
    id: 'apiEnabled',
    align: 'right',
    disablePadding: true,
    label: 'API enabled',
    pointerEvents: true,
  },
];

const BUDatatable = ({
  data,
  setData,
  setTrigger,
  usersLoaded,
  activeOnly,
  handleActiveOnly,
  inactiveOnly,
  handleInactiveOnly,
  handleEnableOnly,
  handleDisableOnly,
  dataDownloadEnabledOnly,
  dataDownloadDisabledOnly,
  handleDataDownloadEnableOnly,
  handleDataDownloadDisableOnly,
  sfmcAuthEnabledOnly,
  sfmcAuthDisabledOnly,
  handleSfmcAuthEnableOnly,
  handleSfmcAuthDisableOnly,
  apiEnabledOnly,
  apiDisabledOnly,
  handleNotificationSend,
  isNotificationSuccessful,
  isError,
  setIsError,
  setIsSuccess,
  errorMessages,
  setErrorMessages,
  setSuccessMessages,
  handleDisplayNameChange,
  handleResponsiblesChange,
  handleDisplayNameSubmit,
  displayNameChangeLoading,
  togglingActive,
  togglingDataDownload,
  togglingSfmcAuth,
  sfmcPackage,
  fetching,
  setTogglingActive,
  setTogglingDataDownload,
  setTogglingSfmcAuth,
  usersData,
  getAccounts,
}) => {
  const deleteRef = useRef(null);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = useState(
    data?.businessUnits
  );
  const [searchValue, setSearchValue] = useState('');

  const [tableLoading, setTableLoading] = useState(true);

  const [openClearModal, setOpenClearModal] = useState(false);
  const [openEnableBuModal, setOpenEnableBuModal] = useState(false);
  const [openDisableBuModal, setOpenDisableBuModal] = useState(false);
  const [openEnableAuthModal, setOpenEnableAuthModal] = useState(false);
  const [openDisableAuthModal, setOpenDisableAuthModal] = useState(false);
  const [openEnableDataDownloadModal, setOpenEnableDataDownloadModal] =
    useState(false);
  const [openDisableDataDownloadModal, setOpenDisableDataDownloadModal] =
    useState(false);

  const theme = useTheme();

  // refresh the data when the data changes
  React.useEffect(() => {
    setTableLoading(true);
    requestSearch(searchValue, data?.businessUnits);
  }, [data]);

  const requestSearch = (searchedVal, bus = data?.businessUnits) => {
    let checkedBusinessUnits;
    // move isChecked from filteredList to new data by comparing their mid
    if (filteredBusinessUnits && bus && bus.some((bu) => bu.users)) {
      checkedBusinessUnits = bus.map((bu) => {
        let isChecked = bus.find((bu2) => {
          return bu2.mid === bu.mid;
        })?.isChecked;
        return { ...bu, isChecked };
      });
    } else {
      checkedBusinessUnits = bus;
    }
    const filteredRows = !searchedVal
      ? checkedBusinessUnits
      : checkedBusinessUnits.filter((row) => {
          let responsibles = row.responsibles.map((responsible) => {
            return responsible.username;
          });
          responsibles = responsibles.join(', ');
          return (
            row.name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
            String(row.mid)
              ?.toLowerCase()
              ?.includes(searchedVal.toLowerCase()) ||
            String(row.parentMid)
              ?.toLowerCase()
              ?.includes(searchedVal.toLowerCase()) ||
            row.displayName
              ?.toLowerCase()
              ?.includes(searchedVal.toLowerCase()) ||
            responsibles?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
            row.businessUnits?.some((bu) => {
              return (
                bu.name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
                String(bu.mid)
                  ?.toLowerCase()
                  ?.includes(searchedVal.toLowerCase()) ||
                bu.displayName
                  ?.toLowerCase()
                  ?.includes(searchedVal.toLowerCase())
              );
            })
          );
        });
    setPage(0);
    setSearchValue(searchedVal);
    setFilteredBusinessUnits(filteredRows);
    setTableLoading(false);
    return filteredRows;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - data?.businessUnits?.length)
      : 0;

  async function handleToggleDataDownload(bu) {
    setTogglingDataDownload(bu.id);
    try {
      await toggleEnableDataDownload({
        ids: [bu.id],
        enableDataDownload: !bu.enableDataDownload,
      })
        .then(async (data) => {
          setTrigger({ toggling: true, value: new Date() });

          setErrorMessages(['']);
          setIsError(false);
          setIsSuccess(true);
          setSuccessMessages([
            `
              Data download ${
                bu.enableDataDownload ? 'disabled' : 'enabled'
              } for ${bu.name}`,
          ]);
          setTimeout(() => {
            setIsSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          setErrorMessages([
            `Something went wrong. Data download could not be ${
              bu.enableDataDownload ? 'disabled' : 'enabled'
            } for ${bu.name}. ${error.message}`,
          ]);
          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 5000);
        });
    } finally {
      //
    }
  }

  async function handleToggleSfmcAuth(bu) {
    setTogglingSfmcAuth(bu.id);
    try {
      await toggleSfmcAuth({ ids: [bu.id], enableSfmcAuth: !bu.enableSfmcAuth })
        .then(async (data) => {
          setTrigger({ toggling: true, value: new Date() });

          setErrorMessages(['']);
          setIsError(false);
          setIsSuccess(true);
          setSuccessMessages([
            `SFMC Auth successfully ${
              bu.enableSfmcAuth ? 'disabled' : 'enabled'
            } for ${bu.name}`,
          ]);
          setTimeout(() => {
            setIsSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          setErrorMessages([
            `Something went wrong. SFMC Auth could not be ${
              bu.enableSfmcAuth ? 'disabled' : 'enabled'
            } for ${bu.name}. ${error.message}`,
          ]);
          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 5000);
        });
    } finally {
      //
    }
  }

  async function handleToggleActive(bu) {
    setTogglingActive(bu.id);
    try {
      await toggleActiveStatus({
        ids: [bu.id],
        active: !bu.active,
      })
        .then(async (data) => {
          setTrigger({ toggling: true, value: new Date() });

          setErrorMessages(['']);
          setIsError(false);
          setIsSuccess(true);
          setSuccessMessages([
            `${bu.name} successfully ${
              bu.active ? 'deactivated' : 'activated'
            }.`,
          ]);
          setTimeout(() => {
            setIsSuccess(false);
          }, 3000);
        })
        .catch((error) => {
          setErrorMessages([
            `Something went wrong. ${bu.name} could not be ${
              bu.active ? 'deactivated' : 'activated'
            }. ${error.message}`,
          ]);
          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 5000);
        });
    } finally {
      //
    }
  }

  const businessUnitFilters = useCallback(
    (bu) => {
      const isApiEnabled = bu?.apiEnabled;
      const isActive = bu?.active;
      const isSfmcAuthEnabled = bu?.enableSfmcAuth;
      const isDataDownloadEnabled = bu?.enableDataDownload;

      const filters = [
        { condition: apiEnabledOnly, value: isApiEnabled },
        { condition: apiDisabledOnly, value: !isApiEnabled },
        { condition: activeOnly, value: isActive },
        { condition: inactiveOnly, value: !isActive },
        { condition: sfmcAuthEnabledOnly, value: isSfmcAuthEnabled },
        { condition: sfmcAuthDisabledOnly, value: !isSfmcAuthEnabled },
        { condition: dataDownloadEnabledOnly, value: isDataDownloadEnabled },
        { condition: dataDownloadDisabledOnly, value: !isDataDownloadEnabled },
      ];

      for (const filter of filters) {
        if (filter.condition && !filter.value) {
          return false;
        }
      }

      return true;
    },
    [
      activeOnly,
      inactiveOnly,
      apiEnabledOnly,
      apiDisabledOnly,
      dataDownloadEnabledOnly,
      dataDownloadDisabledOnly,
      sfmcAuthEnabledOnly,
      sfmcAuthDisabledOnly,
    ]
  );

  const getUsersForBusinessUnit = useCallback(
    (mid) => {
      if (usersLoaded != null) {
        const users = data?.businessUnits?.find((bu) => bu.mid === mid)?.users;
        return users;
      }
    },
    [usersLoaded, data?.businessUnits]
  );

  const handleChangeRowSelection = (e) => {
    const { value, checked } = e.target;
    if (value === 'allselect') {
      const checkedBUList = data?.businessUnits.map((bu) => {
        const isFiltered = filteredBusinessUnits
          ?.filter((bu) => businessUnitFilters(bu))
          .some((filteredBu) => filteredBu?.mid === bu?.mid);
        if (isFiltered && bu) {
          return { ...bu, isChecked: checked };
        } else {
          return bu;
        }
      });
      setData({ ...data, businessUnits: checkedBUList });
    } else {
      const checkedBUList = data?.businessUnits?.map((bu) =>
        bu.mid.toString() === value ? { ...bu, isChecked: checked } : bu
      );
      setFilteredBusinessUnits(checkedBUList);
      setData({ ...data, businessUnits: checkedBUList });
    }
  };

  async function handleAddResponsible(username) {
    try {
      const mids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.mid);
      if (mids?.length === 0) {
        return;
      }
      const userId = usersData?.users?.find((u) => u.username === username)?.id;
      if (!userId) {
        return;
      }
      await addResponsibles({
        mids: mids,
        userId: userId,
        jobTitle: '',
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully added ${username} as responsible for selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. ${username} could not be added as responsible for selected business units. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function handleRemoveResponsibles(username) {
    try {
      const mids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.mid);
      if (mids?.length === 0) {
        return;
      }
      const userId = usersData?.users?.find((u) => u.username === username)?.id;
      if (!userId) {
        return;
      }
      await removeResponsibles({
        mids: mids,
        userId: userId,
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully removed ${username} from responsibles of selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. ${username} could not be removed from responsibles of selected business units. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  async function handleClearResponsibles() {
    try {
      const mids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.mid);
      if (mids?.length === 0) {
        return;
      }
      await clearResponsibles({
        mids: mids,
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully cleared responsibles of selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. Responsibles could not be cleared from selected business units. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setOpenClearModal(false);
    }
  }

  async function handleToggleActiveStatus(isEnabled) {
    try {
      const ids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.id);
      if (ids?.length === 0) {
        return;
      }
      await toggleActiveStatus({
        ids: ids,
        active: isEnabled ? false : true,
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully ${
          isEnabled ? 'disabled' : 'enabled'
        } selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. Selected business units could not be ${
          isEnabled ? 'disabled' : 'enabled'
        }. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setOpenEnableBuModal(false);
      setOpenDisableBuModal(false);
    }
  }

  async function handleDataDownloadStatus(isEnabled) {
    try {
      const ids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.id);
      if (ids?.length === 0) {
        return;
      }
      await toggleEnableDataDownload({
        ids: ids,
        enableDataDownload: isEnabled ? false : true,
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully ${
          isEnabled ? 'disabled' : 'enabled'
        } data download for selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. Data download could not be ${
          isEnabled ? 'disabled' : 'enabled'
        } for selected business units. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setOpenEnableDataDownloadModal(false);
      setOpenDisableDataDownloadModal(false);
    }
  }

  async function handleToggleAuthStatus(isEnabled) {
    try {
      const ids = data?.businessUnits
        .filter((bu) => bu?.isChecked)
        ?.map((bu) => bu?.id);
      if (ids?.length === 0) {
        return;
      }
      await toggleSfmcAuth({
        ids: ids,
        enableSfmcAuth: isEnabled ? false : true,
      });
      setErrorMessages(['']);
      setIsError(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Successfully ${
          isEnabled ? 'disabled' : 'enabled'
        } SFMC Auth for selected business units.`,
      ]);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      getAccounts();
    } catch (error) {
      setErrorMessages([
        `Something went wrong. SFMC Auth could not be ${
          isEnabled ? 'disabled' : 'enabled'
        } for selected business units. ${error.message}`,
      ]);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    } finally {
      setOpenEnableAuthModal(false);
      setOpenDisableAuthModal(false);
    }
  }

  const isSelected = data?.businessUnits
    ?.filter((bu) => businessUnitFilters(bu))
    .some((bu) => bu?.isChecked === true);

  const isSelectedActiveAndChecked = data?.businessUnits
    ?.filter((bu) => businessUnitFilters(bu))
    .some((bu) => bu?.active === true && bu?.isChecked === true);

  return (
    <Card style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1rem',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <FormControlLabel
              control={
                <Checkbox checked={activeOnly} onChange={handleActiveOnly} />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={inactiveOnly}
                  onChange={handleInactiveOnly}
                />
              }
              label="Inactive"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={apiEnabledOnly}
                  onChange={handleEnableOnly}
                />
              }
              label="API Enabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={apiDisabledOnly}
                  onChange={handleDisableOnly}
                />
              }
              label="API Disabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataDownloadEnabledOnly}
                  onChange={handleDataDownloadEnableOnly}
                />
              }
              label="Data Download Enabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataDownloadDisabledOnly}
                  onChange={handleDataDownloadDisableOnly}
                />
              }
              label="Data Download Disabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sfmcAuthEnabledOnly}
                  onChange={handleSfmcAuthEnableOnly}
                />
              }
              label="SFMC Auth Enabled"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sfmcAuthDisabledOnly}
                  onChange={handleSfmcAuthDisableOnly}
                />
              }
              label="SFMC Auth Disabled"
            />
          </Box>

          <div
            style={{
              fontSize: ' 12px',
              fontWeight: '700',
            }}
          >
            {`${data.businessUnits?.length || 0}`} business unit
            {`${data.businessUnits?.length > 1 ? 's' : ''}`} (
            {data.businessUnits?.filter((bu) => bu.apiEnabled)?.length || 0}{' '}
            valid, {data.businessUnits?.filter((bu) => bu.active)?.length || 0}{' '}
            active)
          </div>
        </Box>
        <Box>
          <ResponsibleAddRemoveFlow
            buttonText="Add Responsible"
            dialogContextText="To add user as responsible person, please enter email address
          here."
            onClickHandler={handleAddResponsible}
            buttonProps={{ disabled: !isSelected }}
            users={usersData?.users}
            getStyles={getStyles}
            theme={theme}
          />
          <ResponsibleAddRemoveFlow
            buttonText="Remove Responsible"
            dialogContextText="To remove user as responsible person, please enter email address
          here."
            onClickHandler={handleRemoveResponsibles}
            buttonProps={{ disabled: !isSelected }}
            users={usersData?.users}
          />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenClearModal(true)}
            disabled={!isSelected}
          >
            Clear Responsibles
          </Button>
          {openClearModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <strong>Selected BUs responsibles will be cleared.</strong>
              }
              open={openClearModal}
              okText="Clear"
              cancelText="Cancel"
              onCancel={() => setOpenClearModal(false)}
              onOk={handleClearResponsibles}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenEnableBuModal(true)}
            disabled={!isSelected}
          >
            Enable BUs
          </Button>
          {openEnableBuModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <strong>Selected BUs will be enabled.</strong>
              }
              open={openEnableBuModal}
              okText="Enable"
              cancelText="Cancel"
              onCancel={() => setOpenEnableBuModal(false)}
              onOk={() => handleToggleActiveStatus(false)}
            />
          )}

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenDisableBuModal(true)}
            disabled={!isSelected}
          >
            Disable BUs
          </Button>
          {openDisableBuModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <div>
                  <strong>Selected BUs will be disabled.</strong>
                  <br />
                  <p>
                    Please not that if selected business unit's SFMC Auth is
                    active, it will also be disabled.
                  </p>
                </div>
              }
              open={openDisableBuModal}
              okText="Disable"
              cancelText="Cancel"
              onCancel={() => setOpenDisableBuModal(false)}
              onOk={() => handleToggleActiveStatus(true)}
            />
          )}

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenEnableDataDownloadModal(true)}
            disabled={!isSelected}
          >
            Enable Data Download
          </Button>
          {openEnableDataDownloadModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <div>
                  <strong>Selected BUs data download will be enabled.</strong>
                </div>
              }
              open={openEnableDataDownloadModal}
              okText="Enable"
              cancelText="Cancel"
              onCancel={() => setOpenEnableDataDownloadModal(false)}
              onOk={() => handleDataDownloadStatus(false)}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              setOpenDisableDataDownloadModal(true);
            }}
            disabled={!isSelected}
          >
            Disable Data Download
          </Button>
          {openDisableDataDownloadModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <strong>Selected BUs Data Download will be disabled.</strong>
              }
              open={openDisableDataDownloadModal}
              okText="Disable"
              cancelText="Cancel"
              onCancel={() => setOpenDisableDataDownloadModal(false)}
              onOk={() => handleDataDownloadStatus(true)}
            />
          )}

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenEnableAuthModal(true)}
            disabled={
              !data?.packages[0]?.publicClientId || !isSelectedActiveAndChecked
            }
          >
            Enable SFMC Auth
          </Button>
          {openEnableAuthModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <div>
                  <strong>Selected BUs SFMC auth will be enabled.</strong>
                  <br />
                  <p>
                    Please not that if selected business unit is not active,
                    SFMC Auth won't be enabled.
                  </p>
                </div>
              }
              open={openEnableAuthModal}
              okText="Enable"
              cancelText="Cancel"
              onCancel={() => setOpenEnableAuthModal(false)}
              onOk={() => handleToggleAuthStatus(false)}
            />
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenDisableAuthModal(true)}
            disabled={
              !data?.packages[0]?.publicClientId || !isSelectedActiveAndChecked
            }
          >
            Disable SFMC Auth
          </Button>
          {openDisableAuthModal && (
            <ConfirmDialog
              dialogProps={{ style: { top: '-50%' } }}
              dialogContentComponents={
                <strong>Selected BUs SFMC auth will be disabled.</strong>
              }
              open={openDisableAuthModal}
              okText="Disable"
              cancelText="Cancel"
              onCancel={() => setOpenDisableAuthModal(false)}
              onOk={() => handleToggleAuthStatus(true)}
            />
          )}
        </Box>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                className="allselect"
                color="primary"
                value="allselect"
                checked={
                  !filteredBusinessUnits
                    ?.filter((bu) => businessUnitFilters(bu))
                    .some((bu) => bu?.isChecked !== true) &&
                  filteredBusinessUnits?.filter((bu) => businessUnitFilters(bu))
                    .length > 0
                }
                onChange={handleChangeRowSelection}
              />
            }
            label="Select all"
          />
        </Box>
      </Box>

      <div className="sfmc-account-admin">
        <DeletePackage
          ref={deleteRef}
          onCompleted={() => setTrigger(new Date())}
        />
        <Box sx={{ width: '100%' }} className="data-table">
          <Paper sx={{ width: '100%', mb: 2 }}>
            <DatatableToolbar
              title="Business Units"
              titleIcon={<BusinessIcon className="icon" />}
              searchButton
              searched={''}
              requestSearch={requestSearch}
            />
            <TableContainer sx={{ borderRadius: '12px' }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <DatatableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={
                    filteredBusinessUnits?.filter((bu) =>
                      businessUnitFilters(bu)
                    ).length
                  }
                  headCells={headCells}
                />
                {filteredBusinessUnits?.filter((bu) => businessUnitFilters(bu))
                  .length > 0 &&
                  !tableLoading && (
                    <TableBody>
                      {stableSort(
                        filteredBusinessUnits,
                        getComparator(order, orderBy)
                      )
                        .filter((bu) => businessUnitFilters(bu))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((bu, index) => {
                          return (
                            <BusinessUnitDatatableRow
                              key={`bu-row-${index}`}
                              bu={bu}
                              deleteRef={deleteRef}
                              getUsersForBusinessUnit={getUsersForBusinessUnit}
                              handleNotificationSend={handleNotificationSend}
                              isNotificationSuccessful={
                                isNotificationSuccessful
                              }
                              isError={isError}
                              setIsError={setIsError}
                              errorMessages={errorMessages}
                              handleDisplayNameChange={handleDisplayNameChange}
                              handleResponsiblesChange={
                                handleResponsiblesChange
                              }
                              handleDisplayNameSubmit={handleDisplayNameSubmit}
                              displayNameChangeLoading={
                                displayNameChangeLoading
                              }
                              handleToggleActive={handleToggleActive}
                              handleChangeRowSelection={
                                handleChangeRowSelection
                              }
                              togglingActive={togglingActive}
                              togglingSfmcAuth={togglingSfmcAuth}
                              togglingDataDownload={togglingDataDownload}
                              sfmcPackage={sfmcPackage}
                              handleToggleSfmcAuth={handleToggleSfmcAuth}
                              handleToggleDataDownload={
                                handleToggleDataDownload
                              }
                              fetching={fetching}
                            />
                          );
                        })}
                    </TableBody>
                  )}
                {tableLoading && (
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          borderBottom: 'none',
                          padding: '0',
                        }}
                        colSpan={12}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem 0',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {filteredBusinessUnits?.filter((bu) => businessUnitFilters(bu))
                .length === 0 && (
                <Box
                  sx={{
                    padding: '1rem 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AccountTree className="icon" />{' '}
                  <Typography
                    className="no-account-title"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: 'rgba(0,0,0,.45)',
                    }}
                  >
                    There are no business units to display.
                  </Typography>
                </Box>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 50, 100, 200, 400]}
              sx={{
                width: '100%',
                paddingTop: `${42.3 * emptyRows}px !important`,
              }}
              component="div"
              count={
                filteredBusinessUnits?.filter((bu) => businessUnitFilters(bu))
                  .length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </Card>
  );
};

export default BUDatatable;
