import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { useState, useEffect } from 'react';

export default function UpdateSfmcPackage({
  onClose,
  onSubmit,
  open,
  publicClientId,
}) {
  const [additionalMIDs, setAddtionalMIDs] = useState('');
  const [localPublicClientId, setPublicClientId] = useState('');

  useEffect(() => {
    setPublicClientId(publicClientId || '');
  }, [publicClientId]);

  const onCloseHandler = onClose;
  onClose = () => {
    setAddtionalMIDs('');
    onCloseHandler?.();
  };

  const onSubmitHandler = onSubmit;
  onSubmit = async (evt) => {
    if (!validateMIDs(additionalMIDs)) {
      return;
    }
    const mids = additionalMIDs;
    setAddtionalMIDs('');
    await onSubmitHandler?.(mids, localPublicClientId);
  };

  function validateClientId() {
    return /^[\w-]*$/.test(localPublicClientId?.trim());
  }

  return (
    <Dialog
      onBackdropClick={onClose}
      className="sfmc-admin update-package"
      onClose={onClose}
      open={open}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>Update SFMC package</DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="false"
          autoCorrect="false"
          sx={{ marginBottom: '15px', marginTop: '5px' }}
          fullWidth
          error={!validateClientId()}
          label="Public Client ID"
          value={localPublicClientId}
          placeholder="Optional. The public client ID."
          title="Public Client ID"
          onChange={(e) => setPublicClientId(e.target.value)}
        />
        <DialogContentText>
          You can help business units discovery by specifying MIDs, especially
          if the package does not have access to the main business unit.
        </DialogContentText>
        <TextField
          autoComplete="false"
          autoCorrect="false"
          sx={{ marginTop: '15px' }}
          fullWidth
          error={!validateMIDs(additionalMIDs)}
          id="outlined-required"
          label="Additional MIDs"
          value={additionalMIDs}
          placeholder="Optional. Enter additional MIDs separated by spaces."
          title="Optional. Enter additional MIDs separated by spaces."
          onChange={(e) => setAddtionalMIDs(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function validateMIDs(input) {
  return (
    !input?.trim() ||
    !_.some(input?.trim()?.split(/[ ]+/), (mid) => !(Number(mid) > 0))
  );
}
