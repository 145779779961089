import { ValueEditor } from 'react-querybuilder';
import QueryUtils from './DataExtensionQueryUtils';
import './Browse.scss';

export const DataExtensionQueryValueEditor = (props) => {
  const { field, operator, value, fieldData } = props;
  const rule = {
    field,
    operator,
    value,
  };

  if (
    fieldData.dataType === 'boolean' &&
    operator !== QueryUtils.operators.isNull.name &&
    operator !== QueryUtils.operators.isNotNull.name
  ) {
    const option = /^true$/i.test(value)
      ? 'true'
      : /^false$/i.test(value)
      ? 'false'
      : '';

    return (
      <div
        className={`custom-value-editor sfmcType-${
          fieldData.sfmcType
        } inputType-${fieldData.inputType} dataType-${fieldData.dataType}${
          !QueryUtils.validateField(rule, fieldData).isValid ? ' invalid' : ''
        }`}
      >
        <select
          defaultValue={option}
          onChange={function (event) {
            props.handleOnChange(event?.target?.value || '');
          }}
        >
          <option value={''}>------</option>
          <option value={'true'}>True</option>
          <option value={'false'}>False</option>
        </select>
      </div>
    );
  } else {
    const formatTip = QueryUtils.formatTipByDataTypes[fieldData.dataType];

    fieldData.placeholder =
      (QueryUtils.operators.in.name === operator ||
      QueryUtils.operators.notIn.name === operator
        ? `${fieldData.dataType},${fieldData.dataType},...`
        : QueryUtils.operators.between.name === operator ||
          QueryUtils.operators.notBetween.name === operator
        ? `${fieldData.dataType},${fieldData.dataType}`
        : fieldData.dataType) +
      (formatTip && formatTip !== fieldData.dataType ? `: ${formatTip}` : '');

    return (
      <div
        className={`custom-value-editor sfmcType-${
          fieldData.sfmcType
        } inputType-${fieldData.inputType} dataType-${fieldData.dataType}${
          !QueryUtils.validateField(rule, fieldData).isValid ? ' invalid' : ''
        }`}
      >
        <ValueEditor {...props} title={fieldData.placeholder} />
      </div>
    );
  }
};
