import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  useLayoutEffect,
  useContext,
} from 'react';
import * as auditService from '../lib/auditsService';
import DataTable from 'react-data-table-component';
import { Alert, Box, Button, Skeleton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { Link, useLocation, useParams } from 'react-router-dom';
import BusinessUnitDetails from './BusinessUnitDetails';
import _ from 'lodash';
import { SettingsBackupRestore } from '@mui/icons-material';
import AppInsights from '../lib/appInsights';
import Util from '../lib/util';
import { DarkModeContext } from '../context/darkModeContext';

import './Audits.scss';
import scssVariables from '../styles/variables.scss';
import DEUsageListFilter from './DEUsageListFilter';
import DEUsageTextFilter from './DEUsageTextFilter';

const auditHeaders = {
  mid: { name: 'MID', minWidth: '10px', omit: true },
  source_name: { name: 'Source', minWidth: '250px' },
  source_customerKey: { name: 'Source key', minWidth: '10px' },
  source_type: { name: 'Type', minWidth: '10px' },
  source_subType: { name: 'Subtype', minWidth: '10px' },
  match: { name: 'Match', minWidth: '10px', omit: true },
  how: { name: 'How', minWidth: '10px' },
  dataExtension_name: { name: 'DE name', minWidth: '250px' },
  dataExtension_customerKey: { name: 'DE key', minWidth: '10px' },
  query_targetName: { name: 'Query Target DE name', minWidth: '250px' },
  query_targetCustomerKey: { name: 'Query Target DE key', minWidth: '10px' },
  dataExtension_exists: { name: 'DE exists', minWidth: '10px', omit: true },
  dataExtension_isDataView: { name: 'Data view', minWidth: '10px' },
  dataExtension_isGlobal: { name: 'Global', minWidth: '10px' },
  dataExtension_contentType: { name: 'DE type', minWidth: '150px' },
  query_targetExists: { name: 'Query Target DE exists', minWidth: '10px' },
  query_targetIsGlobal: { name: 'Query Target DE global?', minWidth: '10px' },
  query_contentType: { name: 'Query target DE type', minWidth: '175px' },
  rowhash: { name: 'Row Hash', minWidth: '10px' },
  timestamp: { name: 'Timestamp', minWidth: '10px' },
};

const initialFilters = {
  initial: true,
  source: '',
  dataextension: '',
  type: ['asset', 'query', 'script'],
  subType: ['codesnippetblock', 'htmlemail', 'templatebasedemail', 'webpage'],
  allSubTypes: [],
};

function filterReducer(state, action) {
  let newState;

  switch (action.type) {
    case 'source':
      newState = { ...state, ...{ source: action.value } };
      break;
    case 'dataextension':
      newState = { ...state, ...{ dataextension: action.value } };
      break;
    case 'type':
      if (!action.value) {
        action.value = [];
      }
      newState = { ...state, ...{ type: action.value } };
      break;
    case 'subType':
      if (!action.value) {
        action.value = [];
      }
      newState = { ...state, ...{ subType: action.value } };
      break;
    case 'loadSubType':
      if (!state.allSubTypes?.length) {
        if (!action.value) {
          action.value = [];
        }
        newState = {
          ...state,
          ...{ allSubTypes: action.value, subType: action.value },
        };
      }
      break;
    case 'clearSubType':
      if (state.allSubTypes?.length) {
        newState = { ...state, ...{ subType: state.allSubTypes } };
      }
      break;
    case 'clearType':
      newState = { ...state, ...{ type: initialFilters.type } };
      break;
    default:
      throw new Error();
  }
  if (!newState) {
    newState = _.cloneDeep(state);
  }
  newState.initial = false;

  return newState;
}

export default function AuditedBusinessUnit({ user } = {}) {
  const params = useParams();
  const location = useLocation();
  const { darkMode } = useContext(DarkModeContext);
  const [mid] = useState({ timestamp: new Date(), value: params.mid });
  const isMounted = useMountedState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [businessUnit, setBusinessUnit] = useState(null);
  const [audit, setAudit] = useState();
  const [loading, setLoading] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filters, dispatchFilter] = useReducer(filterReducer, initialFilters);
  const [subTypes, setSubTypes] = useState(null);
  // Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  // Sorting
  const [sortField, setSortField] = useState('source_type');
  const [sortDirection, setSortDirection] = useState('asc');

  const getDataExtensionUsage = async (
    mid,
    page = currentPage,
    size = perPage,
    sortFld = sortField,
    sortDrc = sortDirection
  ) => {
    const subTypesWithoutTitle = ',';
    const searchParams = {
      limit: size,
      offset: size * (page - 1),
      sortField: sortFld,
      sortDirection: sortDrc,
      type: filters?.type.join(','),
      subType:
        filters?.subType.length > 0
          ? subTypesWithoutTitle + filters.subType.join(',')
          : subTypesWithoutTitle,
      source: filters?.source,
      dataExtension: filters?.dataextension,
    };
    return await auditService.getDataExtensionUsage(mid, searchParams);
  };

  const loadDataExtensionUsage = async (
    mid,
    page = currentPage,
    size = perPage,
    firstLoad = false,
    sortFld = sortField,
    sortDrc = sortDirection
  ) => {
    if (!businessUnit?.id) return;

    try {
      setLoading(true);
      const res = await getDataExtensionUsage(
        mid,
        page,
        size,
        sortFld,
        sortDrc
      );

      switch (res.status) {
        case 200:
          const audit = res.data;
          const headerMap = _.keyBy(Object.keys(auditHeaders));
          const header = _.entries(auditHeaders)
            .map((h) => h[0])
            .filter((h) => headerMap[h])
            .map((h) => {
              const column = {
                name: auditHeaders[h]?.name || h,
                selector: (row) => row[h],
                sortable: true,
                reorder: true,
                omit: auditHeaders[h].omit || false,
                minWidth: auditHeaders[h]?.minWidth,
              };
              return column;
            });

          audit.header = header;
          if (isMounted()) {
            setTotalRows(res.data.pagination.total);
            setAudit(audit);
          }

          if (firstLoad) {
            setSubTypes(audit.subTypes);
          }

          break;

        case 401:
        case 403:
          if (isMounted()) {
            setErrorMessage('Insufficient permissions');
          }
          break;

        default:
          if (isMounted()) {
            setErrorMessage(
              'An error occurred. If the problem persist, please contact support.'
            );
          }
          break;
      }
    } catch (error) {
      console.error(error);
      if (isMounted()) {
        setErrorMessage(
          'An error occurred. If the problem persist, please contact support.'
        );
      }
    }

    if (isMounted()) {
      setLoading(false);
    }
  };

  const onBusinessUnitChange = useCallback(
    (bu) => {
      if (isMounted()) {
        // console.log(`set bu ${new Date()}`);
        setBusinessUnit(bu);
      }
    },
    [isMounted]
  );

  const handlePageChange = (page) => {
    if (businessUnit?.id) {
      loadDataExtensionUsage(
        businessUnit.mid,
        page,
        perPage,
        false,
        sortField,
        sortDirection
      );
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (businessUnit?.id) {
      loadDataExtensionUsage(
        businessUnit.mid,
        page,
        newPerPage,
        false,
        sortField,
        sortDirection
      );
    }
    setCurrentPage(1);
    setPerPage(newPerPage);
  };

  const handleSort = async (column, sortDirection) => {
    if (column?.name) {
      Object.entries(auditHeaders).forEach((audit) => {
        if (audit[1].name === column.name) {
          const sortFieldName = audit[0];
          setSortField(() => sortFieldName);
          setSortDirection(() => sortDirection);
          // When sorting, it also changes the page to first page. It's not a problem when we sort it on the first page, but when we sort it on the other pages, it makes double call. So, it makes the first call here and then the second one when the page changes to first page. I wrote it in setTimeout function to make the call after the one that is called when the page changes. Not an ideal solution and it doesn't guarantee that this call will be succeed the last. But I couldn't find better solution for our case so far.
          setTimeout(() => {
            loadDataExtensionUsage(
              businessUnit.mid,
              1,
              perPage,
              false,
              sortFieldName,
              sortDirection
            );
          }, 100);
        }
      });
    }
  };

  useLayoutEffect(() => {
    // if (!filters.type?.length || _.find(filters.type, v => v === 'asset')) {
    if (audit?.usage) {
      const subTypes = _.orderBy(
        _.uniq(
          audit?.usage?.map((row) => row.source_subType)?.filter((v) => v)
        ),
        (v) => v
      );

      dispatchFilter({ type: 'loadSubType', value: subTypes });
    }
    // }
    // return [];
  }, [audit?.usage]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = (prop) => {
      if (filters[prop]) {
        setResetPaginationToggle(!resetPaginationToggle);
        if (prop === 'subType') {
          dispatchFilter({ type: 'clearSubType' });
        } else if (prop === 'type') {
          dispatchFilter({ type: 'clearType' });
        } else {
          dispatchFilter({ type: prop, value: '' });
        }
      }
    };

    const onfilter = (event, type) => {
      dispatchFilter({ type: type, value: _.castArray(event.target.value) });
    };

    const trackEvent = (name) => {
      AppInsights.trackEvent(user, {
        name: name,
        properties: {
          businessUnit: _.pick(businessUnit, 'name', 'id', 'repository.name'),
        },
      });
    };
    return !businessUnit?.id || loading ? (
      <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '10px' }}>
        {[1, 2, 3, 4].map((i) => {
          return (
            <Skeleton
              key={i}
              variant="rectangular"
              width={'100%'}
              height={'100%'}
              style={{
                flex: '1 1',
                width: '200px',
                borderRadius: '0.8rem',
                minHeight: '60px',
              }}
            />
          );
        })}
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={'100%'}
          style={{
            width: '90px',
            borderRadius: '0.8rem',
            minHeight: '60px',
          }}
        />
      </Box>
    ) : (
      <>
        <DEUsageListFilter
          id="type"
          items={initialFilters.type}
          label={'Type'}
          onFilter={(e) => {
            trackEvent('Click::Filter:Type');
            onfilter(e, 'type');
          }}
          onClear={() => {
            trackEvent('Click::Clear:Type');
            handleClear('type');
          }}
          selection={filters?.type}
          clearButtonLabel="Reset to default values"
          clearButtonIcon={<SettingsBackupRestore />}
        />
        <DEUsageListFilter
          id="subType"
          enabled={filters.type.filter((v) => v === 'asset').length > 0}
          items={subTypes}
          label={'Sub type'}
          onFilter={(e) => {
            trackEvent('Click::Filter:Sub type');
            onfilter(e, 'subType');
          }}
          onClear={() => {
            trackEvent('Click::Clear:Sub type');
            handleClear('subType');
          }}
          selection={filters.subType}
          clearButtonLabel="Reset to default values"
          clearButtonIcon={<SettingsBackupRestore />}
        />
        <DEUsageTextFilter
          id="source"
          label={'Source'}
          placeholder={'name or key'}
          onFilter={(e) => {
            dispatchFilter({ type: 'source', value: e.target.value });
          }}
          onClear={() => {
            trackEvent('Click::Clear:Source');
            handleClear('source');
          }}
          filterText={filters.source}
          loadDataExtensionUsage={loadDataExtensionUsage}
          businessUnit={businessUnit}
          currentPage={currentPage}
          perPage={perPage}
          sortField={sortField}
          sortDirection={sortDirection}
        />
        <DEUsageTextFilter
          id="dataextension"
          label={'Data extension'}
          placeholder={'name or key'}
          onFilter={(e) => {
            dispatchFilter({ type: 'dataextension', value: e.target.value });
          }}
          onClear={() => {
            trackEvent('Click::Clear:Data extension');
            handleClear('dataextension');
          }}
          filterText={filters.dataextension}
          loadDataExtensionUsage={loadDataExtensionUsage}
          businessUnit={businessUnit}
          currentPage={currentPage}
          perPage={perPage}
          sortField={sortField}
          sortDirection={sortDirection}
        />
        <Button
          style={{ zIndex: '2' }}
          size="small"
          variant="contained"
          color="primary"
          startIcon={<FilterListIcon />}
          onClick={() => {
            if (businessUnit?.id) {
              loadDataExtensionUsage(
                businessUnit.mid,
                currentPage,
                perPage,
                false,
                sortField,
                sortDirection
              );
            }
          }}
        >
          Filter
        </Button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, subTypes, resetPaginationToggle, businessUnit, loading, user]);

  useEffect(() => {
    setLoading(true);
    if (businessUnit?.id) {
      loadDataExtensionUsage(businessUnit.mid, 1, perPage, true);
    } else {
      if (isMounted()) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnit, isMounted]);

  const ExpandedComponent = ({ data }) => (
    <table className="expanded-row">
      <tbody>
        {Object.keys(data).map((key, index) => (
          <tr key={'x-r-' + index}>
            <td className="label">{key}</td>
            <td className="value">{data[key]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const backUrlFull = useMemo(
    () =>
      Util.resolveBackUrl({
        navigationState: location.state,
        fallback: document.referrer,
        fallbackHome: true,
      }),
    [location.state]
  );
  const backUrlParts = backUrlFull.split('/');
  const backUrl = backUrlParts[backUrlParts.length - 1]; // Or parts.pop();

  return (
    <div className="audits de-usage">
      <Box className={'backButtonBox' + (!backUrlFull ? ' invalid' : '')}>
        <Link to={`/${backUrl}`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <div className="audits-header">
        <h2 className="audits-title">
          <DataUsageIcon className="audits-title__icon" />
          <span>Data extension usage</span>
        </h2>
      </div>
      <div className="data-table-container">
        <BusinessUnitDetails
          mid={mid?.value}
          onLoad={onBusinessUnitChange}
          user={user}
          showOnlyHeader={true}
        />

        <Box>
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
          {!errorMessage && businessUnit?.id ? (
            <>
              <div className="data-table" style={{ marginTop: '1rem' }}>
                <DataTable
                  columns={loading ? [] : audit?.header}
                  data={audit?.usage}
                  progressPending={loading}
                  progressComponent={
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={'100%'}
                      style={{
                        flex: '1 1',
                        width: '0',
                        borderRadius: '0.8rem',
                        minHeight: '550px',
                      }}
                    />
                  }
                  pagination
                  paginationServer
                  onSort={handleSort}
                  sortServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[50, 100, 200, 500]}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  dense
                  fixedHeader={!loading}
                  fixedHeaderScrollHeight={'600px'}
                  order
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  customStyles={{
                    headRow: {
                      style: {
                        background: `${
                          darkMode
                            ? scssVariables.darkmodeColorPrimary
                            : scssVariables.colorPrimary
                        }`,
                      },
                    },
                    noData: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        background: darkMode
                          ? 'rgb(25 37 49)'
                          : 'rgb(241 241 241)',
                        color: darkMode ? '#fff' : '#222',
                      },
                    },
                    progress: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkMode
                          ? 'rgb(25 37 49)'
                          : 'rgb(241 241 241)',
                      },
                    },
                    pagination: darkMode
                      ? {
                          pageButtonsStyle: {
                            color: '#fff',
                            fill: '#fff',
                            '&:disabled': {
                              color: '#ffffff54',
                              fill: '#ffffff54',
                            },
                          },
                        }
                      : {
                          pageButtonsStyle: {
                            color: '#8d8c8c',
                            fill: '#8d8c8c',
                            '&:disabled': {
                              color: '#adadad54',
                              fill: '#adadad54',
                            },
                          },
                        },
                  }}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </Box>
      </div>
    </div>
  );
}

const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      // console.log("unmount");
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
