import React, { useEffect, useRef, useState } from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import './richTextEditor.scss';

const optionButtons = [
  {
    id: 'bold',
    tag: 'b',
    icon: <FormatBoldIcon />,
    highligtable: true,
  },
  {
    id: 'italic',
    tag: 'i',
    icon: <FormatItalicIcon />,
    highligtable: true,
  },
  {
    id: 'underline',
    tag: 'u',
    icon: <FormatUnderlinedIcon />,
    highligtable: true,
  },
  {
    id: 'strikethrough',
    tag: 'strike',
    icon: <FormatStrikethroughIcon />,
    highligtable: true,
  },
  {
    id: 'undo',
    icon: <UndoIcon />,
    highligtable: false,
  },
  {
    id: 'redo',
    icon: <RedoIcon />,
    highligtable: false,
  },
  {
    id: 'createLink',
    icon: <LinkIcon />,
    highligtable: false,
  },
  {
    id: 'unlink',
    icon: <LinkOffIcon />,
    highligtable: false,
  },
];

const advancedOptionButtons = [
  {
    id: 'foreColor',
    title: 'Font',
  },
  {
    id: 'backColor',
    title: 'Highlight',
  },
];

function getTagsInNode(node) {
  const tags = [];
  while (node !== document && node !== null) {
    if (node.tagName) {
      tags.push(node.tagName.toLowerCase());
    }
    node = node.parentNode;
  }
  return tags;
}

const RichTextEditor = ({
  content,
  setContent,
  contentRef,
  renderAgain,
  setRenderAgain,
}) => {
  const optionRef = useRef(null);
  const [tagsArray, setTagsArray] = useState([]);

  useEffect(() => {
    function handleEvent(event) {
      if (event.type === 'keyup') {
        const target = event.target;
        if (target === contentRef.current) {
          const selection = window.getSelection();
          if (selection.anchorNode !== null) {
            const tags = getTagsInNode(selection.anchorNode.parentNode);
            setTagsArray(tags);
          } else {
            setTagsArray([]);
          }
        } else {
          setTagsArray([]);
        }
      }
    }

    let contentRefValue = contentRef.current;

    contentRefValue.addEventListener('keyup', handleEvent, true);

    return () => {
      contentRefValue.removeEventListener('keyup', handleEvent, true);
    };
  }, [contentRef]);

  const onContentChange = React.useCallback(
    (evt) => {
      setContent(evt.target.value);
    },
    [setContent]
  );

  const modifyText = (command, defaultUi, value) => {
    document.execCommand(command, defaultUi, value);
  };

  const imageCopyFlow = React.useCallback(() => {
    if (!contentRef?.current) {
      return;
    }
    contentRef.current.addEventListener('paste', (e) => {
      const clipboardData = e.clipboardData;
      clipboardData.types.forEach((type, i) => {
        const fileType = clipboardData.items[0].type;
        if (fileType.match(/image.*/)) {
          const file = clipboardData.items[0].getAsFile();
          const reader = new FileReader();
          reader.onload = function (evt) {
            const dataURL = evt.target.result;
            const img = document.createElement('img');
            img.src = dataURL;
            document.execCommand('insertHTML', true, img.outerHTML);
          };
          reader.readAsDataURL(file);
        }
      });
    });
  }, [contentRef]);

  useEffect(() => {
    imageCopyFlow();
  }, [imageCopyFlow]);

  return (
    <div className="rich-text__editor">
      <div className="rich-text__editor-options" ref={optionRef}>
        {optionButtons.map((button) => {
          return (
            <button
              key={button.id}
              id={button.id}
              className={
                tagsArray.includes(button.tag)
                  ? 'option-button format active'
                  : 'option-button format'
              }
              onMouseDown={(evt) => {
                evt.preventDefault();
              }}
              onClick={(evt) => {
                if (button.highligtable) {
                  evt.currentTarget.classList.toggle('active');
                }
                evt.preventDefault();
                if (evt.currentTarget.classList.contains('active')) {
                  modifyText(evt.currentTarget.id, true, null);
                } else {
                  modifyText(evt.currentTarget.id, false, null);
                }
                if (button.id === 'createLink') {
                  let userLink = prompt('Enter a URL');
                  if (/http/i.test(userLink)) {
                    modifyText(button.id, false, userLink);
                  } else {
                    userLink = 'http://' + userLink;
                    modifyText(button.id, false, userLink);
                  }
                }
              }}
            >
              {button.icon}
            </button>
          );
        })}
        {advancedOptionButtons.map((button) => {
          return (
            <div key={button.id} className="input-wrapper">
              <input
                id={button.id}
                type="color"
                className="adv-option-button"
                onChange={(e) => {
                  modifyText(e.currentTarget.id, false, e.currentTarget.value);
                }}
                onMouseDown={(evt) => {
                  evt.preventDefault();
                }}
              />
              <span>{button.title}</span>
            </div>
          );
        })}
      </div>
      <div
        className="rich-text__editor-content"
        onChange={onContentChange}
        onKeyUp={() => setRenderAgain(!renderAgain)}
        html={content}
        ref={contentRef}
        contentEditable
      ></div>
    </div>
  );
};

export default RichTextEditor;
