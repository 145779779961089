import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CancelIcon from '@mui/icons-material/Cancel';
import scssVariables from '../../styles/variables.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Groups = ({ userState, setUserState, theme, getStyles }) => {
  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;

    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        groups: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };

  const handleGroupChipDelete = (value) => (event) => {
    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        groups: userState.permissions.groups.filter(
          (groupName) => groupName !== value
        ),
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Typography
        component="h4"
        variant="h6"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.1rem',
          fontWeight: '600',
        }}
      >
        <WorkspacesIcon className="icon" />
        <Box sx={{ lineHeight: '1' }}>Groups</Box>
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Groups</InputLabel>
        <Select
          sx={{ minHeight: '56px' }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={userState.permissions.groups || []}
          onChange={handleGroupChange}
          input={<OutlinedInput id="select-multiple-chip" label="Groups" />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                padding: '.5rem',
              }}
            >
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  className="chip chip_active"
                  onDelete={handleGroupChipDelete(value)}
                  style={{
                    background: scssVariables.scss,
                    fontWeight: 'bold',
                    color: '#fff',
                    borderRadius: '16px',
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {userState.availableGroups.map((group) => (
            <MenuItem
              key={group}
              value={group}
              style={getStyles(
                group,
                userState.permissions.groups || [],
                theme
              )}
            >
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Groups;
