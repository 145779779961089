import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useState } from 'react';

const UserAddFlow = ({ handleRowAdd }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState('');

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const userAddHandler = () => {
    let newData = {
      username: username,
      tableData: {
        id: username,
      },
    };
    return new Promise((resolve) => {
      handleRowAdd(newData, {}, resolve);
      handleCloseDialog();
    });
  };

  return (
    <Box>
      <Tooltip title="Add User">
        <Button
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={handleClickOpenDialog}
        >
          Add
        </Button>
      </Tooltip>
      <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add user, please enter email address here. After adding user,
              you can select the permissions of the user.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={userAddHandler}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default UserAddFlow;
