import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../styles/tutorial/tutorial.scss';

export default function TutorialHelper(page) {
  let steps;

  if (page === 'Home') {
    // Home
    steps = HomeSteps();
  } else if (page === 'DE-Usage') {
    // DE-Usage
    steps = DEUsage();
  } else if (page === 'DE-Dependency') {
    // DE-Dependency
    steps = DEDependency();
  } else if (page === 'Browse-Data-Extensions') {
    // Browse-Data-Extensions
    steps = BrowseDE();
  } else if (page === 'None') {
    // None
    steps = NoneSteps();
  }

  let scrollToElement = true;

  if (page === 'DE-Usage' || page === 'Home') {
    scrollToElement = false;
  }

  introJs()
    .setOptions({
      steps: steps,
      tooltipClass: 'customTooltip',
      showProgress: true,
      showBullets: false,
      scrollToElement: scrollToElement,
    })
    .start();
}

function NoneSteps() {
  return [
    {
      title: 'Tutorials',
      intro:
        'There is no tutorial for this page. But if you have a question, feel free to reach us.',
    },
  ];
}

function HomeSteps() {
  return [
    {
      title: 'Welcome to SFMC Workbench!',
      intro:
        "Welcome to our app! We are glad you are here. We hope you find the app useful and enjoyable. If you have any questions or need help with anything, please don't hesitate to reach out to us. We are here to assist you and make your experience with our app as enjoyable as possible.",
    },
    {
      element: document.querySelector('.navbar-center'),
      intro: 'You can use the navbar to navigate through the app',
      title: 'Navigation',
    },
    {
      element: document.querySelector('#workbench-logo'),
      intro:
        'This is the initial page, which displays a list of existing business units.',
      title: 'Navigation',
    },
    {
      intro:
        'In Salesforce Marketing Cloud, a business unit is a logical container for organizing and managing marketing activities and resources. Business units can be used to separate marketing activities and resources for different parts of an organization. Each business unit has its own set of permissions and controls, which allows administrators to manage access to marketing resources and activities at a granular level.',
      title: 'Business Units',
    },
    {
      element: document.querySelector('.audits'),
      intro:
        'On this Business Unit panel, you can view all the Business Units that you have access to.',
      title: 'Workbench',
    },
    {
      element: document.querySelector('.search-field'),
      intro:
        'Using this field, you can filter your search to specific Business Units.',
      title: 'Workbench',
    },
    {
      element: document.querySelector('.bu-list-item__panel'),
      intro:
        'You can view the details of each business unit by expanding it, and you will find the navigation links below.',
      title: 'Workbench',
    },
    {
      element: document.querySelector('.bu-list-item__panel'),
      intro:
        'To view usage information for the selected Business Unit, you can navigate to the Data Extension Usage page. ',
      title: 'Data Extension Usage',
    },
    {
      element: document.querySelector('.bu-list-item__panel'),
      intro:
        'To see the dependency graph and browse through the data extensions and queries for the selected Business Unit, you can go to the Data Extension Dependencies page and make your selections there. ',
      title: 'Data Extension Dependencies',
    },
    {
      element: document.querySelector('.bu-list-item__panel'),
      intro:
        'To explore data extensions, you can visit the Browse Data Extensions page. ',
      title: 'Browse Data Extensions',
    },
  ];
}

function DEUsage() {
  return [
    {
      title: 'Data Extension Usage',
      intro:
        'In Salesforce Marketing Cloud, data extensions are used to store and manage large sets of data. A data extension usage table is a special type of data extension that is used to track and store information about the usage of other data extensions within the Marketing Cloud platform.',
    },
    {
      element: document.querySelector('.data-table-container'),
      intro:
        'On this field, you can view data extensions, apply filters, and sort them according to your needs.',
      title: 'Data Extension Usage Table',
    },
    {
      element: document.querySelector('#type'),
      intro:
        'You have the option to filter data extension usage by their type.',
      title: 'Data Extension Usage Table Filter',
    },
    {
      element: document.querySelector('#subType'),
      intro: 'You can filter the data extension usages by their sub types.',
      title: 'Data Extension Usage Table Filter',
    },
    {
      element: document.querySelector('#source'),
      intro:
        'You can filter the data extension usages by the source name to view and analyze usage data from specific sources.',
      title: 'Data Extension Usage Table Filter',
    },
    {
      element: document.querySelector('#dataextension'),
      intro:
        'You can filter data extension usage by the data extension itself.',
      title: 'Data Extension Usage Table Filter',
    },
    {
      element: document.querySelector('.rdt_TableHeadRow'),
      intro:
        'You can sort the fields by clicking on the column headers in the table data, which can be found here.',
      title: 'Data Extension Usage Table Sort',
    },
  ];
}

function DEDependency() {
  return [
    {
      title: 'Data Extension Dependencies',
      intro:
        'Data extension dependencies refer to the relationships between data extensions and how they are used within the Marketing Cloud platform. For example, one data extension may depend on another data extension in order to properly function or be used in certain marketing activities.',
    },
    {
      element: document.querySelector('.de-dependency'),
      title: 'Data Extension Dependencies',
      intro:
        'To view data extension dependencies and queries, select the data extensions and queries you want to browse.',
    },
  ];
}

function BrowseDE() {
  return [
    {
      title: 'Browse Data Extensions',
      intro:
        'Data extension dependencies refer to the relationships between data extensions and how they are used within the Marketing Cloud platform.',
    },
    {
      element: document.querySelector('.de-selection'),
      title: 'Browse Data Extensions',
      intro:
        'To browse data extension dependencies, select the data extensions you want to browse.',
    },
    {
      element: document.querySelector('.query-grid-item'),
      title: 'Browse Data Extensions',
      intro:
        'You can add rules and condition groups to query to specify and refine the search criteria.',
    },
    {
      element: document.querySelector('#data-extension-search'),
      title: 'Browse Data Extensions',
      intro:
        'After you make the selections, you can click Search button to browse data extensions.',
    },
    {
      element: document.querySelector('.popover-button'),
      title: 'Browse Data Extensions',
      intro:
        'To download the table as a CSV, simply click the "Download" button.',
    },
  ];
}
