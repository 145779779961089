import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Flags = ({ userState, setUserState, theme, getStyles }) => {
  const handleFlagChange = (event) => {
    const {
      target: { value },
    } = event;

    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        flags: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };

  const handleFlagChipDelete = (value) => (event) => {
    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        flags: userState.permissions.flags.filter(
          (flagName) => flagName !== value
        ),
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Typography
        component="h4"
        variant="h6"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.1rem',
          fontWeight: '600',
        }}
      >
        <FlagIcon className="icon" />
        <Box sx={{ lineHeight: '1' }}>Flags</Box>
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Flags</InputLabel>
        <Select
          sx={{ minHeight: '56px' }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={userState.permissions.flags || []}
          onChange={handleFlagChange}
          input={<OutlinedInput id="select-multiple-chip" label="Flags" />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                padding: '.5rem',
              }}
            >
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={handleFlagChipDelete(value)}
                  className="chip chip_active"
                  style={{
                    fontWeight: 'bold',
                    color: '#fff',
                    borderRadius: '16px',
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {userState.availableFlags.map((flags) => (
            <MenuItem
              key={flags}
              value={flags}
              style={getStyles(flags, userState.permissions.flags || [], theme)}
            >
              {flags}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Flags;
