import DataTable from 'react-data-table-component';
import Util from '../lib/util';
import { isFirefox } from 'react-device-detect';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, IconButton, LinearProgress, Tooltip } from '@mui/material';
import QueryUtils from './DataExtensionQueryUtils';
import {
  ArrowDropDown,
  ArrowDropUp,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import RowCopyFieldDialog from './RowCopyFieldDialog';

function RenderHeader({ name, sortable, field, sort, direction, onSort }) {
  const sortByThis = sort === name;
  const toggleDirection =
    sortByThis && direction === 'asc'
      ? 'desc'
      : sortByThis && direction === 'desc'
      ? ''
      : 'asc';
  const icon =
    sortByThis && direction === 'desc' ? (
      <ArrowDropDown />
    ) : sortByThis && direction === 'asc' ? (
      <ArrowDropUp />
    ) : (
      ''
    );

  // display the name with proper case, from soap request.

  const req = field?.IsRequired === 'true';
  const pk = field?.IsPrimaryKey === 'true';

  return (
    <>
      <Tooltip
        title={
          <div className="rowdata-cell-tooltip">
            {field?.FieldType || 'Text'}
            {getFieldSizeNote(field, { padLeft: true })}
            {pk ? ' (primary key)' : req ? ' (required)' : ''}
          </div>
        }
        enterDelay={500}
        enterNextDelay={500}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <span>
          <Button
            size="small"
            disabled={!sortable}
            endIcon={icon}
            onClick={() => {
              onSort?.(toggleDirection ? name : '', toggleDirection);
            }}
          >
            {field?.Name || name}
          </Button>
        </span>
      </Tooltip>
    </>
  );
}

const pageStateReducer = (state, action) => {
  const newState = state || action ? _.merge({}, state || {}, action) : null;
  if (action.resetOrder !== true) {
    delete newState.resetOrder;
  }
  return newState;
};

export default function RowData({ input, loading, paging, onChange }) {
  const measures = useRef();

  const [pageState, reducePageState] = useReducer(pageStateReducer);
  const [ready, setReady] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo] = useState({});

  function handleTooltipClick(e, cell, title) {
    if (e.detail === 2) {
      setOpenDialog(true);
      setSelectedRowInfo({
        title,
        cell,
      });
    }
  }

  function renderCell(field, row, index, column, id) {
    let cell = column.selector(row);
    let selectable = true;
    let titleValue = '';
    if (/^http(|s):[/][/]/i.test(cell)) {
      selectable = false;
      cell = (
        <a href={cell} target="_blank" rel="noreferrer">
          {cell}
        </a>
      );
    } else {
      if (/^(0[.]d\+|[1-9]\d+([.]\d+){0,1})$/.test(cell)) {
        const val = Number(cell);
        if (val) {
          titleValue = Util.numberWithCommas(val);
        }
      }
    }

    // const req = field?.IsRequired === 'true';
    // const pk = field?.IsPrimaryKey === 'true';

    const title = cell && titleValue && cell !== titleValue && (
      <div className="rowdata-cell-tooltip">
        {/* {field?.Name}: {field?.FieldType || 'Text'}{' '}
        {pk ? '(primary key)' : req ? '(required)' : ''} <br /> */}
        {titleValue}
      </div>
    );

    const cssClasses = ['i-cell'];
    if (selectable) {
      cssClasses.push('selectable');
    }
    return title ? (
      <Tooltip
        title={title}
        enterDelay={500}
        enterNextDelay={500}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <div className={cssClasses.join(' ')}>{cell}</div>
      </Tooltip>
    ) : (
      <>
        <div
          onClick={(e) => handleTooltipClick(e, cell, field.Name)}
          className={cssClasses.join(' ')}
        >
          {cell}
        </div>
      </>
    );
  }

  useEffect(() => {
    if (pageState && !pageState.resetOrder) {
      onChange(pageState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  useEffect(() => {
    // grid render waits for DOM ready
    setReady(measures.current ? 1 : 0);
  }, []);

  const rowData = useMemo(() => {
    if (!ready) {
      return { header: [], items: [] };
    }

    // columns names in rest api results are lower case
    const fieldsMap = _.keyBy(
      input?.dataExtension?.Fields?.filter((field) => !field.wsfmc__hidden) ||
        [],
      (field) => field.Name.toLowerCase()
    );
    const firstRow = input?.results?.items?.[0] || {};
    const columnNames = [..._.keys(firstRow.keys), ..._.keys(firstRow.values)];
    const attributeNames = _.keys(firstRow.attributes || {});
    const headerWidths = _.keyBy(
      columnNames.concat(attributeNames).map((c) => {
        measures.current.textContent = c;
        const width = measures.current.clientWidth + 20;
        return { width, name: c };
      }),
      'name'
    );

    const items =
      input?.results?.items?.map((row, index) => {
        const mergedRow = _.merge(
          { id: index },
          row.keys,
          row.values,
          row.attributes || {}
        );
        // we change the format here because it affects sorting
        for (const field in mergedRow) {
          const fieldData = fieldsMap[field];
          const cell = mergedRow[field];
          if (cell && fieldData?.FieldType === 'Date') {
            mergedRow[field] = QueryUtils.validateDateValue(cell).value;
          }
        }
        return mergedRow;
      }) || [];

    const getSize = (c) => {
      const largest = _.maxBy(items, (item) => item[c]?.length || 1)?.[c];
      //   const length = largest?.length || 1;
      measures.current.textContent = largest || '';
      const cellWidth = measures.current.clientWidth + 15;
      const maxWidth = Math.max(cellWidth, headerWidths[c].width);
      return Math.min(largest?.length <= 100 ? maxWidth : 400, maxWidth);
    };

    const orderByParts = input?.orderBy?.split(' ') || [];
    const currentSortDirection = orderByParts.pop();
    const currentSort = orderByParts.join(' ');

    const headerColumns = _.sortBy(
      columnNames,
      (col) => (fieldsMap[col]?.IsPrimaryKey === 'true' ? 0 : 1),
      (col) => col
    )
      .concat(_.sortBy(attributeNames, (col) => col))
      .map((name, index) => {
        const sortable = !/^_subscribers$/i.test(
          input?.dataExtension?.CustomerKey
        );

        const minWidth = `${getSize(name) + (sortable ? 30 : 0)}px`;
        const maxWidth = `${getSize(name) + 200}px`;

        const pk = fieldsMap[name]?.IsPrimaryKey === 'true';
        const req = fieldsMap[name]?.IsRequired === 'true';

        return {
          name: (
            <RenderHeader
              name={name}
              sortable={sortable}
              sort={currentSort}
              direction={currentSortDirection}
              field={fieldsMap[name]}
              onSort={(sort, direction) => {
                reducePageState({
                  page: 1,
                  orderBy: [sort || '', direction || ''].join(
                    direction ? ' ' : ''
                  ),
                });
              }}
            />
          ),
          id: `${name + (pk ? '_$pk' : req ? '_$req' : '')}`,
          selector: (row) => row[name],
          // sortable,
          // reorder: true,
          // omit: name === 'id',
          right: true,
          cell: (row, index, column, id) =>
            renderCell(fieldsMap[name], row, index, column, id),
          minWidth,
          maxWidth,
          conditionalCellStyles: [
            {
              when: (cell) => pk,
              classNames: ['pk'],
            },
            {
              when: (cell) => !pk && req,
              classNames: ['req'],
            },
          ],
        };
      });

    if (!input?.orderBy) {
      reducePageState({ orderBy: '', resetOrder: true });
    }

    const headers = (input?.dataExtension?.Fields || [])
      .filter((field) => !field.wsfmc__hidden)
      .map((field) => field?.Name?.toLowerCase())
      .concat(attributeNames);
    const filteredHeader = headerColumns?.filter((h) => {
      return headers?.includes(h?.name?.props?.name);
    });
    return {
      totalRowCount: input?.results?.count,
      header: filteredHeader,
      items,
      error: input?.error,
      orderBy: input?.orderBy,
      currentPage: input?.results?.page,
      rowsPerPage: input?.results?.pageSize,
      requestToken: input?.results?.requestToken,
      tokenExpireDateUtc: input?.results?.tokenExpireDateUtc,
    };
  }, [input, ready]);

  const cssClasses = ['datable-container'];
  if (loading) {
    cssClasses.push('loading');
  }
  if (paging) {
    cssClasses.push('paging');
  }

  return (
    <div className="RowData">
      {/* For measuring */}
      <div
        id="for-measures"
        ref={measures}
        style={{
          fontSize: '10pt',
          fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace',
          position: 'absolute',
          visibility: 'hidden',
          height: 'auto',
          width: 'auto',
          whiteSpace: 'nowrap',
        }}
      ></div>
      <RowCopyFieldDialog
        selectedRowInfo={selectedRowInfo}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <div className={cssClasses.join(' ')}>
        <PagingComponent
          countOnly={/^_subscribers$/i.test(input?.dataExtension?.CustomerKey)}
          rowData={rowData}
          onChangePage={(options) => reducePageState(options)}
          paging={paging}
          loading={loading}
        />
        <DataTable
          columns={rowData?.header || []}
          data={rowData?.items || []}
          noDataComponent={``}
          paginationComponent={() => ''}
          noTableHead={loading}
          progressPending={loading}
          progressComponent={
            <LinearProgress className="main-color" style={{ width: '100%' }} />
          }
          pagination
          paginationServer
          fixedHeader={!loading}
          fixedHeaderScrollHeight={'600px'}
          dense
          persistTableHead
          customStyles={{
            table: {
              style: {
                paddingRight: isFirefox ? '10px' : '0',
              },
            },
          }}
        />
        {!/^_subscribers$/i.test(input?.dataExtension?.CustomerKey) ? (
          <PagingComponent
            rowData={rowData}
            onChangePage={(options) => reducePageState(options)}
            paging={paging}
            loading={loading}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

const PagingComponent = React.memo(
  ({ onChangePage, rowData, paging, loading, countOnly }) => {
    const rowCount = rowData?.totalRowCount || 0;
    const rowsPerPage = rowData?.rowsPerPage;
    const currentPage = rowData?.currentPage || 1;
    const from = (currentPage - 1) * rowsPerPage + 1;
    const to = Math.min(rowCount, from + rowsPerPage - 1);
    const lastPage = Math.ceil(rowCount / rowsPerPage);
    const firstPage = 1;
    const previousPage = currentPage > 1 ? currentPage - 1 : 1;
    const nextPage = currentPage < lastPage ? currentPage + 1 : lastPage;

    return loading || rowCount === 0 ? (
      ''
    ) : countOnly && !loading && rowCount > 0 ? (
      <div className="paging-component count-only">
        Showing {rowCount} result{rowCount > 1 ? 's' : ''}.
      </div>
    ) : (
      <>
        <div className="paging-component">
          <IconButton
            variant="outlined"
            size={'small'}
            disabled={rowCount === 0 || currentPage <= 1 || paging || paging}
            onClick={() => {
              return rowCount > 0
                ? onChangePage({
                    page: firstPage,
                  })
                : '';
            }}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            variant="outlined"
            size={'small'}
            disabled={rowCount === 0 || currentPage <= 1 || paging || paging}
            onClick={() => {
              return currentPage > 1
                ? onChangePage({
                    page: previousPage,
                  })
                : '';
            }}
          >
            <NavigateBefore />
          </IconButton>
          {' page '}
          {Util.numberWithCommas(currentPage)}
          {' / '}
          {Util.numberWithCommas(lastPage)}
          {' | rows '}
          {Util.numberWithCommas(from)}
          {' - '}
          {Util.numberWithCommas(to)}
          {' / '}
          {Util.numberWithCommas(rowCount)}
          <IconButton
            variant="outlined"
            size={'small'}
            disabled={
              rowCount === 0 || currentPage >= lastPage || paging || paging
            }
            onClick={() => {
              return currentPage < lastPage
                ? onChangePage({
                    page: nextPage,
                  })
                : '';
            }}
          >
            <NavigateNext />
          </IconButton>
          <IconButton
            variant="outlined"
            size={'small'}
            disabled={
              rowCount === 0 || currentPage >= lastPage || paging || paging
            }
            onClick={() =>
              lastPage > 0
                ? onChangePage({
                    page: lastPage,
                  })
                : ''
            }
          >
            <LastPage />
          </IconButton>
        </div>
      </>
    );
  }
);

/**
 * Returns the size note for a given field, wrapped in parenthesis.
 * Ex: (100), (10,3) as in Text (100) or Decimal (10,3)
 * @param {object} field - the field object
 * @param {object} [options] - optional parameters
 * @param {boolean} [options.padLeft] - whether to pad the left side of the size note
 * @returns {string} - the size note for the field
 */
export function getFieldSizeNote(field, { padLeft }) {
  const length = Number(field?.MaxLength);
  const scale = Number(field?.Scale);
  let size = '';
  switch (field?.FieldType) {
    case 'Text':
      size = ` (${length > 0 ? length : 'MAX'})`;
      break;
    case 'Decimal':
      if (length > 0) {
        if (scale > 0) {
          size = `(${length},${scale})`;
        } else {
          size = `(${length})`;
        }
      }
      break;
  }
  return padLeft ? ' ' + size : size;
}
