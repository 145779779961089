import axios from 'axios';

const api = axios.create({
  baseURL: '/api/notifications',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const BODY_MESSAGE_TYPES = Object.freeze({
  PLAIN_TEXT: 'text',
  HTML: 'html',
});

export const NOTIFICATION_STATUSES = Object.freeze({
  unread: 'unread',
  read: 'read',
  dismissed: 'dismissed',
  pending: 'pending',
  sending: 'sending',
  sent: 'sent',
  notSent: 'notSent',
});

export const NOTIFICATION_CHANNEL = Object.freeze({
  email: 'email',
  portal: 'portal',
});

export const notificationUsersString = (notificationData) => {
  let users;
  if (notificationData.length === 1) {
    users = notificationData[0].username;
  } else if (notificationData.length === 2) {
    users =
      notificationData[0].username + ' and ' + notificationData[1].username;
  } else if (notificationData.length > 2) {
    users =
      notificationData[0].username +
      ', ' +
      notificationData[1].username +
      ' and ' +
      ` ${notificationData.length - 2} other users.`;
  }
  return users;
};

export async function getUserNotifications({
  username,
  statuses,
  limit,
  offset,
  dateFrom,
  dateTo,
}) {
  try {
    const endpoint = `/user/${username}`;
    const response = await api.get(endpoint, {
      params: {
        statuses,
        limit,
        offset,
        dateFrom,
        dateTo,
      },
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the user notifications.'
    );
    error.status = 500;
    throw error;
  }
}

export async function postUserNotifications(notificationData) {
  try {
    const endpoint = '';
    const response = await api.post(endpoint, notificationData);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to post the user notifications.'
    );
    error.status = 500;
    throw err.response.data;
  }
}

export async function updateUserNotificationStatus(
  notificationId,
  username,
  newStatus,
  fromStateName
) {
  try {
    const endpoint = `/user/${username}`;
    const response = await api.put(endpoint, {
      status: newStatus,
      notificationId,
      fromStateName,
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to update the user notification status.'
    );
    error.status = 500;
    throw error;
  }
}

export async function getUserUnreadState(username) {
  try {
    const endpoint = `/user/${username}/unread-state`;
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the user unread state.'
    );
    error.status = 500;
    throw error;
  }
}

export async function updateUserUnreadStatus(username, hasUnseen) {
  try {
    const endpoint = `/user/${username}/unread-state`;
    const response = await api.post(endpoint, { hasUnseen });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the user unread state.'
    );
    error.status = 500;
    throw error;
  }
}
