import axios from 'axios';
import _ from 'lodash';

const api = axios.create({
  baseURL: '/api/admin',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const apiWsfmc = axios.create({
  baseURL: '/api/wsfmc',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const authApi = axios.create({
  baseURL: '/auth',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let Constants = {
  // ALSO CHANGE in wsfmc-common/src/service/api/admin.service.js
  allScopes: ['audits', 'sfmc', 'businessUnitUsersView', 'builderSuiteDeploy'],
};

/**
 * Checks that the user has the given permissions
 * @param {object} user
 * @param {string | string[]} scopes - when using '*', the wildcard only applies to BU scopes.
 * @param {object} options - additional options
 * @param {Number} [options.mid] - mid
 * @param {boolean} [options.scopesOnly] - only considers scopes
 * @returns {boolean} true if the user has the given permissions
 */
export function checkPermissions(
  user,
  scopes,
  { businessUnit, scopesOnly } = {}
) {
  const mid = Number(businessUnit?.mid);
  const roles = user?.roles || user?.permissions;
  scopesOnly = !!scopesOnly;

  let allUserGroups = _.isArray(roles.groups)
    ? roles.groups.filter((g) => g && _.isString(g))
    : [];

  let usersGroups = !scopesOnly ? allUserGroups : [];

  scopes = _.castArray(scopes || []).filter((s) => s);

  if (!scopes.length) {
    return false;
  }

  const anyScope = scopes.some((s) => s === '*');
  if (anyScope) {
    scopes = _.union(
      Constants.allScopes,
      scopes.filter((s) => s !== '*')
    );
  }

  const buScopes =
    mid > 0 && _.isArray(roles.scopes?.[mid])
      ? roles.scopes?.[mid].filter((s) => s)
      : [];

  if (_.intersection(buScopes, scopes).length > 0) {
    return true;
  }

  if (_.intersection(usersGroups, scopes).length > 0) {
    return true;
  }
  if (mid > 0) {
    if (
      _.intersection(
        usersGroups,
        scopes.map((scope) => `admin.${scope}`)
      ).length > 0
    ) {
      return true;
    }
  }

  return false;
}

export function isAdmin(user) {
  return user?.roles?.groups?.includes('admin') ? true : false;
}

export async function me() {
  try {
    const endpoint = '/me';
    const response = await authApi.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get current user.'
    );
    error.status = err.response?.status || 500;
    error.maintenance =
      err.response?.headers?.['x-workbench-maintenance'] === 'true';
    throw error;
  }
}

export async function getUsersData({ mid } = {}) {
  try {
    const endpoint = '/users';
    const response = await api.get(endpoint, { mid });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the users data.'
    );
    error.status = err.response?.status || 500;
    throw error;
  }
}

export async function getUserData({ username }) {
  try {
    const endpoint = `/users/${username}`;
    const response = await api.post(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the user data.'
    );
    error.status = err.response?.status || 500;
    throw error;
  }
}

export async function postUserData({ username, permissions }) {
  try {
    const endpoint = '/users';
    const response = await api.post(endpoint, {
      username: username,
      permissions: permissions,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function updateUserData({ username, permissions }) {
  try {
    const endpoint = `/users/${username}`;
    const response = await api.put(endpoint, {
      username: username,
      permissions: permissions,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteUserData({ username }) {
  try {
    const endpoint = `/users/${username}`;
    const response = await api.delete(endpoint);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getPermissionsData() {
  try {
    const endpoint = '/permissions';
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the permissions data.'
    );
    error.status = err.response?.status || 500;
    throw error;
  }
}

export async function getAccountsData() {
  try {
    const getResponsibles = true;
    const endpoint = `/account?getResponsibles=${getResponsibles}`;
    const response = await apiWsfmc.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the accounts data.'
    );
    error.status = err.response?.status || 500;
    throw error;
  }
}

export async function getWsfmcConfigurations(key) {
  try {
    const endpoint = `/wsfmc-configuration`;
    const response = await api.get(endpoint, { key });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the app configurations.'
    );
    error.status = err.response?.status || 500;
    throw error;
  }
}

export async function editWsfmcConfiguration(key, newValue) {
  try {
    const endpoint = `/wsfmc-configuration`;
    const response = await api.put(endpoint, { key, value: newValue });
    return response.data;
  } catch (err) {
    if (err.response.status === 400) {
      let error = new Error(err.response.data.message.details[0].message);
      error.status = err.response?.status || 500;
      throw error;
    } else {
      let error = new Error(
        'An error has occurred while trying to edit the app configurations.'
      );
      error.status = err.response?.status || 500;
      throw error;
    }
  }
}
