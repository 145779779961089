import { createContext, useReducer } from 'react';
import SidebarCollapseReducer from './sidebarCollapseReducer';

const INITIAL_STATE = {
  collapseMode: localStorage.getItem('collapseMode') === 'true' ? true : false,
};

export const SidebarCollapseContext = createContext(INITIAL_STATE);

export const SidebarCollapseContextProvider = ({ children }) => {
  const [state, dispatchCollapseMode] = useReducer(
    SidebarCollapseReducer,
    INITIAL_STATE
  );

  return (
    <SidebarCollapseContext.Provider
      value={{ collapseMode: state.collapseMode, dispatchCollapseMode }}
    >
      {children}
    </SidebarCollapseContext.Provider>
  );
};
