import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import Util from '../../lib/util';

const ExportCSVButton = ({ headers, data, filename }) => {
  const header = Util.toCsv(headers, ',');
  const rows = data.map((row) => Util.toCsv(row, ','));

  const csvData = [header, ...rows].join('\n');

  const handleExport = () => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <Tooltip title="Export CSV">
      <IconButton
        color="primary"
        aria-label="Export CSV"
        onClick={handleExport}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportCSVButton;
