import _ from 'lodash';
import { Alert, Box, Button, CircularProgress, Stack } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';

import { Children, useCallback, useState } from 'react';
import { downloadBuilderSuitePackage } from '../../../service/builderSuiteApiService/builderSuiteApiService';
import PackageSummary from './PackageSummary';

export default function ExpandedTaskDetails({ data }) {
  const [isLoadingDownloadButton, setIsLoadingDownloadButton] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [isPackageDeployment] = useState(
    /^BS-ZipDeploy-\d+$/i.test(data.taskCode)
  );
  const [isPackageCreation] = useState(
    /^BS-CreatePackage-\d+$/i.test(data.taskCode)
  );

  const handleDownloadPackageClick = useCallback(async () => {
    try {
      setIsLoadingDownloadButton(true);
      try {
        const downloadUrl = await downloadBuilderSuitePackage(
          data.mid,
          data.id
        );
        window.open(downloadUrl.url);
      } catch (error) {
        if (error?.request?.status === 404) {
          setDownloadError('The package is not available for download');
        } else {
          setDownloadError(
            'Error downloading the package. Please try again later. If the error persists, please contact support.'
          );
        }
      }
      setIsLoadingDownloadButton(false);
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const doEnableDownloadPackage = useCallback(() => {
    return ['completed', 'failed', 'completedWithErrors'].includes(
      data.statusName
    );
  }, [data]);

  const doShowLoadingDownloadButton = useCallback(() => {
    return isLoadingDownloadButton;
  }, [isLoadingDownloadButton]);

  const hasMissingOrUnsupported =
    isPackageCreation === true &&
    (Object.values(data?.output?.unsupportedDependencies || []).length > 0 ||
      Object.values(data?.output?.missingObjects || []).length > 0);

  const packageCreationError = isPackageCreation
    ? data?.output?.error?.message
    : null;

  const packageCreationWarning = isPackageCreation
    ? data?.output?.warning?.message
    : null;

  const isLegacy = data.output?.['$version'] === 'legacy';
  const canDownload =
    (isPackageCreation && !packageCreationError) ||
    (isPackageDeployment && (isLegacy || data.output?.hasZip));

  const logs =
    data.logs?.filter((log) => !log.message?.startsWith('System -')) || [];

  return (
    <Box
      sx={{
        textAlign: 'left',
        maxWidth: '100%',
        mt: 2,
        mb: 2,
        ml: 4,
      }}
    >
      <table className="expanded-row" style={{ textAlign: 'left' }}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <strong>Details</strong>
            </td>
          </tr>
          <tr className="filler"></tr>
          <tr>
            <td className="label">Task ID</td>
            <td className="value">{data.id}</td>
          </tr>
          <tr>
            <td className="label">Instance ID</td>
            <td className="value">{data.instanceId}</td>
          </tr>
          <tr>
            <td className="label">Task Code</td>
            <td className="value">{data.taskCode}</td>
          </tr>
          {data.revision ? (
            <>
              <tr>
                <td className="label">Revision</td>
                <td className="value">{data.revision?.sha}</td>
              </tr>
              <tr>
                <td className="label">Commit Date</td>
                <td className="value">{data.revision?.date}</td>
              </tr>
            </>
          ) : (
            ''
          )}
          <tr className="filler"></tr>
          <tr>
            <td colSpan={2}>
              <Stack spacing={1} direction={'row'}>
                {isPackageCreation ? (
                  <PackageSummary
                    showButton={true}
                    buttonTitle={'View the objects list'}
                    objectReferences={data.output?.objectReferences}
                    missingObjects={data.output?.missingObjects}
                    unsupportedDependencies={
                      data.output?.unsupportedDependencies
                    }
                  />
                ) : (
                  <></>
                )}
                {canDownload ? (
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadPackageClick}
                    disabled={!doEnableDownloadPackage()}
                    sx={{ mr: 2, width: '250px' }}
                  >
                    <div>Download Package</div>
                    {doShowLoadingDownloadButton() ? (
                      <CircularProgress
                        sx={{ ml: 2 }}
                        size="1.5rem"
                        color="inherit"
                      />
                    ) : (
                      ''
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
              {downloadError ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setDownloadError(null);
                  }}
                  sx={{ mr: 2, mt: 1, textAlign: 'left', maxWidth: '400px' }}
                >
                  {downloadError}
                </Alert>
              ) : (
                ''
              )}
            </td>
          </tr>
          <tr className="filler"></tr>
        </tbody>
      </table>
      {isPackageDeployment ? <InstalledObjects data={data} /> : <></>}
      {isPackageCreation && hasMissingOrUnsupported ? (
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Alert
                  severity="warning"
                  sx={{ mb: 1, mt: 1, textAlign: 'left', maxWidth: '100vw' }}
                >
                  The package was generated despite some objects being missing
                  or unsupported. Please note that this package is incomplete.
                </Alert>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {packageCreationError ? (
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Alert
                  severity="error"
                  sx={{ mb: 1, mt: 1, textAlign: 'left', maxWidth: '100vw' }}
                >
                  {packageCreationError}
                </Alert>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {packageCreationWarning ? (
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <Alert
                  severity="warning"
                  sx={{ mb: 1, mt: 1, textAlign: 'left', maxWidth: '100vw' }}
                >
                  {packageCreationWarning}
                </Alert>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {logs.length ? (
        <div>
          <strong>Task logs</strong>
          <table style={{ padding: '0', borderSpacing: '10px' }}>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Severity</th>
                <th>Message</th>
              </tr>
              {Children.toArray(
                logs.map((log) => {
                  return (
                    <tr>
                      <td>{log.timestamp}</td>
                      <td>{log.levelName}</td>
                      <td>{log.message}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
}

function nameCollisionItems(nameCollisions) {
  try {
    return _.sortBy(Object.keys(nameCollisions), (filename) =>
      filename.toLowerCase()
    ).map((filename) => (
      <li style={{ marginLeft: '20px' }}>
        {filename}
        <ul>
          {Children.toArray(
            _.sortBy(Object.values(nameCollisions[filename]), (folder) =>
              folder.toLowerCase()
            ).map((folder) => <li style={{ marginLeft: '30px' }}>{folder}</li>)
          )}
        </ul>
      </li>
    ));
  } catch {
    return [];
  }
}

function keyCollisionItems(keyCollisions) {
  try {
    return _.sortBy(Object.keys(keyCollisions), (type) => type).map((type) => (
      <li style={{ marginLeft: '20px' }}>
        {type}
        <ul>
          {Children.toArray(
            _.sortBy(Object.values(keyCollisions[type]), (key) =>
              key.toLowerCase()
            ).map((key) => <li style={{ marginLeft: '30px' }}>{key}</li>)
          )}
        </ul>
      </li>
    ));
  } catch {
    return [];
  }
}

function InstalledObjects({ data }) {
  const objectReferences = data.output?.objectReferences || {};
  const flatReferences = _.sortBy(
    _.flatMap(Object.entries(objectReferences), (entryItem) => {
      return entryItem[1].map((valueItem) => ({
        type: entryItem[0],
        ...valueItem,
      }));
    }),
    (r) => r.type,
    (r) => r.name
  );
  const byIdentifiers = _.keyBy(flatReferences, (r) => `${r.name}.${r.type}`);
  const identifiers = Object.keys(byIdentifiers);
  const error = data.output?.error?.message;
  const nameCollisions = data.output?.error?.nameCollisions;
  const keyCollisions = data.output?.error?.keyCollisions;

  const getMessages = (messages) => {
    return messages
      ? messages.map((msg) => {
          switch (msg?.toLowerCase()) {
            case 'movedtoroot':
              return ' The automation was created on the root folder because the specified folder does not exist.';
            case 'folderunchanged':
              return ' The folder was left unchanged because the specified folder does not exist.';
            default:
              return '';
          }
        })
      : '';
  };
  return error || identifiers.length > 0 ? (
    <>
      <div>
        <strong>Results</strong>
      </div>
      <table className="expanded-row">
        <tbody>
          {error ? (
            <>
              <tr className="filler"></tr>
              <tr className={'bs-results-error'}>
                <td colSpan={2}>
                  <Alert
                    severity="error"
                    sx={{ mb: 1, mt: 1, textAlign: 'left', maxWidth: '100vw' }}
                  >
                    {error}
                  </Alert>
                </td>
              </tr>
              {nameCollisions ? (
                <>
                  <tr className="filler"></tr>
                  <tr className={'bs-results-error'}>
                    <td colSpan={2}>
                      <ul>
                        {Children.toArray(nameCollisionItems(nameCollisions))}
                      </ul>
                    </td>
                  </tr>
                </>
              ) : keyCollisions ? (
                <>
                  <tr className="filler"></tr>
                  <tr className={'bs-results-error'}>
                    <td colSpan={2}>
                      <ul>
                        {Children.toArray(keyCollisionItems(keyCollisions))}
                      </ul>
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}
              <tr className="filler"></tr>
            </>
          ) : identifiers.length > 0 ? (
            <>
              <tr>
                <td colSpan={2}>
                  <table className="package-install-result">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Children.toArray(
                        identifiers.map((identifier) => {
                          const bundle = byIdentifiers[identifier];
                          const [action, actionLabel] =
                            bundle.action === 'created'
                              ? ['created', 'Created.']
                              : bundle.action === 'updated'
                              ? ['updated', 'Updated.']
                              : bundle.error
                              ? ['error', bundle.error.message]
                              : '';
                          return (
                            <tr className={`bs-results-${action}`}>
                              <td className="identifier-column">
                                {bundle.type}
                              </td>
                              <td className="identifier-column">
                                {bundle.name}
                              </td>
                              <td className="identifier-column">
                                {bundle.key}
                              </td>
                              <td>
                                {_.isArray(actionLabel)
                                  ? Children.toArray(
                                      actionLabel
                                        .filter(
                                          (item) => item && _.isString(item)
                                        )
                                        .map((item, index) => (
                                          <>
                                            {index > 0 ? <br /> : ''}
                                            {item}
                                          </>
                                        ))
                                    )
                                  : actionLabel}
                                {getMessages(bundle.messages)}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </>
          ) : _.isNil(data?.output) &&
            !['aborted', 'denied'].includes(data?.statusName) ? (
            <>
              <tr className="filler"></tr>
              <tr className="bs-results-collecting">
                <td colSpan={2}>
                  <strong>
                    The result is still being collected. Please refresh the
                    history.
                  </strong>
                </td>
              </tr>
              <tr className="filler"></tr>
            </>
          ) : (
            <tr className="filler"></tr>
          )}
        </tbody>
      </table>
    </>
  ) : (
    <></>
  );
}
