import PrePopover from '../components/ui/PrePopover';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import QueryBuilder, {
  defaultTranslations,
  formatQuery,
} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import { useSearchParams } from '../state/Navigation';
import { Alert, Grid } from '@mui/material';
import DataExtensionQueryValueProcessor from './DataExtensionQueryValueProcessor';
import { DataExtensionQueryValueEditor } from './DataExtensionQueryValueEditor';
import QueryUtils from './DataExtensionQueryUtils';

const initialQueryParam = QueryUtils.createQueryParam(QueryUtils.initialQuery);

export function DataExtensionsQueryBuilder(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useIsFirstRender();
  const selectedDataExtension = props.dataExtension;
  const { dataExtensionLoading } = props;

  const fieldsConfig = useMemo(() => {
    const list = _.sortBy(selectedDataExtension?.Fields || [], (f) =>
      f.Name.toLowerCase()
    ).map((field) => {
      const inputType = QueryUtils.inputTypes[field.FieldType] || 'text';
      const dataType = QueryUtils.dataTypes[field.FieldType] || 'text';
      return {
        name: `[${field.Name}]`,
        label: field.Name,
        sfmcType: field.FieldType,
        inputType: inputType,
        dataType: dataType,
        operators:
          (/^_subscribers$/i.test(selectedDataExtension?.CustomerKey)
            ? QueryUtils.subscriberOperatorsByDataTypes[dataType]
            : QueryUtils.dataExtensionOperatorsByDataTypes[dataType]) ||
          QueryUtils.operatorsList,
      };
    });
    const map = _.keyBy(list, 'name');
    return {
      list,
      map,
      valueProcessor: new DataExtensionQueryValueProcessor(map),
    };
  }, [selectedDataExtension?.Fields, selectedDataExtension?.CustomerKey]);

  const [rawQuery, setRawQuery] = useState(
    QueryUtils.parseRawQueryParam(searchParams.get('query'))
  );

  const query = useMemo(() => {
    const clone = _.cloneDeep(rawQuery);
    QueryUtils.unselectInvalidFields(clone, fieldsConfig || []);
    return clone;
  }, [rawQuery, fieldsConfig]);

  const cleanQuery = useMemo(() => {
    const clone = _.cloneDeep(query);
    QueryUtils.removeEmptyCriteria(clone);
    const isValid = QueryUtils.validateQuery(clone, fieldsConfig.map);

    let sql = isValid
      ? formatQuery(clone, {
          format: 'sql',
          valueProcessor: (field, operator, value) =>
            fieldsConfig?.valueProcessor?.process(field, operator, value, true),
        })
      : '!';

    let sfmcSql = isValid
      ? formatQuery(clone, {
          format: 'sql',
          valueProcessor: (field, operator, value) =>
            fieldsConfig?.valueProcessor?.process(field, operator, value),
        })
      : '!';

    if (isValid && /^[(].*[)]$/.test(sql)) {
      sql = sql.replace(/^[(]|[)]$/g, '');
    }

    if (isValid && /^[(].*[)]$/.test(sfmcSql)) {
      sfmcSql = sfmcSql.replace(/^[(]|[)]$/g, '');
    }

    const cssClasses = ['sqlQuery'];
    if (!isValid) {
      cssClasses.push('invalid');
    }

    return {
      isValid,
      sql: sql === '1 = 1' ? '' : sql,
      sfmcSql: sfmcSql === '1 = 1' ? '' : sfmcSql,
      query: clone,
      className: cssClasses.join(' '),
    };
  }, [query, fieldsConfig?.valueProcessor, fieldsConfig?.map]);

  const onQueryUpdated = props.onChange;
  useEffect(() => {
    onQueryUpdated?.(cleanQuery);
  }, [cleanQuery, onQueryUpdated, selectedDataExtension?.Fields]);

  const onQueryChange = (newQuery) => {
    const newQueryParam = QueryUtils.createQueryParam(newQuery);
    if (!isFirstRender) {
      if (newQueryParam === initialQueryParam) {
        setSearchParams();
        setRawQuery(QueryUtils.initialQuery);
      } else {
        setSearchParams({ query: newQueryParam });
        setRawQuery(newQuery);
      }
    }
  };

  const translations = {
    en: {
      ...defaultTranslations,
      addGroup: { label: '+Condition Group', title: 'Add Condition Group' },
    },
  };

  const showDebugInfo = props.user?.roles?.flags?.includes('debug');

  const queryIsInvalid = () => {
    return (
      !cleanQuery.isValid &&
      cleanQuery.sql &&
      !dataExtensionLoading &&
      selectedDataExtension?.Name
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryBuilder
          operators={QueryUtils.operatorsList}
          fields={fieldsConfig.list}
          query={query}
          onQueryChange={onQueryChange}
          autoSelectField={false}
          showCombinatorsBetweenRules={true}
          enableDragAndDrop={true}
          resetOnFieldChange={false}
          getDefaultField={() => '~'}
          controlElements={{
            valueEditor: DataExtensionQueryValueEditor,
          }}
          translations={translations['en']}
        />
      </LocalizationProvider>

      <Grid container direction={'column'}>
        <Grid item className={cleanQuery.className}>
          <pre>
            {queryIsInvalid() ? (
              <Alert
                severity="error"
                title="Invalid query"
                style={{ margin: '.5rem' }}
              >
                Invalid query
              </Alert>
            ) : (
              cleanQuery.sql &&
              !dataExtensionLoading &&
              selectedDataExtension?.Name && (
                <Alert
                  severity="success"
                  title="Query valid"
                  style={{ margin: '.5rem' }}
                >
                  {cleanQuery.sql}
                </Alert>
              )
            )}
          </pre>
        </Grid>
        {showDebugInfo ? (
          <Grid item>
            <Grid container direction={'row'} columnSpacing={1}>
              <Grid item>
                <PrePopover
                  objectContent={query}
                  buttonVariant={'outlined'}
                  buttonText={'Show JSON query'}
                  buttonColor={'secondary'}
                  buttonSize={'small'}
                />
              </Grid>
              <Grid item></Grid>
              <PrePopover
                objectContent={cleanQuery.query}
                buttonVariant={'outlined'}
                buttonText={'Show Clean JSON query'}
                buttonColor={'secondary'}
                buttonSize={'small'}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
}

function useIsFirstRender() {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
}
