import React, { useMemo, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import BusinessUnitsSearch from '../adminDetailPanel/BusinessUnitsSearch';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
};

const containsText = (user, searchText) => {
  searchText = searchText?.toString();
  return (
    !searchText ||
    user?.username
      ?.toString()
      ?.toLowerCase()
      ?.indexOf(searchText.toLowerCase()) > -1 ||
    user?.id?.toString()?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const ResponsibleAddRemoveFlow = ({
  onClickHandler,
  dialogContextText,
  buttonText,
  buttonProps,
  users,
  getStyles,
  theme,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState('');

  const [searchText, setSearchText] = useState('');

  const displayedUsernames = useMemo(
    () => users?.filter((user) => containsText(user, searchText)),
    [searchText, users]
  );

  const handleUserChange = (event) => {
    setUsername(event.target.value);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Tooltip title={buttonText}>
        <span>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpenDialog}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>
      <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{buttonText}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContextText}</DialogContentText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <FormControl sx={{ margin: '1rem 0', width: '100%' }}>
                <InputLabel id="demo-multiple-chip-label">Users</InputLabel>
                <Select
                  sx={{ minHeight: '56px' }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  value={username}
                  onChange={handleUserChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Users" />
                  }
                  onBlur={() => setSearchText('')}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                        padding: '.5rem',
                      }}
                    >
                      <HtmlTooltip
                        key={selected}
                        title={
                          <React.Fragment>
                            <Typography
                              component="div"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                lineHeight: '1.2',
                                marginBottom: '.5rem',
                              }}
                              color="inherit"
                            >
                              {selected}
                            </Typography>
                          </React.Fragment>
                        }
                        placement="right"
                        arrow
                      >
                        <Chip label={selected} className="chip chip_active" />
                      </HtmlTooltip>
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <ListSubheader sx={{ padding: '0' }}>
                    <BusinessUnitsSearch setSearchText={setSearchText} />
                  </ListSubheader>
                  {displayedUsernames?.map((user) => {
                    return (
                      <MenuItem
                        key={user.id}
                        value={user.username}
                        // style={getStyles(user.id, user.username, theme)}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '40px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              color: '#222',
                              fontWeight: 'normal',
                            }}
                          >
                            {user.username}
                          </span>
                        </Box>
                      </MenuItem>
                    );
                  }) || ''}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onClickHandler(username);
                handleCloseDialog();
              }}
            >
              {buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default ResponsibleAddRemoveFlow;
