import { Button, Popover } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useState } from 'react';

export default function PrePopover({
  objectContent,
  textContent,
  buttonText,
  buttonColor,
  buttonVariant,
  buttonSize,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverId = useId();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? popoverId : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        size={buttonSize || ''}
        variant={buttonVariant || 'contained'}
        color={buttonColor || 'primary'}
        onClick={handleClick}
      >
        {buttonText || 'Show content'}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div
          style={{
            maxWidth: '800px',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          <pre style={{ margin: '10px' }}>
            {objectContent
              ? JSON.stringify(objectContent, null, 2)
              : textContent
              ? textContent
              : ''}
          </pre>
        </div>
      </Popover>
    </div>
  );
}
