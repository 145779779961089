import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

const UserDeleteFlow = ({ handleRowDelete, rowData }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const userDeleteHandler = () => {
    return new Promise((resolve) => {
      handleRowDelete(rowData, resolve);
      handleCloseDialog();
    });
  };

  return (
    <Box>
      <Tooltip title="Delete This User">
        <IconButton
          color="error"
          aria-label="Delete This User"
          onClick={handleClickOpenDialog}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Are you sure to delete this user?`}
        </DialogTitle>
        <DialogContent>
          <strong>{rowData.username} </strong>
          will be deleted.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={userDeleteHandler}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserDeleteFlow;
