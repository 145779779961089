import { FormControl, InputAdornment, styled, TextField } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import scssVariables from '../styles/variables.scss';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
  },
}));

const DEUsageTextFilter = ({
  id,
  filterText,
  onFilter,
  onClear,
  placeholder,
  label,
  loadDataExtensionUsage,
  businessUnit,
  currentPage,
  perPage,
  sortField,
  sortDirection,
}) => {
  const inputId = 'search-' + id;

  return (
    <>
      <FormControl id={id} sx={{ m: 1, width: 200 }}>
        <CssTextField
          focusColor={scssVariables.colorPrimary}
          className="search-field"
          id={inputId}
          name={inputId}
          label={label}
          variant="outlined"
          autoComplete="nope"
          onChange={onFilter}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (businessUnit?.id) {
                loadDataExtensionUsage(
                  businessUnit.mid,
                  currentPage,
                  perPage,
                  false,
                  sortField,
                  sortDirection
                );
              }
            }
          }}
          value={filterText || ''}
          title={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {
                  <HighlightOffIcon
                    style={{ zIndex: '2' }}
                    title="Clear filter"
                    className="search-close-icon"
                    onClick={onClear}
                  ></HighlightOffIcon>
                }
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  );
};

export default DEUsageTextFilter;
