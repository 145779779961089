import React, { useRef, useState } from 'react';
import './datatable.scss';
import '../../pages/wsfmc-admin/accountAdmin.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Card from '../ui/Card';
import { AccountTree } from '@mui/icons-material';
import { Typography } from '@mui/material';
import AccountDatatableRow from './AccountDatatableRow';
import _ from 'lodash';
import DeletePackage from '../../pages/wsfmc-admin/components/DeletePackage';
import DatatableHead from './DatatableHead';
import DatatableToolbar from './DatatableToolbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getPackages(accounts) {
  const groupedBus = _.groupBy(accounts.businessUnits, 'credentialsId');
  const items = accounts.packages.map((pkg) => {
    const businessUnits = groupedBus[pkg.id] || [];
    const mainBusinessUnit = _.find(businessUnits, (bu) => !bu.parentMid);
    const activeBusinessUnits = businessUnits.filter(
      (bu) => bu.active && bu.apiEnabled
    );
    return {
      ...{
        name: mainBusinessUnit?.displayName || mainBusinessUnit?.name,
        mid: mainBusinessUnit.mid,
        activeBusinessUnits: activeBusinessUnits,
        businessUnits: businessUnits,
      },
      ...pkg,
    };
  });

  return items;
}

const accountHeadCells = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    label: 'Account Name',
    pointerEvents: true,
  },
  {
    id: 'eid',
    align: 'right',
    disablePadding: false,
    label: 'eid',
    pointerEvents: true,
  },
  {
    id: 'clientId',
    align: 'right',
    disablePadding: true,
    label: 'Client Id',
    pointerEvents: true,
  },
  {
    id: 'tenantSubdomain',
    align: 'right',
    disablePadding: true,
    label: 'Subdomain',
    pointerEvents: true,
  },
  {
    id: 'businessUnits',
    align: 'right',
    disablePadding: true,
    label: 'BUs (active)',
    pointerEvents: true,
  },
  {
    id: 'created',
    align: 'right',
    disablePadding: true,
    label: 'Created',
    pointerEvents: true,
  },
  {
    id: 'Actions',
    align: 'right',
    disablePadding: true,
    label: 'Actions',
    pointerEvents: false,
  },
];

const AccountDatatable = ({ accounts, setTrigger }) => {
  const deleteRef = useRef(null);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('email');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [data] = useState(getPackages(accounts));
  const [filteredPackages, setFilteredPackages] = useState(data);
  const [searched] = useState('');

  const requestSearch = (searchedVal) => {
    const filteredRows = !searchedVal
      ? data
      : data.filter((row) => {
          return (
            row.name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
            String(row.eid)
              ?.toLowerCase()
              ?.includes(searchedVal.toLowerCase()) ||
            row.clientId?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
            row.tenantSubdomain
              ?.toLowerCase()
              ?.includes(searchedVal.toLowerCase()) ||
            row.businessUnits?.some((bu) => {
              return (
                bu.name?.toLowerCase()?.includes(searchedVal.toLowerCase()) ||
                String(bu.mid)
                  ?.toLowerCase()
                  ?.includes(searchedVal.toLowerCase()) ||
                bu.displayName
                  ?.toLowerCase()
                  ?.includes(searchedVal.toLowerCase())
              );
            })
          );
        });
    setPage(0);
    setFilteredPackages(filteredRows);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accounts.length) : 0;

  return (
    <Card style={{ width: '100%' }}>
      <div className="sfmc-account-admin">
        <DeletePackage
          ref={deleteRef}
          onCompleted={() => setTrigger(new Date())}
        />
        <Box sx={{ width: '100%' }} className="data-table">
          <Paper sx={{ width: '100%', mb: 2 }}>
            <DatatableToolbar
              title="SFMC Accounts"
              titleIcon={<AccountTree className="icon" />}
              searchButton
              businessUnitAddButton
              searched={searched}
              requestSearch={requestSearch}
            />
            <TableContainer sx={{ borderRadius: '12px' }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <DatatableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredPackages.length}
                  headCells={accountHeadCells}
                />
                {filteredPackages.length > 0 && (
                  <TableBody>
                    {stableSort(filteredPackages, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((pkg, index) => {
                        return (
                          <AccountDatatableRow
                            key={`account-row-${index}`}
                            pkg={pkg}
                            deleteRef={deleteRef}
                          />
                        );
                      })}
                  </TableBody>
                )}
              </Table>
              {filteredPackages.length === 0 && (
                <Box
                  sx={{
                    padding: '1rem 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AccountTree className="icon" />{' '}
                  <Typography
                    className="no-account-title"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: 'rgba(0,0,0,.45)',
                    }}
                  >
                    There are no accounts to display.
                  </Typography>
                </Box>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[100, 200, 400]}
              sx={{
                width: '100%',
                paddingTop: `${42.3 * emptyRows}px !important`,
              }}
              component="div"
              count={filteredPackages.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </Card>
  );
};

export default AccountDatatable;
