import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import scssVariables from '../../styles/variables.scss';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://osf.digital/">
        OSF Digital
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleLogin = (event) => {
    window.location.href = '/auth/login';
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          elevation={0}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              gap: '5rem',
              padding: '2rem',
            }}
          >
            <img
              style={{
                maxWidth: '400px',
                margin: '0 auto',
                display: 'block',
              }}
              src="/logo_workV2.1.svg"
              alt="Workbench Logo"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: scssVariables.colorPrimary,
                  //   width: '50px',
                  //   height: '50px',
                  transform: 'scale(1.3)',
                  transformOrigin: 'bottom',
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                sx={{ fontWeight: '800' }}
                component="h1"
                variant="h3"
              >
                Welcome!
              </Typography>
              <Typography
                sx={{ marginTop: '.5rem' }}
                variant="body2"
                color="text.secondary"
                align="center"
              >
                Log in with your Microsoft account to access your SFMC Workbench
                account.
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
                <Button
                  type="submit"
                  onClick={handleLogin}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, display: 'flex', gap: '.2rem' }}
                >
                  <img
                    style={{ width: '30px' }}
                    src="/icons8-microsoft.svg"
                    alt=""
                  />
                  <span>Log In</span>
                </Button>
                <Grid container>
                  <Grid item xs>
                    {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                  </Grid>
                  <Grid item>
                    <Link
                      href="https://osfdigital.slack.com/archives/C03T8PLTBFS"
                      target="_blank"
                      variant="body2"
                      className="main-color"
                    >
                      {"Don't have an account? Reach us at our Slack channel."}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div>
              <Copyright sx={{ mt: 5 }} />
              <img
                style={{
                  marginTop: '1rem',
                  width: '150px',
                  margin: '0 auto',
                  display: 'block',
                }}
                src="/osf-logo-one-line.png"
                alt="OSF Digital Logo"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
