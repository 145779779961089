import {
  CircularProgress,
  Grid,
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useEffect, useRef, useState } from 'react';

export default function InlineEditInput({
  value,
  onChange,
  onSubmit,
  isLoading = true,
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditing(false);
      }
    }
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <div ref={inputRef}>
      {editing && (
        <Input
          fullWidth
          value={value}
          autoFocus
          disabled={isLoading}
          onChange={(e) => onChange && onChange(e)}
          endAdornment={
            <InputAdornment position="end">
              {!isLoading && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit && onSubmit(e);
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  edge="end"
                >
                  <SaveRoundedIcon></SaveRoundedIcon>
                </IconButton>
              )}
              {isLoading && <CircularProgress size={20}></CircularProgress>}
            </InputAdornment>
          }
        ></Input>
      )}
      {!editing && (
        <Grid justifyContent={'space-between'} container alignItems={'center'}>
          <Grid item>
            <div style={value ? {} : { fontStyle: 'italic' }}>
              {value || 'N/A'}
            </div>
          </Grid>

          <Grid item>
            <IconButton onClick={() => setEditing(true)} edge="end">
              <EditIcon></EditIcon>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
