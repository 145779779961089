const SidebarCollapseReducer = (state, action) => {
  switch (action.type) {
    case 'COLLAPSE': {
      localStorage.setItem('collapseMode', true);
      return {
        collapseMode: true,
      };
    }
    case 'EXPAND': {
      localStorage.setItem('collapseMode', false);
      return {
        collapseMode: false,
      };
    }
    case 'TOGGLE': {
      localStorage.setItem('collapseMode', `${!state.collapseMode}`);
      return {
        collapseMode: !state.collapseMode,
      };
    }
    default:
      return state;
  }
};

export default SidebarCollapseReducer;
