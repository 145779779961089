import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import BusinessUnitDetails from '../../audits/BusinessUnitDetails';
import { Alert, Box, Grid } from '@mui/material';
import './mcLogin.scss';

const MCLogin = ({ user }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [businessUnit, setBusinessUnit] = useState({});
  const mid = params.mid;
  const sfmcUser = user?.roles?.connectedAccounts?.[mid]?.username;

  const signinRequest = searchParams.get('login') === 'true';

  function onLoadBusinessUnit(bu) {
    if (bu?.mid !== businessUnit?.mid) {
      setBusinessUnit(bu);
    }
  }

  const renderSuccess = (sfmcUser) => {
    return (
      <>
        <Alert severity="success">
          Connected as <b>{sfmcUser}</b>{' '}
        </Alert>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="/" variant="contained" type="button">
            Go back to the main page
          </Link>
        </div>
      </>
    );
  };

  const renderSignIn = () => {
    return (
      <>
        {!signinRequest ? <Alert severity="error">Not connected.</Alert> : ''}
        <div style={{ marginTop: signinRequest ? '10px' : '20px' }}>
          <div className="explain">
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <strong>
                  Make sure that you do not already have an open session in
                  SFMC, unless it is in the same account as the one you are
                  attempting to connect.
                </strong>
              </Grid>
              <Grid item>
                An exception to this is if your user has a default business unit
                that is not enrolled in the Workbench, in which case you need to
                sign in in manually, navigate to the business unit you want to
                connect and attempt again to connect from here.
              </Grid>
              <Grid item>
                You can quickly sign out using the link below. Doing so will
                redirect you to the SFMC login page. After signing out or
                signing in manually, come back here to finish connecting the
                Workbench to the business unit.
              </Grid>
              <Grid item>
                If you land on an error page, try the manual approach. The sign
                out link will send you to the login page.
              </Grid>
              <Grid item>
                Always sign out from SFMC before attempting to sign in manually.
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <a href={`/auth/sfmc/login/${mid}`}>Connect the Workbench to SFMC</a>
          <a
            href="https://mc.exacttarget.com/#logout"
            target="_blank"
            rel="noreferrer"
          >
            Sign out from SFMC
          </a>
        </div>
      </>
    );
  };

  const renderValidationNotEnabled = () => {
    return (
      <>
        <Alert severity="warning">
          Account validation is not enabled for this business unit.
        </Alert>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="/" variant="contained" type="button">
            Go back to the main page
          </Link>
        </div>
      </>
    );
  };

  const validBusinessUnit = businessUnit?.mid > 0;
  const enableSfmcAuth = !!businessUnit.enableSfmcAuth;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <div className="mc-login audits" style={{ maxWidth: '600px' }}>
        <h3>Connecting to a Salesforce Marketing Cloud business unit</h3>

        <div style={{ marginTop: '20px' }}>
          <BusinessUnitDetails
            mid={mid}
            showOnlyHeader={true}
            onLoad={onLoadBusinessUnit}
          />
        </div>
        {sfmcUser && validBusinessUnit && enableSfmcAuth ? (
          renderSuccess(sfmcUser)
        ) : !validBusinessUnit ? (
          <>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link to="/">Go back to the main page</Link>
            </div>
          </>
        ) : !enableSfmcAuth ? (
          renderValidationNotEnabled()
        ) : (
          renderSignIn()
        )}
      </div>
    </Box>
  );
};

export default MCLogin;
