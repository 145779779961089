import {
  CircularProgress,
  Grid,
  IconButton,
  Input,
  TextField,
  Alert,
  Autocomplete,
  Button,
  Box,
  Modal,
  Stack,
  Divider,
  Typography,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import { useEffect, useMemo, useState } from 'react';
import { getUsersData } from '../../../service/adminApiService/adminApiService';
import axios from 'axios';

const OPERATIONS = Object.freeze({
  ADD: 'add',
  UPDATE: 'update',
  NONE: 'none',
  DELETE: 'delete',
});

export default function InlineAccountResponsibleEditor({
  originalAccountResponsibles,
  mid,
  onResponsiblesChange,
}) {
  const [stashedResponsibles, setStashedResponsibles] = useState();
  const [doShowModal, setDoShowModal] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const accountResponsibles = useMemo(
    function setAccountResponsibles() {
      return originalAccountResponsibles || [];
    },
    [originalAccountResponsibles]
  );

  useEffect(() => {
    setStashedResponsibles(accountResponsibles);
  }, [accountResponsibles]);

  const autocompletUsersList = useMemo(() => {
    if (!users) {
      return [];
    }

    return updateUsersAutocompleteArrayAvailability(users);

    function updateUsersAutocompleteArrayAvailability(users) {
      return users.map((user) => {
        const isUserInUse = stashedResponsibles.some(
          (responsible) => responsible.userId === user.id
        );

        return {
          ...user,
          isAvailable: !isUserInUse,
        };
      });
    }
  }, [users, stashedResponsibles]);

  async function handleEditClick() {
    const tempArr = [];

    accountResponsibles.forEach((item) => {
      tempArr.push({ operation: OPERATIONS.NONE, ...item });
    });

    setStashedResponsibles(tempArr);

    setLoadingUsers(true);
    const apiRes = await getUsersData();
    setUsers(apiRes.users);

    setLoadingUsers(false);

    setEditEnabled(true);
  }

  function handleShowModal() {
    setStashedResponsibles(accountResponsibles);
    setDoShowModal(true);
  }

  function handleModalClose() {
    handleEditCancel();
    setDoShowModal(false);
  }

  function handleEditCancel() {
    setStashedResponsibles(accountResponsibles);
    setEditEnabled(false);
  }

  function handleAddItem() {
    const defaultUser = autocompletUsersList.find((user) => user.isAvailable);

    const newItem = {
      userId: defaultUser.id,
      username: defaultUser.username,
      jobTitle: '',
      operation: OPERATIONS.ADD,
    };

    setStashedResponsibles([...stashedResponsibles, newItem]);
  }

  function handleUserAutocompleteSelectionChange(responsibleIdx, newUser) {
    stashedResponsibles[responsibleIdx] = {
      ...stashedResponsibles[responsibleIdx],
      userId: newUser.id,
      username: newUser.username,
    };

    setStashedResponsibles([...stashedResponsibles]);
  }

  function handleJobTitleChange(idx, newValue) {
    stashedResponsibles[idx] = {
      ...stashedResponsibles[idx],
      jobTitle: newValue,
      originalJobTitle:
        stashedResponsibles[idx].originalJobTitle ||
        stashedResponsibles[idx].jobTitle,
      operation:
        stashedResponsibles[idx].operation !== OPERATIONS.ADD
          ? OPERATIONS.UPDATE
          : OPERATIONS.ADD,
    };

    setStashedResponsibles([...stashedResponsibles]);
  }

  function handleResponsibleDeleteClick(responsibleIndex) {
    stashedResponsibles[responsibleIndex].operation = OPERATIONS.DELETE;
    setStashedResponsibles([...stashedResponsibles]);
  }

  function handleRevertResponsibleChanges(idx) {
    if (stashedResponsibles[idx].operation === OPERATIONS.ADD) {
      stashedResponsibles.splice(idx, 1);
      setStashedResponsibles([...stashedResponsibles]);
      return;
    }

    stashedResponsibles[idx] = {
      ...stashedResponsibles[idx],
      jobTitle:
        stashedResponsibles[idx].originalJobTitle ||
        stashedResponsibles[idx].jobTitle,
      originalJobTitle: undefined,
      operation: OPERATIONS.NONE,
    };

    setStashedResponsibles([...stashedResponsibles]);
  }

  async function handleSave() {
    const responsiblesToUpsertOrDelete = stashedResponsibles.filter(
      (resp) => resp.operation !== OPERATIONS.NONE
    );

    setLoadingUsers(true);
    const promises = responsiblesToUpsertOrDelete.map(async (resp) => {
      const api = axios.create({
        baseURL: '/api/wsfmc/admin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (
        resp.operation === OPERATIONS.ADD ||
        resp.operation === OPERATIONS.UPDATE
      ) {
        return await api.post('/account/responsible', {
          mids: [mid],
          userId: resp.userId,
          jobTitle: resp.jobTitle,
        });
      }

      if (resp.operation === OPERATIONS.DELETE) {
        return await api.post('/account/responsible/remove', {
          mids: [mid],
          userId: resp.userId,
        });
      }

      return null;
    });

    await Promise.all(promises);

    if (onResponsiblesChange) {
      onResponsiblesChange(
        stashedResponsibles
          .filter((resp) => resp.operation !== OPERATIONS.DELETE)
          .map((resp) => ({
            userId: resp.userId,
            username: resp.username,
            jobTitle: resp.jobTitle,
          }))
      );
    }

    setLoadingUsers(false);
    handleEditCancel();
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 2,
    width: 700,
  };

  function getRowStyle(responsible, idx) {
    let bgcolor = idx % 2 === 0 ? 'rgba(0,0,0,.05)' : 'white';

    if (editEnabled) {
      if (responsible.operation === OPERATIONS.DELETE) {
        bgcolor = 'rgba(197,26,26,.2)';
      }

      if (responsible.operation === OPERATIONS.ADD) {
        bgcolor = 'rgba(76,175,80,.2)';
      }

      if (responsible.operation === OPERATIONS.UPDATE) {
        bgcolor = 'rgba(66,165,245,.2)';
      }
    }

    return {
      pr: 2,
      pl: 2,
      pt: editEnabled ? 0 : 1,
      pb: editEnabled ? 0 : 1,
      bgcolor,
    };
  }

  return (
    <>
      <Grid justifyContent={'space-between'} container alignItems={'center'}>
        <Grid item>
          {accountResponsibles.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No responsibles
            </Typography>
          )}
          {accountResponsibles.length > 0 && (
            <Stack direction="row" spacing={2}>
              <span>{accountResponsibles[0].username}</span>
              <span>
                {accountResponsibles.length > 1
                  ? `+${accountResponsibles.length - 1} more`
                  : ''}
              </span>
            </Stack>
          )}
        </Grid>

        <Grid item>
          <Tooltip title="View or manage responsibles">
            <span>
              <IconButton onClick={handleShowModal}>
                <OpenInNewIcon></OpenInNewIcon>
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Modal
        open={doShowModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {loadingUsers && <CircularProgress />}
          {!loadingUsers && (
            <>
              <Grid
                container
                justifyContent={'space-between'}
                gap={2}
                sx={{ mb: 1, pr: 2, pl: 2 }}
              >
                <Grid item xs={6}>
                  Email
                </Grid>
                <Grid item xs>
                  Job
                </Grid>
                {editEnabled && <Grid item xs={1}></Grid>}
              </Grid>

              <Divider />

              {!stashedResponsibles || stashedResponsibles.length === 0 ? (
                <Grid>
                  <Alert severity="info">
                    This Business Unit has no responsibles.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
              {stashedResponsibles &&
                stashedResponsibles.map((responsible, idx) => {
                  return (
                    <Grid
                      container
                      sx={getRowStyle(responsible, idx)}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      gap={2}
                      key={idx}
                    >
                      <Grid item xs={6}>
                        {(!editEnabled ||
                          responsible.operation !== OPERATIONS.ADD) && (
                          <div>{responsible.username}</div>
                        )}
                        {editEnabled &&
                          responsible.operation === OPERATIONS.ADD && (
                            <Autocomplete
                              options={autocompletUsersList}
                              getOptionLabel={(option) => option.username}
                              onChange={(event, newValue) => {
                                handleUserAutocompleteSelectionChange(
                                  idx,
                                  newValue
                                );
                              }}
                              getOptionDisabled={(option) =>
                                !option.isAvailable
                              }
                              value={autocompletUsersList.find(
                                (user) => user.id === responsible.userId
                              )}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                              )}
                            />
                          )}
                      </Grid>
                      <Grid item xs>
                        {!editEnabled && <div>{responsible.jobTitle}</div>}
                        {editEnabled && (
                          <Input
                            fullWidth
                            value={responsible.jobTitle}
                            onChange={(e) =>
                              handleJobTitleChange(idx, e.target.value)
                            }
                            variant="standard"
                          />
                        )}
                      </Grid>
                      {editEnabled && (
                        <>
                          {responsible.operation === OPERATIONS.NONE && (
                            <Grid item xs={1}>
                              <IconButton
                                onClick={() =>
                                  handleResponsibleDeleteClick(idx)
                                }
                              >
                                <DeleteIcon></DeleteIcon>
                              </IconButton>
                            </Grid>
                          )}
                          {responsible.operation !== OPERATIONS.NONE && (
                            <Grid item xs={1}>
                              <IconButton
                                onClick={() =>
                                  handleRevertResponsibleChanges(idx)
                                }
                              >
                                <HistoryIcon></HistoryIcon>
                              </IconButton>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  );
                })}

              <Box>
                {!editEnabled && (
                  <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={handleEditClick}>
                      Edit
                    </Button>
                  </Stack>
                )}

                {editEnabled && (
                  <>
                    <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                      <IconButton
                        onClick={handleAddItem}
                        disabled={
                          !autocompletUsersList.find((user) => user.isAvailable)
                        }
                        size="large"
                        color="primary"
                        variant="filled"
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <Button variant="outlined" onClick={handleEditCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                        disabled={
                          !stashedResponsibles.some(
                            (resp) => resp.operation !== OPERATIONS.NONE
                          )
                        }
                      >
                        Save
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
