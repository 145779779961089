import axios from 'axios';

const api = axios.create({
  baseURL: '/api/builder-suite',
});

export async function createPackage(mid, objectReferences, settings) {
  try {
    const endpoint = `/create-package/${mid}`;
    const response = await api.post(
      endpoint,
      { objectReferences, ...settings },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function postPackageFile(mid, file) {
  try {
    const endpoint = `/deploy-zip/${mid}`;
    const response = await api.post(
      endpoint,
      { package: file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getPackageCreationHistory(mid, { offset, count }) {
  try {
    offset = Number(offset) > 0 ? Number(offset) : 0;
    count = Number(count) > 0 ? Number(count) : 1000;
    const endpoint = `/create-package/${mid}/history?offset=${offset}&count=${count}`;
    const response = await api.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeployHistory(mid, deploymentType, { offset, count }) {
  try {
    offset = Number(offset) > 0 ? Number(offset) : 0;
    count = Number(count) > 0 ? Number(count) : 1000;
    const endpoint = `/deployment/${mid}/history?offset=${offset}&count=${count}`;
    const response = await api.get(endpoint, {
      params: { deploymentType },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getVaultPackages() {
  try {
    const endpoint = `/vault-packages`;
    const response = await api.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function deployGithubPackage(mid, packageFolderName) {
  try {
    const endpoint = `/deploy-github/${mid}`;
    const response = await api.post(
      endpoint,
      { packageFolderName },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function downloadBuilderSuitePackage(mid, taskId) {
  try {
    const endpoint = `/download-package/${mid}/${taskId}`;
    const response = await api.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
