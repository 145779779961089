import React, { useEffect, useState } from 'react';
import './datatable.scss';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Card from '../ui/Card';
import AdminDatatableRow from './AdminDatatableRow';
import AdminDatatableToolbar from './DatatableToolbar';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  deleteUserData,
  postUserData,
} from '../../service/adminApiService/adminApiService';
import { Alert, Typography } from '@mui/material';
import {
  notificationUsersString,
  postUserNotifications,
} from '../../service/wsfmcNotificationsService/wsfmcNotificationsService';
import DatatableHead from './DatatableHead';
import _ from 'lodash';

function validateEmail(email) {
  const re =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})$/;
  return re.test(String(email));
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const adminHeadCells = [
  {
    id: 'Actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
    pointerEvents: false,
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    pointerEvents: true,
  },
  {
    id: 'avatar',
    numeric: false,
    disablePadding: true,
    label: 'Avatar',
    pointerEvents: false,
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
    pointerEvents: false,
  },
];

const AdminDatatable = ({ users, accounts, permissions, loadUsersData }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('email');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [data, setData] = useState(users); //table data;
  const [searched] = useState('');
  const [openDetailPanel, setOpenDetailPanel] = useState(null);
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [isNotificationSuccessful, setIsNotificationSuccessful] =
    useState(false);

  const handleOpenDetailPanel = (event, clickedIndex) => {
    if (openDetailPanel === clickedIndex) {
      setOpenDetailPanel(null);
      loadUsersData();
    } else {
      setOpenDetailPanel(clickedIndex);
    }
  };

  const handleRowAdd = (newData, permissions, resolve) => {
    //validation
    let errorList = [];
    if (
      newData.username === undefined ||
      validateEmail(newData.username) === false
    ) {
      errorList.push('Please enter a valid email');
    }

    if (errorList.length < 1) {
      //no error
      createUser(newData.username, permissions, newData);
      resolve();
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteUser(oldData.username, oldData);
    resolve();
  };

  const handleNotificationSend = (notificationData) => {
    sendNotification(notificationData);
  };

  async function createUser(username, permissions, newData) {
    try {
      // eslint-disable-next-line no-unused-vars
      await postUserData({ username, permissions });
      loadUsersData();
      setErrorMessages([]);
      setIserror(false);
    } catch (error) {
      const errorRes = error.response.data;
      setErrorMessages([errorRes.message]);
      setIserror(true);
    }
  }

  async function deleteUser(username, oldData) {
    try {
      await deleteUserData({ username });
      loadUsersData();
      setErrorMessages([]);
      setIserror(false);
    } catch (error) {
      const errorRes = error.response.data;
      setErrorMessages([errorRes.message]);
      setIserror(true);
    }
  }

  async function sendNotification(notificationData) {
    try {
      await postUserNotifications(notificationData);
      setErrorMessages(['']);
      setIserror(false);
      setIsSuccess(true);
      setSuccessMessages([
        `Notification succesfully sent to ${notificationUsersString(
          notificationData
        )}`,
      ]);
      setIsNotificationSuccessful(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      setTimeout(() => {
        setIsNotificationSuccessful(false);
      }, 1000);
    } catch (error) {
      setIsNotificationSuccessful(false);
      setErrorMessages([
        `Something went wrong. The notification could not be sent.`,
      ]);
      setIserror(true);
      setTimeout(() => {
        setIserror(false);
      }, 5000);
    }
  }

  const requestSearch = (searchedVal) => {
    const filteredRows = users.filter((row) => {
      return row.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setPage(0);
    setData(filteredRows);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    const searchInputNode = document.querySelector('.admin-search-input');
    const searchInputValue = searchInputNode?.childNodes[0]?.value;
    const filteredData = searchInputValue
      ? users.filter((row) => {
          return row.username
            .toLowerCase()
            .includes(searchInputValue.toLowerCase());
        })
      : _.clone(users);
    setData(filteredData);
  }, [users]);

  return (
    <Card style={{ width: '100%' }}>
      <div>
        {isSuccess ? (
          <Alert
            severity="success"
            style={{ transform: 'translateY(-10px)', marginTop: '10px' }}
          >
            {successMessages.map((msg, i) => {
              return (
                <div key={i} style={{ fontWeight: '600' }}>
                  {msg}
                </div>
              );
            })}
          </Alert>
        ) : (
          ''
        )}
      </div>
      <Box sx={{ width: '100%' }} className="data-table">
        <Paper sx={{ width: '100%', mb: 2 }}>
          <AdminDatatableToolbar
            title="User List"
            titleIcon={<PeopleAltIcon className="icon" />}
            searchButton
            userAddButton
            notificationButton
            handleRowAdd={handleRowAdd}
            searched={searched}
            requestSearch={requestSearch}
            handleNotificationSend={handleNotificationSend}
            users={data}
            setErrorMessages={setErrorMessages}
            setIserror={setIserror}
            isError={iserror}
            errorMessages={errorMessages}
            isNotificationSuccessful={isNotificationSuccessful}
          />
          <TableContainer sx={{ borderRadius: '12px' }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <DatatableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headCells={adminHeadCells}
              />
              {data.length > 0 && (
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <AdminDatatableRow
                          key={user.username}
                          user={user}
                          labelId={labelId}
                          handleOpenDetailPanel={handleOpenDetailPanel}
                          openDetailPanel={openDetailPanel}
                          accounts={accounts}
                          permissions={permissions}
                          loadUsersData={loadUsersData}
                          handleRowAdd={handleRowAdd}
                          handleRowDelete={handleRowDelete}
                          handleNotificationSend={handleNotificationSend}
                          setErrorMessages={setErrorMessages}
                          setIserror={setIserror}
                          isError={iserror}
                          errorMessages={errorMessages}
                          setData={setData}
                          isNotificationSuccessful={isNotificationSuccessful}
                        />
                      );
                    })}
                </TableBody>
              )}
            </Table>
            {data.length === 0 && (
              <Box
                sx={{
                  padding: '1rem 0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PersonIcon className="icon" />{' '}
                <Typography
                  className="no-account-title"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'rgba(0,0,0,.45)',
                  }}
                >
                  There are no users to display.
                </Typography>
              </Box>
            )}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 200, 400]}
            sx={{
              width: '100%',
              paddingTop: `${42.3 * emptyRows}px !important`,
            }}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Card>
  );
};

export default AdminDatatable;
