import axios from 'axios';

const api = axios.create({
  baseURL: '/api/sfmc',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export async function getAssets({ mid, typeId, fields }) {
  try {
    const endpoint = `/asset/${mid}/typeid/${typeId}`;
    const response = await api.get(endpoint, {
      params: {
        fields: fields?.join(','),
      },
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the assets.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getAutomations({ mid, basicInfo }) {
  try {
    const endpoint = `/automation/${mid}`;
    const response = await api.get(endpoint, {
      params: {
        basicInfo,
      },
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the automations.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getDataExtensions({
  mid,
  properties,
  getFields,
  forBuilderSuite,
}) {
  try {
    const endpoint = `/dataextension/${mid}`;
    const response = await api.get(endpoint, {
      params: {
        properties: properties?.join(','),
        getFields: getFields ? 'true' : 'false',
        forBuilderSuite: forBuilderSuite ? 'true' : 'false',
      },
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the data extensions.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getScripts({ mid }) {
  try {
    const endpoint = `/script/${mid}`;
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the script activities.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getQueries({ mid, properties }) {
  try {
    const endpoint = `/query/${mid}`;
    const response = await api.get(endpoint, {
      params: {
        properties: properties?.join(','),
      },
    });
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the query activities.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getExtracts({ mid }) {
  try {
    const endpoint = `/dataextract/${mid}`;
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the data extract activities.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getImports({ mid }) {
  try {
    const endpoint = `/import/${mid}`;
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the import activities.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}

export async function getTransfers({ mid }) {
  try {
    const endpoint = `/filetransfer/${mid}`;
    const response = await api.get(endpoint);
    return response.data;
  } catch (err) {
    let error = new Error(
      'An error has occurred while trying to get the file transfer activities.'
    );
    error.status = err.response.status || 500;
    throw error;
  }
}
