import axios from 'axios';

const api = axios.create({
  baseURL: '/api/wsfmc',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export async function addResponsibles({ mids, userId, jobTitle }) {
  try {
    const endpoint = '/admin/account/responsible';
    const response = await api.post(endpoint, {
      mids: mids,
      userId: userId,
      jobTitle: jobTitle || '',
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function removeResponsibles({ mids, userId }) {
  try {
    const endpoint = '/admin/account/responsible/remove';
    const response = await api.post(endpoint, {
      mids: mids,
      userId: userId,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function clearResponsibles({ mids, userId }) {
  try {
    const endpoint = '/admin/account/responsible/clear';
    const response = await api.post(endpoint, {
      mids: mids,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function toggleActiveStatus({ ids, active }) {
  try {
    const endpoint = `/admin/togglebusinessunit`;
    const response = await api.put(endpoint, {
      ids: ids,
      active: active,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function toggleEnableDataDownload({ ids, enableDataDownload }) {
  try {
    const endpoint = `/admin/toggledatadownload`;
    const response = await api.put(endpoint, {
      ids: ids,
      enableDataDownload,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function toggleSfmcAuth({ ids, enableSfmcAuth }) {
  try {
    const endpoint = `/admin/togglesfmcauth`;
    const response = await api.put(endpoint, {
      ids: ids,
      enableSfmcAuth,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
}
