import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import './rowCopyFieldDialog.scss';
import { useRef } from 'react';

const RowCopyFieldDialog = ({ selectedRowInfo, openDialog, setOpenDialog }) => {
  const textFieldRef = useRef(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box
    // sx={{
    //   width: '100%',
    //   height: '100%',
    //   marginTop: '90px',
    //   marginBottom: '45px',
    // }}
    >
      <div>
        <Dialog
          className="row-copy__field-dialog"
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{selectedRowInfo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <pre ref={textFieldRef} style={{ whiteSpace: 'pre-wrap' }}>
                {selectedRowInfo.cell}
              </pre>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(evt) => {
                navigator.clipboard.writeText(selectedRowInfo.cell).then(() => {
                  handleCloseDialog();
                });
                evt.stopPropagation();
                evt.preventDefault();
              }}
            >
              Copy Field
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default RowCopyFieldDialog;
