import './Sandbox.scss';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { Cached } from '@mui/icons-material';
// import * as d3 from 'd3';
// import * as graphviz from 'd3-graphviz';

const initialDotSrc = `
    digraph {
        {
            node [shape=cylinder]
            a
        }
        b;
        c;
        subgraph {
            edge [dir=none]
            a -> b
            a -> c
        }
        b -> c
        b -> a
    }
`;

/** @type {import('d3')} */
const d3 = window.d3;

/** @type {import('d3-graphviz').GraphvizOptions} */
const graphvizOptions = {
  width: 600,
  height: 600,
  // fit: true
};

export default function Sandbox(props) {
  const username = props?.user?.username;

  const [dotSrc, setDotSrc] = useState(initialDotSrc);

  var elem = useRef();
  var dotRef = useRef();

  useEffect(() => {
    if (!username) {
      return;
    }
    // elem.current.innerHTML = '';

    d3.select(elem.current)
      .graphviz(graphvizOptions)
      // graphviz.graphviz(elem.current, graphvizOptions)
      .renderDot(dotSrc);
  });

  return username ? (
    <>
      <Stack direction={'row'}>
        <h2>Sandbox</h2>
        <IconButton
          onClick={() => setDotSrc(dotRef.current.value)}
          title="Refresh display"
        >
          <Cached />
        </IconButton>
      </Stack>
      <Stack direction={'row'}>
        <textarea
          ref={dotRef}
          cols={70}
          rows={25}
          onChange={() => setDotSrc(dotRef.current.value)}
          value={dotSrc}
        />
        <div className="App">
          <div ref={elem} style={{ textAlign: 'center' }}></div>
        </div>
      </Stack>
    </>
  ) : (
    <h2>Please sign in</h2>
  );
}
