import _ from 'lodash';
import Util from './util';

/**
 * Utility methods that relates to SFMC
 */
class SFMC {
  /**
   * Creates a SQL select statement for a given data extension
   * @param {Object} dataExtension data extension
   * @param {string[]} fieldsOverride data extension
   * @returns {string} SQL select statement
   */
  static getSelectStatement(dataExtension, fieldsOverride) {
    if (!dataExtension?.Name || typeof dataExtension?.Name !== 'string') {
      throw new Error('Invalid data extension');
    }

    const isSharedDataExtension =
      dataExtension?.wsfmc?.folder?.contentType === 'shared_dataextension';

    const fields =
      fieldsOverride ||
      _.sortBy(dataExtension?.Fields || [], (f) => Number(f?.Ordinal)).map(
        (f) => f?.Name
      );

    if (
      fields &&
      (!_.isArray(fields) ||
        !fields.length ||
        _.some(fields, (f) => !f || typeof f !== 'string'))
    ) {
      throw new Error('Invalid fields');
    }

    const isInvalid = /[^\w_]/i;

    const formattedFields = fields
      ? fields.map((fieldName) => {
          fieldName = isInvalid.test(fieldName) ? `[${fieldName}]` : fieldName;
          return `${fields.length === 1 ? ' ' : '\n    '}${fieldName}`;
        })
      : ['*'];

    const name = isInvalid.test(dataExtension.Name)
      ? `[${dataExtension.Name}]`
      : dataExtension.Name;

    const selectStatement = `select${formattedFields.join(',')} \nfrom ${
      isSharedDataExtension ? 'ENT.' : ''
    }${name}`;

    return selectStatement;
  }

  /**
   * Creates a legacy XML template (for idetbatch users)
   * @param {Object} dataExtension data extension
   * @returns {string} the XML template
   */
  static getDataExtensionXmlTemplate(dataExtension) {
    if (
      !dataExtension?.Name ||
      typeof dataExtension?.Name !== 'string' ||
      !dataExtension?.CustomerKey ||
      typeof dataExtension?.CustomerKey !== 'string'
    ) {
      throw new Error('Invalid data extension');
    }

    const fields = _.sortBy(dataExtension.Fields || [], (f) =>
      Number(f?.Ordinal)
    );

    if (
      fields &&
      (!_.isArray(fields) ||
        !fields.length ||
        _.some(fields, (f) => !f || typeof f.Name !== 'string'))
    ) {
      throw new Error('Invalid fields');
    }

    var xmlDoc = document.implementation.createDocument('', '', null);
    const extensionsNode = xmlDoc.createElement('extensions');

    const pi = xmlDoc.createProcessingInstruction(
      'xml',
      'version="1.0" encoding="UTF-8"'
    );
    xmlDoc.insertBefore(pi, xmlDoc.firstChild);
    const extensionNode = xmlDoc.createElement('extension');

    extensionNode.setAttribute('name', dataExtension.Name);
    extensionNode.setAttribute('external-key', dataExtension.CustomerKey);
    extensionNode.setAttribute(
      'content-type',
      dataExtension.wsfmc?.folder?.contentType
    );
    extensionNode.setAttribute(
      'folder',
      dataExtension.wsfmc?.folder?.path?.replace(/^[^/]+[/]/, '')
    );

    extensionNode.setAttribute('testable', dataExtension.IsTestable === 'true');

    const sendable = dataExtension.IsSendable === 'true';
    extensionNode.setAttribute('sendable', sendable);
    if (sendable) {
      extensionNode.setAttribute(
        'sendable-subscriber-field-name',
        dataExtension.SendableSubscriberFieldName
      );
      extensionNode.setAttribute(
        'sendable-dataextension-field-name',
        dataExtension.SendableDataExtensionFieldName
      );
    }

    const descriptionNode = xmlDoc.createElement('description');
    if (dataExtension.Description) {
      descriptionNode.innerText = dataExtension.Description;
    }
    extensionNode.appendChild(descriptionNode);

    for (const field of fields) {
      const columnNode = xmlDoc.createElement('column');
      columnNode.setAttribute('name', field.Name);
      if (field.FieldType && typeof field.FieldType === 'string') {
        columnNode.setAttribute('type', field.FieldType);
        let scale;
        let length;
        switch (field.FieldType.toLowerCase()) {
          case 'decimal':
            scale = field.Scale ? Number(field.Scale) : '';
            length = field.MaxLength ? Number(field.MaxLength) : '';
            break;

          case 'phone':
            length = field.MaxLength ? Number(field.MaxLength) : 11;
            break;

          case 'text':
            length = field.MaxLength ? Number(field.MaxLength) : '';
            break;

          default:
            //   case 'boolean':
            //   case 'date':
            //   case 'emailaddress':
            //   case 'locale':
            //   case 'number':
            break;
        }

        if (typeof length === 'number') {
          if (typeof scale === 'number') {
            columnNode.setAttribute('length', `${length},${scale}`);
          } else {
            columnNode.setAttribute('length', length);
          }
        }

        columnNode.setAttribute('primary-key', field.IsPrimaryKey === 'true');
        columnNode.setAttribute('nullable', field.IsRequired !== 'true');
        if (field.DefaultValue && typeof field.DefaultValue === 'string') {
          columnNode.setAttribute('default-value', field.DefaultValue);
        }
      }
      extensionNode.appendChild(columnNode);
    }

    extensionsNode.appendChild(extensionNode);
    xmlDoc.appendChild(extensionsNode);
    const serializer = new XMLSerializer();
    let xml = serializer.serializeToString(xmlDoc);

    xml = Util.formatXml(xml); //.replace(/" sendable="/, '"\n    testable="');

    return xml;
  }
}

export default SFMC;
