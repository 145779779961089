import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Util from './util';

// /** @typedef {import('@microsoft/applicationinsights-web').IEventTelemetry} IEventTelemetry */
// /** @typedef {import('@microsoft/applicationinsights-web').ICustomProperties} ICustomProperties */

const appInsightsCSValidator =
  /^InstrumentationKey=[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12};IngestionEndpoint=https:[/][/][a-z0-9_.-]+.azure.com[/]{0,1}(;LiveEndpoint=https:[/][/][a-z0-9_-]+.livediagnostics.monitor.azure.com[/]{0,1}){0,1}/i;

const appInsightsCS = (() => {
  let value = Util.getEnvVariable('REACT_APP_APPINSIGHTS_CS');
  if (appInsightsCSValidator.test(value)) {
    return value;
  }
})();

let cloudRoleInstance = Util.getEnvVariable(
  'REACT_APP_APPINSIGHTS_CLOUD_ROLEINSTANCE'
);

cloudRoleInstance =
  cloudRoleInstance && !/^%|^%/.test(cloudRoleInstance)
    ? cloudRoleInstance
    : window.location.host;

let cloudRole = Util.getEnvVariable('REACT_APP_APPINSIGHTS_CLOUD_ROLENAME');
cloudRole = !/^%|^%/.test(cloudRole) ? cloudRole : window.location.host;

const appInsights = appInsightsCS
  ? new ApplicationInsights({
      config: {
        connectionString: appInsightsCS,
        disableCorrelationHeaders: false,
        enableCorsCorrelation: true,
        maxBatchInterval: 0,
        disableFetchTracking: false,
      },
    })
  : null;
if (appInsights) {
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({
      'ai.cloud.role': cloudRole,
      'ai.cloud.roleInstance': cloudRoleInstance,
    });
  });
}

/**
 * Application Insights utility class
 */
class AppInsights {
  /**
   * Tracks page views
   * @param {Object} user as returned by '/auth/me'
   * @returns {void}
   */
  static trackPageView(user) {
    try {
      if (appInsights && user?.username !== undefined) {
        const properties = { username: user?.username || 'anonymous' };
        const uri = window.location.href.split('?')[0];
        const isLoggedIn = typeof user?.username === 'string';
        appInsights.trackPageView({ uri, isLoggedIn, properties });
        appInsights.flush();
        // console.log('app insights', new Date(), location.pathname, properties);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Tracks events
   * @param {Object} user as returned by '/auth/me'
   * @param {import('@microsoft/applicationinsights-web').IEventTelemetry} event event params
   * @returns {void}
   */
  static trackEvent(user, event) {
    try {
      if (appInsights && user?.username !== undefined) {
        event.properties = {
          ...{
            username: user?.username || 'anonymous',
            url: window.location.href.split('?')[0],
          },
          ...event.properties,
        };
        appInsights.trackEvent(event);
        appInsights.flush();
        // console.log('app insights', new Date(), location.pathname, properties);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default AppInsights;
