import React from 'react';

import ReactApexChart from 'react-apexcharts';
import scssVariables from '../../../styles/variables.scss';

class BasicLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Users',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        colors: [scssVariables.colorPrimary, '#247BA0'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
          ],
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.userData) {
      this.implementUserData();
    }

    if (this.props.accountData) {
      this.implementAccountData();
    }
  }

  implementUserData() {
    let uniqueDates = {};
    let seriesData = [];
    let categories = [];

    const { users } = this.props.userData;
    users.sort(this.dynamicSort('enabledAt'));
    users.forEach((user) => {
      user.enabledAt = user.enabledAt.split('T')[0];
    });
    users.forEach((user) => {
      if (!uniqueDates[user.enabledAt]) {
        uniqueDates[user.enabledAt] = [];
      }
    });
    users.forEach((user) => {
      uniqueDates[user.enabledAt] = [
        ...uniqueDates[user.enabledAt],
        user.username,
      ];
    });

    let totalUsers = 0;

    for (const [key, value] of Object.entries(uniqueDates)) {
      totalUsers += value.length;
      seriesData.push(totalUsers);
      categories.push(key);
    }

    this.setState({
      ...this.state,
      series: [
        {
          name: 'Users',
          data: seriesData,
        },
      ],
      options: {
        ...this.state.options,
        title: {
          text: this.props.chartTitle,
          align: 'left',
        },
        xaxis: {
          ...this.state.options.xaxis,
          categories: categories,
        },
      },
    });
  }

  implementAccountData() {
    let uniqueDates = {};
    let seriesData = [];
    let categories = [];

    const { accounts } = this.props.accountData;
    accounts.sort(this.dynamicSort('createdDate'));
    accounts.forEach((account) => {
      account.createdDate = account.createdDate.split('T')[0];
    });
    accounts.forEach((account) => {
      if (!uniqueDates[account.createdDate]) {
        uniqueDates[account.createdDate] = [];
      }
    });
    accounts.forEach((account) => {
      uniqueDates[account.createdDate] = [
        ...uniqueDates[account.createdDate],
        account.repository,
      ];
    });

    let totalAccounts = 0;

    for (const [key, value] of Object.entries(uniqueDates)) {
      totalAccounts += value.length;
      seriesData.push(totalAccounts);
      categories.push(key);
    }

    this.setState({
      ...this.state,
      series: [
        {
          name: 'Accounts',
          data: seriesData,
        },
      ],
      options: {
        ...this.state.options,
        title: {
          text: this.props.chartTitle,
          align: 'left',
        },
        xaxis: {
          ...this.state.options.xaxis,
          categories: categories,
        },
      },
    });
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default BasicLine;
