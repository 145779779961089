import { forwardRef, useImperativeHandle, useState } from 'react';
import ConfirmDialog from '../../../components/ui/ConfirmDialog';
import { deleteSfmcAccount } from '../../../service/dashboardApiService/wsfmcService';

const DeletePackage = forwardRef(({ onCompleted }, ref) => {
  const [openDelete, setOpenDelete] = useState({ status: false });
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    open(sfmcPackage) {
      setOpenDelete({ status: true, sfmcPackage });
    },
    close() {
      closeDelete();
    },
  }));

  async function deleteAccount() {
    try {
      if (!openDelete?.sfmcPackage) {
        return;
      }
      setLoading(true);
      await deleteSfmcAccount(openDelete.sfmcPackage)
        .then(async (response) => {})
        .catch((error) => {});
    } finally {
      closeDelete();
      onCompleted?.();
    }
  }

  function closeDelete() {
    setOpenDelete({ ...openDelete, status: false });
    setLoading(false);
  }

  return (
    <ConfirmDialog
      closeOnBackdropClick={false}
      open={openDelete?.status}
      okText="Delete"
      cancelText="Cancel"
      dialogContentComponents={<DeleteMessage {...openDelete} />}
      okProps={{ disabled: loading }}
      cancelProps={{ disabled: loading }}
      onCancel={closeDelete}
      onOk={() => deleteAccount()}
      loading={loading}
    />
  );
});

export default DeletePackage;

function DeleteMessage({ sfmcPackage }) {
  return (
    <>
      {'Are you sure you want to delete account '}
      <strong>
        {sfmcPackage?.eid}
        {' - '}
        {sfmcPackage?.displayName || sfmcPackage?.name}
      </strong>
      {' from the Workbench?'}
      <br />
      <br />
      {'This does not delete the account from SFMC.'}
    </>
  );
}
