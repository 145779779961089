import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

export default function DEDependencyGraphInfoModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Dependency Graph Information</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The data used to resolve the relationships is refreshed daily.
          <br />
          <br />
          When a data extension is selected, the graph will show what queries
          read from and write into it, if any. If a data extension is not
          written or read by at least one query, it will not be found.
          <br />
          <br />
          When a query is selected, the graph will show what data extensions it
          reads from and writes to. If a query does not reference an existing
          data extension, it will not be found. This can happen if the
          implicated data extensions were deleted.
          <br />
          <br />
          The graph is limited to direct interactions with the selected object,
          although the graph will always contain the source of each displayed
          query.
          <br />
          <br />
          The information about the target of queries is 100% accurate, while
          the information about their source may be less accurate as it relies
          on searching the code without a proper parser.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
