import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Check, NotInterested, ToggleOff, ToggleOn } from '@mui/icons-material';
import { checkPermissions } from '../../service/adminApiService/adminApiService';
import UserNotificationFlow from '../../components/admin/UserNotificationFlow';
import InlineEditInput from '../../pages/wsfmc-admin/components/InlineEditInput';
import InlineAccountResponsibleEditor from '../../pages/wsfmc-admin/components/InlineAccountResponsibleEditor';

const BusinessUnitDatatableRow = ({
  bu,
  deleteRef,
  getUsersForBusinessUnit,
  handleNotificationSend,
  isNotificationSuccessful,
  isError,
  setIsError,
  errorMessages,
  handleDisplayNameChange,
  handleResponsiblesChange,
  handleDisplayNameSubmit,
  displayNameChangeLoading,
  handleToggleActive,
  handleChangeRowSelection,
  togglingActive,
  togglingSfmcAuth,
  handleToggleSfmcAuth,
  togglingDataDownload,
  handleToggleDataDownload,
  sfmcPackage,
  fetching,
}) => {
  const [openDetailPanel, setOpenDetailPanel] = useState(null);

  const handleOpenDetailPanel = (event, clickedIndex) => {
    if (openDetailPanel === clickedIndex) {
      setOpenDetailPanel(null);
    } else {
      setOpenDetailPanel(clickedIndex);
    }
  };

  return (
    <React.Fragment key={`bu-${bu.id}`}>
      <TableRow>
        <TableCell align="left">
          <Box sx={{ display: 'flex', gap: '.2rem' }}>
            <Checkbox
              value={bu.mid}
              checked={bu?.isChecked || false}
              size="small"
              onChange={handleChangeRowSelection}
            />
            <Tooltip title="User Info">
              <span>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={(event) => handleOpenDetailPanel(event, bu.mid)}
                >
                  {openDetailPanel === bu.mid ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <UserNotificationFlow
              users={getUsersForBusinessUnit(bu.mid)}
              handleNotificationSend={handleNotificationSend}
              isNotificationSuccessful={isNotificationSuccessful}
              isError={isError}
              setIsError={setIsError}
              errorMessages={errorMessages}
              buDisplayName={bu.displayName ? bu.displayName : bu.name}
              smallIcon
            />
          </Box>
        </TableCell>
        <TableCell sx={{ minWidth: '280px' }}>{bu.name}</TableCell>
        <TableCell>
          <InlineEditInput
            value={bu.displayName}
            onChange={(e) => handleDisplayNameChange(bu.id, e.target.value)}
            onSubmit={() => handleDisplayNameSubmit(bu)}
            isLoading={displayNameChangeLoading}
          />
        </TableCell>
        <TableCell align="right">{bu.mid}</TableCell>
        <TableCell align="right">{bu.parentMid}</TableCell>
        <TableCell align="right">
          {togglingActive === bu.id ? (
            <CircularProgress size={'32px'} />
          ) : (
            <IconButton
              disabled={!!(togglingActive || fetching)}
              onClick={() => handleToggleActive(bu)}
              sx={{ padding: '0' }}
            >
              {bu.active ? (
                <ToggleOn
                  fontSize="large"
                  color={togglingActive || fetching ? 'gray' : 'success'}
                />
              ) : (
                <ToggleOff
                  fontSize="large"
                  color={togglingActive || fetching ? 'gray' : 'error'}
                />
              )}
            </IconButton>
          )}
        </TableCell>

        <TableCell align="right">
          {togglingDataDownload === bu.id ? (
            <CircularProgress size={'32px'} />
          ) : (
            <Tooltip
              title={
                togglingDataDownload || fetching || !bu.active
                  ? 'Business unit must be active.'
                  : ''
              }
            >
              <span>
                <IconButton
                  disabled={!!(togglingDataDownload || fetching || !bu.active)}
                  onClick={() => handleToggleDataDownload(bu)}
                  sx={{ padding: '0' }}
                >
                  {bu.enableDataDownload ? (
                    <ToggleOn
                      fontSize="large"
                      color={
                        togglingDataDownload || fetching || !bu.active
                          ? 'gray'
                          : 'success'
                      }
                    />
                  ) : (
                    <ToggleOff
                      fontSize="large"
                      color={
                        togglingDataDownload || fetching || !bu.active
                          ? 'gray'
                          : 'error'
                      }
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>

        <TableCell align="right">
          {togglingSfmcAuth === bu.id ? (
            <CircularProgress size={'32px'} />
          ) : (
            <Tooltip
              title={
                togglingSfmcAuth ||
                fetching ||
                !bu.active ||
                !sfmcPackage?.publicClientId
                  ? 'Business unit must be active and have a public client id.'
                  : ''
              }
            >
              <span>
                <IconButton
                  disabled={
                    !!(
                      togglingSfmcAuth ||
                      fetching ||
                      !bu.active ||
                      !sfmcPackage?.publicClientId
                    )
                  }
                  onClick={() => handleToggleSfmcAuth(bu)}
                  sx={{ padding: '0' }}
                >
                  {bu.enableSfmcAuth ? (
                    <ToggleOn
                      fontSize="large"
                      color={
                        togglingSfmcAuth ||
                        fetching ||
                        !bu.active ||
                        !sfmcPackage?.publicClientId
                          ? 'gray'
                          : 'success'
                      }
                    />
                  ) : (
                    <ToggleOff
                      fontSize="large"
                      color={
                        togglingSfmcAuth ||
                        fetching ||
                        !bu.active ||
                        !sfmcPackage?.publicClientId
                          ? 'gray'
                          : 'error'
                      }
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell sx={{ minWidth: '310px' }}>
          {
            <InlineAccountResponsibleEditor
              originalAccountResponsibles={bu.responsibles}
              mid={bu.mid}
              onResponsiblesChange={(newResponsibles) =>
                handleResponsiblesChange(bu.id, newResponsibles)
              }
            ></InlineAccountResponsibleEditor>
          }
        </TableCell>
        <TableCell align="right">
          {bu.apiEnabled ? (
            <Check fontSize="large" color="success" />
          ) : (
            <NotInterested fontSize="large" color="error" />
          )}
        </TableCell>
      </TableRow>
      <TableRow key={`bu-s${bu.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={openDetailPanel === bu.mid}
            timeout="auto"
            unmountOnExit
          >
            {bu?.users && bu?.users.length > 0 ? (
              <Paper
                sx={{
                  width: '100%',
                  padding: '1rem',
                  background: 'inherit',
                }}
              >
                <TableContainer sx={{ borderRadius: '12px' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>View users</TableCell>
                        <TableCell>Audits</TableCell>
                        <TableCell>SFMC</TableCell>
                        <TableCell>Builder Suite</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bu.users.map((user) => {
                        return (
                          <React.Fragment key={`bu-${user.id}`}>
                            <TableRow>
                              <TableCell>{user.username}</TableCell>
                              <TableCell>
                                {checkPermissions(user, 'admin', {
                                  businessUnit: bu,
                                }) ? (
                                  <Check fontSize="small" color="success" />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell>
                                {checkPermissions(
                                  user,
                                  ['businessUnitUsersView', 'admin'],
                                  { businessUnit: bu }
                                ) ? (
                                  <Check fontSize="small" color="success" />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell>
                                {checkPermissions(user, ['audits', 'admin'], {
                                  businessUnit: bu,
                                }) ? (
                                  <Check fontSize="small" color="success" />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell>
                                {checkPermissions(user, ['sfmc', 'admin'], {
                                  businessUnit: bu,
                                }) ? (
                                  <Check fontSize="small" color="success" />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell>
                                {checkPermissions(
                                  user,
                                  ['builderSuiteDeploy'],
                                  { businessUnit: bu }
                                ) ? (
                                  <Check fontSize="small" color="success" />
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            ) : (
              <Box sx={{ padding: '1rem' }}>There are no users.</Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default BusinessUnitDatatableRow;
