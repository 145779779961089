import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { DarkModeContextProvider } from './context/darkModeContext';
import { SidebarCollapseContextProvider } from './context/sidebarCollapseContext';
import Util from './lib/util';

if (!window.wsfmc) {
  window.wsfmc = {};
  window.wsfmc.tabId = Util.generateRandomHexString();
}

const root = createRoot(document.getElementById('root'));

root.render(
  Util.getEnvVariable('REACT_APP_STRICTMODE') === 'false' ? (
    <SidebarCollapseContextProvider>
      <DarkModeContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DarkModeContextProvider>
    </SidebarCollapseContextProvider>
  ) : (
    <React.StrictMode>
      <SidebarCollapseContextProvider>
        <DarkModeContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DarkModeContextProvider>
      </SidebarCollapseContextProvider>
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
