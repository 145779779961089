import './accountAdmin.scss';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Delete, SystemUpdateAlt } from '@mui/icons-material';
import { useNavigate } from '../../state/Navigation';
import UpdateSfmcPackage from './updatePackage';
import DeletePackage from './components/DeletePackage';
import {
  checkPermissions,
  getUsersData,
} from '../../service/adminApiService/adminApiService';
import {
  notificationUsersString,
  postUserNotifications,
} from '../../service/wsfmcNotificationsService/wsfmcNotificationsService';
import BUDatatable from '../../components/datatable/BUDatatable';

function counterReducer(state, value) {
  if (value === true) {
    return state + 1;
  }
  return Math.max(state - 1, 0);
}

export default function EditAccount({ user }) {
  const navigate = useNavigate();
  const params = useParams();

  const deleteRef = useRef(null);
  const [usersData, setUsersData] = useState({});
  const [usersLoaded, setUsersLoaded] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [updatePackage, openUpdatePackage] = useState(false);
  const [activeOnly, setActiveOnly] = useState(false);
  const [inactiveOnly, setInactiveOnly] = useState(false);
  const [apiEnabledOnly, setApiEnabledOnly] = useState(false);
  const [apiDisabledOnly, setApiDisabledOnly] = useState(false);
  const [sfmcAuthEnabledOnly, setSfmcAuthEnabledOnly] = useState(false);
  const [sfmcAuthDisabledOnly, setSfmcAuthDisabledOnly] = useState(false);
  const [dataDownloadEnabledOnly, setDataDownloadEnabledOnly] = useState(false);
  const [dataDownloadDisabledOnly, setDataDownloadDisabledOnly] =
    useState(false);
  const [togglingActive, setTogglingActive] = useState(null);
  const [togglingSfmcAuth, setTogglingSfmcAuth] = useState(null);
  const [togglingDataDownload, setTogglingDataDownload] = useState(null);
  const [data, setData] = useState(null);
  const [trigger, setTrigger] = useState(null);
  const [account, setAccount] = useState();
  const [sfmcPackage, setSfmcPackage] = useState();
  const [displayNameChangeLoading, setDisplayNameChangeLoading] =
    useState(false);
  //for error handling
  const [iserror, setIserror] = useReducer(counterReducer, 0);
  const [isSuccess, setIsSuccess] = useReducer(counterReducer, 0);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [isNotificationSuccessful, setIsNotificationSuccessful] =
    useState(false);

  function handleActiveOnly(evt) {
    setActiveOnly(evt.target.checked);
    if (evt.target.checked) setInactiveOnly(false);
  }
  function handleInactiveOnly(evt) {
    setInactiveOnly(evt.target.checked);
    if (evt.target.checked) setActiveOnly(false);
  }
  function handleEnableOnly(evt) {
    setApiEnabledOnly(evt.target.checked);
    if (evt.target.checked) setApiDisabledOnly(false);
  }
  function handleDisableOnly(evt) {
    setApiDisabledOnly(evt.target.checked);
    if (evt.target.checked) setApiEnabledOnly(false);
  }

  function handleDataDownloadEnableOnly(evt) {
    setDataDownloadEnabledOnly(evt.target.checked);
    if (evt.target.checked) setDataDownloadDisabledOnly(false);
  }
  function handleDataDownloadDisableOnly(evt) {
    setDataDownloadDisabledOnly(evt.target.checked);
    if (evt.target.checked) setDataDownloadEnabledOnly(false);
  }

  function handleSfmcAuthEnableOnly(evt) {
    setSfmcAuthEnabledOnly(evt.target.checked);
    if (evt.target.checked) setSfmcAuthDisabledOnly(false);
  }
  function handleSfmcAuthDisableOnly(evt) {
    setSfmcAuthDisabledOnly(evt.target.checked);
    if (evt.target.checked) setSfmcAuthEnabledOnly(false);
  }

  async function loadUsersData() {
    try {
      const apiRes = await getUsersData({});
      setUsersData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setUsersData(errorRes);
    }
  }

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }

    loadUsersData();
  }, []);

  useEffect(() => {
    if (usersData?.users && data?.businessUnits) {
      data.businessUnits.forEach((bu) => {
        let users = usersData.users.filter((user) =>
          checkPermissions(user, ['*', 'admin'], { businessUnit: bu })
        );
        bu.users = _.sortBy(users, (u) => u.username.toLowerCase());
      });
      setUsersLoaded(new Date());
    }
  }, [usersData?.users, data?.businessUnits]);

  const getAccounts = useCallback(async () => {
    try {
      if (!trigger?.toggling) {
        setFetching(true);
      }
      const response = await fetch(
        `/api/wsfmc/account/${params.eid}/${params.clientId}?getResponsibles=true`
      );
      const accountData = await response.json();
      setData(accountData);

      defineAccount(accountData?.businessUnits, accountData?.packages[0]);
    } finally {
      setFetching(false);
      setTogglingActive(null);
      setTogglingDataDownload(null);
      setTogglingSfmcAuth(null);
    }

    function defineAccount(businessUnits, sfmcPackage) {
      if (!businessUnits || !sfmcPackage?.eid) {
        setAccount({});
        setSfmcPackage({});
        return;
      }

      const acc = _.find(businessUnits, (bu) => (bu.mid = sfmcPackage.eid));
      setAccount({ ...acc });
      setSfmcPackage(sfmcPackage);
    }
  }, [params.clientId, params.eid, trigger]);

  useEffect(() => {
    getAccounts().catch(console.error);
  }, [params.clientId, params.eid, trigger, getAccounts]);

  async function handleUpdate(evt) {
    openUpdatePackage(true);
  }

  async function performUpdate(additionalMIDs, publicClientId) {
    openUpdatePackage(false);
    try {
      setFetching(true);

      const body = JSON.stringify({
        credentials: {
          additionalMIDs,
          clientId: data.packages[0].clientId,
          subdomain: data.packages[0].tenantSubdomain,
          publicClientId,
        },
      });
      const response = await fetch('/api/wsfmc/admin/setupcredentials', {
        method: 'PUT',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      try {
        const json = await response.json();
        if (response.ok && json.eid) {
          setFetching(false);
          setErrorMessages(['']);
          setIserror(false);
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 3000);
          setSuccessMessages([
            `Credentials updated successfully.
          `,
          ]);
          setTrigger(new Date());
        } else {
          setFetching(false);
          setErrorMessages([
            `Unable to update credentials. Please try again later.`,
          ]);
          setIserror(true);
          setTimeout(() => {
            setIserror(false);
          }, 5000);
        }
      } catch {
        setFetching(false);
        setErrorMessages([
          `Unable to update credentials. Please try again later.`,
        ]);
        setIserror(true);
        setTimeout(() => {
          setIserror(false);
        }, 5000);
      }
    } catch (error) {
      setFetching(false);
      setErrorMessages([
        `Unable to update credentials. Please try again later.`,
      ]);
      setIserror(true);
      setTimeout(() => {
        setIserror(false);
      }, 5000);
    }
  }

  function handleDisplayNameChange(buId, newDisplayName) {
    if (newDisplayName.length > 200) {
      return;
    }

    const buIdx = data.businessUnits.findIndex((bu) => bu.id === buId);
    if (buIdx >= 0) {
      data.businessUnits[buIdx].displayName = newDisplayName;
      setData({ ...data });
    }
  }

  async function handleDisplayNameSubmit(bu) {
    setDisplayNameChangeLoading(true);

    const body = {
      displayName: bu.displayName,
    };

    try {
      const response = await fetch(
        `/api/wsfmc/admin/account/${bu.id}/display-name`,
        {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Operation failed');
      }

      setErrorMessages(['']);
      setIserror(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      setSuccessMessages([`Display name for ${bu.name} updated successfully.`]);
      setTrigger({ toggling: true, value: new Date() });
    } catch (e) {
      setErrorMessages([
        `Unable to update display name for ${bu.displayName}. Please try again later. ${e.message}`,
      ]);
      setIserror(true);
      setTimeout(() => {
        setIserror(false);
      }, 5000);
    }

    setDisplayNameChangeLoading(false);
  }

  function handleResponsiblesChange(buId, newResponsibles) {
    const buIdx = data.businessUnits.findIndex((bu) => bu.id === buId);
    if (buIdx >= 0) {
      data.businessUnits[buIdx].responsibles = newResponsibles;
      setData({ ...data });
    }
  }

  async function sendNotification(notificationData) {
    try {
      await postUserNotifications(notificationData);
      setErrorMessages(['']);
      setIserror(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
      setSuccessMessages([
        `Notification successfully sent to ${notificationUsersString(
          notificationData
        )}`,
      ]);
      setIsNotificationSuccessful(true);
      setTimeout(() => {
        setIsNotificationSuccessful(false);
      }, 1000);
    } catch (error) {
      setIsNotificationSuccessful(false);
      setErrorMessages([
        'Something went wrong. The notification could not be sent.',
      ]);
      setIserror(true);
      setTimeout(() => {
        setIserror(false);
      }, 5000);
    }
  }

  const handleNotificationSend = (notificationData) => {
    sendNotification(notificationData);
  };

  return (
    <div className="sfmc-account-admin edit-sfmc-account">
      <div>
        {isSuccess ? (
          <Alert
            severity="success"
            style={{
              position: 'absolute',
              right: '5px',
              top: ' 5px',
              minWidth: '50%',
            }}
          >
            {successMessages.map((msg, i) => {
              return (
                <div key={i} style={{ fontWeight: '600' }}>
                  {msg}
                </div>
              );
            })}
          </Alert>
        ) : (
          ''
        )}
      </div>{' '}
      <div>
        {iserror ? (
          <Alert
            severity="error"
            style={{ position: 'absolute', right: '5px', top: ' 5px' }}
          >
            {errorMessages.map((msg, i) => {
              return (
                <div key={i} style={{ fontWeight: '600' }}>
                  {msg}
                </div>
              );
            })}
          </Alert>
        ) : (
          ''
        )}
      </div>
      <DeletePackage
        ref={deleteRef}
        onCompleted={() => {
          navigate('/admin/sfmc/account');
        }}
      />
      <UpdateSfmcPackage
        open={updatePackage}
        publicClientId={data?.packages[0]?.publicClientId}
        onSubmit={performUpdate}
        onClose={() => openUpdatePackage(false)}
      />
      <Box className={'backButtonBox'}>
        <Link to={`/admin/sfmc/account`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <h2>
        Edit business units{' '}
        {account && !_.isEmpty(account) ? (
          <>
            <Tooltip title="Update package from SFMC" placement="bottom">
              <span>
                <IconButton onClick={handleUpdate} disabled={fetching}>
                  <SystemUpdateAlt />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title="Delete account from the Workbench"
              placement="bottom"
            >
              <span>
                <IconButton
                  disabled={fetching || _.isEmpty(account)}
                  onClick={() =>
                    deleteRef.current.open({
                      ...{ name: account.name },
                      ...data.packages[0],
                    })
                  }
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </>
        ) : (
          ''
        )}
      </h2>
      <br />
      {fetching ? <LinearProgress /> : ''}
      {!fetching && !_.isEmpty(account) ? (
        <div>
          <div>
            <Grid container spacing={2}>
              <Grid item>
                <dl>
                  <dt component="dt">Account</dt>
                  <dd>{account.displayName || account.name}</dd>
                </dl>
              </Grid>
              <Grid item>
                <dl>
                  <dt>Account type</dt>
                  <dd>{account.accountType}</dd>
                </dl>
              </Grid>
              <Grid item>
                <dl>
                  <dt>EID</dt>
                  <dd>{account.mid}</dd>
                </dl>
              </Grid>
              <Grid item>
                <dl>
                  <dt>Client Id</dt>
                  <dd>{data.packages[0].clientId}</dd>
                </dl>
              </Grid>
              <Grid item>
                <dl>
                  <dt>Public Client Id</dt>
                  <dd>{data.packages[0].publicClientId}</dd>
                </dl>
              </Grid>
              <Grid item>
                <dl>
                  <dt>Subdomain</dt>
                  <dd>{data.packages[0].tenantSubdomain}</dd>
                </dl>
              </Grid>
            </Grid>
          </div>
          <br />
          <br />
          <BUDatatable
            data={data}
            setData={setData}
            setTrigger={setTrigger}
            usersLoaded={usersLoaded}
            activeOnly={activeOnly}
            handleActiveOnly={handleActiveOnly}
            inactiveOnly={inactiveOnly}
            apiEnabledOnly={apiEnabledOnly}
            apiDisabledOnly={apiDisabledOnly}
            dataDownloadEnabledOnly={dataDownloadEnabledOnly}
            dataDownloadDisabledOnly={dataDownloadDisabledOnly}
            sfmcAuthEnabledOnly={sfmcAuthEnabledOnly}
            sfmcAuthDisabledOnly={sfmcAuthDisabledOnly}
            handleInactiveOnly={handleInactiveOnly}
            handleEnableOnly={handleEnableOnly}
            handleDisableOnly={handleDisableOnly}
            handleDataDownloadEnableOnly={handleDataDownloadEnableOnly}
            handleDataDownloadDisableOnly={handleDataDownloadDisableOnly}
            handleSfmcAuthEnableOnly={handleSfmcAuthEnableOnly}
            handleSfmcAuthDisableOnly={handleSfmcAuthDisableOnly}
            handleNotificationSend={handleNotificationSend}
            isNotificationSuccessful={isNotificationSuccessful}
            isError={!!iserror}
            setIsError={setIserror}
            isSuccess={!!isSuccess}
            setIsSuccess={setIsSuccess}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            successMessages={successMessages}
            setSuccessMessages={setSuccessMessages}
            handleDisplayNameChange={handleDisplayNameChange}
            handleResponsiblesChange={handleResponsiblesChange}
            handleDisplayNameSubmit={handleDisplayNameSubmit}
            displayNameChangeLoading={displayNameChangeLoading}
            setTogglingActive={setTogglingActive}
            setTogglingDataDownload={setTogglingDataDownload}
            setTogglingSfmcAuth={setTogglingSfmcAuth}
            togglingActive={togglingActive}
            togglingDataDownload={togglingDataDownload}
            togglingSfmcAuth={togglingSfmcAuth}
            sfmcPackage={sfmcPackage}
            fetching={fetching}
            usersData={usersData}
            getAccounts={getAccounts}
          />
        </div>
      ) : (
        ''
      )}
      {!fetching && _.isEmpty(account) ? 'No such account' : ''}
    </div>
  );
}
