import QueryUtils from './DataExtensionQueryUtils';

export default class DataExtensionQueryValueProcessor {
  fieldMap;

  /**
   * ValueProcessor
   * @param {import('react-querybuilder').Field[]} fields
   */
  constructor(fieldMap) {
    this.fieldMap = fieldMap;
  }

  /**
   * Renders the right operand
   * @param {string} field field name
   * @param {operator} field operator name (not the label)
   * @param {string|number} value field value
   * @param {boolean} sqlCompliant this will make the value processor treat quotes in a way that is compatible with sql
   * @returns {string} rendered right operand
   */
  process(field, operator, value, sqlCompliant) {
    const fieldData = this.fieldMap[field];
    if (!fieldData || !operator) {
      return '';
    }

    const validation = QueryUtils.validateField(
      { field, operator, value },
      fieldData,
      sqlCompliant
    );

    if (!validation.isValid) {
      return "''";
    } else {
      return validation.processedValue || '';
    }
  }
}
