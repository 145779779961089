import _ from 'lodash';

/**
 * Get the business units that the user is allowed to access
 * @return {Promise<{status: number, businessUnits: Object.<string, BusinessUnitItem>}>}
 */
export const getBusinessUnits = async ({
  active = true,
  getResponsibles = false,
} = {}) => {
  // const businessUnits = await fetch('/api/audits/businessunits').then(
  //   async (res) => {
  //     return { status: res.status, businessUnits: await res.json() };
  //   }
  // );

  const accounts = await fetch(
    `/api/wsfmc/account?active=${
      active ? 'true' : 'false'
    }&getResponsibles=${getResponsibles}`
  ).then(async (res) => {
    return { status: res.status, accounts: await res.json() };
  });

  //   await new Promise((resolve) => setTimeout(resolve, 3000));

  return accounts;
};

/**
 * Get the business units that the user is allowed to access
 * @param {string} mid
 * @param {('csv'|'json')} [type]
 * @param {Object} [options] query attributes for request. {attributeName: attributeValue}
 * @return {Promise<Object>}
 */
export const getDataExtensionUsage = async (mid, options) => {
  let url = `/api/audits/businessunits/${mid}/de-usage`;

  if (_.isPlainObject(options)) {
    url += '?' + new URLSearchParams(options).toString();
  }

  const res = await fetch(url);

  const data = await res.json();

  return { status: res.status, data };
};
