import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Util from '../lib/util';
import * as auditService from '../lib/auditsService';
import * as _ from 'lodash';
import { parseISO as parseISODate } from 'date-fns';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  TextField,
  styled,
  LinearProgress,
  Box,
  InputAdornment,
  Tooltip,
  Button,
} from '@mui/material';
import {
  Business as BusinessIcon,
  Cached,
  RocketLaunch,
  ExpandMore,
  ContentCopy,
  Warning as WarningIcon,
  AccountTree,
} from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ExtensionIcon from '@mui/icons-material/Extension';
import CloudIcon from '@mui/icons-material/Cloud';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from '../state/Navigation';
import LaunchWorkflowDialog from './LaunchWorkflowDialog';
import ClipboardCopy from '../components/ui/ClipboardCopy';
import AppInsights from '../lib/appInsights';

import './Audits.scss';
import scssVariables from '../styles/variables.scss';
import UserListManager from '../components/manager/UserListManager';
import {
  checkPermissions,
  getUsersData,
  isAdmin,
} from '../service/adminApiService/adminApiService';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
  },
}));

/**
 * Reload business units
 * @returns {{status: string, items: BusinessUnitItem[]}}
 */
const reloadBusinessUnits = async () => {
  try {
    return await auditService.getBusinessUnits().then((res) => {
      switch (res.status) {
        case 200:
        case 404:
          return {
            items: _.sortBy(
              res.accounts?.businessUnits?.filter((bu) => bu.active) || [],
              [
                (bu) =>
                  bu.displayName?.toLowerCase() ||
                  bu.name?.toLowerCase() ||
                  bu.mid,
                (bu) => bu.enterprise?.name?.toLowerCase() || bu.mid,
              ]
            ),
            packages: _.groupBy(res.accounts?.packages, 'id'),
            properties: res.accounts?.properties || {},
            status: '',
          };
        case 403:
        case 401:
          return {
            items: null,
            status: 'Insufficient permissions',
          };
        default:
          throw res.status;
      }
    });
  } catch (error) {
    console.error(error);
    return {
      items: null,
      status:
        'An error occurred. If the problem persist, please contact support.',
    };
  }
};

const businessUnitPerRow = 20;

export default function BusinessUnit({ user, reloadUser }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const clipboard = useRef();
  const [workflowDialogParams, setWorkflowDialogParams] = useState({
    open: false,
    item: null,
  });
  const [businessUnits, setBusinessUnits] = useState({
    items: null,
    properties: {},
    packages: {},
    status: null,
  });
  const [nextBusinessUnits, setNextBusinessUnits] =
    useState(businessUnitPerRow);
  const [loading, setLoading] = useState(null);
  const [usersData, setUsersData] = useState({});
  const [buFilter, setBuFilter] = useState(searchParams?.get('q'));

  const handleMoreBusinessUnits = () => {
    setNextBusinessUnits(nextBusinessUnits + businessUnitPerRow);
  };

  async function loadUsersData() {
    try {
      const apiRes = await getUsersData();
      setUsersData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setUsersData(errorRes);
    }
  }

  useEffect(() => {
    if (businessUnits?.items?.some((bu) => bu.usersViewAccessGranted)) {
      loadUsersData();
    }
  }, [businessUnits?.items, user]);

  const handleClickOpenLaunchWorkflow = (event, item) => {
    setWorkflowDialogParams({ open: true, item });
    event.stopPropagation();
  };

  const handleCloseLaunchWorkflow = () => {
    setWorkflowDialogParams({ open: false, item: workflowDialogParams.item });
  };

  const onBuFilterChange = (value) => {
    setBuFilter(value || '');
    setSearchParams(value ? { q: value } : {}, {
      replace: true,
    });
  };

  const handleRefresh = useCallback(() => {
    const refresh = async () => {
      // if (isMounted()) {
      setLoading(true);
      // }
      try {
        await reloadUser?.();
        const value = await reloadBusinessUnits();
        // if (isMounted()) {
        setBusinessUnits(value);
        // }
      } catch (error) {
        console.error(error);
      } finally {
        // if (isMounted()) {
        setLoading(false);
        // }
      }
    };
    refresh();
  }, [reloadUser]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const filteredBusinessUnits = useMemo(() => {
    return !buFilter
      ? businessUnits?.items
      : businessUnits?.items?.filter((bu) => {
          const properties = businessUnits.properties[bu.mid] || {};

          const repositoryName = properties.RepositoryName?.value || '';

          const buDisplayNameOrName = bu.displayName || bu.name;
          const enterpriseDisplayNameOrName =
            bu.enterprise?.displayName || bu.enterprise?.name;

          const result =
            buDisplayNameOrName
              ?.toLowerCase()
              ?.indexOf(buFilter.toLowerCase()) >= 0 ||
            enterpriseDisplayNameOrName
              ?.toLowerCase()
              ?.indexOf(buFilter.toLowerCase()) >= 0 ||
            repositoryName?.toLowerCase()?.indexOf(buFilter.toLowerCase()) >=
              0 ||
            String(bu.mid)?.indexOf(buFilter) >= 0 ||
            (Number(bu.enterprise?.mid) > 0 &&
              String(bu.enterprise?.mid)?.indexOf(buFilter) >= 0);
          return result;
        });
  }, [businessUnits, buFilter]);

  const formatDate = (input) => {
    return input
      ?.replace(/-/g, '/')
      ?.replace('T', ' ')
      ?.replace(/:\d{2}\.\d{3}Z$/, ' UTC');
  };

  /**
   * @param {string} input
   */
  const renderMatch = (input) => {
    if (input && buFilter) {
      const lowercaseFilter = buFilter.toLowerCase();
      const indices = _.toArray(
        String(input).matchAll(new RegExp(_.escapeRegExp(buFilter), 'gi'))
      )
        .flatMap((m) => [m.index, m.index + buFilter.length])
        .filter((index) => index > 0);
      indices.push(input.length);

      let lastIndex = 0;
      return React.Children.toArray(
        indices.map((index) => {
          const part = String(input).substring(lastIndex, index);
          lastIndex = index;
          return part.toLowerCase() === lowercaseFilter ? (
            <span className="search-match">{part}</span>
          ) : (
            <span className="search-unmatched">{part}</span>
          );
        })
      );
    }
    return input;
  };

  const isSyncOverdue = (datestr) => {
    if (!datestr) {
      return true;
    }
    const parsed = parseISODate(datestr);
    const now = new Date();
    return now - parsed > 1000 * 3600 * 36;
  };

  function setBusinessUnitUsers(businessUnit) {
    if (usersData?.users && businessUnit) {
      let users = usersData.users.filter((user) =>
        checkPermissions(user, '*', { businessUnit, scopesOnly: true })
      );
      businessUnit.users = _.sortBy(users, (u) => u.username.toLowerCase());
    }
  }

  return (
    <div className="audits">
      <ClipboardCopy ref={clipboard} flashPopperClassName={'clipboard'} />
      <LaunchWorkflowDialog
        openParams={workflowDialogParams}
        onClose={handleCloseLaunchWorkflow}
      />
      <div className="audits-header">
        <h2 className="audits-title">
          <BusinessIcon className="audits-title__icon" />
          <span>Business Units</span>
        </h2>
        <IconButton
          className="refresh-list"
          disabled={loading}
          onClick={() => handleRefresh()}
          title="Refresh display"
        >
          <Cached />
        </IconButton>
      </div>

      {businessUnits?.items?.length ? (
        <Grid container spacing={2} className="filter-bu" wrap={'nowrap'}>
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              fontSize: 'larger',
            }}
            component={'div'}
          ></Box>
          <Box sx={{ marginTop: '16px' }}>
            <CssTextField
              focusColor={scssVariables.colorPrimary}
              // id="filter-bu"
              name="filter-bu"
              label="Name, MID, repository, parent business unit..."
              id="outlined-search"
              variant="outlined"
              value={buFilter || ''}
              autoComplete="nope"
              className="search-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon className="icon" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {buFilter && (
                      <HighlightOffIcon
                        className="search-close-icon"
                        onClick={() => onBuFilterChange('')}
                      ></HighlightOffIcon>
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                onBuFilterChange(event.target.value);
              }}
            />
          </Box>
          <Grid
            item
            style={{
              alignItems: 'center',
              display: 'flex',
              fontSize: 'larger',
            }}
            component={'div'}
          ></Grid>
        </Grid>
      ) : null}
      {!loading ? (
        <div className="bu-count">
          <span>
            {!businessUnits?.status
              ? `${filteredBusinessUnits?.length || 0} Business Unit${
                  filteredBusinessUnits?.length > 1 ? 's' : ''
                }`
              : ''}
          </span>
        </div>
      ) : null}
      {loading ? (
        <LinearProgress className="main-color" style={{ marginTop: '20px' }} />
      ) : (
        ''
      )}
      <List style={{ minWidth: '600px' }}>
        {filteredBusinessUnits
          ?.slice(0, nextBusinessUnits)
          ?.map((item, index) => {
            if (item.usersViewAccessGranted) {
              setBusinessUnitUsers(item);
            }
            const properties = businessUnits.properties[item.mid] || {};

            const lastSyncOverdue = isSyncOverdue(
              properties.RepositoryLastSyncDate?.value
            );

            const repositoryLastSyncDate = properties.RepositoryLastSyncDate
              ? `${formatDate(properties.RepositoryLastSyncDate?.value)}`
              : 'Never determined';

            const repositoryName = properties.RepositoryName?.value || '';

            const sfmcPackage =
              businessUnits?.packages?.[item.credentialsId]?.[0];

            const sfmcConnected =
              item.enableSfmcAuth && user?.roles?.connectedAccounts?.[item.mid]
                ? true
                : false;

            const canViewAudits = repositoryName && item.auditsAccessGranted;

            const canInstallPackages =
              item.builderSuiteAccessGranted && item.apiEnabled;

            const canBrowseDataExtensions =
              item.sfmcAccessGranted && item.apiEnabled;

            const accordion =
              canViewAudits || canBrowseDataExtensions || canInstallPackages;

            return (
              <ListItem key={`${item.id}_link`} className="bu-list-item">
                <Accordion
                  className="bu-list-item__panel"
                  onClickCapture={(evt) => {
                    if (!accordion) {
                      if (
                        !_.find(Util.composedDOMPath(evt.target), (node) => {
                          return (
                            node.tagName === 'BUTTON' ||
                            node.classList?.contains?.('connectSfmc')
                          );
                        })
                      ) {
                        evt.stopPropagation();
                        evt.preventDefault();
                      }
                    }
                  }}
                >
                  <AccordionSummary
                    style={{
                      cursor: accordion ? 'pointer' : 'default',
                    }}
                    className="bu-accordion-summary"
                    expandIcon={
                      accordion ? (
                        <ExpandMore
                          className="expand-icon"
                          sx={{
                            pointerEvents: 'auto',
                            cursor: 'pointer',
                            fontSize: '36px',
                          }}
                        />
                      ) : (
                        <span
                          style={{ display: 'inline-block', width: '36px' }}
                        >
                          {' '}
                        </span>
                      )
                    }
                    aria-controls={`panel1a-content-${item.id}`}
                    id={`panel1a-header-${item.id}`}
                  >
                    <ListItemAvatar>
                      <Avatar className="bu-avatar">
                        <BusinessIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container>
                        <Grid
                          item
                          xs={8}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.3rem',
                          }}
                        >
                          <div className="bu-link">
                            <div
                              className="bu-name"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <span>
                                {renderMatch(item.displayName || item.name)}
                              </span>
                              {item.usersViewAccessGranted && (
                                <UserListManager
                                  businessUnit={item}
                                  smallIcon
                                />
                              )}
                              {sfmcPackage?.eid && isAdmin(user) ? (
                                <Tooltip
                                  title="View the package configuration."
                                  placement="top"
                                >
                                  <Link
                                    to={`/admin/sfmc/account/edit/${sfmcPackage.eid}/${sfmcPackage?.clientId}`}
                                    target="_blank"
                                    className="logo"
                                  >
                                    <AccountTree fontSize="small" />
                                  </Link>
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                              {sfmcConnected ? (
                                <div
                                  style={{
                                    position: 'relative',
                                    display: 'flex',
                                  }}
                                >
                                  <img
                                    alt="SFMC logo"
                                    style={{ marginLeft: '10px' }}
                                    width="24"
                                    src="/salesforce-logo.svg"
                                  />
                                </div>
                              ) : item.enableSfmcAuth ? (
                                <Tooltip
                                  title="Connect to SFMC"
                                  placement="top"
                                >
                                  <Button
                                    style={{ marginLeft: '10px' }}
                                    className="connectSfmc"
                                    href={`/sfmc/connected-account/${item.mid}?login=true`}
                                    variant="outlined"
                                    type="button"
                                    color="primary"
                                    target="_blank"
                                    onClick={(evt) => {
                                      evt.stopPropagation();
                                    }}
                                    startIcon={
                                      <img
                                        alt="SFMC logo"
                                        width="24"
                                        src="/salesforce-logo.svg"
                                      />
                                    }
                                  >
                                    Connect
                                  </Button>
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="bu-mid">
                              <span
                                className="bu-mid-text"
                                style={{
                                  whiteSpace: 'nowrap',
                                  display: 'inline-block',
                                }}
                              >
                                {renderMatch(String(item.mid))}{' '}
                                <Tooltip title="Copy MID" placement="right">
                                  <IconButton
                                    className="copy-bu-field"
                                    size={'small'}
                                    onClick={(evt) => {
                                      clipboard.current.copy(
                                        evt,
                                        String(item.mid)
                                      );
                                      evt.stopPropagation();
                                      evt.preventDefault();
                                    }}
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                          {repositoryName ? (
                            <>
                              <div className="bu-repository">
                                <GitHubIcon />
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                  }}
                                >
                                  <Tooltip title="View on Github">
                                    <a
                                      href={`https://github.com/OSFDigital/${repositoryName}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={(evt) => {
                                        evt.stopPropagation();
                                      }}
                                    >
                                      {renderMatch(repositoryName)}
                                    </a>
                                  </Tooltip>{' '}
                                  <Tooltip
                                    title="Copy repository name"
                                    placement="right"
                                  >
                                    <IconButton
                                      className="copy-bu-field"
                                      size={'small'}
                                      onClick={(evt) => {
                                        clipboard.current.copy(
                                          evt,
                                          String(repositoryName)
                                        );
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </div>
                              <div
                                className={`bu-lastSyncDate${
                                  lastSyncOverdue ? ' overdue' : ''
                                }`}
                              >
                                <span className="bu-lastSyncDate-text">
                                  <CloudSyncIcon />
                                  <span className="value">
                                    {repositoryLastSyncDate}
                                  </span>
                                  <Tooltip title="More information on synchronization">
                                    <IconButton
                                      onClick={(event) => {
                                        AppInsights.trackEvent(user, {
                                          name: 'Click::Trigger sync',
                                          properties: {
                                            businessUnit: _.pick(item, 'mid'),
                                          },
                                        });
                                        handleClickOpenLaunchWorkflow(event, {
                                          item,
                                          repositoryName,
                                        });
                                      }}
                                    >
                                      <RocketLaunch />
                                    </IconButton>
                                  </Tooltip>
                                  {lastSyncOverdue ? (
                                    <span className="overdue-warning">
                                      <Tooltip
                                        title="Overdue"
                                        placement="right"
                                      >
                                        <WarningIcon />
                                      </Tooltip>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </Grid>
                        {item.enterprise?.mid && item.enterprise?.name ? (
                          <Grid item xs={4}>
                            <div className="bu-parent">
                              <div className="bu-name">
                                {renderMatch(
                                  item.enterprise?.displayName ||
                                    item.enterprise?.name
                                )}
                              </div>
                              <div className="bu-mid">
                                <span
                                  className="bu-mid-text"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                  }}
                                >
                                  {renderMatch(String(item.enterprise?.mid))}{' '}
                                  <Tooltip
                                    title="Copy MID"
                                    placement="bottom-end"
                                  >
                                    <IconButton
                                      className="copy-bu-field"
                                      size={'small'}
                                      onClick={(evt) => {
                                        clipboard.current.copy(
                                          evt,
                                          String(item.enterprise?.mid)
                                        );
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                      }}
                                    >
                                      <ContentCopy />
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </ListItemText>
                  </AccordionSummary>
                  {accordion ? (
                    <AccordionDetails className="bu-accordion-details">
                      <List>
                        {canViewAudits && repositoryName ? (
                          <>
                            <BusinessUnitLinkListItem
                              iconComponent={
                                <DataUsageIcon className="audit-item-icon" />
                              }
                              tooltipText={'View data extension usage'}
                              toolTipPlacement={'right'}
                              linkText={'Data extension usage'}
                              navigateTo={`/audits/de-usage/${item.mid}`}
                              key={`/audits/de-usage/${item.mid}`}
                            />
                            <BusinessUnitLinkListItem
                              iconComponent={
                                <ExtensionIcon className="audit-item-icon" />
                              }
                              tooltipText={'View data extension dependencies'}
                              toolTipPlacement={'right'}
                              linkText={'Data extension dependencies'}
                              navigateTo={`/audits/de-dependency/${item.mid}`}
                              key={`/audits/de-dependency/${item.mid}`}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {canBrowseDataExtensions ? (
                          <BusinessUnitLinkListItem
                            iconComponent={
                              <CloudIcon className="audit-item-icon" />
                            }
                            tooltipText={'View data extensions records'}
                            toolTipPlacement={'right'}
                            linkText={'Browse data extensions '}
                            navigateTo={`/browse/${item.mid}/e`}
                            key={`/browse/${item.mid}/e`}
                          />
                        ) : (
                          <></>
                        )}
                      </List>
                      {canInstallPackages && (
                        <List>
                          <BusinessUnitLinkListItem
                            iconComponent={
                              <CloudDownloadIcon className="audit-item-icon" />
                            }
                            tooltipText={'Create Builder Suite Zip Packages'}
                            toolTipPlacement={'left'}
                            linkText={'Builder Suite Package Creation - Zip'}
                            navigateTo={`/builder-suite/create-package/${item.mid}`}
                            key={`/builder-suite/create-package/${item.mid}`}
                          />
                          <BusinessUnitLinkListItem
                            iconComponent={
                              <CloudUploadIcon className="audit-item-icon" />
                            }
                            tooltipText={'Deploy Builder Suite Zip Packages'}
                            toolTipPlacement={'left'}
                            linkText={'Builder Suite Package Deployment - Zip'}
                            navigateTo={`/builder-suite/deploy-zip/${item.mid}`}
                            key={`/builder-suite/deploy-zip/${item.mid}`}
                          />
                          {/* <BusinessUnitLinkListItem
                            iconComponent={
                              <CloudUploadIcon className="audit-item-icon" />
                            }
                            tooltipText={
                              'Deploy Builder Suite Packages from Code Vault'
                            }
                            toolTipPlacement={'left'}
                            linkText={
                              'Builder Suite Package Deployment - Vault'
                            }
                            navigateTo={`/builder-suite/deploy-vault/${item.mid}`}
                            key={`/builder-suite/deploy-vault/${item.mid}`}
                          /> */}
                        </List>
                      )}
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              </ListItem>
            );
          })}
      </List>
      {nextBusinessUnits < filteredBusinessUnits?.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMoreBusinessUnits}
          >
            Load more
          </Button>
        </Box>
      )}
      {businessUnits?.items?.length ? (
        ''
      ) : businessUnits?.status ? (
        <Alert severity="error">{businessUnits?.status}</Alert>
      ) : (
        ''
      )}
    </div>
  );
}

function BusinessUnitLinkListItem({
  iconComponent,
  tooltipText,
  toolTipPlacement,
  linkText,
  navigateTo,
}) {
  const navigate = useNavigate();
  return (
    <ListItem key={navigateTo} className="audit-item">
      <ListItemText className="audit-item-text">
        <Link
          to={navigateTo}
          onClick={(event) => {
            event.preventDefault();
            navigate(navigateTo, {
              state: { referrer: window.location.href },
            });
          }}
        >
          {iconComponent}
          <div className="audit-item-title">
            <Tooltip title={tooltipText} placement={toolTipPlacement}>
              <div>{linkText}</div>
            </Tooltip>
          </div>
        </Link>
      </ListItemText>
    </ListItem>
  );
}
