import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Card from '../../components/ui/Card';
import BasicLine from '../../components/charts/line/BasicLine';
import Widget from '../../components/widget/Widget';
import Table from '../../components/table/Table';
import TrackingTableThreeCols from '../../components/table/TrackingTableThreeCols';
import TrackingTableTwoCols from '../../components/table/TrackingTableTwoCols';
import {
  getAccountData,
  getTrackingData,
  getTrackingItemsData,
  getTrackingPagesData,
  getUserData,
  getTrackingDataSummary,
  getTrackingDataByMonthSummary,
  getAccountTrackingDataSummary,
} from '../../service/dashboardApiService/dashboardApiService';
import GenericTable from '../../components/table/GenericTable';
import { Skeleton } from '@mui/material';
import ErrorHandler from '../../components/ui/ErrorHandler';
import { checkPermissions } from '../../service/adminApiService/adminApiService';
import { useNavigate } from 'react-router-dom';

function getUserClassification(usage, byMonth) {
  const classes = {
    INACTIVE: 'Inactive User',
    ACTIVE: 'Active User',
    POWER: 'Power User',
  };

  if (byMonth) {
    return usage < 20
      ? classes.INACTIVE
      : usage < 200
      ? classes.ACTIVE
      : classes.POWER;
  }

  return usage < 50
    ? classes.INACTIVE
    : usage < 500
    ? classes.ACTIVE
    : classes.POWER;
}

const Dashboard = ({ user }) => {
  // To prevent multiple api calls
  const allowUseEffectRun = useRef(true);
  const navigate = useNavigate();
  const [userTrackingShowAllUsers, setUserTrackingShowAllUsers] =
    useState(false);
  const [userTrackingByMonthShowAllUsers, setUserTrackingByMonthShowAllUsers] =
    useState(false);
  const [userData, setUserData] = useState({});
  const [accountData, setAccountData] = useState({});
  const [trackingData, setTrackingData] = useState({});
  const [trackingPagesData, setTrackingPagesData] = useState({});
  const [trackingItemsData, setTrackingItemsData] = useState({});
  const [trackingSummaryData, setTrackingSummaryData] = useState({});
  const [trackingSummaryDataHeaders, setTrackingSummaryDataHeaders] = useState(
    []
  );
  const [trackingSummaryByMonthData, setTrackingSummaryByMonthData] = useState(
    {}
  );
  const [
    trackingSummaryByMonthDataHeaders,
    setTrackingSummaryByMonthDataHeaders,
  ] = useState({});
  const [accountTrackingSummaryData, setAccountTrackingSummaryData] = useState(
    []
  );
  const [
    accountTrackingSummaryDataHeaders,
    setAccountTrackingSummaryDataHeaders,
  ] = useState([]);
  const [stealthUsers, setStealthUsers] = useState([]);

  const changeUserTrackingShowAllUsers = useCallback((status) => {
    setUserTrackingShowAllUsers(status);
  }, []);

  const changeUserTrackingByMonthShowAllUsers = useCallback((status) => {
    setUserTrackingByMonthShowAllUsers(status);
  }, []);

  const hasAccess = () => {
    return checkPermissions(user, ['admin', 'dashboards']);
  };

  useEffect(() => {
    if (!hasAccess()) {
      return navigate('/');
    }
  }, []);

  async function loadUserData() {
    try {
      const apiRes = await getUserData({
        activeOnly: false,
        sortBy: 'username',
        sortDirection: 'asc',
      });
      setUserData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setUserData(errorRes);
    }
  }

  async function loadAccountData() {
    try {
      const apiRes = await getAccountData({
        activeOnly: true,
        sortBy: 'name',
        sortDirection: 'asc',
      });
      setAccountData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setAccountData(errorRes);
    }
  }

  async function loadTrackingData() {
    try {
      const apiRes = await getTrackingData({
        sortBy: 'eventName',
        sortDirection: 'asc',
        limit: 999,
        offset: 0,
      });
      setTrackingData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setTrackingData(errorRes);
    }
  }

  async function loadTrackingPagesData() {
    try {
      const apiRes = await getTrackingPagesData({
        sortDirection: 'asc',
        limit: 999,
        offset: 0,
      });
      setTrackingPagesData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setTrackingPagesData(errorRes);
    }
  }

  async function loadTrackingItemsData() {
    try {
      const apiRes = await getTrackingItemsData({
        sortDirection: 'asc',
        limit: 999,
        offset: 0,
      });
      setTrackingItemsData(apiRes);
    } catch (error) {
      const errorRes = { error: error };
      setTrackingItemsData(errorRes);
    }
  }

  async function loadTrackingSummaryData() {
    try {
      const apiRes = await getTrackingDataSummary();

      let tData = apiRes.items.map((item) => {
        return {
          username: item.username,
          ...item.weeks,
          total: item.total,
          classification: getUserClassification(item.total),
        };
      });

      setTrackingSummaryData({ data: tData });

      let headCells = [
        {
          id: 'username',
          numeric: false,
          disablePadding: false,
          label: 'User',
        },
      ];

      apiRes.weeksStarts.forEach((week) => {
        headCells.push({
          id: week,
          numeric: true,
          disablePadding: true,
          label: week.split('-').slice(1, 3).join('-'),
        });
      });

      headCells.push({
        id: 'total',
        numeric: true,
        disablePadding: true,
        label: 'Grand Total',
      });

      headCells.push({
        id: 'classification',
        numeric: true,
        disablePadding: true,
        label: 'Classification',
      });

      setTrackingSummaryDataHeaders(headCells);
    } catch (error) {
      const errorRes = { error: error };
      setTrackingSummaryData(errorRes);
    }
  }

  async function loadTrackingSummaryByMonthData() {
    try {
      const apiRes = await getTrackingDataByMonthSummary();

      let tData = apiRes.items.map((item) => {
        const months = Object.keys(item.months).reduce((acc, month) => {
          acc[month] = item.months[month];
          acc[`${month}_classification`] = getUserClassification(
            acc[month],
            true
          );
          return acc;
        }, {});

        return {
          username: item.username,
          ...months,
          total: item.total,
        };
      });

      setTrackingSummaryByMonthData({ data: tData });

      let headCells = [
        {
          id: 'username',
          numeric: false,
          disablePadding: false,
          label: 'User',
        },
      ];

      apiRes.months.forEach((month) => {
        headCells.push({
          id: month,
          numeric: true,
          disablePadding: true,
          label: month,
        });

        headCells.push({
          id: `${month}_classification`,
          numeric: true,
          disablePadding: true,
          label: 'Classification',
        });
      });

      headCells.push({
        id: 'total',
        numeric: true,
        disablePadding: true,
        label: 'Grand Total',
      });

      setTrackingSummaryByMonthDataHeaders(headCells);
    } catch (error) {
      const errorRes = { error: error };
      setTrackingSummaryByMonthData(errorRes);
    }
  }

  async function loadAccountTrackingSummaryData() {
    try {
      const accountTrackingData = await getAccountTrackingDataSummary();

      let tData = accountTrackingData.items.map((item) => {
        return {
          account: item.businessUnit?.name,
          ...item.weeks,
          total: item.total,
        };
      });

      setAccountTrackingSummaryData({ data: tData });

      let headCells = [
        {
          id: 'account',
          numeric: false,
          disablePadding: false,
          label: 'Account',
        },
      ];

      accountTrackingData.weeksStarts.forEach((week) => {
        headCells.push({
          id: week,
          numeric: true,
          disablePadding: true,
          label: week.split('-').slice(1, 3).join('-'),
        });
      });

      headCells.push({
        id: 'total',
        numeric: true,
        disablePadding: true,
        label: 'Grand Total',
      });

      setAccountTrackingSummaryDataHeaders(headCells);
    } catch (error) {
      const errorRes = { error: error };
      setAccountTrackingSummaryData(errorRes);
    }
  }

  function getUserTrackingCsvFileName(showAll, byMonth) {
    return showAll
      ? `Usage_by_User${byMonth ? '_by_Month' : ''}_all.csv`
      : `Usage_by_User${byMonth ? '_by_Month' : ''}.csv`;
  }

  useEffect(() => {
    if (allowUseEffectRun.current) {
      allowUseEffectRun.current = false;
      loadUserData();
      loadAccountData();
      loadTrackingData();
      loadTrackingPagesData();
      loadTrackingItemsData();
      loadTrackingSummaryData();
      loadTrackingSummaryByMonthData();
      loadAccountTrackingSummaryData();
    }
  });

  useEffect(() => {
    if (userData.users) {
      const stealthUsers = userData.users
        .filter((u) => u.permissions?.flags?.includes('stealth'))
        .map((u) => u.username);
      if (stealthUsers) {
        setStealthUsers(stealthUsers);
      }
    }
  }, [userData.users]);

  const filteredData = useMemo(() => {
    return userTrackingShowAllUsers
      ? trackingSummaryData.data || []
      : trackingSummaryData.data?.filter(
          (user) => !stealthUsers.includes(user.username)
        ) || [];
  }, [trackingSummaryData.data, userTrackingShowAllUsers, stealthUsers]);

  const usersUsageByMonthFilteredData = useMemo(() => {
    return userTrackingByMonthShowAllUsers
      ? trackingSummaryByMonthData.data || []
      : trackingSummaryByMonthData.data?.filter(
          (user) => !stealthUsers.includes(user.username)
        ) || [];
  }, [
    trackingSummaryByMonthData.data,
    userTrackingByMonthShowAllUsers,
    stealthUsers,
  ]);

  return (
    <>
      {true ? (
        <div className="dashboard">
          <div className="row">
            {userData.users ? (
              <Card>
                <Widget type="user" userData={userData} />
              </Card>
            ) : (
              !userData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '125px',
                  }}
                />
              )
            )}
            {userData.error && (
              <Card style={{ maxHeight: '125px' }}>
                <ErrorHandler message={userData.error.message} />
              </Card>
            )}
            {accountData.accounts ? (
              <Card>
                <Widget type="account" accountData={accountData} />
              </Card>
            ) : (
              !accountData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '125px',
                  }}
                />
              )
            )}
            {accountData.error && (
              <Card style={{ maxHeight: '125px' }}>
                <ErrorHandler message={accountData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {userData.users ? (
              <Card>
                <BasicLine userData={userData} chartTitle="Users vs Time" />
              </Card>
            ) : (
              !userData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '390px',
                  }}
                />
              )
            )}
            {userData.error && (
              <Card style={{ maxHeight: '390px' }}>
                <ErrorHandler message={userData.error.message} />
              </Card>
            )}
            {accountData.accounts ? (
              <Card>
                <BasicLine
                  accountData={accountData}
                  chartTitle="Accounts vs Time"
                />
              </Card>
            ) : (
              !accountData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '390px',
                  }}
                />
              )
            )}
            {accountData.error && (
              <Card style={{ maxHeight: '390px' }}>
                <ErrorHandler message={accountData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingSummaryData.data ? (
              <Card>
                <GenericTable
                  data={filteredData}
                  headCells={trackingSummaryDataHeaders}
                  title="Usage by User"
                  exportCSV
                  CSVFileName={getUserTrackingCsvFileName(
                    userTrackingShowAllUsers
                  )}
                  enablePaging={false}
                  defaultOrder="desc"
                  defaultOrderBy="total"
                  filters={[
                    {
                      label: 'Show All Users',
                      checked: userTrackingShowAllUsers,
                      onChange: () =>
                        changeUserTrackingShowAllUsers(
                          !userTrackingShowAllUsers
                        ),
                    },
                  ]}
                />
              </Card>
            ) : (
              !trackingSummaryData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingSummaryData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingSummaryData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingSummaryByMonthData.data ? (
              <Card>
                <GenericTable
                  data={usersUsageByMonthFilteredData}
                  headCells={trackingSummaryByMonthDataHeaders}
                  title="Users Usage by Month"
                  exportCSV
                  CSVFileName={getUserTrackingCsvFileName(
                    userTrackingByMonthShowAllUsers,
                    true
                  )}
                  enablePaging={false}
                  defaultOrder="desc"
                  defaultOrderBy="total"
                  filters={[
                    {
                      label: 'Show All Users',
                      checked: userTrackingByMonthShowAllUsers,
                      onChange: () =>
                        changeUserTrackingByMonthShowAllUsers(
                          !userTrackingByMonthShowAllUsers
                        ),
                    },
                  ]}
                />
              </Card>
            ) : (
              !trackingSummaryByMonthData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingSummaryByMonthData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler
                  message={trackingSummaryByMonthData.error.message}
                />
              </Card>
            )}
          </div>
          <div className="row">
            {accountTrackingSummaryData.data ? (
              <Card>
                <GenericTable
                  data={accountTrackingSummaryData.data}
                  headCells={accountTrackingSummaryDataHeaders}
                  title="Usage by Account"
                  exportCSV={true}
                  CSVFileName={'Usage_by_Account.csv'}
                  enablePaging={false}
                  defaultOrder="desc"
                  defaultOrderBy="total"
                />
              </Card>
            ) : (
              !accountTrackingSummaryData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {accountTrackingSummaryData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler
                  message={accountTrackingSummaryData.error.message}
                />
              </Card>
            )}
          </div>
          <div className="row">
            {userData.users ? (
              <Card>
                <Table userData={userData} />
              </Card>
            ) : (
              !userData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '595px',
                  }}
                />
              )
            )}
            {userData.error && (
              <Card style={{ maxHeight: '595px' }}>
                <ErrorHandler message={userData.error.message} />
              </Card>
            )}
            {accountData.accounts ? (
              <Card>
                <Table accountData={accountData} />
              </Card>
            ) : (
              !accountData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '595px',
                  }}
                />
              )
            )}
            {accountData.error && (
              <Card style={{ maxHeight: '595px' }}>
                <ErrorHandler message={accountData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingData.events ? (
              <Card>
                <TrackingTableThreeCols
                  trackingData={trackingData}
                  accounts={accountData.accounts}
                  isPage={true}
                />
              </Card>
            ) : (
              !trackingData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingData.events ? (
              <Card>
                <TrackingTableThreeCols
                  trackingData={trackingData}
                  isItem={true}
                />
              </Card>
            ) : (
              !trackingData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingData.events ? (
              <Card>
                <TrackingTableThreeCols
                  trackingData={trackingData}
                  isEventName={true}
                />
              </Card>
            ) : (
              !trackingData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingData.error.message} />
              </Card>
            )}
          </div>
          <div className="row">
            {trackingPagesData.pages ? (
              <Card>
                <TrackingTableTwoCols
                  trackingData={trackingPagesData}
                  isPage={true}
                  accounts={accountData.accounts}
                />
              </Card>
            ) : (
              !trackingPagesData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingPagesData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingPagesData.error.message} />
              </Card>
            )}

            {trackingItemsData.items ? (
              <Card>
                <TrackingTableTwoCols
                  trackingData={trackingItemsData}
                  isItem={true}
                />
              </Card>
            ) : (
              !trackingItemsData.error && (
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    flex: '1 1',
                    width: '0',
                    borderRadius: '0.8rem',
                    minHeight: '563px',
                  }}
                />
              )
            )}
            {trackingItemsData.error && (
              <Card style={{ maxHeight: '563px' }}>
                <ErrorHandler message={trackingItemsData.error.message} />
              </Card>
            )}
          </div>
        </div>
      ) : (
        <div className="home-spinner"></div>
      )}
    </>
  );
};

export default Dashboard;
