import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Close,
  ContentCopy,
  Download,
  ExpandMore,
  Info,
} from '@mui/icons-material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CloudIcon from '@mui/icons-material/Cloud';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useNavigate, useSearchParams } from '../state/Navigation';
import { DataExtensionsQueryBuilder } from './DataExtensionsQueryBuilder';
import _ from 'lodash';
import RowData, { getFieldSizeNote } from './RowData';
import { Box } from '@mui/system';
import CustomPopover from '../components/ui/CustomPopover';
import ClipboardCopy from '../components/ui/ClipboardCopy';
import SFMC from '../lib/sfmc';
import BusinessUnitDetails from '../audits/BusinessUnitDetails';
import AppInsights from '../lib/appInsights';
import Util from '../lib/util';
import { format as sqlFormat } from 'sql-formatter';

import './Browse.scss';
import DataExtensionsBrowseSwitcher from './DataExtensionsBrowseSwitcher';
import { getBusinessUnits } from '../lib/auditsService';

const reduceProgress = (state, action) => {
  const newState = _.cloneDeep(state);
  newState.count = 0;
  for (const key in action) {
    switch (action[key]) {
      case true:
        newState[key] = true;
        newState.count++;
        break;
      case false:
        delete newState[key];
        // newState.count = --count;
        break;
      default:
        break;
    }
  }
  return newState;
};

export default function BrowseDataExtensions({ user }) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useMountedState();
  const [businessUnit, setBusinessUnit] = useState(null);
  const [dataExtensionSelectEvent, setDataExtensionSelectEvent] =
    useState(null);
  const [progress, dispatchProgress] = useReducer(reduceProgress, { count: 0 });
  const [dataExtensions, setDataExtensions] = useState(null);
  const [searchParams] = useSearchParams();
  const [selectedDataExtension, setSelectedDataExtension] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [currentName, setCurrentName] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});
  const [lastQuery, setLastQuery] = useState({});
  const [openDataExtensionDialog, setOpenDataExtensionDialog] =
    React.useState(false);
  const [accountsData, setAccountsData] = useState({});
  const [dataExtensionError, setDataExtensionError] = useState(false);

  const downloadRef = useRef();

  const handleClickOpenDataExtensionDialog = () => {
    setOpenDataExtensionDialog(true);
    AppInsights.trackEvent(user, {
      name: 'Click::Additional information',
      properties: {
        businessUnit: _.pick(businessUnit, 'mid'),
      },
    });
  };

  const handleCloseDataExtensionDialog = (string) => {
    setOpenDataExtensionDialog(false);
  };

  async function loadAccountsData() {
    try {
      const apiRes = await getBusinessUnits({});
      if (apiRes.accounts.businessUnits) {
        apiRes.accounts.businessUnits = _.flatMap(
          _.sortBy(_.groupBy(apiRes.accounts.businessUnits, 'mid'), (bu) => {
            return bu[0].active ? 0 : 1;
          }),
          (bus) => bus[0]
        );
        setAccountsData(apiRes);
      }
    } catch (error) {
      const errorRes = { error: error };
      setAccountsData(errorRes);
    }
  }

  /**
   * @param {string} input
   */
  const renderMatch = (input) => {
    if (input && currentName) {
      const lowercaseFilter = currentName.toLowerCase();
      const indices = _.toArray(
        String(input).matchAll(new RegExp(_.escapeRegExp(currentName), 'gi'))
      )
        .flatMap((m) => [m.index, m.index + currentName.length])
        .filter((index) => index > 0);
      indices.push(input.length);

      let lastIndex = 0;
      return React.Children.toArray(
        indices.map((index) => {
          const part = String(input).substring(lastIndex, index);
          lastIndex = index;
          return part.toLowerCase() === lowercaseFilter ? (
            <span className="search-match">{part}</span>
          ) : (
            <span className="search-unmatched">{part}</span>
          );
        })
      );
    }
    return input;
  };

  const onBusinessUnitChange = useCallback(
    (bu) => {
      if (isMounted()) {
        // console.log(`set bu ${new Date()}`);
        setBusinessUnit(bu);
      }
    },
    [isMounted]
  );

  useEffect(() => {
    loadAccountsData();
  }, []);

  useEffect(() => {
    const getDE = async () => {
      try {
        if (!dataExtensions) return;
        if (params.name) {
          const deExists = dataExtensions.find(
            (de) => de.Name.toLowerCase() === params.name.toLowerCase()
          );
          if (!deExists) {
            setDataExtensionError(true);
            return;
          } else {
            params.name = deExists.Name;
          }
        }

        dispatchProgress({ selectingDE: true });
        if (params.name) {
          const result = await fetch(
            `/api/sfmc/dataextension/${params.mid}/name/${params.name}?getFields=1&getFolderTree=1&properties=*`
          );
          if (result.status !== 200) {
            setSearchResults({
              results: null,
              error: result.status,
              dataExtension: null,
            });
            setSelectedDataExtension({ Name: params.name, invalid: true });
            setCurrentName(params.name);
          } else {
            const obj = await result.json();
            const de = obj?.items?.[0];
            setSelectedDataExtension(de);
            setCurrentName(de.Name);
            setDataExtensionError(false);
          }
        } else {
          setSelectedDataExtension(null);
          setCurrentName(null);
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          dataExtension: null,
        });
      } finally {
        dispatchProgress({ selectingDE: false });
      }
    };
    if (
      params.name?.toLowerCase() !== selectedDataExtension?.Name?.toLowerCase()
    ) {
      getDE();
    }
  }, [
    params.mid,
    params.name,
    selectedDataExtension,
    searchParams,
    dataExtensionSelectEvent,
    dataExtensions,
  ]);

  const setSelectedDataExtensionToLocalStorage = (dataExtension, action) => {
    const storageKey = `${params.mid}-selectedDataExtensions`;
    const selectedDataExtensions = JSON.parse(localStorage.getItem(storageKey));

    dataExtension.isStoredInLocalStorage = true;
    if (action === 'searched') dataExtension.isSearched = true;
    if (action === 'downloaded') dataExtension.isDownloaded = true;

    if (!selectedDataExtensions) {
      localStorage.setItem(storageKey, JSON.stringify([dataExtension]));
      return;
    }

    const selectedDe = selectedDataExtensions?.find(
      (de) => de.Name === dataExtension.Name
    );
    if (action === 'downloaded') {
      dataExtension.isSearched = selectedDe?.isSearched;
    }

    if (action === 'searched') {
      dataExtension.isDownloaded = selectedDe?.isDownloaded;
    }

    const selectedDeIndex = selectedDataExtensions.findIndex(
      (de) => de.Name === dataExtension.Name
    );

    const isAlreadySelected = selectedDeIndex >= 0;

    if (isAlreadySelected) {
      selectedDataExtensions.splice(selectedDeIndex, 1);
    }
    selectedDataExtensions.push(dataExtension);
    if (selectedDataExtensions.length > 5) {
      selectedDataExtensions.shift();
    }

    localStorage.setItem(storageKey, JSON.stringify(selectedDataExtensions));
  };

  const selectDataExtension = (dataExtension) => {
    if (dataExtension?.Name) {
      if (
        (params.name === dataExtension?.Name &&
          params.targetmid === dataExtension?.Client?.ID) ||
        selectedDataExtension?.invalid
      ) {
        setDataExtensionSelectEvent(new Date());
      }
      navigate({
        pathname: dataExtension.Name
          ? `/browse/${params.mid}/e/${dataExtension.Name}`
          : `/browse/${params.mid}/e`,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  useEffect(() => {
    const getDEs = async () => {
      try {
        dispatchProgress({ loadingDEs: true });
        const result = await fetch(
          `/api/sfmc/dataextension/${params.mid}?includeAllSubscribers=true&properties=ObjectId,CustomerKey,Name,CategoryID,Client.ID`
        );
        if (result.status !== 200) {
          setSearchResults({
            results: null,
            error: result.status,
            dataExtension: null,
          });
        } else {
          const des = (await result.json())?.items || [];
          setDataExtensions(des);
          if (des?.length) {
            cleanupHiddenDataExtensionFields(params.mid, des);
          }
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          dataExtension: null,
        });
      } finally {
        dispatchProgress({ loadingDEs: false });
      }
    };
    getDEs();
  }, [params.mid]);

  const deList = useMemo(() => {
    // const names = dataExtensions?.map((de) => de.Name) || [];
    let list = _.sortBy(
      dataExtensions,
      (dataExtension) => {
        const ix = dataExtension.Name?.toLowerCase().indexOf(
          currentName?.toLowerCase()
        );
        return ix >= 0 ? ix : 1e9;
      },
      (dataExtension) => dataExtension.Name?.toLowerCase(),
      (dataExtension) => {
        const ix = dataExtension.wsfmc?.folder?.path
          ?.toLowerCase()
          .indexOf(currentName?.toLowerCase());
        return ix >= 0 ? ix : 1e9;
      },
      (dataExtension) => {
        const ix = dataExtension.CustomerKey?.toLowerCase().indexOf(
          currentName?.toLowerCase()
        );
        return ix >= 0 ? ix : 1e9;
      },
      (dataExtension) => dataExtension.wsfmc?.folder?.path?.toLowerCase(),
      (dataExtension) => dataExtension.CustomerKey?.toLowerCase(),
      (dataExtension) => Number(dataExtension.Client?.ID) || 1e9
    );

    const storageKey = `${params.mid}-selectedDataExtensions`;
    const selectedDataExtensions = JSON.parse(localStorage.getItem(storageKey));

    if (selectedDataExtensions?.length) {
      const allDesByName = _.keyBy(dataExtensions, 'Name');
      // DE in storage may be out of date => filter + update with actual metadata
      const selectDesByName = _.keyBy(
        selectedDataExtensions
          .filter((de) => allDesByName[de.Name])
          .map((de) => {
            const corrected = _.cloneDeep(allDesByName[de.Name]);
            corrected.isDownloaded = de.isDownloaded;
            corrected.isSearched = de.isSearched;
            corrected.isStoredInLocalStorage = de.isStoredInLocalStorage;
            return corrected;
          }),
        'Name'
      );
      list = list.filter((item) => !selectDesByName[item.Name]);

      list.unshift(...Object.values(selectDesByName).reverse());
    }
    return list;
  }, [dataExtensions, currentName, params.mid]);

  const downloadOptions = useMemo(() => {
    if (!params.mid || !selectedDataExtension?.CustomerKey) {
      return;
    }

    // NOTE: we build the querystring this way to prevent the $ character from being encoded.
    let uri;
    const queryItems = [];

    if (/^_subscribers$/i.test(selectedDataExtension?.CustomerKey)) {
      uri = `/api/sfmc/subscribers/csv/${params.mid}`;
      if (currentQuery?.sfmcSql) {
        queryItems.push(
          `filter=${encodeURIComponent(JSON.stringify(currentQuery.query))}`
        );
      }
    } else {
      queryItems.push(`$page=${1}`);
      uri = `/api/sfmc/dataextension/csv/${params.mid}/key/${selectedDataExtension.CustomerKey}`;
      if (currentQuery?.sfmcSql) {
        queryItems.push(`$filter=${encodeURIComponent(currentQuery.sfmcSql)}`);
      }
    }

    return { uri, queryItems };
  }, [params.mid, selectedDataExtension, currentQuery]);

  const search = useCallback(
    async ({ searchQuery, isSearching, page, orderBy }) => {
      if (isSearching) {
        setLastQuery(searchQuery);
      }

      AppInsights.trackEvent(user, {
        name: 'Click::Search',
        properties: {
          businessUnit: _.pick(businessUnit, 'mid'),
        },
      });

      const fromPaging =
        (typeof page === 'number' && page) ||
        (typeof orderBy === 'string' && orderBy)
          ? true
          : false;
      try {
        if (fromPaging) {
          dispatchProgress({ paging: true });
        } else {
          dispatchProgress({ searching: true });
        }
        page = typeof page === 'number' ? page || 1 : 1;
        orderBy = orderBy && typeof orderBy === 'string' ? orderBy : '';

        if (/^_subscribers$/i.test(selectedDataExtension?.CustomerKey)) {
          const uri = `/api/sfmc/subscribers/json/${params.mid}?asDataExtension=true`;

          const searchUri = searchQuery.sfmcSql
            ? `${uri}&filter=${encodeURIComponent(
                JSON.stringify(searchQuery.query)
              )}`
            : uri;
          const response = await fetch(searchUri);
          const results = (await response.json()) || {};
          results.status = results.status || response.status;
          const error = results.status === 200 ? null : results.status;
          setSearchResults({
            results: results.status === 200 ? results : null,
            error,
            errorSearching:
              results.status === 401 ? results.message : results.status !== 200,
            dataExtension: selectedDataExtension,
            searchQuery,
            orderBy,
          });
        } else {
          const uri = `/api/sfmc/dataextension/json/${params.mid}/key/${selectedDataExtension.CustomerKey}`;

          // NOTE: we build the querystring this way to prevent the $ character from being encoded.
          const queryItems = [`?$page=${page}&$pageSize=50`];
          if (orderBy) {
            queryItems.push(`$orderBy=${encodeURIComponent(orderBy)}`);
          }
          if (searchQuery.sfmcSql) {
            queryItems.push(
              `$filter=${encodeURIComponent(searchQuery.sfmcSql)}`
            );
          }

          const searchUri = uri + queryItems.join('&');
          const response = await fetch(searchUri);
          const results = (await response.json()) || {};
          results.status = results.status || response.status;
          const error = results.status === 200 ? null : results.status;
          setSearchResults({
            results: results.status === 200 ? results : null,
            error,
            errorSearching:
              results.status === 401 ? results.message : results.status !== 200,
            dataExtension: selectedDataExtension,
            searchQuery,
            orderBy,
          });
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          errorSearching: true,
          dataExtension: selectedDataExtension,
        });
      } finally {
        if (fromPaging) {
          dispatchProgress({ paging: false });
        } else {
          dispatchProgress({ searching: false });
        }
      }
    },
    [params.mid, selectedDataExtension, user, businessUnit]
  );

  const selectionCssClasses = useMemo(() => {
    if (progress.count) {
      // console.log('busy', progress);
      return 'de-selection busy';
    } else if (
      !selectedDataExtension?.invalid &&
      selectedDataExtension?.Name &&
      selectedDataExtension?.Name?.toLowerCase() === currentName?.toLowerCase()
    ) {
      // console.log('selected', progress);
      return 'de-selection selected';
    } else if (
      (!selectedDataExtension?.invalid || currentName !== params.name) &&
      _.find(
        dataExtensions,
        (de) => de.Name?.toLowerCase() === currentName?.toLowerCase()
      )
    ) {
      return 'de-selection valid';
    } else if (
      selectedDataExtension?.Name &&
      selectedDataExtension?.Name?.toLowerCase() !== currentName?.toLowerCase()
    ) {
      return 'de-selection invalid';
    } else if (
      selectedDataExtension?.invalid ||
      (currentName && !selectedDataExtension?.Name)
    ) {
      return 'de-selection invalid';
    }
    return 'de-selection';
  }, [
    progress.count,
    selectedDataExtension,
    currentName,
    dataExtensions,
    params.name,
  ]);

  const filterOptions = createFilterOptions({
    stringify: (option) => {
      const folderPath = option.wsfmc?.folder?.path?.split('/')?.slice(1);
      return [option.Name, option.CustomerKey, ...(folderPath || [])]
        .join(' ')
        .trim();
    },
  });

  const backUrlFull = useMemo(
    () =>
      Util.resolveBackUrl({
        navigationState: location.state,
        fallback: document.referrer,
        fallbackHome: true,
      }),
    [location.state]
  );
  const backUrlParts = backUrlFull.split('/');
  const backUrl = backUrlParts[backUrlParts.length - 1]; // Or parts.pop();

  const activeBusinessUnits = useMemo(() => {
    return accountsData?.accounts?.businessUnits?.filter(
      (bu) => bu.active && bu.sfmcAccessGranted && bu.apiEnabled
    );
  }, [accountsData]);

  useEffect(() => {
    let hiddenFields = getHiddenDataExtensionFields(params.mid);
    if (hiddenFields[selectedDataExtension?.ObjectID]) {
      selectedDataExtension.Fields.forEach((f) => {
        f.wsfmc__hidden = hiddenFields[
          selectedDataExtension?.ObjectID
        ].includes(f.Name.toLowerCase());
      });
    }
    if (
      searchResults?.dataExtension?.ObjectID === selectedDataExtension?.ObjectID
    ) {
      setSearchResults({
        ...searchResults,
        dataExtension: { ...selectedDataExtension },
      });
    }
  }, [selectedDataExtension]);

  function showMissingColumnsWarning() {
    return (
      selectedDataExtension?.Name &&
      searchResults?.results?.items?.length &&
      searchResults?.dataExtension?.Name === selectedDataExtension.Name &&
      getHiddenDataExtensionFields(params.mid)[selectedDataExtension?.ObjectID]
        ?.length > 0
    );
  }

  return (
    <>
      <div className="audits browse">
        <Box
          className={'backButtonBox' + (!backUrlFull ? ' invalid' : '')}
          // style={collapseMode ? { left: '62px' } : {}}
        >
          <Link to={`/${backUrl}`}>
            <ArrowCircleLeftIcon />
            <span>BACK</span>
          </Link>
        </Box>
        <div className="audits-header">
          <h2 className="audits-title">
            <CloudIcon className="audits-title__icon" />
            <span>Browse data extensions</span>
          </h2>
        </div>
        <div className="data-extension">
          <Box sx={{ display: 'flex', width: '100%', gap: '1rem' }}>
            <BusinessUnitDetails
              mid={params?.mid}
              onLoad={onBusinessUnitChange}
              showSyncDate={false}
              user={user}
              showOnlyHeader={true}
            />
            {accountsData?.accounts?.businessUnits && (
              <DataExtensionsBrowseSwitcher
                mid={params.mid}
                availableAccounts={activeBusinessUnits}
                properties={accountsData?.accounts?.properties || {}}
              />
            )}
          </Box>

          {progress.loadingDEs && businessUnit ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <CircularProgress className="main-color" />
            </Box>
          ) : (
            ''
          )}
          {businessUnit?.id && dataExtensions ? (
            <>
              <Grid direction={'column'} spacing={1} container className="">
                <Grid item>
                  <Grid direction={'row'} container spacing={1}>
                    <Grid item>
                      <Autocomplete
                        onKeyDownCapture={(e) => {
                          if (['Home', 'End'].includes(e.key)) {
                            e.stopPropagation();
                          }
                        }}
                        // disablePortal
                        className={selectionCssClasses}
                        readOnly={progress.count > 0}
                        // freeSolo
                        openOnFocus={false}
                        onSelect={(event) => {
                          // console.log(event);
                          setCurrentName(event.target.value);
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option && value && option.Name === value?.Name;
                        }}
                        // filterSelectedOptions={true}
                        options={deList}
                        value={selectedDataExtension}
                        onChange={(event, option) => {
                          selectDataExtension(option);
                        }}
                        filterOptions={filterOptions}
                        getOptionLabel={(option) => {
                          return option.Name || '';
                        }}
                        renderOption={(props, option) => (
                          <AutocompleteOption
                            key={JSON.stringify({
                              n: option?.Name,
                              k: option?.CustomerKey,
                              i: option?.Client?.ID,
                            })}
                            renderMatch={renderMatch}
                            props={props}
                            option={option}
                          />
                        )}
                        renderInput={(inputParams) => (
                          <TextField
                            {...inputParams}
                            placeholder="Start typing or press arrow keys to view the full list"
                            label="Data extension"
                            onMouseDownCapture={(e) => e.stopPropagation()}
                            onClick={(event) => {
                              if (currentName !== '') {
                                if (
                                  (event.target &&
                                    event.target.dataset &&
                                    event.target.dataset.testid ===
                                      'CloseIcon') ||
                                  (event.target &&
                                    event.target.parentNode.dataset &&
                                    event.target.parentNode.dataset.testid ===
                                      'CloseIcon') ||
                                  (event.target &&
                                    event.target.firstElementChild &&
                                    event.target.firstElementChild.dataset
                                      .testid === 'CloseIcon')
                                ) {
                                  navigate(`/browse/${params.mid}/e`);
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {progress.selectingDE ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '1rem',
                        }}
                      >
                        <CircularProgress className="main-color" />
                      </Box>
                    ) : (
                      ''
                    )}
                    {!progress.selectingDE
                      ? !selectedDataExtension?.Name &&
                        dataExtensionError && (
                          <Alert
                            severity="error"
                            title="Data extension doesn't exist"
                            style={{ margin: '.5rem' }}
                          >
                            {params.name ? params.name : 'Data extension'}{' '}
                            doesn't exist on this Business Unit
                          </Alert>
                        )
                      : ''}
                    {selectedDataExtension?.CustomerKey &&
                    !/^_subscribers$/i.test(
                      selectedDataExtension?.CustomerKey
                    ) &&
                    !progress.loadingDEs &&
                    !progress.selectingDE ? (
                      <Grid className="dataextension-details" item>
                        <List>
                          <ListItem>
                            <span>
                              <strong>Customer key: </strong>
                              {selectedDataExtension?.CustomerKey || 'N/A'}
                            </span>
                            <IconButton
                              className="darkHover"
                              onClick={handleClickOpenDataExtensionDialog}
                              title="Additional information"
                            >
                              <Info />
                            </IconButton>
                            <DataExtensionDetails
                              businessUnit={businessUnit}
                              searchResults={searchResults}
                              setSearchResults={setSearchResults}
                              selectedDataExtension={selectedDataExtension}
                              sqlQuery={currentQuery?.sql}
                              open={openDataExtensionDialog}
                              onClose={handleCloseDataExtensionDialog}
                              user={user}
                              params={params}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText>
                              <strong>Path: </strong>
                              {selectedDataExtension?.wsfmc?.folder?.path ||
                                'N/A'}
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    ) : (
                      ''
                    )}

                    {/^_subscribers$/i.test(
                      selectedDataExtension?.CustomerKey
                    ) &&
                    !progress.loadingDEs &&
                    !progress.selectingDE ? (
                      <Grid className="subscribers-details" item maxWidth={600}>
                        <Alert
                          severity="info"
                          sx={{ padding: '1px 3px 1px 3px' }}
                        >
                          Different operators are available for retrieving
                          subscribers. Sorting and paging are not supported. To
                          retrieve more records, please use the Download button.
                        </Alert>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
                <Grid className="query-grid-item" item>
                  <fieldset>
                    <legend>Query</legend>
                    <DataExtensionsQueryBuilder
                      user={user}
                      dataExtension={selectedDataExtension}
                      dataExtensionLoading={progress.selectingDE}
                      onChange={(q) => {
                        setCurrentQuery(q);
                      }}
                    />
                  </fieldset>
                </Grid>

                <Grid className="query-grid-search" item>
                  <Grid direction={'row'} container spacing={1}>
                    <Grid item>
                      <Button
                        id="data-extension-search"
                        variant="contained"
                        color="primary"
                        size="small"
                        // endIcon={<SearchIcon />}
                        onClick={(options) => {
                          setSelectedDataExtensionToLocalStorage(
                            selectedDataExtension,
                            'searched'
                          );
                          search({
                            isSearching: true,
                            searchQuery: currentQuery,
                          });
                        }}
                        disabled={
                          progress.count > 0 ||
                          currentQuery.sfmcSql === '!' ||
                          selectedDataExtension?.invalid ||
                          !selectedDataExtension?.Name ||
                          selectedDataExtension?.Name !== currentName ||
                          !getVisibleDataExtensionFields(
                            params.mid,
                            selectedDataExtension
                          )?.length
                        }
                      >
                        Search
                      </Button>
                    </Grid>
                    {businessUnit?.enableDataDownload === true ? (
                      <Grid item>
                        <CustomPopover
                          ref={downloadRef}
                          buttonText={'Download'}
                          buttonSize="small"
                          buttonDisabled={
                            progress.count > 0 ||
                            currentQuery.sfmcSql === '!' ||
                            selectedDataExtension?.invalid ||
                            !selectedDataExtension?.Name ||
                            selectedDataExtension?.Name !== currentName ||
                            !getVisibleDataExtensionFields(
                              params.mid,
                              selectedDataExtension
                            )?.length
                          }
                          component={
                            <>
                              <DownloadPanel
                                searchResults={searchResults}
                                businessUnit={businessUnit}
                                user={user}
                                options={downloadOptions}
                                selectedDataExtension={selectedDataExtension}
                                setSelectedDataExtensionToLocalStorage={
                                  setSelectedDataExtensionToLocalStorage
                                }
                                onDismiss={() => downloadRef?.current?.close()}
                              />
                            </>
                          }
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                    <Grid item className="orderBy-warning-grid">
                      {searchResults?.orderBy && !progress.searching ? (
                        <Alert color="warning" className="orderBy-warning">
                          When using the sort feature, the API only returns the
                          top 100,000 rows matching the search criteria.
                        </Alert>
                      ) : showMissingColumnsWarning() && !progress.searching ? (
                        <Grid item>
                          <Alert color="warning" className="orderBy-warning">
                            Some columns are hidden.
                          </Alert>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {searchResults?.results?.count > 0 || progress.searching ? (
                  <Grid className="query-grid-results" item>
                    <RowData
                      user={user}
                      input={searchResults}
                      loading={progress.searching}
                      paging={progress.paging}
                      onChange={(options) => {
                        search({
                          ...options,
                          ...{ isSearching: false, searchQuery: lastQuery },
                        });
                      }}
                    />
                  </Grid>
                ) : (
                  ''
                )}

                {searchResults?.error &&
                (searchResults?.errorSearching ||
                  selectedDataExtension?.invalid) ? (
                  <Grid item>
                    <Alert severity="error">
                      {searchResults?.error === 401 ||
                      searchResults?.error === 403 ? (
                        <>
                          <ul>
                            <li>{'Insufficient permissions.'}</li>
                            <li>
                              {
                                'Some system data extensions, like _MobileAddress, simply cannot be retrieved.'
                              }
                            </li>
                          </ul>
                        </>
                      ) : (
                        <>
                          {
                            'An error occurred. If the problem persist, please contact support.'
                          }
                          {searchResults?.errorSearching ? (
                            <>
                              <ul>
                                <li>
                                  {
                                    'Some system data extensions, like _MobileAddress, cannot be retrieved.'
                                  }
                                </li>
                                <li>
                                  {
                                    'The API may also not be able to fetch results from very large data extensions.'
                                  }
                                </li>
                                <li>
                                  {' '}
                                  {`Tip: Try using the 'between' operator instead of <, >, =, !=. `}
                                </li>
                              </ul>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </Alert>
                  </Grid>
                ) : searchResults?.results?.count === 0 &&
                  selectedDataExtension?.Name ===
                    searchResults?.results?.customObjectName ? (
                  <Grid item>
                    <Alert severity="warning">
                      No records match the search criteria.
                    </Alert>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            </>
          ) : businessUnit?.id && searchResults?.error ? (
            <Alert severity="error">
              {searchResults?.error === 401 || searchResults?.error === 403
                ? 'Insufficient permissions.'
                : 'An error occurred. If the problem persist, please contact support.'}
            </Alert>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

function DownloadPanel({
  options,
  onDismiss,
  searchResults,
  user,
  businessUnit,
  selectedDataExtension,
  setSelectedDataExtensionToLocalStorage,
}) {
  const [nbItems, setNbItems] = useState(50);
  const [checked, setChecked] = useState(false);
  onDismiss = onDismiss || (() => undefined);
  const downloadUri = useMemo(() => {
    const queryItems = _.cloneDeep(options.queryItems || []);

    if (/^[/]api[/]sfmc[/]subscribers[/]csv[/]/.test(options.uri)) {
      queryItems.push(`batchSize=${checked ? 0 : nbItems}`);
    } else {
      queryItems.push(`$pageSize=${checked ? 0 : nbItems}`);
    }
    const selectedFields = getVisibleDataExtensionFields(
      businessUnit.mid,
      selectedDataExtension
    );
    if (selectedFields?.length) {
      queryItems.push(
        `$fields=${encodeURIComponent(selectedFields.join(','))}`
      );
    }
    if (searchResults.orderBy) {
      queryItems.push(`$orderBy=${encodeURIComponent(searchResults.orderBy)}`);
    }

    const uri = options.uri + '?' + queryItems.join('&');
    return uri;
  }, [nbItems, options, checked]);
  return (
    <Box className="browse download-panel">
      <Grid spacing={1} container direction={'column'}>
        <Grid item>
          <Grid container direction={'row'} spacing={1}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                    />
                  }
                  label="all rows"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <Typography
                component="div"
                style={{
                  lineHeight: '40px',
                  verticalAlign: 'middle',
                }}
              >
                or
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                disabled={checked}
                value={nbItems}
                onChange={(event) => {
                  setNbItems(event.target.value);
                }}
                InputProps={{
                  style: { width: '150px', height: '40px' },
                }}
                id="download-amount"
                label="Number of rows"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={downloadUri ? false : true}
                component="a"
                onClick={() => {
                  setSelectedDataExtensionToLocalStorage(
                    selectedDataExtension,
                    'downloaded'
                  );
                  AppInsights.trackEvent(user, {
                    name: 'Click::Download CSV',
                    properties: {
                      businessUnit: _.pick(businessUnit, 'mid'),
                    },
                    measurements: { nbRows: checked ? -1 : nbItems },
                  });
                }}
                href={downloadUri}
                target={'_blank'}
                style={{
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
                variant="contained"
                size="small"
                color="secondary"
              >
                Download CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Alert
            variant="outlined"
            color="warning"
            className="lookup-download-warning"
            style={{ maxWidth: '500px' }}
          >
            {getHiddenDataExtensionFields(businessUnit.mid)[
              selectedDataExtension?.ObjectID
            ]?.length ? (
              <p>
                <strong>Only the selected columns will be downloaded.</strong>
              </p>
            ) : (
              <></>
            )}
            {searchResults.orderBy ? (
              <p>
                Sorting also applies when downloading, which limits to 100,000
                the number of rows downloadable.
              </p>
            ) : (
              <></>
            )}
            {selectedDataExtension.Name !== '_Subscribers' ? (
              <p>
                Downloading starts at the first page and not the current page.
              </p>
            ) : (
              <></>
            )}
            <p>
              Downloading via the API is <strong>extremely</strong> slow and
              resource intensive. Please use the export feature in SFMC if you
              have more than a few thousand rows to export.
            </p>
            <p>
              If SFMC does not answer within the first 30 seconds, the download
              will fail.
            </p>
            <p>
              If the subsequent pages are not returned by SFMC within 55 seconds
              of each other, the dowload will fail.
            </p>
          </Alert>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <Button color="secondary" size="small" onClick={onDismiss}>
            Dismiss
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DataExtensionDetails({
  selectedDataExtension,
  searchResults,
  setSearchResults,
  sqlQuery,
  onClose,
  open,
  user,
  businessUnit,
  params,
}) {
  const [tabValue, setTabValue] = useState(0);
  const dialogRef = useRef();
  const [bestTabWidth, setBestTabWidth] = useState(null);
  const onCloseHandler = onClose;
  onClose = () => {
    onCloseHandler?.();
    setTabValue(0);
  };

  const tabs = [
    {
      name: 'Details',
      component: (
        <FieldsDefinition
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          selectedDataExtension={selectedDataExtension}
          params={params}
        />
      ),
    },
    {
      name: 'Helpers',
      component: (
        <DataExtensionHelpers
          selectedDataExtension={selectedDataExtension}
          user={user}
          businessUnit={businessUnit}
          sqlQuery={sqlQuery}
        />
      ),
    },
    {
      name: 'Queries',
      component: (
        <DataExtensionQueries
          selectedDataExtension={selectedDataExtension}
          user={user}
          businessUnit={businessUnit}
        />
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    // the default max-width is the computed min-content of the first tab
    // we do not allow the width to be recomputed if it leads to a reduction
    let tabWidth = dialogRef.current.offsetWidth;
    if (!bestTabWidth || bestTabWidth < tabWidth) {
      setBestTabWidth(dialogRef.current.offsetWidth);
    }
    setTabValue(newValue);
    if (bestTabWidth || tabWidth) {
      dialogRef.current.style.width = `${bestTabWidth || tabWidth}px`;
    }
    AppInsights.trackEvent(user, {
      name: `TabChange::Additional information::${tabs[newValue].name}`,
      properties: {
        businessUnit: _.pick(businessUnit, 'mid'),
      },
    });
  };
  return (
    <Dialog className="DataExtensionDetails" onClose={onClose} open={open}>
      <div ref={dialogRef} className="browse dataExtensionPopover">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Data extension details"
            >
              {React.Children.toArray(
                tabs.map((tab, index) => (
                  <Tab label={tab.name} {...a11yProps(index)} />
                ))
              )}
            </Tabs>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Box>
          {React.Children.toArray(
            tabs.map((tab, index) => (
              <TabPanel value={tabValue} index={index}>
                {tab.component}
              </TabPanel>
            ))
          )}
        </Box>
      </div>
    </Dialog>
  );
}

function DataExtensionQueries({ selectedDataExtension, user, businessUnit }) {
  const clipboard = useRef();
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getQueries = async () => {
      setLoading(true);
      try {
        const result = await fetch(
          `/api/sfmc/query/${businessUnit.mid}/target-name/${selectedDataExtension.Name}`
        );
        if (result.status === 200) {
          const json = await result.json();
          setQueries(json?.items || []);
        } else {
          setQueries([]);
        }
      } catch (e) {
        setQueries([]);
        console.error('Error loading queries');
      } finally {
        setLoading(false);
      }
    };
    getQueries();
  }, [selectedDataExtension?.Name, businessUnit?.mid]);

  return (
    <Box className="browse DataExtensionHelpers">
      <Typography component={'div'} sx={{ padding: '10px' }}>
        <strong>These are the queries that write to the data extension</strong>
      </Typography>

      {loading ? <LinearProgress /> : ''}
      {!loading && queries.length ? (
        <>
          {React.Children.toArray(
            _.sortBy(queries, 'name').map((query) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{query.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <QueryActivity
                    query={query}
                    clipboard={clipboard}
                    user={user}
                    businessUnit={businessUnit}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </>
      ) : !loading && !queries.length ? (
        <Typography component={'div'} sx={{ padding: '10px' }}>
          No queries found
        </Typography>
      ) : (
        ''
      )}
    </Box>
  );
}

function DataExtensionHelpers({
  selectedDataExtension,
  user,
  businessUnit,
  sqlQuery,
}) {
  const clipboard = useRef();

  return (
    <Box className="browse DataExtensionHelpers">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>SQL select statement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SqlSelectStatement
            selectedDataExtension={selectedDataExtension}
            clipboard={clipboard}
            user={user}
            businessUnit={businessUnit}
            sqlQuery={sqlQuery}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>XML template (legacy)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <XmlTemplate
            selectedDataExtension={selectedDataExtension}
            clipboard={clipboard}
            user={user}
            businessUnit={businessUnit}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function XmlTemplate({ clipboard, selectedDataExtension, user, businessUnit }) {
  const xmlTemplate = selectedDataExtension
    ? SFMC.getDataExtensionXmlTemplate(selectedDataExtension)
    : '';

  return (
    <div className="browse DataExtensionHelpers">
      <ClipboardCopy
        ref={clipboard}
        flashPopperClassName="xml-template-popper"
        flashPopperPlacement="left"
      />
      <pre className="de-select-statement">{xmlTemplate}</pre>
      <div className="de-select-statement-buttons">
        <IconButton
          onClick={() =>
            AppInsights.trackEvent(user, {
              name: 'Click::Download XML template',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            })
          }
          href={`data:text/plain,${encodeURIComponent(xmlTemplate)}`}
          download={`${selectedDataExtension?.Name}.xml`}
          size={'small'}
        >
          <Download className="darkHover" />
        </IconButton>
        <IconButton
          size={'small'}
          onClick={(evt) => {
            AppInsights.trackEvent(user, {
              name: 'Click::Copy XML template',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            });
            clipboard.current.copy(evt, String(xmlTemplate));
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <ContentCopy className="darkHover" />
        </IconButton>
      </div>
    </div>
  );
}

function QueryActivity({ clipboard, query, user, businessUnit }) {
  return (
    <div className="browse DataExtensionHelpers">
      <ClipboardCopy
        ref={clipboard}
        flashPopperClassName="select-sql-statement-popper"
        flashPopperPlacement="left"
      />
      <pre className="de-select-statement">{query.queryText}</pre>
      <div className="de-select-statement-buttons">
        <IconButton
          onClick={() =>
            AppInsights.trackEvent(user, {
              name: 'Click::Download Query SQL',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            })
          }
          href={`data:text/plain,${encodeURIComponent(query.queryText)}`}
          download={`${query.name}.sql`}
          size={'small'}
        >
          <Download className="darkHover" />
        </IconButton>
        <IconButton
          size={'small'}
          onClick={(evt) => {
            AppInsights.trackEvent(user, {
              name: 'Click::Copy Query SQL',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            });
            clipboard.current.copy(evt, String(query.queryText));
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <ContentCopy className="darkHover" />
        </IconButton>
      </div>
    </div>
  );
}

function SqlSelectStatement({
  clipboard,
  selectedDataExtension,
  user,
  businessUnit,
  sqlQuery,
}) {
  let sqlSelectStatement = selectedDataExtension
    ? SFMC.getSelectStatement(selectedDataExtension)
    : '';

  if (sqlQuery && typeof sqlQuery === 'string' && sqlQuery !== '!') {
    try {
      sqlSelectStatement = `${sqlSelectStatement}\nwhere ${sqlFormat(sqlQuery, {
        language: 'tsql',
        indent: '  ',
      })}`;
    } catch (e) {
      console.error(e.message);
    }
  }

  return (
    <div className="browse DataExtensionHelpers">
      <ClipboardCopy
        ref={clipboard}
        flashPopperClassName="select-sql-statement-popper"
        flashPopperPlacement="left"
      />
      <pre className="de-select-statement">{sqlSelectStatement}</pre>
      <div className="de-select-statement-buttons">
        <IconButton
          onClick={() =>
            AppInsights.trackEvent(user, {
              name: 'Click::Download SQL select statement',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            })
          }
          href={`data:text/plain,${encodeURIComponent(sqlSelectStatement)}`}
          download={`${selectedDataExtension?.Name}.sql`}
          size={'small'}
        >
          <Download className="darkHover" />
        </IconButton>
        <IconButton
          size={'small'}
          onClick={(evt) => {
            AppInsights.trackEvent(user, {
              name: 'Click::Copy SQL select statement',
              properties: {
                businessUnit: _.pick(businessUnit, 'mid'),
              },
            });
            clipboard.current.copy(evt, String(sqlSelectStatement));
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <ContentCopy className="darkHover" />
        </IconButton>
      </div>
    </div>
  );
}

function FieldsDefinition({
  selectedDataExtension,
  searchResults,
  setSearchResults,
  params,
}) {
  const [dataExtension] = useState(selectedDataExtension);
  const [checkAll, setCheckAll] = useState(false);

  const updateSelectedFields = () => {
    setSearchResults({
      ...searchResults,
      dataExtension:
        searchResults?.dataExtension?.ObjectID ===
        selectedDataExtension?.ObjectID
          ? { ...dataExtension }
          : searchResults?.dataExtension,
    });

    const currentHiddenDataExtensionFields = getHiddenDataExtensionFields(
      params.mid
    );
    setHiddenDataExtensionFields(params.mid, {
      ...currentHiddenDataExtensionFields,
      [dataExtension.ObjectID]: dataExtension.Fields.filter(
        (fld) => fld.wsfmc__hidden
      ).map((f) => f.Name.toLowerCase()),
    });
  };

  const handleChangeCheckAll = () => {
    setCheckAll(!checkAll);
    dataExtension.Fields.forEach((field) => {
      field.wsfmc__hidden = checkAll;
    });
    updateSelectedFields();
  };

  const handleChange = (name) => {
    let field = dataExtension.Fields.find((field) => field.Name === name);
    field.wsfmc__hidden = !field.wsfmc__hidden;

    if (
      dataExtension.Fields.filter((field) => !field.wsfmc__hidden).length !==
      dataExtension.Fields.length
    ) {
      setCheckAll(false);
    }

    updateSelectedFields();
  };

  return (
    <>
      <strong className="fieldTitle">Fields</strong>
      <table>
        <thead>
          <tr>
            <th>Visible</th>
            <th>Name</th>
            <th>Type</th>
            <th>Default value</th>
            <th>Required</th>
            <th>Primary key</th>
          </tr>
        </thead>
        <tbody>
          <>
            {selectedDataExtension?.Fields?.length ? (
              <tr className="row-select-all">
                <td colSpan={6}>
                  {/* <FormControl>
                  <FormControlLabel
                    control={ */}
                  <Checkbox
                    checked={checkAll}
                    onChange={() => handleChangeCheckAll()}
                    inputProps={{ 'aria-label': 'controlled' }}
                    id="visibleFieldsSelectAll"
                  />
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor="visibleFieldsSelectAll"
                  >
                    Select all
                  </label>
                  {/*      }
                     label="Select all"
                   />
                 </FormControl> */}
                </td>
              </tr>
            ) : (
              <></>
            )}
            {selectedDataExtension?.Fields?.length
              ? React.Children.toArray(
                  _.sortBy(
                    selectedDataExtension.Fields,
                    (f) => (f.IsPrimaryKey === 'true' ? 0 : 1),
                    (f) => f.Name.toLowerCase()
                  ).map((field) => (
                    <tr
                      className={
                        field.IsPrimaryKey === 'true'
                          ? 'pk'
                          : field.IsRequired === 'true'
                          ? 'required'
                          : 'std'
                      }
                    >
                      <td className="fieldName">
                        <Checkbox
                          checked={!field.wsfmc__hidden}
                          onChange={() => handleChange(field.Name)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </td>
                      <td className="fieldName">{field.Name}</td>
                      <td className="fieldType">
                        {field.FieldType}
                        {getFieldSizeNote(field, { padLeft: true })}
                      </td>
                      <td className="fieldDefault">{field.DefaultValue}</td>
                      <td className="fieldRequired">
                        {field.IsRequired === 'true' ? 'Yes' : 'No'}
                      </td>
                      <td className="fieldPK">
                        {field.IsPrimaryKey === 'true' ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  ))
                )
              : ''}
          </>
        </tbody>
      </table>
    </>
  );
}

function AutocompleteOption({ props, option, renderMatch }) {
  const pathParts = option.wsfmc?.folder?.path?.split('/');
  const pathPrefix = pathParts?.[0];
  pathParts?.shift();
  const pathSuffix =
    pathPrefix && pathParts?.length
      ? renderMatch('/' + pathParts?.join('/'))
      : '';
  return (
    <li {...props}>
      <Box className="autocomplete-option-item">
        {option.wsfmc?.folder?.path ? (
          <>
            <em
              style={{
                fontSize: '0.8rem',
                marginRight: '0.5rem',
              }}
            >
              {pathPrefix}
              {pathSuffix}
            </em>
          </>
        ) : (
          ''
        )}
        {option.isStoredInLocalStorage ? (
          <span
            className="autocomplete-option-item__history-icon"
            style={
              option.folder?.path
                ? {}
                : {
                    transform: 'translateY(-6px)',
                  }
            }
          >
            <HistoryIcon />
          </span>
        ) : (
          <></>
        )}

        <div
          style={
            option.folder?.path
              ? {}
              : {
                  transform: 'translateY(3px)',
                }
          }
        >
          {renderMatch(option.Name)}
        </div>
        <div>
          <span
            style={{
              fontSize: '0.8rem',
              marginRight: '0.5rem',
              color: '#aaa',
            }}
          >
            {renderMatch(option.CustomerKey)}
          </span>
        </div>
      </Box>
    </li>
  );
}

const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      // console.log("unmount");
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

function getHiddenDataExtensionFields(mid) {
  const HiddenDataExtensionFieldsStorageKey = `${mid}-hiddenDataExtensionFields`;
  return (
    JSON.parse(localStorage.getItem(HiddenDataExtensionFieldsStorageKey)) || {}
  );
}

function getVisibleDataExtensionFields(mid, dataExtension) {
  const hiddenFields =
    getHiddenDataExtensionFields(mid)?.[dataExtension?.ObjectID] || [];
  const selectedFields = _.differenceBy(
    _.sortBy(dataExtension?.Fields || [], (f) => Number(f.Ordinal))?.map(
      (f) => f.Name
    ) || [],
    hiddenFields,
    (f) => f?.toLowerCase()
  );
  return selectedFields;
}

function setHiddenDataExtensionFields(mid, newValue) {
  const deIDs = Object.keys(newValue);
  for (const objectId of deIDs) {
    if (!newValue[objectId]?.length) {
      delete newValue[objectId];
    }
  }
  const HiddenDataExtensionFieldsStorageKey = `${mid}-hiddenDataExtensionFields`;
  if (Object.keys(newValue).length) {
    localStorage.setItem(
      HiddenDataExtensionFieldsStorageKey,
      JSON.stringify(newValue)
    );
  } else {
    localStorage.removeItem(HiddenDataExtensionFieldsStorageKey);
  }
}

function cleanupHiddenDataExtensionFields(mid, dataExtensions) {
  // cleanup field selection
  // remove obsolete object ids
  if (!dataExtensions) {
    return;
  }
  const unselectedFields = getHiddenDataExtensionFields(mid);
  const deIDs = _.groupBy(dataExtensions, (de) => de.ObjectID);
  const obsoleteKeys = Object.keys(unselectedFields).filter((objectId) => {
    return !deIDs[objectId] || !unselectedFields[objectId]?.length;
  });
  obsoleteKeys.forEach((key) => {
    delete unselectedFields[key];
  });
  if (obsoleteKeys.length) {
    setHiddenDataExtensionFields(mid, unselectedFields);
  }
}
