import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Util from '../../../../lib/util';
import './style.scss';
import { NOTIFICATION_STATUSES } from '../../../../service/wsfmcNotificationsService/wsfmcNotificationsService';

export default function WsfmcNotification({
  notification,
  onNotificationClick,
  onDismissClick,
  ...props
}) {
  const [expanded, setExpanded] = useState(false);

  function handleNotificationClick() {
    setExpanded(!expanded);
    onNotificationClick(notification.id);
  }

  function handleDismissClick(event) {
    event.stopPropagation();
    onDismissClick(notification.id);
  }

  function isUnread() {
    return notification.status === NOTIFICATION_STATUSES.unread;
  }

  return (
    <Slide
      direction="left"
      in={notification.status !== NOTIFICATION_STATUSES.dismissed}
      unmountOnExit={true}
      appear={false}
    >
      <div
        className={`wsfmc-notification ${isUnread() ? 'unread' : ''} `}
        onClick={handleNotificationClick}
      >
        <div className="wsfmc-notification__header">
          <div className="wsfmc-notification__header-title">
            {notification.body?.title || 'Untitled'}
            {isUnread() ? (
              <div className="wsfmc-notification__unread-mark"></div>
            ) : (
              ''
            )}
          </div>
          <IconButton onClick={handleDismissClick} aria-label="dismiss">
            <CloseIcon />
          </IconButton>
        </div>
        <div
          className={`wsfmc-notification__body ${expanded ? 'expanded' : ''}`}
        >
          {notification.body.type === 'html' ? (
            <div
              dangerouslySetInnerHTML={{ __html: notification.body.message }}
            />
          ) : (
            <div>{notification.body.message}</div>
          )}
        </div>
        <div className="wsfmc-notification__date">
          <div>{Util.formatDateAsTimeSince(notification.createdDate)}</div>
        </div>
      </div>
    </Slide>
  );
}
