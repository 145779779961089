import React from 'react';
import './errorHandler.scss';

const ErrorHandler = ({ message }) => {
  return (
    <div className="error-container">
      <img src="/Warning-bro.svg" alt="" />
      <p>{message}</p>
    </div>
  );
};

export default ErrorHandler;
