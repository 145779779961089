import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export default function ConfirmDialog({
  closeOnBackdropClick,
  onCancel,
  onOk,
  open,
  dialogTitleComponents,
  dialogContentComponents,
  okText,
  okProps,
  cancelText,
  cancelProps,
  dialogProps,
  loading,
}) {
  const onCancelHandler = onCancel;
  onCancel = (evt) => {
    onCancelHandler?.();
  };

  const onOkHandler = onOk;
  onOk = async (evt) => {
    await onOkHandler?.();
  };

  okProps = {
    ...{ color: 'primary', variant: 'contained' },
    ...okProps,
  };

  cancelProps = {
    ...{ color: 'secondary', variant: 'outlined' },
    ...cancelProps,
  };

  return (
    <Dialog
      onClose={(evt, reason) => {
        closeOnBackdropClick === false && reason === 'backdropClick'
          ? evt.preventDefault()
          : onCancel();
      }}
      className="sfmc-admin update-package"
      open={open ? true : false}
      //   maxWidth={'md'}
      {...dialogProps}
    >
      {dialogTitleComponents ? (
        <DialogTitle>{dialogTitleComponents}</DialogTitle>
      ) : (
        ''
      )}
      <DialogContent>
        {dialogContentComponents ? <>{dialogContentComponents}</> : 'Proceed?'}
      </DialogContent>
      <DialogActions>
        {loading ? <CircularProgress sx={{ marginRight: '5px' }} /> : ''}
        <Button onClick={onCancel} {...cancelProps} color="error">
          {typeof cancelText === 'string' ? <>{cancelText} </> : 'Cancel'}
        </Button>
        <Button onClick={onOk} {...okProps}>
          {typeof okText === 'string' ? <>{okText}</> : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
