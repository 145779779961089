import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Business as BusinessIcon,
  ContentCopy,
  RocketLaunch,
  Warning as WarningIcon,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import * as auditService from '../lib/auditsService';
import LaunchWorkflowDialog from './LaunchWorkflowDialog';
import { parseISO as parseISODate } from 'date-fns';
import _ from 'lodash';
import ClipboardCopy from '../components/ui/ClipboardCopy';
import AppInsights from '../lib/appInsights';

import './BusinessUnitDetails.scss';
import './Audits.scss';

const formatDate = (input) => {
  return input
    ?.replace(/-/g, '/')
    ?.replace('T', ' ')
    ?.replace(/:\d{2}\.\d{3}Z$/, ' UTC');
};

/**
 * @callback OnBusinessUnitChange
 * @param {BusinessUnitItem} businessUnit the business unit details
 */

/**
 * @callback OnBusinessUnitError
 * @param {Error} error
 */

/**
 * @param {{mid, {timestamp: Date, value: string}, onLoad: OnBusinessUnitChange, onError: OnBusinessUnitError}}
 */
const BusinessUnitDetails = ({
  mid,
  user,
  onLoad,
  onError,
  className,
  showOnlyHeader,
  showParent = true,
}) => {
  mid = Number(mid);
  const isMounted = useMountedState();
  const [workflowDialogParams, setWorkflowDialogParams] = useState({
    open: false,
    item: null,
  });
  const [businessUnit, setBusinessUnit] = useState(null);
  const [properties, setProperties] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const clipboard = useRef();

  const handleClickOpenLaunchWorkflow = (event, item) => {
    setWorkflowDialogParams({ open: true, item });
    event.stopPropagation();
  };

  const handleCloseLaunchWorkflow = () => {
    setWorkflowDialogParams({ open: false, item: workflowDialogParams.item });
  };

  useEffect(() => {
    setLoading(true);
    auditService
      .getBusinessUnits()
      .then((res) => {
        if (isMounted) {
          switch (res.status) {
            case 200:
              const bu =
                res.accounts.businessUnits.find((bu) => bu.mid === mid) || null;
              const properties = res.accounts.properties[bu?.mid] || {};
              onLoad?.(bu);
              if (bu) {
                setBusinessUnit(bu);
                setProperties(properties);
                setErrorMessage(null);
              } else {
                setErrorMessage(
                  'Business unit not found or insufficient permission.'
                );
                onError?.(
                  new Error(
                    'Business unit not found or insufficient permission.'
                  )
                );
              }
              break;

            case 401:
              onError?.(new Error('Insufficient permissions'));
              setErrorMessage('Insufficient permissions');
              break;
            default:
              throw new Error(res.status);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        onError?.(new Error('Insufficient permissions'));
        if (isMounted()) {
          setErrorMessage(
            'An error occurred. If the problem persist, please contact support.'
          );
        }
      });
  }, [mid, onLoad, onError, isMounted]);

  const classeNames = ['business-unit-details'];
  if (classeNames && typeof className === 'string') {
    classeNames.push(className);
  }
  if (loading) {
    classeNames.push('loading');
  }

  const isSyncOverdue = (datestr) => {
    if (!datestr) {
      return true;
    }
    const parsed = parseISODate(datestr);
    const now = new Date();
    return now - parsed > 1000 * 3600 * 36;
  };

  const lastSyncOverdue = isSyncOverdue(
    properties.RepositoryLastSyncDate?.value
  );

  const repositoryLastSyncDate = properties.RepositoryLastSyncDate
    ? `${formatDate(properties.RepositoryLastSyncDate?.value)}`
    : 'Never determined';

  const repositoryName = properties.RepositoryName?.value || '';

  return (
    <>
      {' '}
      <ClipboardCopy ref={clipboard} flashPopperClassName={'clipboard'} />
      <LaunchWorkflowDialog
        openParams={workflowDialogParams}
        onClose={handleCloseLaunchWorkflow}
      />
      <Box
        className={showOnlyHeader ? '' : classeNames.join(' ')}
        sx={{ width: '100%' }}
      >
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
        {!errorMessage && businessUnit ? (
          <>
            <Grid
              direction={'row'}
              className={
                showOnlyHeader ? 'bu-list-item' : 'audits bu-list-item'
              }
              container
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                <ListItemAvatar>
                  <Avatar className="bu-avatar">
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Grid container>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3rem',
                      }}
                    >
                      <div className="bu-link">
                        <div className="bu-name">
                          {businessUnit.displayName || businessUnit.name}
                        </div>
                        <div className="bu-mid">
                          <span
                            className="bu-mid-text"
                            style={{
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                            }}
                          >
                            {businessUnit.mid}{' '}
                            <Tooltip title="Copy MID" placement="right">
                              <span>
                                <IconButton
                                  className="copy-bu-field"
                                  size={'small'}
                                  onClick={(evt) => {
                                    clipboard.current.copy(
                                      evt,
                                      String(businessUnit.mid)
                                    );
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                  }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                      {repositoryName && !showOnlyHeader ? (
                        <>
                          <div className="bu-repository">
                            <GitHubIcon />
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                              }}
                            >
                              <Tooltip title="View on Github">
                                <a
                                  href={`https://github.com/OSFDigital/${repositoryName}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(evt) => {
                                    evt.stopPropagation();
                                  }}
                                >
                                  {repositoryName}
                                </a>
                              </Tooltip>{' '}
                              <Tooltip
                                title="Copy repository name"
                                placement="right"
                              >
                                <span>
                                  <IconButton
                                    className="copy-bu-field"
                                    size={'small'}
                                    onClick={(evt) => {
                                      clipboard.current.copy(
                                        evt,
                                        String(repositoryName)
                                      );
                                      evt.stopPropagation();
                                      evt.preventDefault();
                                    }}
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </span>
                          </div>
                          <div
                            className={`bu-lastSyncDate${
                              lastSyncOverdue ? ' overdue' : ''
                            }`}
                          >
                            <span className="bu-lastSyncDate-text">
                              <CloudSyncIcon />
                              <span className="value">
                                {repositoryLastSyncDate}
                              </span>
                              <Tooltip
                                title="More information on synchronization"
                                placement="bottom"
                              >
                                <span>
                                  <IconButton
                                    onClick={(event) => {
                                      AppInsights.trackEvent(user, {
                                        name: 'Click::Trigger sync',
                                        properties: {
                                          businessUnit: _.pick(
                                            businessUnit,
                                            'mid'
                                          ),
                                        },
                                      });
                                      handleClickOpenLaunchWorkflow(event, {
                                        businessUnit,
                                        repositoryName,
                                      });
                                    }}
                                  >
                                    <RocketLaunch />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              {lastSyncOverdue ? (
                                <span className="overdue-warning">
                                  <Tooltip title="Overdue" placement="right">
                                    <WarningIcon />
                                  </Tooltip>
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>
                    {showParent &&
                    businessUnit.enterprise?.mid &&
                    businessUnit.enterprise?.name ? (
                      <Grid item xs={4}>
                        <div className="bu-parent">
                          <div className="bu-name">
                            {businessUnit.enterprise?.name}
                          </div>
                          <div className="bu-mid">
                            <span
                              className="bu-mid-text"
                              style={{
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                              }}
                            >
                              {businessUnit.enterprise?.mid}{' '}
                              <Tooltip title="Copy MID" placement="bottom-end">
                                <span>
                                  <IconButton
                                    className="copy-bu-field"
                                    size={'small'}
                                    onClick={(evt) => {
                                      clipboard.current.copy(
                                        evt,
                                        String(businessUnit.enterprise?.mid)
                                      );
                                      evt.stopPropagation();
                                      evt.preventDefault();
                                    }}
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </ListItemText>
              </Box>
            </Grid>
          </>
        ) : (
          ''
        )}
      </Box>
      {loading ? <LinearProgress className="main-color" /> : ''}
    </>
  );
};

const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      // console.log("unmount");
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};

export default BusinessUnitDetails;
