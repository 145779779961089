import Card from '../../components/ui/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import './wsfmcPropertiesAdmin.scss';

import {
  getWsfmcConfigurations,
  editWsfmcConfiguration,
  checkPermissions,
} from '../../service/adminApiService/adminApiService';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function WsfmcProperty({
  propertyKey,
  propertyDescription,
  propertyValue,
  onChange,
  onSubmit,
  loading,
}) {
  const [submitEnabled, setSubmitEnabled] = useState(false);

  return (
    <div className="wsfmc-properties-admin__property">
      <div className="wsfmc-properties-admin__property-label">
        {`${propertyKey}`}
      </div>
      <div className="wsfmc-properties-admin__property-description">
        <Tooltip title={propertyDescription}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      </div>
      <TextField
        defaultValue={propertyValue}
        size="small"
        style={{ marginRight: '10px' }}
        onChange={(e) => {
          setSubmitEnabled(true);
          onChange(propertyKey, e.target.value);
        }}
      />
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Button
          variant="contained"
          size="medium"
          onClick={(e) => {
            setSubmitEnabled(false);
            onSubmit(propertyKey);
          }}
          disabled={!submitEnabled}
        >
          Save
        </Button>
      )}
    </div>
  );
}

export default function WsfmcPropertiesAdmin({ user }) {
  const [properties, setProperties] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(true);
  const [loadingPropertyUpdate, setLoadingPropertyUpdate] = useState(false);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }

    async function getProperties() {
      setLoadingProperties(true);
      const response = await getWsfmcConfigurations();
      setProperties(response.properties);
      setLoadingProperties(false);
    }
    getProperties();
  }, []);

  function updatePropertyValue(propertyKey, propertyValue) {
    setError(null);
    const newProperties = { ...properties };
    newProperties[propertyKey].value = propertyValue;
    setProperties(newProperties);
  }

  async function savePropertyValue(propertyKey) {
    setError(null);
    setLoadingPropertyUpdate(true);
    const propertyValue = properties[propertyKey].value;
    try {
      await editWsfmcConfiguration(propertyKey, propertyValue);
    } catch (error) {
      setError(error.message);
    }
    setLoadingPropertyUpdate(false);
  }

  return (
    <Card style={{ width: '100%', marginTop: '20px', padding: '26px' }}>
      <Typography
        sx={{ flex: '1 1 100%' }}
        component="div"
        className="data-table__title"
      >
        <span>WSFMC Properties Admin</span>
      </Typography>
      <Box autoComplete="off" style={{ marginTop: '20px' }}>
        {error ? (
          <Alert severity="error" style={{ marginBottom: '20px' }}>
            {error}
          </Alert>
        ) : (
          ''
        )}
        {loadingProperties || !properties ? (
          <div>Loading...</div>
        ) : (
          Object.keys(properties).map((pKey) => {
            return (
              <WsfmcProperty
                key={pKey}
                propertyKey={pKey}
                propertyValue={properties[pKey].value}
                propertyDescription={properties[pKey].description}
                onChange={updatePropertyValue}
                onSubmit={savePropertyValue}
                loading={loadingPropertyUpdate}
              />
            );
          })
        )}
      </Box>
    </Card>
  );
}
