import { useEffect, useState, useContext, useRef, useMemo } from 'react';
import {
  getUserNotifications,
  updateUserNotificationStatus,
  getUserUnreadState,
  updateUserUnreadStatus,
  NOTIFICATION_STATUSES,
} from '../../../../service/wsfmcNotificationsService/wsfmcNotificationsService';

import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grow from '@mui/material/Grow';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { DarkModeContext } from '../../../../context/darkModeContext';
import WsfmcNotification from '../WsfmcNotification/WsfmcNotification';
import './style.css';
import { ClickAwayListener, Snackbar, SnackbarContent } from '@mui/material';
import Util from '../../../../lib/util';

const NOTIFICATION_PAGE_SIZE = 20;
const NotificationsPollingFrequency =
  Number(Util.getEnvVariable('REACT_APP_NOTIFICATIONS_POLLING_FREQUENCY')) *
    1000 || 10 * 60 * 1000; // defaults to 10 minutes

export default function WsfmcNotifications({ user, ...props }) {
  const [notifications, setNotifications] = useState([]);
  const [hasUnseen, setHasUnseen] = useState(null);
  const [notSignedIn, setNotSignedIn] = useState(null);
  const [showPanel, setShowPanel] = useState(false);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notificationsLeft, setNotificationsLeft] = useState(null);
  const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] =
    useState(null);

  const [openDismissError, setOpenDismissError] = useState(false);

  const skipNextNotificationPoll = useRef(false);
  const notificationsChannel = useRef(new BroadcastChannel('notifications'));
  const isFirstStatePoll = useRef(true);

  useEffect(function setBrodacastChannelCallback() {
    notificationsChannel.current.onmessage = (event) => {
      if (window.wsfmc.tabId === event?.data?.tabId) {
        return;
      }
      // Handle the received data
      skipNextNotificationPoll.current = true;

      if (
        !event.data.pollStart &&
        /[?&]debug[=]1(&|$)/.test(window.location.search)
      ) {
        console.log([
          new Date().toISOString(),
          'pollId: ' + event.data.pollId,
          'from tab ' + event.data.tabId + ' to tab ' + window.wsfmc.tabId,
        ]);
      }

      if (event.data.pollStart) return;

      setNotSignedIn(event.data.unreadState.message === 'Not signed in');
      setHasUnseen(event.data.unreadState.hasUnseen || false);
    };
  });

  const triggerDismissErrorOpen = () => {
    setOpenDismissError(true);
  };

  const handleDismissErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenDismissError(false);
  };

  const pollInterval = useRef();
  const isNotificationsEmpty = useMemo(
    () =>
      !notifications.filter((n) => n.status !== NOTIFICATION_STATUSES.dismissed)
        .length,
    [notifications]
  );

  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    setupNewNotificationsPoll();

    async function setupNewNotificationsPoll() {
      if (!user?.username) return;

      async function getUserUnreadNotificationsState() {
        if (skipNextNotificationPoll.current && !isFirstStatePoll.current) {
          skipNextNotificationPoll.current = false;
          return;
        }
        const pollId = Util.generateRandomHexString();

        if (!isFirstStatePoll.current) {
          notificationsChannel.current.postMessage({
            pollStart: true,
            pollId,
            tabId: window.wsfmc.tabId,
          });
        }

        // If the other tab hasn't sent any data yet, get the data from the server
        const unreadState = await getUserUnreadState(user.username);

        setNotSignedIn(unreadState.message === 'Not signed in');
        setHasUnseen(unreadState.hasUnseen || false);

        if (!isFirstStatePoll.current) {
          notificationsChannel.current.postMessage({
            unreadState,
            pollId,
            tabId: window.wsfmc.tabId,
          });
        } else {
          isFirstStatePoll.current = false;
        }
      }

      getUserUnreadNotificationsState();

      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }

      const interval = setInterval(() => {
        try {
          getUserUnreadNotificationsState();
        } catch {}
      }, NotificationsPollingFrequency);
      pollInterval.current = interval;
    }
  }, [user]);

  async function handleBellClick(event) {
    setShowPanel(!showPanel);

    if (showPanel) {
      handleClickAway();
      return;
    }

    setLoadingNotifications(true);
    try {
      const apiRes = await getUserNotifications({
        username: user.username,
        limit: NOTIFICATION_PAGE_SIZE,
        statuses: [NOTIFICATION_STATUSES.unread, NOTIFICATION_STATUSES.read],
      });
      setNotifications(apiRes.notifications);
      setNotificationsLeft(apiRes.count - NOTIFICATION_PAGE_SIZE);

      await updateUserUnreadStatus(user.username, false);
      setHasUnseen(false);

      setLoadingNotifications(false);
    } catch (e) {
      triggerDismissErrorOpen();
      handleClickAway();
      return;
    }
  }

  async function loadMoreNotifications() {
    if (notificationsLeft <= 0) return;

    setLoadingNotifications(true);

    if (!notifications.length) return;

    let dateTo = notifications[notifications.length - 1].createdDate;

    const apiRes = await getUserNotifications({
      username: user.username,
      limit: NOTIFICATION_PAGE_SIZE + 1,
      statuses: [NOTIFICATION_STATUSES.unread, NOTIFICATION_STATUSES.read],
      dateTo,
    });

    apiRes.notifications.shift();

    setNotificationsLeft(apiRes.count - NOTIFICATION_PAGE_SIZE);
    setNotifications([...notifications, ...apiRes.notifications]);
    setLoadingNotifications(false);
  }

  function handleScroll(event) {
    if (loadingNotifications) {
      return;
    }
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollHeight - scrollTop - clientHeight < 100) {
      loadMoreNotifications();
    }
  }

  function resetPopoverData() {
    setNotifications([]);
    setNotificationsLeft(null);
  }

  function handleClickAway() {
    setShowPanel(false);
    resetPopoverData();
  }

  function doHideNotificationDot() {
    return !hasUnseen;
  }

  async function onNotificationClick(notificationId) {
    try {
      const clickedNotification = notifications.findIndex(
        (notification) => notification.id === notificationId
      );

      if (
        clickedNotification === -1 ||
        notifications[clickedNotification].status === NOTIFICATION_STATUSES.read
      ) {
        return;
      }

      await updateUserNotificationStatus(
        notificationId,
        user.username,
        NOTIFICATION_STATUSES.read
      );

      notifications[clickedNotification].status = NOTIFICATION_STATUSES.read;
      setNotifications([...notifications]);
    } catch (error) {
      triggerDismissErrorOpen();
    }
  }

  async function onNotificationDismissClick(notificationId) {
    try {
      const clickedNotification = notifications.findIndex(
        (notification) => notification.id === notificationId
      );

      if (clickedNotification === -1) {
        return;
      }

      await updateUserNotificationStatus(
        notificationId,
        user.username,
        NOTIFICATION_STATUSES.dismissed
      );

      notifications[clickedNotification].status =
        NOTIFICATION_STATUSES.dismissed;
      setNotifications([...notifications]);
    } catch (error) {
      triggerDismissErrorOpen();
    }
  }

  async function massUpdateStatus(status, fromStatus) {
    try {
      setHasUnseen(false);

      setNotifications(
        notifications.map((n) => {
          return {
            ...n,
            status: n.status === fromStatus || !fromStatus ? status : n.status,
          };
        })
      );

      await updateUserNotificationStatus(
        undefined,
        user.username,
        status,
        fromStatus
      );

      if (status === NOTIFICATION_STATUSES.dismissed) setNotifications([]);
    } catch (error) {
      triggerDismissErrorOpen();
    }
  }

  async function onReadAllClick() {
    await massUpdateStatus(
      NOTIFICATION_STATUSES.read,
      NOTIFICATION_STATUSES.unread
    );
  }

  async function onDismissAllClick() {
    await massUpdateStatus(NOTIFICATION_STATUSES.dismissed);
  }

  function handleCloseOptionsMenu() {
    setOptionsMenuAnchorElement(null);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="wsfmc-notifications">
        <Snackbar
          disableWindowBlurListener={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openDismissError || notSignedIn}
          autoHideDuration={3000} // set the duration in milliseconds
          onClose={handleDismissErrorClose}
        >
          <SnackbarContent
            className="wsfmc-notifications__polling-error"
            message={
              notSignedIn ? (
                <span>
                  Please{' '}
                  <a className="login" href="/auth/login">
                    sign in
                  </a>
                </span>
              ) : (
                'An error occurred.'
              )
            }
          />
        </Snackbar>
        <Badge
          color="error"
          variant="dot"
          invisible={doHideNotificationDot()}
          onClick={handleBellClick}
          style={{ cursor: 'pointer' }}
          className="navbar-right__item"
        >
          <NotificationsIcon
            className={darkMode ? 'light-mode-icon' : 'dark-mode-icon'}
          />
        </Badge>
        <Box sx={{ position: 'relative' }}>
          <Grow in={showPanel}>
            <div className="wsfmc-notifications__popover">
              <div className="wsfmc-notifications__popover-header">
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: '.1rem' }}
                >
                  <NotificationsIcon className="icon" />
                  <div>Notifications</div>
                </Box>
                <div className="wsfmc-notifications__popover-header__refresh-btn">
                  <IconButton
                    aria-label="options"
                    onClick={(e) =>
                      setOptionsMenuAnchorElement(e.currentTarget)
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    anchorEl={optionsMenuAnchorElement}
                    open={Boolean(optionsMenuAnchorElement)}
                    onClose={handleCloseOptionsMenu}
                    style={{ zIndex: 5000 }}
                  >
                    <MenuItem
                      style={{ fontSize: '12px' }}
                      onClick={onReadAllClick}
                    >
                      Read All
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: '12px' }}
                      onClick={onDismissAllClick}
                    >
                      Dismiss All
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div
                className="wsfmc-notifications__popover-content"
                onScroll={handleScroll}
              >
                <>
                  {notifications.map((notification, idx) => (
                    <WsfmcNotification
                      notification={notification}
                      key={notification.id}
                      onNotificationClick={onNotificationClick}
                      onDismissClick={onNotificationDismissClick}
                    />
                  ))}
                  {!loadingNotifications &&
                  isNotificationsEmpty &&
                  showPanel ? (
                    <div className="wsfmc-notifications__popover-content-empty">
                      You don't have any new notifications
                    </div>
                  ) : (
                    ''
                  )}
                  {loadingNotifications ? (
                    <div className="wsfmc-notifications__popover-content-loading">
                      <CircularProgress />
                    </div>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>
          </Grow>
        </Box>
      </div>
    </ClickAwayListener>
  );
}
