/** @type {import('d3')} */
const d3 = window.d3;

export default function renderGraphvizGraph(
  graphElementRef,
  graphvizString,
  { onNodeClick = undefined, setNodeClass = undefined } = {}
) {
  // NOTE: using percentage here produces an error in the d3 library, visible in the console.
  /** @type {import('d3-graphviz').GraphvizOptions} */
  const graphvizOptions = {
    width: window.screen.width,
    height: window.screen.height,
    fit: false,
    scale: 1,
  };

  d3.select(graphElementRef).selectAll('*').remove();
  /** @type {import('d3-graphviz').Graphviz} */
  const graphviz = d3
    .select(graphElementRef)
    .append('div')
    .attr('class', 'graphviz-parent')
    .graphviz(graphvizOptions);

  graphviz.renderDot(graphvizString).on('end', function () {
    d3.select(graphElementRef)
      .select('svg')
      .selectAll('g.node')
      .on('click', (event, source) => {
        onNodeClick && onNodeClick(event, source);
      })
      .selectAll(`g.node g a path,g.node g a polygon`)
      .attr('class', (node) => {
        return setNodeClass(node);
      });
  });
}
