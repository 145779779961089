import {
  Box,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  LinearProgress,
  Alert,
} from '@mui/material';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import React, { useEffect, useState } from 'react';
import { checkPermissions } from '../../service/adminApiService/adminApiService';
import { Link, useNavigate } from 'react-router-dom';

export default function DDSAdminAddAccountPage({ user }) {
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState('');
  const [domain, setDomain] = useState('');
  const [enabled, setEnabled] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  function validateClientName() {
    return /^[\w -]+$/.test(clientName?.trim());
  }

  function validateClientId() {
    return /^[\w-]+$/.test(clientId?.trim());
  }

  function validateDomain() {
    return /^[\w-]+$/.test(domain?.trim());
  }

  function validate() {
    return validateClientId() && validateClientName() && validateDomain();
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setError('');

    if (!validate()) return;

    try {
      setLoading(true);
      const body = JSON.stringify({
        clientId: clientId.trim(),
        clientName: clientName.trim(),
        domain: domain.trim(),
        enabled,
      });

      const response = await fetch('/api/admin/dds-accounts', {
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        navigate(`/dds-admin`);
      } else {
        try {
          const json = await response.json();
          console.log(json);
          let message;
          if (json.message) {
            message = json.message;
          } else if (json.error) {
            message = json.error;
          } else if (json.errors) {
            message = json.errors[0].message || json.errors[0].msg;
          } else {
            message = 'Unable to process the request.';
          }

          setError(message);
        } catch {
          setError('Unable to process the request.');
        }
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }
  }, []);

  const inputsSx = {
    width: '600px',
    mb: '25px',
  };

  return (
    <div className="new-sfmc-account">
      <Box className={'backButtonBox'}>
        <Link to={`/dds-admin`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <h2>New DDS Account</h2>
      <div>
        <br />
        <FormControl
          disabled={loading}
          onSubmit={handleSubmit}
          component="form"
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-required"
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={inputsSx}
            error={
              !validateClientName() && (clientName || submitted) ? true : false
            }
            required
            label="Client Name"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={inputsSx}
            error={
              !validateClientId() && (clientId || submitted) ? true : false
            }
            required
            id="outlined-required"
            label="Client Id"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          />
          <TextField
            disabled={loading}
            autoComplete="false"
            autoCorrect="false"
            sx={inputsSx}
            error={!validateDomain() && (domain || submitted) ? true : false}
            required
            id="outlined-required"
            label="Domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                disabled={loading}
                onChange={(e) => setEnabled(e.target.checked)}
              />
            }
            label="Enabled"
          />
          <Button
            sx={{ marginTop: '25px' }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            Submit
          </Button>
        </FormControl>
      </div>
      {loading ? (
        <>
          <br />
          <LinearProgress sx={{ width: '600px' }} />
        </>
      ) : error ? (
        <>
          <br />
          <div>
            <Alert color="error" sx={{ width: '600px' }}>
              {error}
            </Alert>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
