import './accountAdmin.scss';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccountDatatable from '../../components/datatable/AccountDatatable';
import ErrorHandler from '../../components/ui/ErrorHandler';
import Card from '../../components/ui/Card';
import { checkPermissions } from '../../service/adminApiService/adminApiService';

export default function AccountAdmin({ user }) {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(true);
  const [trigger, setTrigger] = useState(null);
  const [data, setData] = useState(null);

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }
    const getAccounts = async () => {
      try {
        setFetching(true);
        const response = await fetch('/api/wsfmc/account');
        if (response.ok) {
          const accountData = await response.json();
          setData(accountData);
        } else {
          setData(null);
        }
      } catch (e) {
        setData(null);
        console.error('Error fetching accounts');
      } finally {
        setFetching(false);
      }
    };
    getAccounts().catch(console.error);
  }, [trigger]);

  return (
    <>
      {fetching ? (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={'100%'}
          style={{
            flex: '1 1',
            width: '100%',
            borderRadius: '0.8rem',
            minHeight: '641px',
          }}
        />
      ) : (
        <>
          {data?.businessUnits ? (
            <AccountDatatable accounts={data} setTrigger={setTrigger} />
          ) : (
            <Card style={{ height: '641px', width: '100%' }}>
              <ErrorHandler
                message={
                  'An error has occurred while trying to get the accounts data.'
                }
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}
