import React, { useContext } from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import './navbar.scss';
import { DarkModeContext } from '../../context/darkModeContext';
import { Box, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DomainIcon from '@mui/icons-material/Domain';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CodeIcon from '@mui/icons-material/Code';
import { Link } from 'react-router-dom';
import { AccountTree, Favorite, LocalFlorist } from '@mui/icons-material';
import TutorialHelper from '../helpers/TutorialHelper';
import WsfmcNotifications from './components/WsfmcNotifications/WsfmcNotifications';
import { checkPermissions } from '../../service/adminApiService/adminApiService';

const Navbar = ({ user, settings }) => {
  const { darkMode } = useContext(DarkModeContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const showDashboards = () => {
    return checkPermissions(user, ['admin', 'dashboards']);
  };

  const showAdmin = () => {
    return checkPermissions(user, ['admin']);
  };

  const showSfmcAdmin = () => {
    return checkPermissions(user, ['admin']);
  };

  const showAmpscriptEditor = () => {
    return checkPermissions(user, ['admin', 'ampscriptEditor']);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = (event) => {
    window.location.href = '/auth/login';
  };

  const handleLogout = (event) => {
    window.location.href = '/auth/logout';
  };

  const handleTutorials = () => {
    TutorialHelper(getTutorial() || 'None');
  };

  const getTutorial = () => {
    const location = window.location.pathname;

    if (location === '/') {
      // Home
      return 'Home';
      // DE-Usage
    } else if (location.startsWith('/audits/de-usage')) {
      // Teams
      return 'DE-Usage';
    } else if (location.startsWith('/audits/de-dependency')) {
      // DE-Dependency
      return 'DE-Dependency';
    } else if (location.startsWith('/browse')) {
      // Browse-Data-Extensions
      return 'Browse-Data-Extensions';
    }
  };

  return (
    <>
      <div className="navbar">
        <div className="wrapper">
          <div className="navbar-logo">
            <Link to="/" className="logo">
              <img
                src={darkMode ? `/logo_workV2-dark.svg` : `/logo_workV2.1.svg`}
                alt=""
              />
            </Link>
          </div>
          <div className="navbar-center">
            <ul>
              {/* <p className="title">main</p> */}
              <li>
                <Tooltip title="Workbench">
                  <Link to="/" className="logo" id="workbench-logo">
                    <DomainIcon className="sidebar-center__icon" />
                    {/* {<span>Workbench</span>} */}
                  </Link>
                </Tooltip>
              </li>
              {showDashboards() ? (
                <li>
                  <Tooltip title="Dashboard">
                    <Link to="/dashboard" className="logo" id="dashboard-logo">
                      <DashboardIcon className="sidebar-center__icon" />
                      {/* {<span>Dashboard</span>} */}
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
              {showAdmin() ? (
                <li>
                  <Tooltip title="Admin">
                    <Link to="/admin" className="logo">
                      <AdminPanelSettingsIcon className="sidebar-center__icon" />
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
              {showAdmin() ? (
                <li>
                  <Tooltip title="WSFMC Properties">
                    <Link to="/admin-wsfmc-properties" className="logo">
                      <ConstructionIcon className="sidebar-center__icon" />
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
              {showSfmcAdmin() ? (
                <li>
                  <Tooltip title="SFMC Accounts">
                    <Link to="/admin/sfmc/account" className="logo">
                      <AccountTree className="sidebar-center__icon" />
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
              {showAmpscriptEditor() ? (
                <li>
                  <Tooltip title="Ampscript Editor">
                    <Link to="/ampscript-editor" className="logo">
                      <CodeIcon className="sidebar-center__icon" />
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
              {showAdmin() ? (
                <li>
                  <Tooltip title="DDS Admin">
                    <Link to="/dds-admin" className="logo">
                      <img
                        width="32"
                        src="/images/dds-simple-logo-20231020.svg"
                        alt="Data Discovery Studio setup"
                        className="sidebar-center__icon dds"
                        onMouseOver={(evt) =>
                          (evt.target.src =
                            '/images/dds-simple-logo-hover-20231020.svg')
                        }
                        onMouseOut={(evt) =>
                          (evt.target.src =
                            '/images/dds-simple-logo-20231020.svg')
                        }
                      />
                    </Link>
                  </Tooltip>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <div className="navbar-right">
            {getTutorial() ? (
              <div className="onboarding navbar-right__item">
                <Tooltip title="Tutorials">
                  <span>
                    <TipsAndUpdatesIcon
                      onClick={handleTutorials}
                      className={
                        darkMode ? 'light-mode-icon' : 'dark-mode-icon'
                      }
                    />
                  </span>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            {/* <div className="mode-switch navbar-right__item">
              <Tooltip
                title={darkMode ? 'Light Mode' : 'Dark Mode (Experimental)'}
              >
                {darkMode ? (
                  <LightModeIcon
                    className="light-mode-icon"
                    onClick={() => dispatch({ type: 'TOGGLE' })}
                  ></LightModeIcon>
                ) : (
                  <DarkModeIcon
                    className="dark-mode-icon"
                    onClick={() => dispatch({ type: 'TOGGLE' })}
                  ></DarkModeIcon>
                )}
              </Tooltip>
            </div> */}
            <WsfmcNotifications user={user} />
            <div className="item navbar-right__item">
              {user?.username && (
                <img src="/osf-logo.png" alt="" className="avatar" />
              )}

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={user?.username ? 'Options' : 'Login'}>
                  <MenuItem
                    className="user-email"
                    onClick={user?.username ? handleOpenUserMenu : handleLogin}
                    sx={{ p: 0 }}
                  >
                    {user?.roles?.flags?.includes?.('vip') ? (
                      <LocalFlorist className="vip" />
                    ) : (
                      ''
                    )}
                    {user?.username || 'Login'}
                    {user?.roles?.flags?.includes('vip') ? (
                      <Favorite className="vip" />
                    ) : (
                      ''
                    )}
                  </MenuItem>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar2"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={handleLogout}
                      title={setting}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
