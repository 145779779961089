import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';

import React, { useEffect, useState } from 'react';
import ErrorHandler from '../../components/ui/ErrorHandler';
import Card from '../../components/ui/Card';
import { checkPermissions } from '../../service/adminApiService/adminApiService';
import { useNavigate } from 'react-router-dom';
import DDSAdminToolbar from './components/DDSAdminToolbar';
import DatatableHead from '../../components/datatable/DatatableHead';

import Util from '../../lib/util';

const ddsAccountHeadCells = [
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  { id: 'domain', numeric: false, disablePadding: false, label: 'Domain' },
  { id: 'clientId', numeric: false, disablePadding: false, label: 'Client ID' },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    align: 'right',
    label: 'Enabled',
  },
  { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'updated', numeric: false, disablePadding: false, label: 'Updated' },
];

export default function DDSAdminPage({ user }) {
  const [fetching, setFetching] = useState(true);
  const [togglingEnabledClientId, setTogglingEnabledClientId] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(null);
  const [ddsAccounts, setDdsAccounts] = useState(null);

  let navigate = useNavigate();

  const isAdmin = () => {
    return checkPermissions(user, 'admin');
  };

  useEffect(() => {
    if (!isAdmin()) {
      return navigate('/');
    }

    const getAccounts = async () => {
      try {
        setFetching(true);
        const response = await fetch('/api/admin/dds-accounts');
        if (response.ok) {
          const accountsData = await response.json();
          setDdsAccounts(accountsData.accounts);
        } else {
          setDdsAccounts(null);
        }
      } catch (e) {
        setDdsAccounts(null);
        console.error('Error fetching accounts');
      } finally {
        setFetching(false);
      }
    };

    getAccounts();
  }, [refreshTrigger]);

  async function handleToggleEnabled(account) {
    setTogglingEnabledClientId(account.clientId);
    try {
      const response = await fetch('/api/admin/dds-accounts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientName: account.clientName,
          domain: account.domain,
          clientId: account.clientId,
          enabled: !account.enabled,
        }),
      });

      setRefreshTrigger({ toggling: true, value: new Date() });

      if (response.ok) {
        setTogglingEnabledClientId(null);
        return;
      }

      setTogglingEnabledClientId(null);
      console.error('Error toggling enabled');
    } catch (e) {
      setTogglingEnabledClientId(null);
      console.error('Error toggling enabled');
    }
  }

  return (
    <>
      {fetching ? (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={'100%'}
          style={{
            flex: '1 1',
            width: '100%',
            borderRadius: '0.8rem',
            minHeight: '641px',
          }}
        />
      ) : (
        <>
          {ddsAccounts ? (
            <Card style={{ width: '100%' }}>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <DDSAdminToolbar showAddButton={true} />
                <TableContainer sx={{ borderRadius: '12px' }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="small"
                  >
                    <DatatableHead
                      rowCount={ddsAccounts.length}
                      headCells={ddsAccountHeadCells}
                      onRequestSort={() => {}}
                      order="asc"
                      orderBy="clientName"
                      hideSortIcon={true}
                    />
                    {ddsAccounts.length > 0 && (
                      <TableBody>
                        {ddsAccounts
                          .sort((a, b) =>
                            `${a.clientName}${a.clientId}` <
                            `${b.clientName}${b.clientId}`
                              ? -1
                              : 1
                          )
                          .map((account, index) => {
                            return (
                              <TableRow key={account.clientId}>
                                <TableCell align="left">
                                  {account.clientName}
                                </TableCell>
                                <TableCell align="left">
                                  {account.domain}
                                </TableCell>
                                <TableCell align="left">
                                  {account.clientId}
                                </TableCell>

                                <TableCell align="right">
                                  {togglingEnabledClientId ===
                                  account.clientId ? (
                                    <CircularProgress size={'32px'} />
                                  ) : (
                                    <IconButton
                                      disabled={
                                        !!(togglingEnabledClientId || fetching)
                                      }
                                      onClick={() =>
                                        handleToggleEnabled(account)
                                      }
                                      sx={{ padding: '0' }}
                                    >
                                      {account.enabled ? (
                                        <ToggleOn
                                          fontSize="large"
                                          color={
                                            togglingEnabledClientId || fetching
                                              ? 'gray'
                                              : 'success'
                                          }
                                        />
                                      ) : (
                                        <ToggleOff
                                          fontSize="large"
                                          color={
                                            togglingEnabledClientId || fetching
                                              ? 'gray'
                                              : 'error'
                                          }
                                        />
                                      )}
                                    </IconButton>
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  {Util.formatDate(account.created)}
                                </TableCell>
                                <TableCell align="left">
                                  {Util.formatDate(account.updated)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    )}
                  </Table>
                  {ddsAccounts.length === 0 && (
                    <Box
                      sx={{
                        padding: '1rem 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        className="no-account-title"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: 'rgba(0,0,0,.45)',
                        }}
                      >
                        There are no accounts to display.
                      </Typography>
                    </Box>
                  )}
                </TableContainer>
              </Paper>
            </Card>
          ) : (
            <Card style={{ height: '641px', width: '100%' }}>
              <ErrorHandler
                message={
                  'An error has occurred while trying to get the dds accounts data.'
                }
              />
            </Card>
          )}
        </>
      )}
    </>
  );
}
