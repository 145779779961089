import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
  Avatar,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  createFilterOptions,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';
import {
  Children,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Util from '../../lib/util';
import {
  getAssets,
  getAutomations,
  getDataExtensions,
  getExtracts,
  getImports,
  getQueries,
  getScripts,
  getTransfers,
} from '../../service/sfmcApiService/sfmcApiService';
import BusinessUnitDetails from '../../audits/BusinessUnitDetails';
import { Business, Cached, Clear, CloudUpload } from '@mui/icons-material';
import {
  createPackage,
  getPackageCreationHistory,
} from '../../service/builderSuiteApiService/builderSuiteApiService';
import scssVariables from '../../styles/variables.scss';
import DataTable from 'react-data-table-component';
import useMountedState from '../../state/useMountedState';
import ExpandedTaskDetails from './components/ExpandedTaskDetails';
import { DarkModeContext } from '../../context/darkModeContext';
import { useNavigate } from '../../state/Navigation';
import PackageSummary, { SfmcObjectTypes } from './components/PackageSummary';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import { useRef } from 'react';
import './BuilderSuite.scss';

const packageCreationHistorySchema = {
  header: [
    {
      name: 'Filename',
      selector: (row) => row.filename,
      sortable: true,
    },
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Started Date',
      selector: (row) => row.started,
      sortable: true,
    },
    {
      name: 'Completed Date',
      selector: (row) => row.completed,
      sortable: true,
    },
    {
      name: 'Job Status',
      selector: (row) => {
        return row.statusName === 'completed' &&
          row.output?.warning?.message?.length
          ? `${row.statusName}`
          : row.statusName;
      },
      sortable: true,
    },
  ],
};

const initialAvailableObject = SfmcObjectTypes.reduce((acc, type) => {
  acc[type.value] = [];
  return acc;
}, {});

function selectedObjectsReducer(state, action) {
  if (action === 'clear') {
    return _.cloneDeep(initialAvailableObject);
  } else {
    state = _.cloneDeep(state);
    state[action.objectType.value] = _.sortBy(action.payload, (o) =>
      o.name.toLowerCase()
    );
    return state;
  }
}

function availabledObjectsReducer(state, action) {
  if (action === 'clear') {
    return _.cloneDeep(initialAvailableObject);
  } else {
    state = _.cloneDeep(state);
    state[`fetched-${action.objectType.value}`] = true;
    state[action.objectType.value] = _.sortBy(action.payload, (o) =>
      o.name.toLowerCase()
    );
    return state;
  }
}

const initialStatus = {
  status: 'idle',
  messages: ['Select objects to include in the package'],
  severity: 'info',
};

function statusReducer(state, action) {
  let newState = state;
  switch (action.type) {
    case 'setStatus':
      if (
        (state.status === 'running' || state.status === 'uploading') &&
        action.payload.status === 'finished'
      ) {
        newState = { ...state, status: action.payload.status };
      } else if (action.payload.status !== 'finished') {
        newState = { ...state, status: action.payload.status };
      }
      break;
    case 'setMessages':
      newState = {
        ...state,
        status: action.payload?.status || 'idle',
        severity: action.payload?.severity || 'info',
        messages: action.payload?.messages || [],
      };
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default function BuilderSuitePackage({ user } = {}) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [mid] = useState(Number(params.mid));
  const isMounted = useMountedState();
  const selectedItemsRef = useRef(null);
  const autocompleteInputRef = useRef(null);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openClear, setOpenClear] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [status, dispatchStatus] = useReducer(statusReducer, initialStatus);
  const [creationHistory, setCreationHistory] = useState({});
  const [historyTableData, setHistoryTableData] = useState({});
  const [historyLoader, setHistoryLoader] = useState(true);
  const [businessUnit, setBusinessUnit] = useState(null);
  const [currentInput, setCurrentInput] = useState('');
  const [objectsLoading, setObjectsLoading] = useState(false);
  const [selectedObjectType, setSelectedObjectType] = useState(
    SfmcObjectTypes[0]
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [availableObjects, dispatchAvailableObjects] = useReducer(
    availabledObjectsReducer,
    initialAvailableObject
  );
  const [selectedObjects, dispatchSelectedObjects] = useReducer(
    selectedObjectsReducer,
    initialAvailableObject
  );
  const [packageName, setPackageName] = useState(`package-${mid}`);
  const [downloadAutomationsActivities, setDownloadAutomationsActivities] =
    useState(true);
  const [
    downloadQueriesTargetDataExtensions,
    setDownloadQueriesDataExtensions,
  ] = useState(true);
  const [
    downloadImportsTargetDataExtensions,
    setDownloadImportsTargetExtensions,
  ] = useState(true);
  const [
    downloadVerificationsSourceDataExtensions,
    setDownloadVerificationsSourceDataExtensions,
  ] = useState(true);
  const [
    downloadExtractsSourceDataExtensions,
    setDownloadExtractsSourceDataExtensions,
  ] = useState(true);
  const [inputFilterValue, setInputFilterValue] = useState('');

  const { darkMode } = useContext(DarkModeContext);
  const backUrlFull = useMemo(
    () =>
      Util.resolveBackUrl({
        navigationState: location.state,
        fallback: document.referrer,
        fallbackHome: true,
      }),
    [location.state]
  );
  const backUrlParts = backUrlFull.split('/');
  const backUrl = backUrlParts[backUrlParts.length - 1];

  function closeSubmit() {
    setSubmitReady(false);
    setOpenSubmit(false);
  }

  const workerIsBusy = useMemo(() => {
    const busy = creationHistory?.longRunningTasks?.some((t) => {
      return [1, 3].includes(t.status);
    });
    if (!busy) {
      dispatchStatus({
        type: 'setStatus',
        payload: {
          status: 'finished',
        },
      });
    } else {
      dispatchStatus({
        type: 'setStatus',
        payload: {
          status: 'running',
        },
      });
    }
    return busy;
  }, [creationHistory.longRunningTasks]);

  const handleTypeChange = (evt) => {
    const objectType =
      SfmcObjectTypes.find(
        (objectType) => objectType.value === evt.target?.value
      ) || SfmcObjectTypes[0];
    setSelectedObjectType(objectType);
    setInputFilterValue('');
  };

  const handleSelectObject = (evt, payload) => {
    dispatchSelectedObjects({
      objectType: selectedObjectType,
      payload,
    });
    dispatchStatus({ type: 'setMessages', payload: initialStatus });

    if (selectedItemsRef.current) {
      setTimeout(() => {
        selectedItemsRef.current.scrollTop =
          selectedItemsRef.current.scrollHeight;
      }, 1);
    }
  };

  const mapOptions = (objects, { name, id } = {}) => {
    const options = objects?.items?.map((obj) => ({
      name: obj[name],
      id: obj[id],
      wsfmc: obj.wsfmc,
    }));
    return options;
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => {
      const folderPath = option.wsfmc?.folder?.path?.split('/')?.slice(1);
      return [option.name, ...(folderPath || [])].join(' ').trim();
    },
  });

  const filteredObjects = useMemo(() => {
    const allObjects = availableObjects?.[selectedObjectType?.value];
    if (currentInput && allObjects?.length) {
      const sortedList = _.sortBy(
        allObjects,
        (item) => {
          const ix = item.name
            ?.toLowerCase()
            .indexOf(currentInput?.toLowerCase());
          return ix >= 0 ? ix : 1e9;
        },
        (item) => item.name?.toLowerCase(),
        (item) => {
          const ix = item.wsfmc?.folder?.path
            ?.toLowerCase()
            .indexOf(currentInput?.toLowerCase());
          return ix >= 0 ? ix : 1e9;
        },
        (item) => item.wsfmc?.folder?.path?.toLowerCase(),
        (item) =>
          Number.isNaN(Number(item.id))
            ? item.id.toLowerCase()
            : Number(item.id)
      );
      return sortedList;
    }

    return _.sortBy(allObjects || [], (item) => item.name.toLowerCase());
  }, [availableObjects, selectedObjectType?.value, currentInput]);

  const handleOpenObjects = async () => {
    if (
      availableObjects &&
      selectedObjectType &&
      !availableObjects[selectedObjectType.value]?.length
    ) {
      try {
        setObjectsLoading(true);
        let options;
        let objects;
        switch (selectedObjectType.value) {
          case 'codesnippetblock':
            objects = await getAssets({
              mid: mid,
              typeId: 220,
              fields: ['id', 'customerKey', 'name', 'category'],
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'id',
            });
            break;
          case 'htmlemail':
            objects = await getAssets({
              mid: mid,
              typeId: 208,
              fields: ['id', 'customerKey', 'name', 'category'],
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'id',
            });
            break;
          case 'automation':
            objects = await getAutomations({
              mid: mid,
              basicInfo: true,
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'id',
            });
            break;
          case 'dataExtension':
            objects = await getDataExtensions({
              mid: mid,
              properties: ['Name', 'ObjectID', 'CustomerKey', 'CategoryId'],
              forBuilderSuite: true,
            });
            options = mapOptions(objects, {
              name: 'Name',
              id: 'ObjectID',
            });
            break;
          case 'query':
            objects = await getQueries({
              mid: mid,
              properties: ['Name', 'CustomerKey', 'ObjectID', 'CategoryID'],
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'queryDefinitionId',
            });
            break;
          case 'fileTransfer':
            objects = await getTransfers({
              mid: mid,
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'id',
            });
            break;
          case 'dataExtract':
            objects = await getExtracts({
              mid: mid,
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'dataExtractDefinitionId',
            });
            break;
          case 'importFile':
            objects = await getImports({
              mid: mid,
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'importDefinitionId',
            });
            break;
          case 'script':
            objects = await getScripts({
              mid: mid,
            });
            options = mapOptions(objects, {
              name: 'name',
              id: 'ssjsActivityId',
            });
            break;
          default:
            //nothing to do;
            break;
        }
        dispatchAvailableObjects({
          objectType: selectedObjectType,
          payload: options || [],
        });
      } finally {
        setObjectsLoading(false);
      }
    }
  };

  /**
   * @param {string} input
   */
  const renderMatch = (input) => {
    if (input && currentInput) {
      const lowercaseFilter = currentInput.toLowerCase();
      const indices = _.toArray(
        String(input).matchAll(new RegExp(_.escapeRegExp(currentInput), 'gi'))
      )
        .flatMap((m) => [m.index, m.index + currentInput.length])
        .filter((index) => index > 0);
      indices.push(input.length);

      let lastIndex = 0;
      return Children.toArray(
        indices.map((index) => {
          const part = String(input).substring(lastIndex, index);
          lastIndex = index;
          return part.toLowerCase() === lowercaseFilter ? (
            <span className="search-match">{part}</span>
          ) : (
            <span className="search-unmatched">{part}</span>
          );
        })
      );
    }
    return input;
  };

  async function loadCreationHistory(mid) {
    setHistoryLoader(true);
    try {
      const apiRes = await getPackageCreationHistory(mid, {
        offset: (currentPage - 1) * rowPerPage,
        count: rowPerPage,
      });
      setTotalRows(apiRes.counts?.tasksCount || 0);
      setCreationHistory(apiRes);
      setHistoryLoader(false);
    } catch (error) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status:
            error.response?.status in [401, 403]
              ? 'permissionError'
              : 'serverError',
          severity: 'error',
          messages: [
            [401, 403].includes(error.response?.status)
              ? 'Insufficient permissions.'
              : 'An error occurred. If the problem persist, please contact support.',
          ],
        },
      });
      const errorRes = { error: error };
      setCreationHistory(errorRes);
      setHistoryLoader(false);
    }
  }

  useEffect(() => {
    if (businessUnit && !businessUnit.builderSuiteAccessGranted) {
      return navigate('/');
    }
    loadCreationHistory(mid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnit, currentPage, rowPerPage]);

  useEffect(() => {
    if (creationHistory) {
      const data = creationHistory.longRunningTasks?.map((task) => {
        const parameters = JSON.parse(task.parameters);
        return {
          completed: task.completed,
          created: task.created,
          functionName: task.functionName,
          id: task.id,
          instanceId: task.instanceId,
          mid: parameters.mid,
          filename: parameters.packageName,
          objectReferences: parameters.objectReferences,
          status: task.status,
          statusName: task.statusName,
          started: task.started,
          username: task.username,
          taskCode: task.taskCode,
          output: task.output,
          logs: task.logs,
        };
      });
      setHistoryTableData(data);
    }
  }, [creationHistory]);

  const onBusinessUnitChange = useCallback(
    (bu) => {
      if (isMounted()) {
        setBusinessUnit(bu.mid ? bu : null);
      }
    },
    [isMounted]
  );

  const onChangeRowsPerPage = (amount) => {
    setResetPaginationToggle(!resetPaginationToggle);
    setRowsPerPage(amount);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const hasSelection = SfmcObjectTypes.some(
    (type) => selectedObjects[type.value]?.length
  );

  const handleCreatePackage = async () => {
    const objectReferences = {};
    for (const type of Object.keys(selectedObjects)) {
      const referencesOfType = selectedObjects[type].map((o) => ({
        id: o.id,
        name: o.name,
      }));
      objectReferences[type] = referencesOfType;
    }
    try {
      const settings = {
        packageName: packageName?.trim(),
        dependenciesSettings: {
          downloadAutomationsActivities,
          downloadQueriesTargetDataExtensions,
          downloadImportsTargetDataExtensions,
          downloadVerificationsSourceDataExtensions,
        },
      };
      await createPackage(mid, objectReferences, settings);

      dispatchStatus({
        type: 'setMessages',
        payload: {
          status: 'uploaded',
          severity: 'success',
          messages: [
            `The package is being created.`,
            'Please check the status of the deployment in the history table below.',
          ],
        },
      });
      await loadCreationHistory(mid);
    } catch (error) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status:
            error.response?.status in [401, 403]
              ? 'permissionError'
              : 'couldNotStart',
          severity: 'error',
          messages: [
            [401, 403].includes(error.response?.status)
              ? 'Insufficient permissions.'
              : error.response?.data?.code === 'alreadyRunning'
              ? error.response.data.message
              : 'An error occurred launching the task. If the problem persist, please contact support.',
          ],
        },
      });
      await loadCreationHistory(mid);
    }
  };

  return (
    <div className="audits builder-suite builder-suite-package">
      <ConfirmDialog
        dialogProps={{
          style: { top: '-40%', width: '100%' },
        }}
        dialogContentComponents={<>Clear current selection?</>}
        closeOnBackdropClick={true}
        open={openClear}
        okText="Clear"
        cancelText="Cancel"
        okProps={{
          disabled: !hasSelection,
        }}
        onCancel={() => setOpenClear(false)}
        onOk={() => {
          dispatchAvailableObjects('clear');
          dispatchSelectedObjects('clear');
          setOpenClear(false);
        }}
      />
      <ConfirmDialog
        dialogProps={{
          style: { width: '100%' },
        }}
        dialogTitleComponents={
          <div className="builder-suite-dialog-title">
            <Box sx={{ display: 'flex', width: '100%' }}>
              <ListItemAvatar>
                <Avatar className="bu-avatar">
                  <Business />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Grid container>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.3rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div className="bu-link">
                      <div className="bu-name">
                        {businessUnit?.displayName || businessUnit?.name}
                      </div>
                      <div className="bu-mid">
                        <span
                          className="bu-mid-text"
                          style={{
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                          }}
                        >
                          {businessUnit?.mid}{' '}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </ListItemText>
            </Box>
          </div>
        }
        dialogContentComponents={
          <>
            <Box style={{ padding: '20px 0 20px 0' }}>
              <FormControl fullWidth>
                <TextField
                  value={packageName}
                  onChange={(evt) => setPackageName(evt.target.value)}
                  InputLabelProps={{ position: 'left' }}
                  label="Package name"
                  variant="outlined"
                  style={{
                    backgroundColor: !/^[\w _-]+$/i.test(packageName?.trim())
                      ? 'rgb(253, 237, 237)'
                      : null,
                  }}
                />
              </FormControl>
            </Box>
            <strong>Dependencies</strong>
            <Box style={{ padding: 'px 20px 20px 20px' }}>
              <div>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadAutomationsActivities}
                        onChange={(evt) =>
                          setDownloadAutomationsActivities(evt.target.checked)
                        }
                      />
                    }
                    label="Detect and download automations activities"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadQueriesTargetDataExtensions}
                        onChange={(evt) =>
                          setDownloadQueriesDataExtensions(evt.target.checked)
                        }
                      />
                    }
                    label="Detect and download query activities target data extensions"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadImportsTargetDataExtensions}
                        onChange={(evt) =>
                          setDownloadImportsTargetExtensions(evt.target.checked)
                        }
                      />
                    }
                    label="Detect and download import activities target data extensions"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadVerificationsSourceDataExtensions}
                        onChange={(evt) =>
                          setDownloadVerificationsSourceDataExtensions(
                            evt.target.checked
                          )
                        }
                      />
                    }
                    label="Detect and download verifications activities source data extensions"
                  />
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadExtractsSourceDataExtensions}
                        onChange={(evt) =>
                          setDownloadExtractsSourceDataExtensions(
                            evt.target.checked
                          )
                        }
                      />
                    }
                    label="Detect and download data extract activities source data extensions"
                  />
                </FormControl>
              </div>
            </Box>
            <Alert severity="info">
              <p>
                If you include dependencies, additional objects will be part of
                the package.
              </p>
              <p style={{ marginTop: '5px' }}>
                Note that Builder Suite only detect data extensions{' '}
                <em>written</em> but not those that are <em>read</em> by a
                query.
              </p>
            </Alert>
          </>
        }
        closeOnBackdropClick={true}
        open={openSubmit}
        okText="Create"
        cancelText="Cancel"
        okProps={{
          disabled: !submitReady || !packageName?.length,
        }}
        onCancel={closeSubmit}
        onOk={() => {
          setResetPaginationToggle(!resetPaginationToggle);
          handleCreatePackage();
          setOpenSubmit(false);
          setSubmitReady(false);
        }}
      />
      <Box className={'backButtonBox' + (!backUrlFull ? ' invalid' : '')}>
        <Link to={`/${backUrl}`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <div className="audits-header">
        <h2 className="audits-title">
          <CloudDownloadIcon className="audits-title__icon" />
          <span>Builder Suite Package Creation - Zip</span>
        </h2>
      </div>
      <div className="package-ui data-table-container">
        <div style={{ marginBottom: '20px' }}>
          <BusinessUnitDetails
            mid={mid}
            onLoad={onBusinessUnitChange}
            onError={onBusinessUnitChange}
            user={user}
            showOnlyHeader={true}
          />
        </div>
        {businessUnit?.id ? (
          <>
            <div className="object-selection">
              <strong>Select the objects to be included in the package.</strong>
              <Stack direction={'row'} style={{ marginTop: '20px' }}>
                <div>
                  <FormControl>
                    <InputLabel id="object-type-select">Object type</InputLabel>
                    <Select
                      style={{ minWidth: '300px' }}
                      value={selectedObjectType.value}
                      labelId="object-type-select"
                      label="Object type"
                      onChange={handleTypeChange}
                      renderValue={(value) => {
                        return SfmcObjectTypes.find(
                          (objectType) => objectType.value === value
                        ).name;
                      }}
                    >
                      {Children.toArray(
                        SfmcObjectTypes.map((objectType) => (
                          <MenuItem value={objectType.value}>
                            {objectType.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      className="builderSuite-autocomplete"
                      fullWidth
                      clearOnBlur={false}
                      readOnly={objectsLoading}
                      openOnFocus={true}
                      options={
                        objectsLoading
                          ? [{ name: 'Loading...', id: '--' }]
                          : filteredObjects || []
                      }
                      filterOptions={filterOptions}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      disableCloseOnSelect
                      // onOpen={() => {
                      //   console.log('onOpen', Date.now());
                      //   // handleOpenObjects();
                      // }}
                      inputValue={inputFilterValue}
                      onKeyDownCapture={(e) => {
                        if (['Home', 'End'].includes(e.key)) {
                          e.stopPropagation();
                        }
                      }}
                      onInputChange={(event, newInputValue, reason) => {
                        if (reason !== 'reset') {
                          setInputFilterValue(newInputValue);
                        }
                      }}
                      onSelect={(event) => {
                        setCurrentInput(event.target.value);
                      }}
                      renderOption={(props, option) => (
                        <ObjectOption
                          key={option.id}
                          props={props}
                          option={option}
                          renderMatch={renderMatch}
                        />
                      )}
                      value={selectedObjects?.[selectedObjectType.value] || []}
                      onChange={handleSelectObject}
                      renderInput={(params) => {
                        const { InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } =
                          InputProps;
                        return (
                          <>
                            <TextField
                              inputRef={autocompleteInputRef}
                              className="autocomplete-input"
                              {...restParams}
                              InputProps={{
                                ...restInputProps,
                                startAdornment: (
                                  <>
                                    <div
                                      onClick={() => {
                                        autocompleteInputRef.current?.focus();
                                      }}
                                      ref={selectedItemsRef}
                                      style={{
                                        maxHeight: '75px',
                                        overflowY: 'auto',
                                      }}
                                    >
                                      {startAdornment}
                                    </div>
                                  </>
                                ),
                              }}
                              InputLabelProps={{ shrink: true }}
                              label={`${
                                selectedObjectType.name
                              }${renderAutocompleteLabel(
                                availableObjects[selectedObjectType?.value]
                                  ?.length,
                                availableObjects[
                                  `fetched-${selectedObjectType?.value}`
                                ]
                              )}`}
                              onFocus={(e) => {
                                e.stopPropagation();
                                handleOpenObjects();
                              }}
                              sx={{
                                input: {
                                  color: 'black',
                                  '&::placeholder': {
                                    opacity: objectsLoading ? 1 : null,
                                  },
                                },
                              }}
                              placeholder={
                                objectsLoading
                                  ? 'Loading...'
                                  : availableObjects[selectedObjectType?.value]
                                      ?.length &&
                                    selectedObjects[selectedObjectType?.value]
                                      ?.length
                                  ? ''
                                  : 'Start typing'
                              }
                            />
                          </>
                        );
                      }}
                      multiple
                    />
                  </FormControl>
                </div>
              </Stack>
            </div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Stack direction={'row'} spacing={1}>
                <Tooltip
                  enterNextDelay={1000}
                  enterDelay={1000}
                  title={
                    workerIsBusy
                      ? 'A task is already running'
                      : !hasSelection
                      ? 'No objects selected'
                      : !creationHistory?.longRunningTasks &&
                        status.status !== 'couldNotStart'
                      ? 'Please wait...'
                      : undefined
                  }
                >
                  <span>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setOpenClear(true);
                      }}
                      startIcon={<Clear />}
                      disabled={
                        !hasSelection ||
                        status.status === 'uploading' ||
                        ((workerIsBusy || !creationHistory?.longRunningTasks) &&
                          status.status !== 'couldNotStart')
                      }
                    >
                      Clear selection
                    </Button>
                  </span>
                </Tooltip>
                <div style={{ verticalAlign: 'top' }}>
                  <PackageSummary
                    showButton={true}
                    objectReferences={selectedObjects}
                  />
                </div>
                <Tooltip
                  enterNextDelay={1000}
                  enterDelay={1000}
                  title={
                    workerIsBusy
                      ? 'A task is already running'
                      : !hasSelection
                      ? 'No objects selected'
                      : !creationHistory?.longRunningTasks &&
                        status.status !== 'couldNotStart'
                      ? 'Please wait...'
                      : undefined
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSubmitReady(businessUnit?.mid ? true : false);
                        setOpenSubmit(true);
                      }}
                      startIcon={<CloudUpload />}
                      disabled={
                        !hasSelection ||
                        status.status === 'uploading' ||
                        ((workerIsBusy || !creationHistory?.longRunningTasks) &&
                          status.status !== 'couldNotStart')
                      }
                    >
                      Create
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
              <Alert
                severity={
                  status.status === 'finished' || status.status === 'idle'
                    ? hasSelection
                      ? 'success'
                      : initialStatus.severity
                    : status.severity
                }
              >
                {(status.status === 'finished' || status.status === 'idle'
                  ? hasSelection
                    ? [
                        'Select more objects to include in the package or click submit to create the package',
                      ]
                    : initialStatus.messages
                  : status.messages
                ).map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            </Box>
            <div className="data-table" style={{ marginTop: '1rem' }}>
              <Stack
                style={{ background: 'none' }}
                direction={'row'}
                alignItems={'center'}
                spacing={1}
              >
                <div>Refresh history</div>
                <IconButton
                  className="refresh-list"
                  disabled={historyLoader}
                  onClick={() => loadCreationHistory(mid)}
                  title="Refresh history"
                >
                  <Cached />
                </IconButton>
              </Stack>
              <DataTable
                columns={packageCreationHistorySchema?.header}
                data={historyTableData}
                progressPending={historyLoader}
                progressComponent={
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'100%'}
                    style={{
                      flex: '1 1',
                      width: '0',
                      borderRadius: '0.8rem',
                      minHeight: '550px',
                    }}
                  />
                }
                expandableRows
                expandableRowsComponent={ExpandedTaskDetails}
                dense
                // fixedHeader={!loading}
                fixedHeaderScrollHeight={'600px'}
                order
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                pagination
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                paginationServer
                paginationTotalRows={totalRows}
                persistTableHead
                conditionalRowStyles={[
                  {
                    when: (row) => {
                      return row?.statusName === 'failed';
                    },
                    classNames: ['bs-results-error'],
                  },
                  {
                    when: (row) => {
                      return row?.statusName === 'completedWithErrors';
                    },
                    classNames: ['bs-results-warn'],
                  },
                  {
                    when: (row) => {
                      return (
                        row.statusName === 'completed' &&
                        row.output?.warning?.message?.length
                      );
                    },
                    classNames: ['bs-results-warn'],
                  },
                ]}
                customStyles={{
                  headRow: {
                    style: {
                      background: `${
                        darkMode
                          ? scssVariables.darkmodeColorPrimary
                          : scssVariables.colorPrimary
                      }`,
                    },
                  },
                  noData: {
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      background: darkMode
                        ? 'rgb(25 37 49)'
                        : 'rgb(241 241 241)',
                      color: darkMode ? '#fff' : '#222',
                    },
                  },
                  progress: {
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: darkMode
                        ? 'rgb(25 37 49)'
                        : 'rgb(241 241 241)',
                    },
                  },
                  pagination: darkMode
                    ? {
                        pageButtonsStyle: {
                          color: '#fff',
                          fill: '#fff',
                          '&:disabled': {
                            color: '#ffffff54',
                            fill: '#ffffff54',
                          },
                        },
                      }
                    : {
                        pageButtonsStyle: {
                          color: '#8d8c8c',
                          fill: '#8d8c8c',
                          '&:disabled': {
                            color: '#adadad54',
                            fill: '#adadad54',
                          },
                        },
                      },
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function ObjectOption({ props, option, renderMatch }) {
  const pathParts = option.wsfmc?.folder?.path?.split('/');
  const pathPrefix = pathParts?.[0];
  pathParts?.shift();
  const pathSuffix =
    pathPrefix && pathParts?.length
      ? renderMatch('/' + pathParts?.join('/'))
      : '';

  return (
    <li {...props}>
      <Box className="autocomplete-option-item">
        {option.wsfmc?.folder?.path ? (
          <>
            <em
              style={{
                fontSize: '0.8rem',
                marginRight: '0.5rem',
              }}
            >
              {pathPrefix}
              {pathSuffix}
            </em>
          </>
        ) : (
          ''
        )}

        <div
          style={
            option.folder?.path
              ? {}
              : {
                  transform: 'translateY(3px)',
                }
          }
        >
          {renderMatch(option.name)}
        </div>
      </Box>
    </li>
  );
}

function renderAutocompleteLabel(input, fetched) {
  return input >= 0 && fetched ? ` (${input})` : '';
}
