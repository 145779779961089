import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useReducer,
} from 'react';
import DataTable from 'react-data-table-component';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CachedIcon from '@mui/icons-material/Cached';
import { Link, useLocation, useParams } from 'react-router-dom';
import BusinessUnitDetails from '../../audits/BusinessUnitDetails';
import Util from '../../lib/util';
import { DarkModeContext } from '../../context/darkModeContext';
import '../../audits/Audits.scss';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import {
  getDeployHistory,
  postPackageFile,
} from '../../service/builderSuiteApiService/builderSuiteApiService';
import { useNavigate } from '../../state/Navigation';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import { Business } from '@mui/icons-material';
import scssVariables from '../../styles/variables.scss';
import ExpandedTaskDetails from './components/ExpandedTaskDetails';

const fileSizeLimitInMB = (() => {
  let size = Number(
    Util.getEnvVariable('REACT_APP_BUILDER_SUITE__MAX_FILE_SIZE_IN_MB')
  );
  if (!(size > 0)) {
    size = 5;
  }
  return size;
})();

const fileUpload = {
  header: [
    {
      name: 'Filename',
      selector: (row) => row.filename,
      sortable: true,
    },
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Started Date',
      selector: (row) => row.started,
      sortable: true,
    },
    {
      name: 'Completed Date',
      selector: (row) => row.completed,
      sortable: true,
    },
    {
      name: 'Job Status',
      selector: (row) => row.statusName,
      sortable: true,
    },
  ],
};

const initialStatus = {
  status: 'idle',
  messages: ['Select a valid zip archive'],
  severity: 'info',
};

function statusReducer(state, action) {
  let newState = state;
  switch (action.type) {
    case 'setStatus':
      if (
        (state.status === 'running' || state.status === 'uploading') &&
        action.payload.status === 'finished'
      ) {
        newState = { ...state, status: action.payload.status };
      } else if (action.payload.status !== 'finished') {
        newState = { ...state, status: action.payload.status };
      }
      break;
    case 'setMessages':
      newState = {
        ...state,
        status: action.payload?.status || 'idle',
        severity: action.payload?.severity || 'info',
        messages: action.payload?.messages || [],
      };
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return newState;
}

export default function BuilderSuite({ user } = {}) {
  const params = useParams();
  const location = useLocation();
  const { darkMode } = useContext(DarkModeContext);
  const [openSubmit, setOpenSubmit] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [mid] = useState(Number(params.mid));
  const isMounted = useMountedState();
  const [status, dispatchStatus] = useReducer(statusReducer, initialStatus);
  const [businessUnit, setBusinessUnit] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [deployHistory, setDeployHistory] = useState({});
  const [historyTableData, setHistoryTableData] = useState({});
  const [historyLoader, setHistoryLoader] = useState(true);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  function closeSubmit() {
    setSubmitReady(false);
    setOpenSubmit(false);
  }

  const workerIsBusy = useMemo(() => {
    const busy = deployHistory?.longRunningTasks?.some((t) => {
      return [1, 3].includes(t.status);
    });
    if (!busy) {
      dispatchStatus({
        type: 'setStatus',
        payload: {
          status: 'finished',
        },
      });
    } else {
      dispatchStatus({
        type: 'setStatus',
        payload: {
          status: 'running',
        },
      });
    }
    return busy;
  }, [deployHistory.longRunningTasks]);

  const uploadChangeHandler = (event) => {
    // only allow zip files
    if (
      (event.target?.files[0]?.type !== 'application/zip' &&
        event.target?.files[0]?.type !== 'application/x-zip-compressed') ||
      event.target?.files[0]?.size <= 0
    ) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status: 'badFile',
          severity: 'error',
          messages: ['Please select a valid zip archive.'],
        },
      });
      setSelectedFile(null);
      setIsFilePicked(false);
      return;
    } else if (
      (event.target?.files[0]?.type === 'application/zip' ||
        event.target?.files[0]?.type === 'application/x-zip-compressed') &&
      event.target?.files[0]?.size > fileSizeLimitInMB * 1e6
    ) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status: 'badFile',
          severity: 'error',
          messages: [
            `Please select a zip archive smaller than ${fileSizeLimitInMB} MB.`,
          ],
        },
      });
      setSelectedFile(null);
      setIsFilePicked(false);
      return;
    }

    setSelectedFile(event.target.files[0]);
    const size = Math.max(event.target.files[0]?.size || 0, 0);
    var unit = size < 1e3 ? 'B' : size < 1e6 ? 'KB' : size < 1e9 ? 'MB' : 'GB';
    var convertedSize =
      unit === 'B'
        ? size
        : unit === 'KB'
        ? size / 1e3
        : unit === 'MB'
        ? size / 1e6
        : size / 1e9;

    const formatSize = (num) => {
      return Number(num.toFixed(1)).toLocaleString('en-US');
    };

    dispatchStatus({
      type: 'setMessages',
      payload: {
        status: 'fileReady',
        severity: 'success',
        messages: [
          `Filename: ${event.target.files[0].name} | Size : ${formatSize(
            convertedSize
          )} ${unit} | Last modification date: ${event.target.files[0].lastModifiedDate?.toLocaleDateString()}`,
        ],
      },
    });
    setIsFilePicked(true);
  };

  const onChangeRowsPerPage = (amount) => {
    setResetPaginationToggle(!resetPaginationToggle);
    setRowsPerPage(amount);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleFileSubmission = () => {
    if (selectedFile) {
      postPackage(mid, selectedFile);
    }
  };

  const onBusinessUnitChange = useCallback(
    (bu) => {
      if (isMounted()) {
        setBusinessUnit(bu);
      }
    },
    [isMounted]
  );

  async function postPackage(mid, selectedFile) {
    try {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status: 'uploading',
          severity: 'info',
          messages: ['Uploading...'],
        },
      });
      const apiRes = await postPackageFile(mid, selectedFile);
      setDeployHistory(apiRes);
      loadDeployHistory(mid);
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status: 'uploaded',
          severity: 'success',
          messages: [
            `Package ${selectedFile.name} uploaded successfully.`,
            'Please check the status of the deployment in the history table below.',
          ],
        },
      });
    } catch (error) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status:
            error.response?.status in [401, 403]
              ? 'permissionError'
              : 'couldNotStart',
          severity: 'error',
          messages: [
            [401, 403].includes(error.response?.status)
              ? 'Insufficient permissions.'
              : error.response?.data?.code === 'alreadyRunning'
              ? error.response.data.message
              : error.response.data.builderSuiteFeedback ||
                'An error occurred launching the task. If the problem persist, please contact support.',
          ],
        },
      });
      loadDeployHistory(mid);
    } finally {
      fileInputRef.current.value = '';
      setSelectedFile({});
      setIsFilePicked(false);
    }
  }

  async function loadDeployHistory(mid) {
    setHistoryLoader(true);
    try {
      const apiRes = await getDeployHistory(mid, 'zip', {
        offset: (currentPage - 1) * rowPerPage,
        count: rowPerPage,
      });
      setTotalRows(apiRes.counts?.tasksCount || 0);
      setDeployHistory(apiRes);
      setHistoryLoader(false);
    } catch (error) {
      dispatchStatus({
        type: 'setMessages',
        payload: {
          status:
            error.response?.status in [401, 403]
              ? 'permissionError'
              : 'serverError',
          severity: 'error',
          messages: [
            [401, 403].includes(error.response?.status)
              ? 'Insufficient permissions.'
              : 'An error occurred. If the problem persist, please contact support.',
          ],
        },
      });
      const errorRes = { error: error };
      setDeployHistory(errorRes);
      setHistoryLoader(false);
    }
  }

  useEffect(() => {
    if (businessUnit && !businessUnit.builderSuiteAccessGranted) {
      return navigate('/');
    }
    loadDeployHistory(mid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnit, currentPage, rowPerPage]);

  useEffect(() => {
    if (deployHistory) {
      const data = deployHistory.longRunningTasks?.map((task) => {
        const parameters = JSON.parse(task.parameters);
        return {
          completed: task.completed,
          created: task.created,
          functionName: task.functionName,
          id: task.id,
          instanceId: task.instanceId,
          mid: parameters.mid,
          filename: parameters.originalFileName,
          status: task.status,
          statusName: task.statusName,
          started: task.started,
          username: task.username,
          taskCode: task.taskCode,
          output: task.output,
        };
      });
      setHistoryTableData(data);
    }
  }, [deployHistory]);

  const backUrlFull = useMemo(
    () =>
      Util.resolveBackUrl({
        navigationState: location.state,
        fallback: document.referrer,
        fallbackHome: true,
      }),
    [location.state]
  );
  const backUrlParts = backUrlFull.split('/');
  const backUrl = backUrlParts[backUrlParts.length - 1]; // Or parts.pop();

  return (
    <div className="audits builder-suite">
      <ConfirmDialog
        dialogProps={{ style: { top: '-40%', width: '100%' } }}
        dialogTitleComponents={
          <div className="builder-suite-dialog-title">
            <Box sx={{ display: 'flex', width: '100%' }}>
              <ListItemAvatar>
                <Avatar className="bu-avatar">
                  <Business />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <Grid container>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.3rem',
                    }}
                  >
                    <div className="bu-link">
                      <div className="bu-name">
                        {businessUnit?.displayName || businessUnit?.name}
                      </div>
                      <div className="bu-mid">
                        <span
                          className="bu-mid-text"
                          style={{
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                          }}
                        >
                          {businessUnit?.mid}{' '}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </ListItemText>
            </Box>
          </div>
        }
        dialogContentComponents={
          <strong>
            {selectedFile ? (
              <>Install the package: {selectedFile?.name} </>
            ) : (
              <>Please select a valid zip archive.</>
            )}
          </strong>
        }
        closeOnBackdropClick={false}
        open={openSubmit}
        okText="Submit"
        cancelText="Cancel"
        okProps={{
          disabled: !submitReady,
        }}
        onCancel={closeSubmit}
        onOk={() => {
          setResetPaginationToggle(!resetPaginationToggle);
          handleFileSubmission();
          setOpenSubmit(false);
          setSubmitReady(false);
        }}
      />
      <Box className={'backButtonBox' + (!backUrlFull ? ' invalid' : '')}>
        <Link to={`/${backUrl}`}>
          <ArrowCircleLeftIcon />
          <span>BACK</span>
        </Link>
      </Box>
      <div className="audits-header">
        <h2 className="audits-title">
          <CloudUploadIcon className="audits-title__icon" />
          <span>Builder Suite Package Deployment - Zip</span>
        </h2>
      </div>
      <div className="data-table-container">
        <BusinessUnitDetails
          mid={mid}
          onLoad={onBusinessUnitChange}
          user={user}
          showOnlyHeader={true}
        />

        <Box>
          {businessUnit?.id ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  alignItems: 'center',
                }}
              >
                <Stack direction={'row'} spacing={1}>
                  <Tooltip
                    enterNextDelay={1000}
                    enterDelay={1000}
                    title={
                      workerIsBusy
                        ? 'A task is already running'
                        : deployHistory?.error instanceof Error
                        ? ''
                        : !deployHistory?.longRunningTasks &&
                          status.status !== 'couldNotStart'
                        ? 'Please wait...'
                        : undefined
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        startIcon={<FolderZipIcon />}
                        disabled={
                          ((workerIsBusy || !deployHistory?.longRunningTasks) &&
                            status.status !== 'couldNotStart') ||
                          status.status === 'uploading'
                        }
                      >
                        Select File
                        <input
                          style={{
                            opacity: '0',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                          }}
                          type="file"
                          name="file"
                          ref={fileInputRef}
                          onChange={uploadChangeHandler}
                        />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip
                    enterNextDelay={1000}
                    enterDelay={1000}
                    title={
                      workerIsBusy
                        ? 'A task is already running'
                        : !isFilePicked
                        ? 'No file chosen'
                        : !deployHistory?.longRunningTasks &&
                          status.status !== 'couldNotStart'
                        ? 'Please wait...'
                        : undefined
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setSubmitReady(businessUnit?.mid ? true : false);
                          setOpenSubmit(true);
                        }}
                        startIcon={<CloudUploadIcon />}
                        disabled={
                          !isFilePicked ||
                          status.status === 'uploading' ||
                          ((workerIsBusy || !deployHistory?.longRunningTasks) &&
                            status.status !== 'couldNotStart')
                        }
                      >
                        Submit
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
                <Alert
                  severity={
                    status.status === 'finished'
                      ? initialStatus.severity
                      : status.severity
                  }
                >
                  {(status.status === 'finished'
                    ? initialStatus.messages
                    : status.messages
                  ).map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
                <div></div>
              </Box>
              <div className="data-table" style={{ marginTop: '1rem' }}>
                <Stack
                  style={{ background: 'none' }}
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}
                >
                  <div>Refresh history</div>
                  <IconButton
                    className="refresh-list"
                    disabled={historyLoader}
                    onClick={() => loadDeployHistory(mid)}
                    title="Refresh history"
                  >
                    <CachedIcon />
                  </IconButton>
                </Stack>
                <DataTable
                  columns={fileUpload?.header}
                  data={historyTableData}
                  progressPending={historyLoader}
                  progressComponent={
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={'100%'}
                      style={{
                        flex: '1 1',
                        width: '0',
                        borderRadius: '0.8rem',
                        minHeight: '550px',
                      }}
                    />
                  }
                  expandableRows
                  expandableRowsComponent={ExpandedTaskDetails}
                  dense
                  // fixedHeader={!loading}
                  fixedHeaderScrollHeight={'600px'}
                  order
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  paginationTotalRows={totalRows}
                  paginationServer
                  persistTableHead
                  conditionalRowStyles={[
                    {
                      when: (row) => {
                        return row?.statusName === 'failed';
                      },
                      classNames: ['bs-results-error'],
                    },
                    {
                      when: (row) => {
                        return row?.statusName === 'completedWithErrors';
                      },
                      classNames: ['bs-results-warn'],
                    },
                  ]}
                  customStyles={{
                    headRow: {
                      style: {
                        background: `${
                          darkMode
                            ? scssVariables.darkmodeColorPrimary
                            : scssVariables.colorPrimary
                        }`,
                      },
                    },
                    noData: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        background: darkMode
                          ? 'rgb(25 37 49)'
                          : 'rgb(241 241 241)',
                        color: darkMode ? '#fff' : '#222',
                      },
                    },
                    progress: {
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkMode
                          ? 'rgb(25 37 49)'
                          : 'rgb(241 241 241)',
                      },
                    },
                    pagination: darkMode
                      ? {
                          pageButtonsStyle: {
                            color: '#fff',
                            fill: '#fff',
                            '&:disabled': {
                              color: '#ffffff54',
                              fill: '#ffffff54',
                            },
                          },
                        }
                      : {
                          pageButtonsStyle: {
                            color: '#8d8c8c',
                            fill: '#8d8c8c',
                            '&:disabled': {
                              color: '#adadad54',
                              fill: '#adadad54',
                            },
                          },
                        },
                  }}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </Box>
      </div>
    </div>
  );
}

const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return isMounted;
};
