import React from 'react';
import './card.scss';

const Card = ({ children, centered, style }) => {
  return (
    <div style={style} className={centered ? 'card card-centered' : 'card'}>
      {children}
    </div>
  );
};

export default Card;
