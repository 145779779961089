import { useRef } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from '@mui/material';

import scssVariables from '../styles/variables.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

/** @type {import('@mui/material').MenuProps} */
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      pointerEvents: 'auto',
    },
  },
};

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  min-width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CssFormControl = styled(FormControl, {
  shouldForwardProp: (props) => props !== 'focusColor',
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: p.focusColor,
  },

  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: p.focusColor,
    },
  },
}));

const DEUsageListFilter = ({
  id,
  items,
  selection,
  onFilter,
  onClear,
  label,
  enabled,
  clearButtonIcon,
  clearButtonLabel,
}) => {
  const selectRef = useRef(null);
  const inputId = 'search-' + id;
  const labelId = 'search-' + id + '-label';
  selection = selection || [];

  const onRender = (selected) => {
    const rendered = selected.join(', ');
    if (selectRef.current) {
      selectRef.current.title = rendered;
    }
    return rendered;
  };

  return (
    <>
      <CssFormControl
        id={id}
        className="search-field"
        sx={{ m: 1, width: 200 }}
        focusColor={scssVariables.colorPrimary}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          disabled={enabled === false}
          variant="outlined"
          ref={selectRef}
          input={<OutlinedInput label={label} />}
          labelId={labelId}
          id={inputId}
          value={selection || ''}
          onChange={onFilter}
          multiple
          autoWidth
          renderValue={onRender}
          startAdornment={
            <ClearButton
              style={{ zIndex: '2' }}
              title={clearButtonLabel || 'Clear filter'}
              disabled={enabled === false}
              className="clear-button"
              type="button"
              onClick={onClear}
            >
              {clearButtonIcon || 'X'}
            </ClearButton>
          }
          MenuProps={MenuProps}
        >
          {items
            ?.filter((value) => value)
            ?.map((value) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={selection.indexOf(value) > -1} />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
        </Select>
      </CssFormControl>
    </>
  );
};

export default DEUsageListFilter;
