import React, { useMemo, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CancelIcon from '@mui/icons-material/Cancel';
import BusinessUnitsSearch from './BusinessUnitsSearch';
import scssVariables from '../../styles/variables.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
};

const containsText = (account, searchText) => {
  searchText = searchText?.toString();
  return (
    !searchText ||
    account?.mid?.toString()?.toLowerCase()?.indexOf(searchText.toLowerCase()) >
      -1 ||
    account?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1 ||
    account?.displayName?.toLowerCase()?.indexOf(searchText.toLowerCase()) >
      -1 ||
    account?.enterprise?.name
      ?.toLowerCase()
      ?.indexOf(searchText.toLowerCase()) > -1
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const BusinessUnits = ({ userState, setUserState, theme, getStyles }) => {
  const [searchText, setSearchText] = useState('');

  const displayedBusinessUnits = useMemo(
    () =>
      userState.availableAccounts.filter((account) =>
        containsText(account, searchText)
      ),
    [searchText, userState.availableAccounts]
  );

  const handleBUChange = (event) => {
    const {
      target: { value },
    } = event;

    let lastAdded = value[value.length - 1];

    const businessUnit = userState.availableAccounts.find(
      (account) => account.mid === lastAdded
    );

    const obj = {};
    obj[businessUnit.mid] = [];

    if (value.length > Object.keys(userState.permissions.scopes).length) {
      setUserState({
        ...userState,
        userAccounts: typeof value === 'string' ? value.split(',') : value,
        permissions: {
          ...userState.permissions,
          scopes: {
            ...userState.permissions.scopes,
            ...obj,
          },
        },
      });
    }

    if (value.length < Object.keys(userState.permissions.scopes).length) {
      const filteredScopes = Object.entries(
        userState.permissions.scopes
      ).filter((scope) => value.includes(scope[0]));
      let scopeObj = {};
      filteredScopes.forEach((scope) => {
        const obj = {};
        obj[scope[0]] = scope[1];
        scopeObj = {
          ...scopeObj,
          ...obj,
        };
      });
      setUserState({
        ...userState,
        userAccounts: typeof value === 'string' ? value.split(',') : value,
        permissions: {
          ...userState.permissions,
          scopes: {
            ...scopeObj,
          },
        },
      });
    }
  };

  const handleBUChipDelete = (value) => (event) => {
    const copy = userState.permissions.scopes;
    delete copy[value];
    setUserState({
      ...userState,
      permissions: {
        ...userState.permissions,
        scopes: copy,
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Typography
        component="h4"
        variant="h6"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.1rem',
          fontWeight: '600',
        }}
      >
        <BusinessIcon className="icon" />
        <Box sx={{ lineHeight: '1' }}>Business Units</Box>
      </Typography>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Business Units</InputLabel>
        <Select
          sx={{ minHeight: '56px' }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={Object.keys(userState.permissions.scopes)}
          onChange={handleBUChange}
          input={
            <OutlinedInput id="select-multiple-chip" label="Business Units" />
          }
          onBlur={() => setSearchText('')}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                padding: '.5rem',
              }}
            >
              {selected.map((value) => {
                const businessUnit = userState.availableAccounts.find(
                  (account) => account.mid === Number(value)
                );
                if (!businessUnit) return '';
                return (
                  <HtmlTooltip
                    key={businessUnit.mid}
                    title={
                      <React.Fragment>
                        <Typography
                          component="div"
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            lineHeight: '1.2',
                            marginBottom: '.5rem',
                          }}
                          color="inherit"
                        >
                          {businessUnit.displayName || businessUnit.name}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: '12px',
                            lineHeight: '1.2',
                            marginBottom: '.25rem',
                          }}
                        >
                          <Typography
                            component="div"
                            sx={{
                              fontSize: '12px',
                              lineHeight: '1.2',
                            }}
                          >
                            <strong>MID:</strong> {businessUnit.mid}
                          </Typography>
                        </Typography>
                        <b>Status:</b>{' '}
                        {businessUnit.active ? 'Active' : 'Inactive'}
                      </React.Fragment>
                    }
                    placement="right"
                    arrow
                  >
                    <Chip
                      label={businessUnit.displayName || businessUnit.name}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={handleBUChipDelete(value)}
                      className={
                        businessUnit.active
                          ? 'chip chip_active'
                          : 'chip chip_inactive'
                      }
                    />
                  </HtmlTooltip>
                );
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <ListSubheader sx={{ padding: '0' }}>
            <BusinessUnitsSearch setSearchText={setSearchText} />
          </ListSubheader>
          {displayedBusinessUnits.map((account) => {
            const businessUnit = userState.availableAccounts.find(
              (acc) => acc.id === account.id
            );
            return (
              <MenuItem
                key={account.mid}
                value={account.mid}
                style={getStyles(
                  account.mid,
                  Object.keys(userState.permissions.scopes),
                  theme
                )}
              >
                <Box
                  sx={{ display: 'flex', position: 'relative', height: '30px' }}
                >
                  <span
                    style={{
                      fontSize: '13px',

                      fontWeight: 'bold',
                      color: businessUnit.active ? '#222' : '#5a5a5ade',
                      position: 'absolute',
                      bottom: '0px',
                      left: '0',
                      opacity: businessUnit.active ? '1' : '.5',
                    }}
                  >
                    {businessUnit.displayName || businessUnit.name}
                  </span>
                  <span
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      color: businessUnit.active
                        ? scssVariables.colorPrimary
                        : '#5a5a5ade',
                      opacity: businessUnit.active ? '1' : '.5',
                    }}
                  >
                    {account.mid}
                  </span>
                  <span
                    style={{
                      fontSize: '10px',
                      color: businessUnit.active
                        ? scssVariables.colorPrimary
                        : '#5a5a5ade',
                      position: 'absolute',
                      top: '9px',
                      right: '0',
                      fontWeight: 'normal',
                    }}
                  >
                    {businessUnit.active ? 'Active' : 'Inactive'}
                  </span>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BusinessUnits;
