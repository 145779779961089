import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessUnitsSearch from '../components/adminDetailPanel/BusinessUnitsSearch';
import _ from 'lodash';
import scssVariables from '../styles/variables.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
};

const containsText = (account, properties, searchText) => {
  searchText = searchText?.toString();
  return (
    !searchText ||
    account?.mid?.toString()?.toLowerCase()?.indexOf(searchText.toLowerCase()) >
      -1 ||
    account?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) > -1 ||
    account?.displayName?.toLowerCase()?.indexOf(searchText.toLowerCase()) >
      -1 ||
    account?.enterprise?.name
      ?.toLowerCase()
      ?.indexOf(searchText.toLowerCase()) > -1 ||
    account?.enterprise?.displayName
      ?.toLowerCase()
      ?.indexOf(searchText.toLowerCase()) > -1 ||
    properties?.RepositoryName?.value
      ?.toLowerCase()
      ?.indexOf(searchText.toLowerCase()) > -1
  );
};

const DataExtensionsBrowseSwitcher = ({
  availableAccounts,
  properties,
  mid,
}) => {
  const [searchText, setSearchText] = useState('');
  const [currentMid, setCurrentMid] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentMid(mid);
  }, [mid]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSearchText('');
    }, 300);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const displayedBusinessUnits = useMemo(() => {
    const byIndex = (bu) => {
      const index = (bu.displayName || bu.name)
        ?.toLowerCase()
        .indexOf(searchText);
      return index >= 0 ? index : 1e9;
    };

    const filtered = availableAccounts.filter((account) =>
      containsText(account, properties[account.mid], searchText)
    );
    return _.sortBy(filtered, byIndex, (bu) =>
      (bu.displayName || bu.name)?.toLowerCase()
    );
  }, [searchText, availableAccounts, properties]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<BusinessIcon />}
        onClick={handleOpen}
      >
        Query on other BUs
      </Button>
      <FormControl sx={{ m: 1, width: 0, height: 0 }}>
        <Select
          sx={{ minHeight: '56px' }}
          labelId="demo-chip-label"
          id="demo-chip"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currentMid}
          // onChange={handleChange}
          MenuProps={MenuProps}
          style={{
            opacity: '0',
            pointerEvents: 'none',
            transform: 'translate(63px, -80px)',
          }}
        >
          <ListSubheader sx={{ padding: '0' }}>
            <BusinessUnitsSearch setSearchText={setSearchText} />
          </ListSubheader>
          {displayedBusinessUnits.map((account) => {
            const businessUnit = availableAccounts.find(
              (acc) => acc.id === account.id
            );
            const path = window.location.href + window.location.search;
            const newUrl = path.replace(
              currentMid.toString(),
              account.mid.toString()
            );

            const excessiveAccountName =
              account?.displayName?.length > 30 || account?.name?.length > 30;
            let tooltip;
            let shortenedName = account.displayName
              ? account.displayName
              : account.name;
            if (excessiveAccountName) {
              tooltip = account.displayName
                ? account.displayName
                : account.name;
              if (account.displayName) {
                shortenedName = account?.displayName?.slice(0, 30) + '...';
              }
              if (account.name) {
                shortenedName = account?.name?.slice(0, 30) + '...';
              }
            }

            return (
              <MenuItem
                key={account.mid}
                value={account.mid}
                style={{
                  padding: '0rem',
                  display: 'block',
                  borderBottom: '1px solid rgb(219 219 219)',
                }}
              >
                <a
                  href={newUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: ' space-between',
                    alignItems: 'center',
                    padding: '1.5rem .5rem',
                    textDecoration: 'none',
                  }}
                >
                  {tooltip ? (
                    <Tooltip title={tooltip} placement="top">
                      <span
                        style={{
                          fontSize: '13px',

                          fontWeight: 'bold',
                          color: businessUnit.active ? '#222' : '#5a5a5ade',
                          opacity: businessUnit.active ? '1' : '.5',
                        }}
                      >
                        {shortenedName}
                      </span>
                    </Tooltip>
                  ) : (
                    <span
                      style={{
                        fontSize: '13px',
                        position: 'absolute',
                        fontWeight: 'bold',
                        color: businessUnit.active ? '#222' : '#5a5a5ade',
                        opacity: businessUnit.active ? '1' : '.5',
                      }}
                    >
                      {businessUnit.displayName || businessUnit.name}
                    </span>
                  )}
                  <span
                    style={{
                      fontSize: '10px',
                      fontWeight: 'bold',
                      color: businessUnit.active
                        ? scssVariables.colorPrimary
                        : '#5a5a5ade',
                      position: 'absolute',
                      top: '6px',
                      right: '7px',
                      opacity: businessUnit.active ? '1' : '.5',
                    }}
                  >
                    {account.mid}
                  </span>
                </a>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DataExtensionsBrowseSwitcher;
